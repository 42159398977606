import moment, { Moment } from 'moment';
import React from 'react';
import { AppointmentControls } from '../../../Components/Appointment/AppointmentControls';
import { EditAppointmentLink } from '../../../Components/Appointment/EditAppointmentLink';
import { JoinAppointmentButton } from '../../../Components/Appointment/JoinAppointmentButton';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import { AdminAppointmentsQuery, Permission, useAdminAppointmentsQuery } from '../../../graphQL';
import { apptStatusText as AptStatusText } from '../../VideoCall/styles';
import { getUpcomingAppts, Restricted, useDrilldownContext, ViewWarning } from '../helpers';
import { Styles } from '../styles';

type Appointment = AdminAppointmentsQuery['adminAppointments'][number] & { startMoment: Moment };
export const resolvedApptStatuses = ['rescheduled', 'cancelled', 'complete', 'no show'];

export const AppointmentsTab = () => {
  const { user, refetch: refetchDrilldown } = useDrilldownContext();
  const {
    data,
    loading,
    refetch: refetchAppts,
  } = useAdminAppointmentsQuery({
    variables: { userId: user.id },
  });
  if (loading) return <LoadingPage />;

  const refetch = () => {
    refetchDrilldown();
    refetchAppts();
  };

  const tz = moment.tz.guess();
  const appointments: Appointment[] = data
    ? data.adminAppointments.map(i => ({ ...i, startMoment: moment(i.startTime).tz(tz) }))
    : [];

  appointments.sort((a, b) => b.startMoment.diff(a.startMoment));

  const upcomingAppointments = getUpcomingAppts(appointments);

  const upcomingIds = new Set(upcomingAppointments.map(i => i.id));
  const allAppointments = appointments.filter(i => !upcomingIds.has(i.id));

  return (
    <div>
      <ViewWarning limited restricted>
        Due to limited record access, some appointments may not be visible.
      </ViewWarning>

      {/* show widget if there is an upcoming appointment that a restricted user can see (intake) */}
      <Restricted override={upcomingAppointments.length > 0}>
        <Styles.widget>
          <Text.h2>Upcoming Appointments</Text.h2>
          {upcomingAppointments.length > 0 && (
            <AppointmentsList appointments={upcomingAppointments} refetch={refetch} expanded />
          )}
          {upcomingAppointments.length === 0 && (
            <Text.body>
              <em>No upcoming appointments</em>
            </Text.body>
          )}
        </Styles.widget>
      </Restricted>
      <Styles.widget>
        <Text.h2>All Appointments</Text.h2>
        {allAppointments.length === 0 ? (
          <Text.body>
            <em>No past appointments</em>
          </Text.body>
        ) : (
          <AppointmentsList appointments={allAppointments} refetch={refetch} expanded={false} />
        )}
      </Styles.widget>
    </div>
  );
};

type AptListItem = AdminAppointmentsQuery['adminAppointments'][number] & { startMoment: Moment };

interface AppointmentsListProps {
  appointments: Array<AptListItem>;
  expanded: boolean;
  refetch: () => void;
}
function AppointmentsList({ appointments, refetch, expanded }: AppointmentsListProps) {
  const { currentProvider, hasPermission } = useCurrentProvider();
  return (
    <div>
      {appointments.map(appt => (
        <Styles.appointmentItem
          key={appt.startMoment.valueOf().toString()}
          faded={appt.status === 'no show'}
        >
          <div className="flex justify-between">
            <div>
              <StatusIndicator appointment={appt} />
              <Text.bodyBold>{appt.startMoment.format('M/D/YYYY h:mm a z')}</Text.bodyBold>
              <Text.body>
                {appt.description || 'Appointment'} (
                {moment(appt.endTime).diff(appt.startMoment, 'minutes')} min) with{' '}
                {appt.provider?.name ?? 'Unknown'}
              </Text.body>
            </div>
            {!expanded &&
              (currentProvider.id === appt.provider.id ||
                hasPermission(Permission.MantraAdmin)) && (
                <div className="o-50">
                  <EditAppointmentLink appointment={appt} onEdit={refetch} iconOnly />
                </div>
              )}
          </div>

          {expanded &&
            appt.status === 'upcoming' &&
            (currentProvider.id === appt.provider.id || hasPermission(Permission.MantraAdmin)) && (
              <div className="flex justify-between items-end mt4">
                <AppointmentControls appointment={appt} refetch={refetch} />
                <PermsOnly allowed={Permission.PatientVideo}>
                  <div>
                    <JoinAppointmentButton
                      appointmentId={appt.id}
                      providerId={appt.provider.id}
                      userId={appt.user.id}
                    >
                      Join Appointment
                    </JoinAppointmentButton>
                  </div>
                </PermsOnly>
              </div>
            )}
        </Styles.appointmentItem>
      ))}
    </div>
  );
}

const StatusIndicator = ({ appointment }: { appointment: Appointment }) => {
  switch (appointment.status) {
    case 'no show':
      return <AptStatusText.noShow>No Show</AptStatusText.noShow>;
    case 'complete':
      return <AptStatusText.completed>Completed</AptStatusText.completed>;
    case 'rescheduled':
      return <AptStatusText.rescheduled>Rescheduled</AptStatusText.rescheduled>;
    case 'cancelled':
      return <AptStatusText.cancelled>Cancelled</AptStatusText.cancelled>;
    case 'upcoming':
    case 'unknown':
    default:
      return null;
  }
};
