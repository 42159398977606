import { Avatar } from 'baseui/avatar';
import React from 'react';
import { isMcpOrReferralUser } from '../../Components/Permissions';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { Text } from '../../globalStyles';
import { SimpleAdminProviderQuery } from '../../graphQL';
import { formatProviderName } from '../../modelUtils/provider';
import * as Styles from './Styles';

interface Props {
  provider: SimpleAdminProviderQuery['adminProvider'];
}

export function ProviderProfileHeader({ provider }: Props) {
  const mcpUser = isMcpOrReferralUser(provider);

  return (
    <Styles.HeaderWrapper shadow={mcpUser}>
      <div style={{ display: 'flex', position: 'relative' }}>
        {provider.portrait?.url && (
          <div className="mt2 mr3">
            <Avatar src={provider.portrait?.url} name={provider.name} size="scale1200" />
          </div>
        )}
        <div style={{ flex: '1' }}>
          <Text.h1>{formatProviderName(provider)}</Text.h1>
          {!mcpUser && (
            <Text.label>
              {provider.geoStates.length > 0
                ? `Provider (${provider.geoStates.join(', ')})`
                : 'Provider'}
            </Text.label>
          )}
          {mcpUser && (
            <>
              <Text.body>
                {provider.classification}, {provider.organizations[0].name}
              </Text.body>
              <Text.bodyGrey>{provider.pronouns}</Text.bodyGrey>
            </>
          )}
        </div>
        {mcpUser && provider.organizations.length > 0 && (
          <UniversityLogo organizationId={provider.organizations[0].id} top="10px" />
        )}
      </div>
    </Styles.HeaderWrapper>
  );
}
