import { Tab, Tabs } from 'baseui/tabs';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { Permission } from '../../graphQL';
import { Overrides } from '../Users/baseuiOverrides';
import { CreateTestPatient } from './CreateTestPatient';
import { DebugButton } from './DebugButton';
import { DebugColors } from './DebugColors';
import { DebugForm } from './DebugForm';
import { DebugIcons } from './DebugIcons';
import { DebugSVGs } from './DebugSVGs';
import { DebugUsers } from './DebugUsers';

export function DebugPage() {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { featureFlags, hasPermission } = useCurrentProvider();

  const tabs = [
    { title: 'button', component: DebugButton },
    { title: 'colors', component: DebugColors },
    { title: 'icons', component: DebugIcons },
    { title: 'SVGs', component: DebugSVGs },
    {
      title: 'create_user',
      component: CreateTestPatient,
      hidden: !hasPermission(Permission.MantraAdmin),
    },
    { title: 'users', component: DebugUsers },
    { title: 'form', component: DebugForm },
  ];

  const permissableTabs = tabs.filter(t => !t.hidden);
  const activeTab = permissableTabs.find(t => t.title === id)?.title ?? permissableTabs[0].title;

  if (!featureFlags.includes('SEED')) throw new Error('');
  return (
    <div>
      <div className="ph4 pv3 bg-white">
        <Text.h3>Debug Tools</Text.h3>
      </div>
      <Tabs
        activeKey={activeTab}
        onChange={({ activeKey }) => history.replace(`/debug/${activeKey}`)}
        overrides={Overrides.Tabs}
      >
        {permissableTabs.map(sub => (
          <Tab title={sub.title} key={sub.title} overrides={Overrides.Tab}>
            <sub.component />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
