import React, { useEffect } from 'react';
import { NextSteps, useCreateCareTransitionMutation } from '../../../../graphQL';
import { FinalButton } from '../../../../Components/FinalButton';
import { useDrilldownContext } from '../../helpers';
import { Text } from '../../styles';
import { COCPathProps } from './types';

export function Sponsored({ onClose, careType }: COCPathProps) {
  const { user, refetch } = useDrilldownContext();
  const [mutate, { error }] = useCreateCareTransitionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    const transition = {
      careType,
      userId: user.id,
      nextSteps: NextSteps.ActiveSponsored,
    };
    mutate({ variables: { transition } });
  }, [careType, user, mutate]);

  return (
    <div>
      {!error ? (
        <div>
          <Text.h2 className="mt3 mb4">
            Confirmed! Patient will continue with sponsored care during break.
          </Text.h2>
          <Text.body>
            Please confirm there are available contracted hours to accommodate this patient.
          </Text.body>
          <div className="tc mt4 mb1">
            <FinalButton kind="minimal_gray" onClick={onClose}>
              Close
            </FinalButton>
          </div>
        </div>
      ) : (
        <Text.h2 className="mb3">
          There was an error while trying to set the patient&apos;s account to continue sponsored
          care.
        </Text.h2>
      )}
    </div>
  );
}
