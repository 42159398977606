import React from 'react';
import { WizardRenderProps } from '../../../../Components/Wizard';
import { AppointmentOverview } from '../../Common';
import { ProviderAvailabilityList } from '../../ProviderAvailabilityList';
import { WizardData } from '../types';

export const ProviderBooking = ({ data, nextStep, prevStep }: WizardRenderProps<WizardData>) => {
  const { user, appointmentTemplate, paymentSource, insuranceDetails, careType } = data;

  return (
    <>
      <div className="flex flex-column items-center mb3 mt4">
        <div className="mb3">
          <AppointmentOverview
            apptUser={user}
            description={appointmentTemplate.description}
            duration={appointmentTemplate.duration}
            paymentSource={paymentSource}
            insuranceDetails={insuranceDetails}
          />
        </div>
      </div>
      <ProviderAvailabilityList
        appointmentTemplate={appointmentTemplate}
        user={user}
        onBack={() => prevStep()}
        onSelectAvailability={({ startTime, endTime, provider, organizationId }) => {
          return nextStep(prevData => ({
            ...prevData,
            appointment: {
              startTime,
              endTime,
              provider,
              careType,
              organizationId,
            },
          }));
        }}
      />
    </>
  );
};
