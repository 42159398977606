import { useParams } from 'react-router-dom';
import { titleCase } from '../../utils';

export function formatActivityStatus(org: { activityStatus: string }) {
  return titleCase(org.activityStatus.replace(/-/g, ' '));
}

export function useOrganizationId() {
  const { organizationId } = useParams<{ organizationId: string }>();
  return Number(organizationId);
}

export interface OrgTabProps {
  setActiveTab: (tab: string) => void;
  refetchHeader: () => void;
}
