import React from 'react';
import { colorMap, Text } from '../../globalStyles';

export const DebugColors = () => {
  return (
    <div className="mb5">
      {Object.entries(colorMap).map(([k, v]) => {
        return (
          <div key={k} className="mb4">
            <h3>{k}</h3>
            <div className="flex">
              {Array.from(v).map(c => (
                <div
                  key={c}
                  className="w4 h4 flex flex-column justify-center items-center"
                  style={{ backgroundColor: c }}
                >
                  <Text.body>{c}</Text.body>
                  <Text.bodyGrey>{c}</Text.bodyGrey>
                  <p className="white f5 ma0 pa0">{c}</p>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
