import { ApolloError } from '@apollo/client';
import { Value } from '../../Components/Form/Select/types';
import { useCurrentProvider } from '../../Components/Permissions';
import { OrganizationEntitlement, Permission, useAdminCheckQuery } from '../../graphQL';
import { useQueryParams } from '../../Hooks';
import { isProviderSuperAdmin } from '../../modelUtils/provider';
import { getProviderOrganizationOptions } from '../Provider/util';

interface OrgItems {
  label: string;
  id: Value;
}

interface AdminResults {
  loading: boolean;
  canSelectCampus: boolean;
  orgOptions: OrgItems[];
  organizationId: number;
  error?: ApolloError;
  entitlementsByOrg?: { [x: number]: Array<OrganizationEntitlement> | any };
}

export const useAdminCheck = (): AdminResults => {
  const { currentProvider, hasPermission } = useCurrentProvider();
  const [queryParams] = useQueryParams();

  let organizationId = Number(queryParams.organizationId) || 0;

  const { data, loading, error } = useAdminCheckQuery({
    variables: { organizationId, isMantraAdmin: hasPermission(Permission.MantraAdmin) },
  });

  if (hasPermission(Permission.MantraAdmin) && !organizationId && data?.adminOrgs) {
    organizationId = Number(data?.adminOrgs[0].id);
  }

  if (hasPermission(Permission.MantraAdmin) && data?.adminOrgs) {
    const entitlementsByOrg = data?.adminOrgs.reduce(
      (acc, curr) =>
        Object.assign(acc, {
          [curr.id]: [
            ...curr.entitlements.map(e => {
              return { id: curr.id, name: curr.name, ...e };
            }),
          ],
        }),
      {}
    );
    return {
      loading,
      canSelectCampus: true,
      orgOptions: data.adminOrgs.map(o => ({ label: o.name, id: String(o.id) })),
      organizationId,
      entitlementsByOrg,
      error,
    };
  }

  const userData = getProviderOrganizationOptions(currentProvider, organizationId)!;

  return {
    loading,
    canSelectCampus: userData.length > 1,
    orgOptions: userData.map(o => ({ label: o.name || o.label, id: String(o.id) })),
    organizationId,
    entitlementsByOrg:
      isProviderSuperAdmin(currentProvider) && userData.length > 1 && data?.singleOrg
        ? userData.reduce(
            (acc, curr) => ({
              ...acc,
              [String(curr.id)]: data?.singleOrg?.entitlements,
            }),
            {}
          )
        : { [organizationId]: data?.singleOrg?.entitlements },
    error,
  };
};
