import React, { useEffect, useState } from 'react';
import { useCurrentProvider } from '../../../Components/Permissions';
import { ConsentTimeline } from './ConsentTimeline';

export const Debug = () => {
  const { featureFlags } = useCurrentProvider();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!featureFlags.includes('DEBUG_TOOLS')) return;

    const onKeyDown = (e: KeyboardEvent) => {
      const isD = e.key === 'd' || e.key === 'D' || e.code === 'KeyD';
      if (e.altKey && e.shiftKey && isD) setIsOpen(o => !o);
    };
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [featureFlags, setIsOpen]);

  if (!isOpen) return null;
  return (
    <div className="mb4 mt2">
      <h4 className="tc mb4">Patient Debug Tools</h4>
      <ConsentTimeline />
    </div>
  );
};
