import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import React, { useCallback } from 'react';
import { AsyncListView } from '../../Components/ListView/AsyncListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  Entitlement,
  SortDirection,
  StudentsDocument,
  StudentsQuery,
  StudentsQueryVariables,
  useStudentListOptionsQuery,
} from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import { UnexpectedError } from '../Shared';
import { taskStatusOptions } from '../UserList/Shared';
import { useOrganizationId } from './util';

export function OrganizationPatients() {
  const organizationId = useOrganizationId();
  const {
    data: optData,
    loading: optDataLoading,
    error: optDataError,
  } = useStudentListOptionsQuery({
    variables: { organizationId },
  });
  const apolloClient = useApolloClient();

  const loadData = useCallback(
    async (uiState, prevPage) => {
      const { data } = await apolloClient.query<StudentsQuery, StudentsQueryVariables>({
        query: StudentsDocument,
        variables: {
          params: {
            dtc: false,
            cursor: prevPage?.cursor,
            search: uiState.debouncedSearchValue,
            organizationId: uiState.filterValues?.organizationId ?? organizationId,
            taskStatus: uiState.filterValues?.taskStatus,
            sortField: uiState.sortColumn,
            sortDirection:
              uiState.sortDirection === 'desc' ? SortDirection.Descending : SortDirection.Ascending,
            limit: uiState.limit,
            includeAllPatients: true,
          },
        },
      });
      if (!data) {
        throw new Error('Could not find student data');
      }
      return data.paginatedUsers;
    },
    [apolloClient]
  );

  if (optDataLoading) return <LoadingPage />;
  if (optDataError || !optData) return <UnexpectedError />;

  const showIdentification = optData.organization.entitlements.some(
    i => i.key === Entitlement.OrgIdentification
  );

  return (
    <div>
      <AsyncListView
        fetchData={loadData}
        getKey={user => user.id}
        pageSize={10}
        initialSortColumn="name"
        initialSortDirection="asc"
        link={user => `/users/${user.id}`}
        search={(user, query) => {
          const q = query.toLowerCase();
          return (
            user.firstName.toLowerCase().includes(q) || user.lastName.toLowerCase().includes(q)
          );
        }}
        columns={[
          {
            key: 'name',
            title: 'Name',
            sort: (a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()),
            render: user => (
              <div>
                <Text.bodyBold data-cy="name" className="ttc">
                  {getFullNameReversed(user)}
                </Text.bodyBold>
                {showIdentification && (
                  <Text.captionGrey>{user.organizationIdentification ?? 'n/a'}</Text.captionGrey>
                )}
              </div>
            ),
          },
          {
            key: 'enrollmentDate',
            title: 'Enrollment Date',
            render: user => moment(user.createdAt).format('MM/DD/YYYY'),
            sort: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
          },
          {
            key: 'campus',
            title: 'Campus',
            render: user => user.organization?.name,
          },
          {
            key: 'taskStatus',
            title: 'Status',
            render: user => user.taskStatus,
          },
        ]}
        filters={[
          {
            key: 'taskStatus',
            placeholder: 'Status',
            options: taskStatusOptions,
          },
        ]}
      />
    </div>
  );
}
