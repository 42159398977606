import { flatMap, partition } from 'lodash';
import React from 'react';
import { Risk, TextIconSvg, Warning } from '../../../Components/Flair';
import { useCurrentProvider } from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import { CareDashboardQuery } from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { UnstyledLink } from '../../Shared';
import { DashboardTable } from '../DashTable';
import { EmptyState } from '../EmptyState';
import * as Styles from '../styles';

type Props = {
  users: CareDashboardQuery['adminPatientMonitoring'];
  admin?: boolean;
};

const emptyState = <EmptyState icon="safetyEvent" text="No high risk patients" />;

export const PatientMonitoring = ({ users, admin }: Props) => {
  const { currentProvider } = useCurrentProvider();
  const [withPlan, withoutPlan] = partition(users, p => p.safetyPlan);
  const [incomplete, complete] = partition(withPlan, p => !p.safetyPlan?.complete);
  const sorted = flatMap([incomplete, complete, withoutPlan]);

  return (
    <Styles.SectionContainer>
      <Text.h3>Patient Monitoring</Text.h3>
      <DashboardTable
        data={sorted}
        getKey={user => user.id}
        emptyState={emptyState}
        columns={[
          {
            render: user => (
              <UnstyledLink to={`/users/${user.id}`}>
                <Text.bodyBold className="ttc">{getFullName(user)}</Text.bodyBold>
                {[user.provider, user.therapist]
                  .filter(i => !!i && (admin || i.id !== currentProvider.id))
                  .map((p, i) => (
                    <Text.bodySmallGrey key={i}>{p?.name}</Text.bodySmallGrey>
                  ))}
                {user.organization && (
                  <Text.bodySmallGrey>
                    <TextIconSvg icon="university">{user.organization.abbreviation}</TextIconSvg>
                  </Text.bodySmallGrey>
                )}
              </UnstyledLink>
            ),
          },
          {
            render: ({ assessmentInfo, safetyPlan }) => (
              <>
                {assessmentInfo.riskLevel && (
                  <Text.bodySmall>
                    <Risk level={assessmentInfo.riskLevel}>{assessmentInfo.riskLevel}</Risk>
                  </Text.bodySmall>
                )}
                {assessmentInfo.suicidal && (
                  <Text.bodySmall>
                    <Warning>{assessmentInfo.suicidal.join(', ')}</Warning>
                  </Text.bodySmall>
                )}
                {safetyPlan && (
                  <Text.bodySmall kind={safetyPlan.complete ? 'text' : 'danger'}>
                    <TextIconSvg icon="safety" kind={safetyPlan.complete ? 'text' : 'danger'}>
                      {safetyPlan.complete ? 'Complete' : 'Incomplete'}
                    </TextIconSvg>
                  </Text.bodySmall>
                )}
              </>
            ),
          },
        ]}
      />
    </Styles.SectionContainer>
  );
};
