import moment from 'moment';
import React from 'react';
import { QuickEmailLink } from '../../../Components/Flair';
import { Text } from '../../../globalStyles';
import { CareDashboardQuery } from '../../../graphQL';
import { getFullNameReversed } from '../../../modelUtils/users';
import { dateSorterFactory } from '../../../utils';
import { UnstyledLink } from '../../Shared';
import { DashboardTable } from '../DashTable';
import { EmptyState } from '../EmptyState';
import * as Styles from '../styles';
import { EngagementPill } from './EngagementTag';

const emptyState = <EmptyState text="No actions" icon="safety" />;

type Props = {
  users: CareDashboardQuery['adminUrgentActionUsers'];
};

export const UrgentActions = ({ users }: Props) => {
  return (
    <Styles.SectionContainer>
      <Text.h3 className="mb2">Urgent Actions</Text.h3>
      <Text.captionGrey className="">
        Patients with upcoming appointments and outstanding tasks.
      </Text.captionGrey>
      <DashboardTable
        data={users}
        emptyState={emptyState}
        columns={[
          {
            title: 'Next Appointment',
            render: d => (
              <Text.bodySmall kind="danger">
                &lt; {moment(d.nextAppointmentTime!).diff(moment(), 'hours')} hours
              </Text.bodySmall>
            ),
          },
          {
            title: 'Patient',
            render: d => (
              <>
                <UnstyledLink to={`/users/${d.id}`} className="mr2">
                  <Text.bodySmall className="b">{getFullNameReversed(d)}</Text.bodySmall>
                </UnstyledLink>
                <QuickEmailLink user={d} />
              </>
            ),
          },
          {
            title: 'Care Type',
            render: d => <Text.bodySmall>{d.careTypes.join(', ')}</Text.bodySmall>,
          },
          {
            title: 'Status',
            render: d => <Text.bodySmall>{d.taskStatus}</Text.bodySmall>,
          },
          {
            title: 'Last Outreach',
            initialSort: true,
            sort: dateSorterFactory(u => u.lastOutreachAttempt, 'DESC'),
            render: ({ lastOutreachAttempt }) => (
              <Text.bodySmall>
                {lastOutreachAttempt ? moment(lastOutreachAttempt).format('L') : '--'}
              </Text.bodySmall>
            ),
          },
          {
            render: d => d.engagementTags.map(e => <EngagementPill key={e} tag={e} />),
          },
        ]}
        getKey={d => d.id}
      />
    </Styles.SectionContainer>
  );
};
