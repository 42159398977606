import { Avatar as BaseAvatar } from 'baseui/avatar';
import React from 'react';
import { Dot, TextIcon } from '../../Components/Flair';
import { Grid } from '../../Components/Grid';
import { icons } from '../../Components/Icons';
import { Link } from '../../Components/Links';
import { useCurrentProvider } from '../../Components/Permissions';
import { colors, Text } from '../../globalStyles';
import { ListChannelFragment, Provider, Upload, UserDetailFragment } from '../../graphQL';
import { getFullNameReversed } from '../../modelUtils/users';
import { ChannelType } from './ChannelList';
import { ItemContainer } from './Styles';
import { getLastSentText } from './utils';

type UserCardProps = {
  user: UserDetailFragment;
  channelType: ChannelType;
  lastMsg?: ListChannelFragment['lastMessage'];
  count?: number;
  isRead?: boolean;
};

export const UserCard = ({ user, isRead, lastMsg, count, channelType }: UserCardProps) => {
  const tab: Record<ChannelType, string> = {
    [ChannelType.Private]: 'messages',
    [ChannelType.AdminPrivate]: 'messages',
    [ChannelType.CareTeam]: 'provider-messages',
  };

  const copyMap: Record<ChannelType, (p: DetailsProps) => JSX.Element> = {
    [ChannelType.Private]: UserMessageDetails,
    [ChannelType.AdminPrivate]: UserMessageDetails,
    [ChannelType.CareTeam]: CareTeamMessageDetails,
  };

  const avatarWidthMap: Record<ChannelType, string> = {
    [ChannelType.Private]: '3.1rem',
    [ChannelType.AdminPrivate]: '3.1rem',
    [ChannelType.CareTeam]: '3.6rem',
  };

  const Copy = copyMap[channelType];
  const Avatar = channelType === ChannelType.CareTeam ? CareTeamMessageAvatar : UserMessageAvatar;

  return (
    <ItemContainer data-read={!!isRead}>
      <Link
        aria-label={`${user.firstName} ${user.lastName}`}
        className="pointer link"
        to={`/users/${user.id}?tab=${tab[channelType]}`}
      >
        <Grid gridTemplateColumns={`${avatarWidthMap[channelType]} 3fr 1fr`}>
          <Avatar user={user} provider={lastMsg?.provider} />
          <div className="flex-auto nt1">
            <Copy user={user} lastMsg={lastMsg} />
          </div>
          <div className="pl2">
            {count && (
              <Dot color={colors.primary}>
                <Text.bodyBold className="truncate" kind="primary">
                  {count} new
                </Text.bodyBold>
              </Dot>
            )}
          </div>
        </Grid>
      </Link>
    </ItemContainer>
  );
};

type DetailsProps = {
  user: UserDetailFragment;
  lastMsg?: ListChannelFragment['lastMessage'];
};

const UserMessageDetails = ({ user, lastMsg }: DetailsProps) => {
  return (
    <>
      <span className="flex items-center truncate">
        <Text.bodyBold className="truncate">{getFullNameReversed(user)}</Text.bodyBold>
        {user.organization && (
          <span className="o-50 ml2">
            <TextIcon preset="university">
              <Text.body>{user.organization.abbreviation}</Text.body>
            </TextIcon>
          </span>
        )}
      </span>
      <Text.bodySmall kind="grayText" className="mb0 mt0">
        {getLastSentText(lastMsg)}
      </Text.bodySmall>
    </>
  );
};

const UserMessageAvatar = ({ user }: { user: UserDetailFragment }) => (
  <BaseAvatar size="2.5rem" src={user.selfie?.url} name={`${user.firstName} ${user.lastName}`} />
);

const CareTeamMessageDetails = ({ user, lastMsg }: DetailsProps) => {
  const { currentProvider } = useCurrentProvider();

  return (
    <>
      <Text.body className="nb1 truncate">
        <span>
          {(() => {
            if (lastMsg?.provider?.id === currentProvider.id) return 'You';
            if (lastMsg?.provider) return lastMsg.provider.name;
            return 'A Provider';
          })()}
        </span>{' '}
        left a message
      </Text.body>
      <Text.body className="truncate">on {getFullNameReversed(user)}&apos;s profile</Text.body>
      <Text.bodySmall kind="grayText" className="mb0">
        {getLastSentText(lastMsg)}
      </Text.bodySmall>
    </>
  );
};

const CareTeamMessageAvatar = ({
  provider,
  user,
}: {
  user: UserDetailFragment;
  provider?: (Pick<Provider, 'name'> & { portrait?: Pick<Upload, 'url'> | null }) | null;
}) => (
  <div className="relative pr2">
    <BaseAvatar
      size="2.5rem"
      src={provider?.portrait?.url ?? icons.assetsDefaultPicturePng}
      name={provider?.name ?? 'Provider'}
    />
    <BaseAvatar
      size="scale800"
      src={user.selfie?.url}
      name={`${user.firstName} ${user.lastName}`}
      overrides={{
        Root: {
          style: {
            position: 'absolute',
            left: '1.5rem',
            top: '1.5rem',
            boxShadow: '0 0 0px 2px white, 0 0 0px 1.5px white',
          },
        },
        Initials: {
          style: {
            fontSize: '13px',
          },
        },
      }}
    />
  </div>
);
