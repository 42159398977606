import React from 'react';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage } from '../../globalStyles';
import { Permission, useCareDashboardQuery } from '../../graphQL';
import { AssignedTasks } from './Widgets/AssignedTasks';
import { Availability } from './Widgets/Availability';
import { IncompletePlans } from './Widgets/IncompletePlans';
import { PatientMonitoring } from './Widgets/PatientMonitoring';
import { AppointmentsToday } from './Widgets/Today';
import { TwoFactorVerifyWidget } from './Widgets/TwoFactorVerifyWidget';
import { UnsubmittedNotes } from './Widgets/UnsubmittedNotes';
import { UrgentActions } from './Widgets/UrgentActions';

export const Home = () => {
  const { hasPermission } = useCurrentProvider();

  /*
  As of 01/14/22 SuperAdmins should not have access to "/home" instead redirect to "/dashboard"
  if (isProviderSuperAdmin(currentProvider) && !isReportingDisabled(currentProvider))
    return <SuperAdminDashboard />;
  */
  if (hasPermission(Permission.MantraAdmin)) return <MantraAdminDashboard />;
  if (hasPermission(Permission.CareDashboards)) return <ProviderDashboard />;
  return null;
};

const ProviderDashboard = () => {
  const { data, loading } = useCareDashboardQuery({ variables: { admin: false } });

  if (loading) return <LoadingPage />;
  if (!data) return null;
  return (
    <PaddedPage>
      <div className="fl w-100 w-60-ns pr4-ns">
        <TwoFactorVerifyWidget />
        <AppointmentsToday appointments={data.adminAppointmentsToday} />
        <AssignedTasks />
        <div>
          <div className="fl w-50-l pr3-l w-100">
            <UnsubmittedNotes notes={data.unsubmittedMedicalNotes} />
          </div>
          <div className="fl w-50-l pl3-l w-100">
            <IncompletePlans plans={data.safetyPlans} />
          </div>
        </div>
      </div>
      <div className="fl w-100 w-40-ns">
        <Availability allocations={data.adminTimeAllocationsToday} />
        <PatientMonitoring users={data.adminPatientMonitoring} />
      </div>
    </PaddedPage>
  );
};

const MantraAdminDashboard = () => {
  const { data, loading } = useCareDashboardQuery({ variables: { admin: true } });

  if (loading) return <LoadingPage />;
  if (!data) return null;

  return (
    <PaddedPage>
      <div className="fl w-100 w-60-ns pr4-ns">
        <TwoFactorVerifyWidget />
        <AppointmentsToday appointments={data.adminAppointmentsToday} admin />
        <UrgentActions users={data.adminUrgentActionUsers} />
        <AssignedTasks />
      </div>
      <div className="fl w-100 w-40-ns">
        <Availability allocations={data.adminTimeAllocationsToday} admin />
        <PatientMonitoring users={data.adminPatientMonitoring} admin />
      </div>
    </PaddedPage>
  );
};
