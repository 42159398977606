import React from 'react';
import { FinalButton, FinalButtonProps } from '../FinalButton';
import { InlineSVG, InlineSVGProps } from '../Icons';

type VideoPageIconButtonProps = Omit<FinalButtonProps, 'iconLeft' | 'iconRight'> & {
  icon: Pick<InlineSVGProps, 'icon' | 'kind' | 'alt' | 'width' | 'height'>;
  toggled: boolean;
};
export const VideoPageIconButton = ({
  icon,
  toggled,
  kind,
  ...props
}: VideoPageIconButtonProps) => {
  const localKind = kind || (toggled ? 'outline_white' : 'danger');

  return (
    <FinalButton kind={localKind} size="normal" pill {...props}>
      <InlineSVG width={18} height={18} {...icon} />
    </FinalButton>
  );
};
