import React from 'react';
import { getLabeledDiagnosisCode } from '../../../Components/MedicalNote/diagnosisCodes';
import { MedicalNotePopover } from '../../../Components/MedicalNote/PopoverNote';
import { PermsOnly } from '../../../Components/Permissions';
import { usePopoverTabContext } from '../../../Components/PopoverTabs/PopoverTabContainer';
import { Permission, UserRecordViewStatus } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';

export const Diagnoses = () => {
  const { assessmentInfo } = useDrilldownContext().user;
  if (!assessmentInfo) return null;
  const { diagnoses } = assessmentInfo;

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Diagnoses</Text.h3>
        <PermsOnly allowed={Permission.Prescribe}>
          <OpenNote noteKey="edit-diagnoses" label="Edit Diagnoses">
            Edit
          </OpenNote>
        </PermsOnly>
      </Styles.sidebarSectionHeader>
      {diagnoses && diagnoses.length ? (
        diagnoses.map((d, i) => (
          <Text.body key={`${d}-${i}`} className="mb2">
            <Text.label as="span">{i + 1}. </Text.label>
            {getLabeledDiagnosisCode(d)}
          </Text.body>
        ))
      ) : (
        <Text.body>No Diagnoses</Text.body>
      )}
    </Styles.sidebarSection>
  );
};

export const OtherMedication = () => {
  const { assessmentInfo } = useDrilldownContext().user;
  if (!assessmentInfo) return null;
  const { otherMeds } = assessmentInfo;

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Other Medications</Text.h3>
        <PermsOnly allowed={Permission.Prescribe}>
          <OpenNote noteKey="edit-additional-medication" label="Edit Additional Medication">
            Edit
          </OpenNote>
        </PermsOnly>
      </Styles.sidebarSectionHeader>
      {otherMeds && otherMeds.length ? (
        otherMeds.map((d, i) => (
          <Text.body key={`${d}-${i}`} className="mb2">
            <Text.label as="span">{i + 1}. </Text.label>
            {d}
          </Text.body>
        ))
      ) : (
        <Text.body>No other medication specified</Text.body>
      )}
    </Styles.sidebarSection>
  );
};

export const Allergies = () => {
  const { assessmentInfo } = useDrilldownContext().user;
  if (!assessmentInfo) return null;
  const { allergies } = assessmentInfo;

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Allergies</Text.h3>
        <PermsOnly allowed={Permission.NoteCreate}>
          <OpenNote noteKey="edit-allergies" label="Edit Allergies">
            Edit
          </OpenNote>
        </PermsOnly>
      </Styles.sidebarSectionHeader>
      {allergies && allergies.length ? (
        allergies.map((a, i) => (
          <Text.body kind="danger" key={`${a}-${i}`}>
            {a}
          </Text.body>
        ))
      ) : (
        <Text.body>No allergies reported</Text.body>
      )}
    </Styles.sidebarSection>
  );
};

export const WellnessGoals = () => {
  const { user, userView } = useDrilldownContext();
  const { assessmentInfo } = user;
  if (!assessmentInfo) return null;
  const { recordViewStatus } = userView;
  const { wellnessGoals } = assessmentInfo;
  if (recordViewStatus === UserRecordViewStatus.Limited && !wellnessGoals) return null;

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Wellness Goals</Text.h3>
        <PermsOnly allowed={Permission.NoteCreate}>
          <OpenNote label="Edit Wellness Goals" noteKey="edit-wellness-goals">
            Edit
          </OpenNote>
        </PermsOnly>
      </Styles.sidebarSectionHeader>
      {wellnessGoals && wellnessGoals.length
        ? wellnessGoals.map((goal, i) => (
            // eslint-disable-next-line react/jsx-indent
            <Text.body key={i + goal} className="mb2">
              <Text.label as="span">{i + 1}. </Text.label>
              {goal}
            </Text.body>
          ))
        : 'No Wellness Goals'}
    </Styles.sidebarSection>
  );
};

export const AdditionalProviders = () => {
  const { assessmentInfo } = useDrilldownContext().user;
  if (!assessmentInfo) return null;
  const { additionalProviderContact, additionalProviderName } = assessmentInfo;

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Additional Providers</Text.h3>
      </Styles.sidebarSectionHeader>
      {additionalProviderName ? (
        <>
          <Text.bodyBold>{additionalProviderName}</Text.bodyBold>
          <Text.body>{additionalProviderContact}</Text.body>
        </>
      ) : (
        'Not specified'
      )}
    </Styles.sidebarSection>
  );
};

const OpenNote: React.FC<{ noteKey: string; label: string }> = ({ noteKey, label, children }) => {
  const { pushTab } = usePopoverTabContext();

  const handleItemSelect = () =>
    pushTab({
      tabKey: noteKey,
      title: label,
      renderBody: props => <MedicalNotePopover noteKey={noteKey} {...props} />,
    });

  return <Text.linkButton onClick={handleItemSelect}>{children}</Text.linkButton>;
};
