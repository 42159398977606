import React from 'react';
import { Text, colors } from '../../globalStyles';
import { Styles } from '../../Pages/Users/styles';
import { InlineSVG } from '../Icons';

/** Widget informing user that referral does not go over credit limit */
export const ReferralCreditsUsageSufficient = ({
  referralCost,
  therapyCreditWeight,
  psychiatryCreditWeight,
}: {
  referralCost?: number;
  therapyCreditWeight?: number;
  psychiatryCreditWeight?: number;
}) => {
  if (!referralCost || !therapyCreditWeight || !psychiatryCreditWeight) {
    return null;
  }
  return (
    <Styles.widget color={colors.success} className="flex" style={{ marginBottom: 0 }}>
      <InlineSVG icon="alert-circle" kind="success" size={24} className="mr2 mt1" />
      <div style={{ flex: 1 }}>
        <Text.bodyBold>
          Submitting this referral will use {String(referralCost)} credit
          {referralCost === 1 ? '' : 's'}
        </Text.bodyBold>
        <Text.bodySmall>
          Reminder: {therapyCreditWeight} credit{therapyCreditWeight === 1 ? '' : 's'} per Therapy
          referral; {psychiatryCreditWeight} credit{psychiatryCreditWeight === 1 ? '' : 's'} per
          Psychiatry referral
        </Text.bodySmall>
      </div>
    </Styles.widget>
  );
};
