import { useEffect, useState } from 'react';
import { useGetAsyncJobLazyQuery } from '../../graphQL';
import { useEvents } from '../Events/EventsProvider';

export const useAsyncJob = () => {
  const metrics = useEvents();
  const [inProgress, setInProgress] = useState(false);
  const [pollJob, { data: jobData, stopPolling, error }] = useGetAsyncJobLazyQuery({
    pollInterval: 200,
  });

  // keep polling until job is complete and open file
  useEffect(() => {
    if (error || jobData?.adminGetJob.status === 'error') {
      if (stopPolling) stopPolling();
      setInProgress(false);
    }
    if (jobData?.adminGetJob.url) {
      if (stopPolling) stopPolling();
      window.location.assign(jobData.adminGetJob.url);
      metrics.track(`export.completed`, { jobId: jobData.adminGetJob.id });
      setInProgress(false);
    }
  }, [jobData, setInProgress, stopPolling, error, metrics]);

  // cancel polling on unmount
  useEffect(() => {
    return () => stopPolling?.();
  }, [stopPolling]);

  const beginJobPoll = async (jobId: number) => {
    setInProgress(true);
    metrics.track(`export.started`, { jobId });
    pollJob({ variables: { jobId } });
  };
  return [beginJobPoll, { status: jobData?.adminGetJob.status, inProgress }] as const;
};
