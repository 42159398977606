import { Modal } from 'baseui/modal';
import React from 'react';
import { FinalButton } from '../../Components/FinalButton';
import { Text } from '../../globalStyles';
import { useTriggerPasswordlessAuthFlowMutation } from '../../graphQL';

interface Props {
  email: string;
  onClose: () => void;
  onResendActivation: () => void;
}

export function DuplicateEmailModal({ email, onClose, onResendActivation }: Props) {
  const [mutate, { loading }] = useTriggerPasswordlessAuthFlowMutation({
    onCompleted: onResendActivation,
  });

  const onResend = () => {
    mutate({ variables: { email } });
  };

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <div className="pa5">
        <Text.h2>A student with this email has already been referred</Text.h2>
        <Text.body className="mt3">
          We cannot add this student to your referral list because the email {email} has already
          been referred.
        </Text.body>
        <Text.body className="mt3">
          If the student has not completed their Mantra account setup, you can resend their account
          activation details to their email.
        </Text.body>
        <div className="flex flex-column gap-3 mt4">
          <FinalButton kind="outline_black" onClick={onResend} loading={loading}>
            Resend activation details
          </FinalButton>
          <FinalButton kind="minimal_gray" onClick={onClose}>
            Use different email address
          </FinalButton>
        </div>
      </div>
    </Modal>
  );
}
