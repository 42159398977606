import React, { useState } from 'react';
import * as Styles from '../styles';
import moment from 'moment-timezone';
import { Text, colors } from '../../../globalStyles';
import { UniversityLogo } from '../../../Components/Organization/UniversityLogo';
import { FinalButton } from '../../../Components/FinalButton';
import { Notification } from '../../../Components/Notification';
import { WarningWithIcon } from '../../../Components/Warning';
import { FormProvider, useForm } from 'react-hook-form';
import { InputRHF, DateInputRHF, NestedSelectRHF } from '../../../Components/Form';
import { Label } from '../../ProviderNetwork/EnrollModal/Common';
import { PermsOnly } from '../../../Components/Permissions/Only';
import {
  Permission,
  ReferralPartners,
  ReferralSource,
  useCreatePartnerReferralMutation,
} from '../../../graphQL';
import { useCurrentProvider } from '../../../Components/Permissions';
import { useHistory } from 'react-router-dom';
import { useOrganizationId } from '../../Organizations/util';

type CharlieHealthEnrollForm = {
  firstName: string;
  lastName: string;
  birthDate: string;
  preferredName?: string;
  organizationId?: number;
};

function isBirthdateEligibleForCharlieHealth(birthdate: string): boolean {
  const age = moment().diff(moment(birthdate), 'years');
  return age >= 18 && age <= 33;
}

export function EnrollOptionCharlieHealth() {
  const required = 'This field is required.';
  const [showDateWarning, setShowDateWarning] = useState(false);
  const [showPreferredName, setShowPreferredName] = useState(false);
  const [createPartnerReferral, { error, loading }] = useCreatePartnerReferralMutation();
  const { currentProvider } = useCurrentProvider();
  const history = useHistory();
  const form = useForm<CharlieHealthEnrollForm>();

  const pageOrgId = useOrganizationId();
  const providerOrganization = currentProvider.organizations.find(org => org.id === pageOrgId);
  const isSuperOrg = providerOrganization?.children && providerOrganization?.children.length > 0;

  const submit = form.handleSubmit(async fields => {
    if (!providerOrganization) {
      return;
    }
    const iopCreated = await createPartnerReferral({
      variables: {
        data: {
          firstName: fields.firstName,
          lastName: fields.lastName,
          // format date to YYYY-MM-DD so its uniform across the database
          birthDate: moment(fields.birthDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          preferredName: fields.preferredName,
          providerId: currentProvider.id,
          referralSource: ReferralSource.Mcp,
          partner: ReferralPartners.CharlieHealth,
          organizationId: fields.organizationId || providerOrganization.id,
        },
      },
    });
    if (iopCreated) {
      window.open('https://www.charliehealth.com/mantra');
      history.push('/');
    }
  });

  const validateDate = (value: string) => {
    if (isBirthdateEligibleForCharlieHealth(value)) {
      setShowDateWarning(false);
      return true;
    } else {
      if (moment(value, 'MM/DD/YYYY', true).isValid()) {
        setShowDateWarning(true);
      }
      return 'Must enter a valid date';
    }
  };

  return (
    <Styles.Background>
      <Styles.Wrapper>
        <UniversityLogo />
        {error && <Notification kind="negative">{error.message}</Notification>}
        <Text.h1>IOP Referral</Text.h1>
        <PermsOnly allowed={Permission.PatientCreate}>
          <Text.h4grey>
            IOP Referrals will be sent to our partner, Charlie Health, and will not be managed by
            Mantra Health or within the MCP.
          </Text.h4grey>

          {showDateWarning && (
            <Styles.AlertBox className="mt4">
              <Text.h4 className="pa3" style={{ color: colors.danger }}>
                Patient must be between 18 years and 33 years old to access the requested care.
              </Text.h4>
            </Styles.AlertBox>
          )}

          <FormProvider {...form}>
            <Text.h2 className="mt4 mb4">
              Please let us know the name of the student you are referring to IOP
            </Text.h2>
            <Styles.FormSection>
              <InputRHF
                name="firstName"
                controlProps={{ label: 'Legal First Name', required: true }}
                rules={{ required }}
              />
              <InputRHF
                name="lastName"
                controlProps={{ label: 'Legal Last Name', required: true }}
                rules={{ required }}
              />
              {showPreferredName ? (
                <Label name="preferredName" label="Preferred Name" className="mb0">
                  <InputRHF name="preferredName" />
                </Label>
              ) : (
                <Text.linkButton className="b tl" onClick={() => setShowPreferredName(true)}>
                  + Add Preferred Name
                </Text.linkButton>
              )}
            </Styles.FormSection>
            <Styles.FormSection>
              <DateInputRHF
                name="birthDate"
                controlProps={{
                  label: 'Student Date of Birth',
                  required: true,
                }}
                rules={{
                  validate: {
                    validateDate,
                  },
                }}
              />
            </Styles.FormSection>
            {isSuperOrg && (
              <Styles.FormSection>
                <NestedSelectRHF
                  controlProps={{ label: 'Campus?', required: true }}
                  rules={{ required: 'This field is required' }}
                  name="organizationId"
                  placeholder="Select campus"
                  options={providerOrganization.children.map(org => ({
                    id: org.id,
                    label: org.name,
                  }))}
                />
              </Styles.FormSection>
            )}
            <Styles.FormSection>
              <FinalButton
                type="submit"
                kind="primary"
                iconRight="iconsExternalLinkWhiteSvg"
                onClick={submit}
                loading={loading}
              >
                Continue to IOP Referral Form
              </FinalButton>
            </Styles.FormSection>
          </FormProvider>

          <WarningWithIcon>
            <Text.bodySmallBold>
              Note: Upon clicking ‘Continue,’ you will be sent to Charlie Health’s referral form,
              which must be submitted to in order to successfully complete the IOP referral.
            </Text.bodySmallBold>
          </WarningWithIcon>
        </PermsOnly>
      </Styles.Wrapper>
    </Styles.Background>
  );
}
