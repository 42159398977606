import React from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { Wizard } from '../../../Components/Wizard';
import { UnexpectedError } from '../../Shared';
import { Success } from './Success';
import { useSelfPayReferralBooking } from './useSelfPayReferralBooking';

export const SelfPayReferralBookingWizard = () => {
  const { wizardData, steps, error, loading, isEligible } = useSelfPayReferralBooking();

  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return <UnexpectedError message={error} cannotRetry />;
  }

  if (!isEligible) {
    return <Redirect to={`/book?userId=${wizardData!.user.id}&referral=1`} />;
  }

  return <Wizard initialData={wizardData!} steps={steps} renderSuccess={Success} />;
};
