import { Modal } from 'baseui/modal';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEvents } from '../../Components/Events/EventsProvider';
import { FinalButton } from '../../Components/FinalButton';
import { Textarea } from '../../Components/Form/Input';
import { colors, Modal as MantraModal, Text } from '../../globalStyles';
import {
  SimpleAdminProviderQuery,
  useDebugImpersonateAsUserMutation,
  useImpersonateMutation,
  User,
} from '../../graphQL';
import { setAuthToken } from '../../token';

interface ImpersonateProps {
  provider?: SimpleAdminProviderQuery['adminProvider'];
  user?: Pick<User, 'id' | 'preferredName' | 'firstName' | 'lastName'>;
}

// Allows product-admin roles to impersonate provider for debugging purposes
export const Impersonate = ({ provider, user }: ImpersonateProps) => {
  const [startImpersonationModalOpen, setStartImpersonationModalOpen] = useState(false);

  return (
    <>
      <FinalButton
        onClick={() => setStartImpersonationModalOpen(true)}
        iconLeft={{ icon: 'iconsUserSvg', size: 24 }}
        kind="minimal_black"
      >
        Impersonate
      </FinalButton>
      <StartImpersonationModal
        isOpen={!!startImpersonationModalOpen}
        provider={provider}
        user={user}
        onClose={() => setStartImpersonationModalOpen(false)}
      />
    </>
  );
};

interface StartImpersonationModalProps extends ImpersonateProps {
  onClose: (cancelled: boolean) => void;
  isOpen: boolean;
}

const StartImpersonationModal = ({
  isOpen,
  onClose,
  provider,
  user,
}: StartImpersonationModalProps) => {
  const [error, setError] = useState('');
  const [reason, setReason] = useState('');
  const metrics = useEvents();
  const history = useHistory();
  const type: 'provider' | 'user' = provider ? 'provider' : 'user';
  const providerId = provider?.id;
  const [impersonateMutation] = useImpersonateMutation({
    variables: { providerToImpersonateId: Number(providerId), reason },
  });

  const impersonateProvider = async () => {
    metrics.track(`${type}.impersonation.start`, {
      providerId,
    });

    const token = await impersonateMutation();
    if (token.data?.impersonate) {
      // all views have a 'settings' page in common
      history.push('/settings');
      setAuthToken(token.data.impersonate);
      window.location.reload();
    }

    return token;
  };

  const [loginAsUser] = useDebugImpersonateAsUserMutation();
  const impersonateUser = async () => {
    if (!user?.id) {
      return;
    }
    const userId = user?.id;

    const mutData = await loginAsUser({ variables: { userId, reason } });
    if (!mutData.data) return;
    window.open(mutData.data.debugImpersonateAsUser, '_blank');
  };

  const handleStartMutation = async () => {
    try {
      if (type === 'provider') {
        await impersonateProvider();
      } else if (type === 'user') {
        await impersonateUser();
      } else {
        setError(
          `There was an error while attempting to impersonate: 'provider' or 'user' not available in mutation.`
        );
      }
    } catch (err) {
      setError(
        `There was an error while attempting to impersonate. This provider may not yet be configured, or you may not have adequate permissions.`
      );
      return;
    }
    onClose(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(false)}>
      <MantraModal.body>
        <MantraModal.header>
          Impersonate{' '}
          {provider?.name || `${user?.preferredName || user?.firstName} ${user?.lastName}`}?
        </MantraModal.header>
        {error ? (
          <Text.body kind="danger">{error}</Text.body>
        ) : (
          <Text.body kind="black" className="mb4">
            Details about this impersonation request will be shared with our admin team, and
            permanently stored. Any action taken while impersonating has the same effect as if the
            impersonated user did it themselves.
            <br />
            <br />
            Please supply your reason for impersonating this user below.
          </Text.body>
        )}
        <Text.bodyBold className="mt4">
          Reason for impersonation<span style={{ color: colors.danger }}> *</span>
        </Text.bodyBold>
        <Textarea
          value={reason}
          onChange={e => setReason(e.target.value)}
          placeholder="Reason (required)"
        />

        <FinalButton
          kind="primary"
          style={{ width: '100%', marginBottom: '15px', marginTop: '26px' }}
          onClick={handleStartMutation}
          disabled={!reason}
        >
          Start impersonation
        </FinalButton>
        <MantraModal.closeLink onClick={() => onClose(false)}>Cancel</MantraModal.closeLink>
      </MantraModal.body>
    </Modal>
  );
};
