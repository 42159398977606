import { Breadcrumbs } from 'baseui/breadcrumbs';
import { StatefulMenu } from 'baseui/menu';
import { toaster } from 'baseui/toast';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { FinalButton } from '../../../Components/FinalButton';
import { MedicalNotePopover } from '../../../Components/MedicalNote/PopoverNote';
import {
  AppsOnly,
  CurrentProviderContext,
  MantraAdminOnly,
  PermsOnly,
  useCurrentProvider,
} from '../../../Components/Permissions';
import { usePopoverTabContext } from '../../../Components/PopoverTabs/PopoverTabContainer';
import { useVideoContextV2 } from '../../../Components/Video/VideoProvider';
import {
  AppView,
  Permission,
  useAdminDosespotLinkQuery,
  useNextAppointmentTimeQuery,
} from '../../../graphQL';
import { getFullNameReversed } from '../../../modelUtils/users';
import { Impersonate } from '../../Provider/Impersonate';
import { isPsychiatrist, isTherapist } from '../../Provider/util';
import { Overrides } from '../baseuiOverrides';
import { FileUpload } from '../Files/FileUpload';
import { Restricted, useDrilldownContext } from '../helpers';
import { StyledNav, Styles, Text } from '../styles';
import { DrillDownContextType } from '../types';
import { UserLabModal } from './UserLabModal';
import { IOPModal } from './IOPModal';

const NavButton = ({
  children,
  iconLeft,
  svgLeft,
  ...props
}: Parameters<typeof FinalButton>[0]) => {
  if (iconLeft && typeof iconLeft !== 'string' && !('size' in iconLeft)) iconLeft.size = 20;
  if (svgLeft && typeof svgLeft !== 'string' && !('size' in svgLeft)) {
    svgLeft.height = 20;
    svgLeft.width = 20;
  }

  return (
    <FinalButton kind="minimal_black" iconLeft={iconLeft} svgLeft={svgLeft} {...props}>
      {children}
    </FinalButton>
  );
};

export const PatientTopNav = () => {
  const { user } = useDrilldownContext();
  const { appView, getCopy, hasPermission } = useCurrentProvider();
  const history = useHistory();

  const linkTo: string =
    appView === 'oz'
      ? '/users'
      : `/organizations/${user.organization && user.organization.id}/students`;

  const { data } = useNextAppointmentTimeQuery({ variables: { userId: user.id } });

  let bookLink = `/book?userId=${user.id}`;
  if (data?.adminUser && !data?.adminUser?.nextAppointmentTime) {
    bookLink += '&referral=1';
  }

  const name = getFullNameReversed(user);

  return (
    <StyledNav>
      <Breadcrumbs>
        <Text.breadcrumbLink to={linkTo} className="ttc underline">
          {getCopy('patients')}
        </Text.breadcrumbLink>
        <Text.bodyGrey>{name}</Text.bodyGrey>
      </Breadcrumbs>
      <div className="flex">
        <PermsOnly allowed={Permission.Impersonate}>
          <Impersonate user={user} />
        </PermsOnly>
        <PermsOnly allowed={Permission.Phi}>
          <UploadButton />
        </PermsOnly>
        <PermsOnly allowed={Permission.OrderLabs}>
          <OrderLabsButton />
        </PermsOnly>
        {(hasPermission(Permission.NoteCreate) ||
          hasPermission(Permission.NoteCreateText) ||
          hasPermission(Permission.NoteCreateReferral)) && <NoteDropdown />}
        <PermsOnly allowed={Permission.Prescribe}>
          <Dosespot />
        </PermsOnly>
        <PermsOnly allowed={Permission.PatientVideo}>
          <PatientVideoButton />
        </PermsOnly>
        <AppsOnly allowed={[AppView.Mcp, AppView.Oz]}>
          <Restricted>
            <NavButton
              iconLeft={{ icon: 'iconsBlackApptSvg', alt: 'Book Appointment' }}
              onClick={() => history.push(bookLink)}
            >
              Book Appointment
            </NavButton>
          </Restricted>
        </AppsOnly>
        <MantraAdminOnly>
          <MoreDropdown />
        </MantraAdminOnly>
      </div>
    </StyledNav>
  );
};

const PatientVideoButton = () => {
  const { user } = useDrilldownContext();
  const history = useHistory();
  const { isSessionConnected } = useVideoContextV2();
  const videoPath = `/users/${user.id}/video/${user.nextAppointment?.id ?? 'none'}`;
  const videoLink = `${window.location.origin}${videoPath}`;

  return (
    <NavButton
      onClick={() => (isSessionConnected ? history.push(videoPath) : window.open(videoLink))}
      iconLeft={{ icon: 'iconsBlackVideoSvg', alt: 'Video' }}
    >
      Video
    </NavButton>
  );
};

type NoteOption = {
  label: string;
  key: string;
  condition: (arg0: CurrentProviderContext, arg1: DrillDownContextType) => boolean;
};

const NOTES: NoteOption[] = [
  {
    label: 'Free-Form',
    key: 'text-note',
    condition: ({ hasPermission }) => hasPermission(Permission.NoteCreateText),
  },
  {
    label: 'Initial Evaluation',
    key: 'initial-evaluation',
    condition: ({ hasPermission, currentProvider }) =>
      hasPermission(Permission.NoteCreate) && isPsychiatrist(currentProvider),
  },
  {
    label: 'Telehealth Follow-Up',
    key: 'follow-up',
    condition: ({ hasPermission, currentProvider }) =>
      hasPermission(Permission.NoteCreate) && isPsychiatrist(currentProvider),
  },
  {
    label: 'Referral',
    key: 'student-referral',
    condition: ({ hasPermission }, { events }) => {
      const completedReferral = events
        .filter(e => e.tag === 'provider:note:complete')
        .some(n => n.data?.key === 'student-referral');
      return hasPermission(Permission.NoteCreateReferral) && !completedReferral;
    },
  },
  {
    label: 'Therapy Follow-up',
    key: 'follow-up-therapy',
    condition: ({ hasPermission, currentProvider }) =>
      hasPermission(Permission.NoteCreate) && isTherapist(currentProvider),
  },
  {
    label: 'Therapy Initial Evaluation',
    key: 'initial-evaluation-therapy',
    condition: ({ hasPermission, currentProvider }) =>
      hasPermission(Permission.NoteCreate) && isTherapist(currentProvider),
  },
  {
    label: 'External Referral: Case Summary',
    key: 'continuity-of-care',
    condition: ({ hasPermission, currentProvider }, { user }) =>
      (hasPermission(Permission.NoteCreate) &&
        [user.therapist?.id, user.provider?.id].includes(currentProvider.id)) ||
      hasPermission(Permission.MantraAdmin),
  },
  {
    label: 'Risk Change Note',
    key: 'risk-change',
    condition: ({ hasPermission, currentProvider }, { user }) =>
      hasPermission(Permission.NoteCreate) || hasPermission(Permission.MantraAdmin),
  },
  {
    label: 'Test Note',
    key: 'test-note',
    condition: ({ hasPermission }) => hasPermission(Permission.Dev),
  },
];

const NoteDropdown = () => {
  const ref = useRef<HTMLDivElement>(null);
  const drilldownContext = useDrilldownContext();
  const providerContext = useCurrentProvider();
  const { pushTab } = usePopoverTabContext();
  const [noteDropdown, setNoteDropdown] = useState(false);

  useOnClickOutside(ref, d => {
    if ((d as any)?.target?.type === 'submit') return;
    setNoteDropdown(false);
  });

  const notes = providerContext.hasPermission(Permission.Dev)
    ? NOTES
    : NOTES.filter(n => n.condition(providerContext, drilldownContext));

  const handleItemSelect = ({ item }: { item: NoteOption }) => {
    pushTab({
      tabKey: item.key,
      title: item.label,
      renderBody: props => <MedicalNotePopover noteKey={item.key} {...props} />,
    });
    setNoteDropdown(false);
  };

  return (
    <div className="relative" ref={ref}>
      <NavButton
        onClick={() => setNoteDropdown(n => !n)}
        iconLeft={{ icon: 'iconsBlackNoteSvg', alt: 'Notes' }}
        iconRight={{ icon: noteDropdown ? 'iconsUpChevronSvg' : 'iconsDownChevronSvg', size: 10 }}
        data-cy="create-note-dropdown"
      >
        Create Note
      </NavButton>
      <Styles.dropdown hidden={!noteDropdown}>
        <StatefulMenu
          overrides={Overrides.DropDownMenu}
          items={notes}
          onItemSelect={handleItemSelect}
        />
      </Styles.dropdown>
    </div>
  );
};

export const canGetDosespotLink = ({
  currentProvider,
  hasPermission,
}: Pick<CurrentProviderContext, 'currentProvider' | 'hasPermission'>) => {
  return currentProvider.hasDosespot && hasPermission(Permission.Prescribe);
};

const Dosespot = () => {
  const { track } = useEvents();
  const { currentProvider, hasPermission } = useCurrentProvider();
  const { user } = useDrilldownContext();

  const canGetLink = canGetDosespotLink({ currentProvider, hasPermission });
  const { data } = useAdminDosespotLinkQuery({
    variables: { userId: user.id },
    skip: !canGetLink,
  });

  if (!canGetLink) return null;
  return (
    <NavButton
      iconLeft={{ icon: 'iconsBlackRxSvg', alt: 'prescribing' }}
      onClick={async () => {
        if (!user.hasDosespot)
          return toaster.negative('No connected Dosespot ID. Contact Mantra team.', {});
        if (!data || !data.adminDosespotLink) return;
        track('user.dosespot.opened', { userId: user.id });
        window.open(data.adminDosespotLink, '_blank');
      }}
    >
      ePrescribing
    </NavButton>
  );
};

const UploadButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setActiveTab, refetch: refetchUser } = useDrilldownContext();

  const refetch = () => {
    refetchUser();
    setActiveTab('files');
  };

  return (
    <>
      <NavButton
        onClick={() => setModalOpen(!modalOpen)}
        iconLeft={{ icon: 'iconsBlackUploadSvg', alt: 'Upload' }}
      >
        Upload File
      </NavButton>
      {modalOpen && <FileUpload closeModal={() => setModalOpen(false)} refetch={refetch} />}
    </>
  );
};

const OrderLabsButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <NavButton svgLeft={{ icon: 'lab', kind: 'offBlack' }} onClick={() => setModalOpen(o => !o)}>
        Order Labs
      </NavButton>
      <UserLabModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

const MoreDropdown = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [moreDropdown, setMoreDropdown] = useState(false);
  const [modalIOPOpen, setIOPModalOpen] = useState(false);
  useOnClickOutside(ref, d => {
    if ((d as any)?.target?.type === 'submit') return;
    setMoreDropdown(false);
  });

  return (
    <div className="relative" ref={ref}>
      <NavButton
        onClick={() => setMoreDropdown(n => !n)}
        iconLeft={{ icon: 'iconsBlackPlusSvg', alt: 'More' }}
        iconRight={{ icon: moreDropdown ? 'iconsUpChevronSvg' : 'iconsDownChevronSvg', size: 10 }}
        data-cy="more-dropdown"
      >
        More
      </NavButton>
      <Styles.dropdown hidden={!moreDropdown}>
        <NavButton
          kind="minimal_gray_bg"
          onClick={() => {
            setMoreDropdown(false);
            setIOPModalOpen(o => !o);
          }}
        >
          Refer to IOP
        </NavButton>
        <IOPModal isOpen={modalIOPOpen} onClose={() => setIOPModalOpen(false)} />
      </Styles.dropdown>
    </div>
  );
};
