import { pick } from 'lodash';
import moment from 'moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { DateRangePickerRHF, InputRHF, SelectRHF } from '../../Components/Form';
import { Grid } from '../../Components/Grid';
import { ListView } from '../../Components/ListView';
import { CenteredNotification } from '../../Components/Notification';
import { useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import {
  CareStatus,
  Permission,
  SearchUserInput,
  useOrganizationsSimpleQuery,
  User,
  useSearchUsersQuery,
} from '../../graphQL';
import { useQueryParams } from '../../Hooks';
import { compareDates, compareLastNames, getFullNameReversed } from '../../modelUtils/users';
import { careStatusCopy } from '../Users/copy';

const parseSearch = (search: any) => {
  const values = pick(search, [
    'value',
    'createdAfter',
    'createdBefore',
    'careStatus',
    'organizationId',
  ]) as Partial<SearchUserInput>;
  if ('organizationId' in values) values.organizationId = Number(values.organizationId);
  return values;
};

export const UserSearch = () => {
  const { hasPermission } = useCurrentProvider();

  const history = useHistory();
  const [rawParsedSearch, updateQueryParams] = useQueryParams();
  const parsedSearch = parseSearch(rawParsedSearch);
  const formContext = useForm<SearchUserInput & { created?: [Date, Date] }>({
    defaultValues: parsedSearch,
  });
  const { data: orgs } = useOrganizationsSimpleQuery();
  const { data, loading, error } = useSearchUsersQuery({
    variables: { search: parsedSearch },
    skip: Boolean(Object.values(parsedSearch).filter(Boolean).length <= 0),
  });

  const onSubmit = formContext.handleSubmit(async ({ created, ...search }) => {
    const [createdAfter, createdBefore] = created ?? [];
    updateQueryParams({ createdAfter, createdBefore, ...search });
  });

  if (!hasPermission(Permission.MantraAdmin))
    throw new Error('Invalid permission to view this page');

  return (
    <>
      {error && (
        <div className="mw6">
          <CenteredNotification kind="negative">
            {error.message ?? 'Something went wrong'}
          </CenteredNotification>
        </div>
      )}
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <Grid gap=".15rem 1rem" gridTemplateColumns="repeat(auto-fit, minmax(12rem, 1fr))">
            <InputRHF type="text" name="value" placeholder="Email, name, customerId etc" />

            <SelectRHF
              clearable
              placeholder="Organization"
              name="organizationId"
              options={[
                { id: -1, label: 'None' },
                ...(orgs?.organizations.map(o => ({ id: o.id, label: o.name })) ?? []),
              ]}
            />
            <SelectRHF
              clearable
              placeholder="Patient Status"
              name="careStatus"
              options={[
                CareStatus.Active,
                CareStatus.New,
                CareStatus.OnHold,
                CareStatus.Cancelled,
                CareStatus.ScreenedOut,
                CareStatus.Discharged,
              ].map(s => ({ id: s, label: careStatusCopy[s] }))}
            />
            <DateRangePickerRHF clearable name="created" placeholder="Enrolled" />

            <div className="flex items-start gap-2">
              <FinalButton loading={loading} type="submit" kind="primary">
                Search
              </FinalButton>
              <FinalButton
                type="reset"
                kind="minimal_gray"
                onClick={() => {
                  formContext.reset({});
                  updateQueryParams({});
                }}
              >
                reset
              </FinalButton>
            </div>
          </Grid>
        </form>
      </FormProvider>
      {data && <p className="o-50 mt2">{data.searchUsers.length} results</p>}
      <ListView
        data={data?.searchUsers ?? []}
        columns={[
          { title: 'Name', key: 'name', render: RenderName, sort: compareLastNames },
          {
            title: 'Date Enrolled',
            key: 'enroll',
            render: u => moment(u.createdAt).format('L'),
            sort: (a, b) => compareDates(a.createdAt?.toString(), b.createdAt?.toString()),
          },
          { title: 'Email', key: 'email', render: u => u.email },
          { title: 'Organization', key: 'org', render: u => u.organization?.name ?? 'None' },
          { title: 'Status', key: 'careStatus', render: u => careStatusCopy[u.careStatus] },
        ]}
        onClick={u => history.push(`/users/${u.id}`)}
        getKey={u => u.id}
        paginate={false}
      />
    </>
  );
};

const RenderName = (u: Pick<User, 'firstName' | 'lastName' | 'customerId'>) => (
  <div>
    <Text.bodyBold className="ttc" data-cy="name">
      {getFullNameReversed(u)}
    </Text.bodyBold>
    <Text.captionGrey
      onClick={e => {
        e.preventDefault();
        window.getSelection()?.selectAllChildren(e.target as Node);
      }}
      style={{ cursor: 'auto', userSelect: 'text', whiteSpace: 'nowrap' }}
    >
      {u.customerId}
    </Text.captionGrey>
  </div>
);
