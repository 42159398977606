import React from 'react';
import { CareType, Contract } from '../../../graphQL';
import { Text } from '../../../globalStyles';
import { Nullable } from '../../../types';
import { SessionsUtilizationWidget } from './SessionsWidget';
import { StudentCapacityWidget } from './StudentCapacityWidget';

type ContractUtilizationDashboardProps = {
  careType: CareType;
  contract: Nullable<Contract>;
};

type UtilizationData = {
  max: number;
  remaining: number;
  utilized: number;
  sessionsUtilized: number;
  sessionsScheduled: number;
  sessionsRemaining: number;
  sessionsLimit: number;
};

const getUtilizationData = (careType: CareType, contract: Contract): UtilizationData => {
  const careTypeMapping = {
    [CareType.Therapy]: {
      max: contract.scope.therapyPatients,
      remaining: contract.utilization.therapyUsage.studentCapacityRemaining,
      utilized: contract.utilization.therapyUsage.studentCapacityUtilized,
      sessionsUtilized: contract.utilization.therapyUsage.sessionsUtilized,
      sessionsScheduled: contract.utilization.therapyUsage.sessionsScheduled,
      sessionsRemaining: contract.utilization.therapyUsage.sessionsRemaining,
      sessionsLimit: contract.scope.therapySessions,
    },
    [CareType.Psychiatry]: {
      max: contract.scope.psychiatryPatients,
      remaining: contract.utilization.psychiatryUsage.studentCapacityRemaining,
      utilized: contract.utilization.psychiatryUsage.studentCapacityUtilized,
      sessionsUtilized: contract.utilization.psychiatryUsage.sessionsUtilized,
      sessionsScheduled: contract.utilization.psychiatryUsage.sessionsScheduled,
      sessionsRemaining: contract.utilization.psychiatryUsage.sessionsRemaining,
      sessionsLimit: contract.scope.psychiatrySessions,
    },
  };

  return careTypeMapping[careType];
};

export const ContractCareTypeUtilization = ({
  careType,
  contract,
}: ContractUtilizationDashboardProps) => {
  if (!contract) {
    return null;
  }

  const {
    max,
    remaining,
    utilized,
    sessionsUtilized,
    sessionsScheduled,
    sessionsRemaining,
    sessionsLimit,
  } = getUtilizationData(careType, contract);

  return (
    <div>
      <Text.h2>{careType} Utilization</Text.h2>
      <div className="flex justify-center">
        <StudentCapacityWidget
          max={max}
          remaining={remaining}
          utilized={utilized}
          careType={careType}
        />
        <div className="br b--gray" />
        <SessionsUtilizationWidget
          careType={careType}
          utilized={sessionsUtilized}
          scheduled={sessionsScheduled}
          remaining={sessionsRemaining}
          sessionsLimit={sessionsLimit}
        />
      </div>
    </div>
  );
};
