import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { StyledSpinnerNext } from 'baseui/spinner';
import moment from 'moment';
import React from 'react';
import {
  useMarkTaskDoneMutation,
  useNextAppointmentTimeQuery,
  useUndoTaskDoneMutation,
} from '../../../graphQL';
import { toggleOverrideFactory } from '../baseuiOverrides';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';
import { UserType } from '../types';

type TaskRowProps = {
  task: NonNullable<UserType['tasks']>[number];
  refetch: () => void;
  urgent?: boolean;
};

const TaskRow: React.FC<TaskRowProps> = ({ task, refetch, urgent }) => {
  const [markTaskDone, markMutation] = useMarkTaskDoneMutation();
  const [undoTaskDone, unmarkMutation] = useUndoTaskDoneMutation();
  const loading = markMutation.loading || unmarkMutation.loading;
  return (
    <div className="flex justify-between items-center">
      <div className="flex">
        <Checkbox
          checked={task.done}
          checkmarkType={STYLE_TYPE.toggle_round}
          onChange={() => {
            task.done
              ? undoTaskDone({ variables: { taskId: task.id } }).then(() => refetch())
              : markTaskDone({ variables: { taskId: task.id } }).then(() => refetch());
          }}
          overrides={toggleOverrideFactory(task)}
          disabled={loading}
        />
        <Text.body className="ml3" kind={urgent ? 'danger' : 'text'}>
          {task.title}
        </Text.body>
      </div>
      {task.done ? (
        <Styles.infoDiv>
          <Text.body>{moment(task.createdAt).format('MM/DD/YYYY')}</Text.body>
        </Styles.infoDiv>
      ) : (
        <div />
      )}
    </div>
  );
};

export const Tasks = () => {
  const { user, refetch } = useDrilldownContext();

  const { data, loading } = useNextAppointmentTimeQuery({ variables: { userId: user.id } });

  if (!user.tasks) return null;
  let urgent = false;

  if (!loading)
    urgent =
      Boolean(data?.adminUser.nextAppointmentTime) &&
      moment(data?.adminUser.nextAppointmentTime!).diff(moment.now(), 'hours') <= 72;

  return (
    <Styles.widget>
      <Text.h3>Tasks</Text.h3>
      {loading ? (
        <div className="flex w-100 justify-center">
          <StyledSpinnerNext />
        </div>
      ) : (
        user.tasks.map(task => (
          <div key={task.id} className="bb b--light-gray pv2">
            <TaskRow task={task} refetch={refetch} urgent={urgent} />
          </div>
        ))
      )}
    </Styles.widget>
  );
};
