import moment from 'moment';
import React from 'react';
import { Modal as MantraModal, Text } from '../../globalStyles';
import { useAdminCancelAppointmentMutation } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import * as Styles from '../../Pages/Appointments/styles';
import { FinalButton } from '../FinalButton';
import { icons } from '../Icons';
import { Modal } from '../Modal/Modal';
import { AppointmentForLink } from './types';

interface Props {
  appointment: AppointmentForLink;
  onClose: (cancelled: boolean) => void;
}

export function CancelAppointmentModal({ appointment, onClose }: Props) {
  const [mutate, { loading, error }] = useAdminCancelAppointmentMutation({
    variables: { id: appointment.id },
  });
  const handleConfirm = async () => {
    await mutate();
    onClose(true);
  };
  return (
    <Modal size="tiny" isOpen onClose={() => onClose(false)}>
      <MantraModal.body>
        <MantraModal.header>Cancel Appointment?</MantraModal.header>
        <Styles.ModalAppointmentBox>
          <Styles.Thumbnail
            src={appointment.user.selfie?.url ?? icons.assetsDefaultPicturePng}
            alt={getFullName(appointment.user)}
          />
          <Styles.ModalAppointmentText>
            <Text.bodyBold>{getFullName(appointment.user)}</Text.bodyBold>
            <Text.body>{appointment.description}</Text.body>
            <Text.bodyBold>
              {moment(appointment.startTime).format('M/D/YYYY h:mm a')} -{' '}
              {moment(appointment.endTime).format('h:mm a')}
            </Text.bodyBold>
          </Styles.ModalAppointmentText>
        </Styles.ModalAppointmentBox>
        <FinalButton
          kind="primary"
          className="w-100 mb2"
          onClick={handleConfirm}
          loading={loading}
          disabled={Boolean(error) || loading}
        >
          Confirm Cancellation
        </FinalButton>
        <MantraModal.closeLink onClick={() => onClose(false)}>Close</MantraModal.closeLink>
      </MantraModal.body>
    </Modal>
  );
}

export function LateCancelAppointmentModal({ appointment, onClose }: Props) {
  const [mutate, { loading }] = useAdminCancelAppointmentMutation({
    variables: { id: appointment.id },
    onCompleted: () => onClose(true),
  });
  return (
    <Modal size="tiny" isOpen onClose={() => onClose(false)}>
      <MantraModal.body>
        <Text.h2 className="mb4">This appointment is less than 24 hours away.</Text.h2>
        <Text.body className="mb3">
          Patient cancellation and reschedule requests made within 24 hours of the appointment time
          are considered in violation of our cancellation policy.
        </Text.body>
        <Text.body className="mb4">
          <b>
            If a provider requested this cancellation or the request was made prior to the 24 hour
            window:
          </b>{' '}
          You can mark this appointment as a regular cancellation to avoid any cancellation
          penalties.
        </Text.body>
        <FinalButton
          className="w-100 mb2"
          onClick={() => mutate({ variables: { id: appointment.id, outOfPolicy: true } })}
          loading={loading}
        >
          Mark as Out-of-Policy Cancellation
        </FinalButton>
        <FinalButton
          kind="outline_black"
          className="w-100 mb2"
          onClick={() => mutate({ variables: { id: appointment.id } })}
          loading={loading}
        >
          Mark as Regular Cancellation
        </FinalButton>
        <FinalButton kind="minimal_gray" className="w-100" onClick={() => onClose(false)}>
          Keep Appointment
        </FinalButton>
      </MantraModal.body>
    </Modal>
  );
}
