import React from 'react';
import { McpOnly } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { PageTitle } from '../Shared';

export const SettingsPageTitle = () => {
  const emailLink = `mailto:hi@mantrahealth.com?subject=Question About Account`;

  return (
    <div className="pa4 ml2 bg-white">
      <PageTitle>Settings</PageTitle>
      <McpOnly>
        <Text.bodySmall className="mt2">
          Questions regarding your account?{' '}
          <Text.externalLink href={emailLink} target="_blank" rel="noopener noreferrer">
            Contact us
          </Text.externalLink>
        </Text.bodySmall>
      </McpOnly>
    </div>
  );
};
