import { chain, uniq, uniqBy } from 'lodash';
import { DiagnosisReportFragment, UserDiagnosis } from '../../../../graphQL';

export type CategoryBreakdown = {
  primaryPercent: number;
  additionalPercent: number;
};

export const generateDataBreakdown = (data: DiagnosisReportFragment['diagnosesBreakdown']) => {
  // additional are the percent of students who
  // _just_ have the cat in as an "additional" dx
  const diagnosesData = uniqBy(data, b => `${b.key}-${b.userId}-${b.category}`);
  const population = uniq(diagnosesData.map(b => b.userId)).length;

  const getCategoryDistribution = (diagnoses: Pick<UserDiagnosis, 'key' | 'userId'>[]) => {
    const primaryCount = diagnoses.filter(d => d.key === 'primary-diagnosis').length;
    const totalCount = uniqBy(diagnoses, v => v.userId).length;

    return {
      primaryPercent: safePercent(primaryCount, population),
      additionalPercent: safePercent(totalCount - primaryCount, population),
    } as CategoryBreakdown;
  };

  const subCategoryBreakdown = (
    diagnoses: Pick<UserDiagnosis, 'subCategory' | 'key' | 'userId'>[]
  ) => {
    return (
      chain(diagnoses)
        .groupBy(b => b.subCategory ?? 'Other')
        // Within an individual category (key, userId) is unique
        .mapValues(getCategoryDistribution)
        .value()
    );
  };

  return chain(diagnosesData)
    .groupBy(b => b.category)
    .mapValues(v => ({ ...getCategoryDistribution(v), subCategories: subCategoryBreakdown(v) }))
    .value();
};

const safePercent = (count: number, total: number) =>
  total === 0 ? Number.POSITIVE_INFINITY : (count / total) * 100;
