import { capitalize, omit } from 'lodash';
import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { MultiSelectRHF, SelectRHF } from '../../../Components/Form';
import {
  CareType,
  CreateTestUser,
  OrganizationsListQuery,
  PaymentSource,
  ProviderFragment,
  ReferralSource,
} from '../../../graphQL';
import { paymentSourceCopy } from '../../Users/copy';
import { Text } from '../../Users/styles';

type Props = {
  control: Control<CreateTestUser>;
  psychiatrists: ProviderFragment[];
  therapists: ProviderFragment[];
  organizations: OrganizationsListQuery['organizations'];
};

export const PatientCare = ({ control, psychiatrists, therapists, organizations }: Props) => {
  const { organizationId, careTypes } = useWatch<{
    organizationId?: number;
    careTypes?: CareType[];
  }>({
    control,
    name: ['organizationId', 'careTypes'],
  });

  return (
    <div className="mb4">
      <Text.h2 className="mb3">Patient Care</Text.h2>
      <MultiSelectRHF
        controlProps={{ label: 'Care Types' }}
        options={[CareType.Psychiatry, CareType.Therapy].map(c => ({ id: c, label: c }))}
        name="careTypes"
      />

      {careTypes?.includes(CareType.Psychiatry) && (
        <SelectRHF
          controlProps={{ label: 'Psychiatrist' }}
          name="psychiatristId"
          options={psychiatrists?.map(p => ({ label: p.name, id: p.id }))}
        />
      )}
      {careTypes?.includes(CareType.Therapy) && (
        <SelectRHF
          controlProps={{ label: 'Therapist' }}
          name="therapistId"
          options={therapists?.map(p => ({ label: p.name, id: p.id }))}
        />
      )}
      <SelectRHF
        controlProps={{ label: 'Organization' }}
        name="organizationId"
        options={organizations.map(o => ({ label: `${o.abbreviation}`, id: o.id }))}
      />

      {!!organizationId && (
        <SelectRHF
          clearable={false}
          defaultValue={PaymentSource.SelfPay}
          controlProps={{ label: 'Payment Source' }}
          name="defaultPaymentSource"
          options={Object.keys(omit(paymentSourceCopy, PaymentSource.Organization)).map(k => ({
            id: k,
            label: paymentSourceCopy[k as PaymentSource],
          }))}
        />
      )}
      {!!organizationId && (
        <SelectRHF
          controlProps={{ label: 'Referral Source' }}
          defaultValue={ReferralSource.Portal}
          name="referralSource"
          clearable={false}
          options={[ReferralSource.Portal, ReferralSource.Mcp].map(k => ({
            id: k,
            label: capitalize(k),
          }))}
        />
      )}
    </div>
  );
};
