import React from 'react';
import { Text } from '../globalStyles';
import { PharmacyFragment } from '../graphQL';
import { formatPhoneNumber } from '../utils';

export function PharmacyDetail({ pharmacy }: { pharmacy: PharmacyFragment }) {
  return (
    <div>
      <Text.body>{pharmacy.storeName}</Text.body>
      <Text.body>
        {pharmacy.address1}, {pharmacy.address2 && `${pharmacy.address2}, `} {pharmacy.city},{' '}
        {pharmacy.state} {pharmacy.zipCode}
      </Text.body>
      {pharmacy.primaryPhone && <Text.body>{formatPhoneNumber(pharmacy.primaryPhone)}</Text.body>}
    </div>
  );
}
