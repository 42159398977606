import { Tab, Tabs } from 'baseui/tabs';
import { throttle } from 'lodash';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { useEvents } from '../../Components/Events/EventsProvider';
import { Grid } from '../../Components/Grid';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useClickCounter } from '../../Components/MedicalNote/hooks';
import { usePanelRef } from '../../Components/NavLayout';
import { AppsOnly, ReferralOnly, useCurrentProvider } from '../../Components/Permissions';
import { PopoverTabContainer } from '../../Components/PopoverTabs/PopoverTabContainer';
import {
  AdminUserViewQuery,
  AppView,
  CareType,
  useAdminUserQuery,
  useAdminUserViewQuery,
  UserRecordViewStatus,
} from '../../graphQL';
import { uuid } from '../../utils';
import { Overrides } from './baseuiOverrides';
import { Debug } from './debug/Debug';
import { DrilldownContext } from './helpers';
import { PatientTopNav } from './Navigation/TopNav';
import { Profile } from './Profile';
import { Sidebar, useSidebarController } from './Sidebar/index';
import { DrilldownTabs } from './Tabs';
import { OverviewTab } from './Tabs/Overview';
import { Tabkeys } from './types';

export const UserDrilldown = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useAdminUserViewQuery({ variables: { id: Number(id) } });
  return data ? <DrilldownView {...data.adminUser} /> : <LoadingPage />;
};

const DrilldownView = ({ id, recordViewStatus }: AdminUserViewQuery['adminUser']) => {
  const { track } = useEvents();
  const { resetClickCount } = useClickCounter();
  const tracker = useRef({
    visitId: `${id}-${uuid()}`,
    lastChange: new Date(),
    num: 1,
    prevTab: null as string | null,
  });
  const providerContext = useCurrentProvider();
  const { search } = useLocation();
  const currentRefetchCount = useRef(0);
  const panel = usePanelRef();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<Tabkeys>(() => {
    const { tab } = qs.parse(search);
    if (!tab || typeof tab !== 'string') return 'overview';
    return tab as Tabkeys;
  });
  const sidebarController = useSidebarController();

  const restricted =
    recordViewStatus === UserRecordViewStatus.Restricted && providerContext.appView !== 'oz';
  const full = recordViewStatus === UserRecordViewStatus.Full || providerContext.appView === 'oz';
  const phi = providerContext.hasPermission('phi');

  const {
    data,
    loading,
    refetch: realRefetch,
  } = useAdminUserQuery({
    variables: { id, phi, full, restricted, assessmentInfo: phi && !restricted },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const { lastChange, visitId, prevTab, num } = tracker.current;
    const payload = { tab: activeTab, visitId, userId: Number(id), num };
    if (prevTab) {
      const timeSpentMs = new Date().getTime() - lastChange.getTime();
      Object.assign(payload, { prevTab, clicks: resetClickCount(), timeSpentMs });
    }
    track(`user.drilldown.tab.changed`, payload);
    Object.assign(tracker.current, { prevTab: activeTab, lastChange: new Date() });
    tracker.current.num += 1;
  }, [track, resetClickCount, id, activeTab]);

  if (loading || !data) return <LoadingPage />;
  if (!data.adminUser) return <Redirect to="/users" />;

  const { adminUser: user, events, permissibleUploadTypes } = data;

  const permissibleTabs = DrilldownTabs.filter(t => t.condition(providerContext, data.adminUser));

  const refetch = throttle(async () => {
    currentRefetchCount.current += 1;
    await realRefetch();
  }, 100);

  const handleActiveTabChanged = (newTab: Tabkeys) => {
    if (!permissibleTabs.some(t => t.key === newTab)) return;
    setActiveTab(newTab);
    if (panel) panel.scrollTop = 0;

    // Replace the history so that the current tab is preserved when refreshing, but
    // when pressing the back button the provider does not need to go back through
    // all the tabs they visited.
    history.replace(`${history.location.pathname}?tab=${newTab}`);
  };

  return (
    <DrilldownContext.Provider
      value={{
        setActiveTab: handleActiveTabChanged,
        user,
        events,
        permissibleUploadTypes,
        refetch,
        refetchCount: currentRefetchCount.current,
        userView: { id, recordViewStatus },
        sidebarController,
        hasPsychiatry: user.careTypes.includes(CareType.Psychiatry),
        hasTherapy: user.careTypes.includes(CareType.Therapy),
        careNavigators: data.careNavigators,
      }}
    >
      <Debug />
      <PopoverTabContainer>
        <PatientTopNav />
        <Grid className="h-100 w-100" gridTemplateColumns="1fr 24rem">
          <div className="bg-white">
            <Profile />
            <AppsOnly allowed={[AppView.Oz, AppView.Mcp]}>
              <Tabs
                activeKey={activeTab}
                onChange={({ activeKey }) => handleActiveTabChanged(activeKey as Tabkeys)}
                overrides={Overrides.Tabs}
              >
                {permissibleTabs.map(sub => (
                  <Tab title={sub.text} key={sub.key} overrides={Overrides.Tab}>
                    <sub.component />
                  </Tab>
                ))}
              </Tabs>
            </AppsOnly>
            <ReferralOnly>
              <div className="mv4 mh4">
                <OverviewTab />
              </div>
            </ReferralOnly>
          </div>
          <Sidebar />
        </Grid>
      </PopoverTabContainer>
    </DrilldownContext.Provider>
  );
};
