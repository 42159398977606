import React from 'react';
import { GeneratePassword } from '../../Session/GeneratePassword';
import { useDrilldownContext } from '../helpers';
import { Entitlement } from '../../../graphQL';
import { Text } from '../../../globalStyles';

export const SecurityTab = () => {
  const { user } = useDrilldownContext();

  const hasSso =
    user.organization?.entitlements.some(entitlement => entitlement.key === Entitlement.Sso) ??
    false;

  if (hasSso) {
    return (
      <Text.bodyGrey className="mv4">
        This patient uses org SSO to authenticate, and therefore password generation is not
        possible.
      </Text.bodyGrey>
    );
  }

  return <GeneratePassword />;
};
