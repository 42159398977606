import React from 'react';
import { Text } from '../../globalStyles';
import { ExampleBox } from './FormComponents';

export const WarningSigns = () => {
  const thoughts = [
    'I am a failure.',
    'I don’t make a difference.',
    'I am worthless.',
    'I can’t cope with my problems.',
    'I can’t take it anymore.',
    'Things aren’t going to get better.',
  ];
  const images = ['Flashbacks', 'Images of tragic events'];
  const mood = ['Feeling irritable', 'Feeling down', 'Worrying a lot', 'Having racing thoughts'];
  const behavior = [
    'Spending a lot of time by myself',
    'Avoiding others',
    'Not doing activities that I usually do',
    'Drinking in excess',
    'Using drugs',
  ];

  return (
    <ExampleBox>
      <Text.bodyGrey>Examples of warning signs</Text.bodyGrey>
      <div className="flex">
        <div className="mr2 flex-auto">
          <Text.bodyBold>Thoughts</Text.bodyBold>
          <ul>
            {thoughts.map(t => (
              <li key={t}>&ldquo;{t}&rdquo;</li>
            ))}
          </ul>
          <Text.bodyBold>Images</Text.bodyBold>
          <ul>
            {images.map(t => (
              <li key={t}>{t}</li>
            ))}
          </ul>
        </div>
        <div className="flex-auto">
          <Text.bodyBold>Mood</Text.bodyBold>
          <ul>
            {mood.map(t => (
              <li key={t}>{t}</li>
            ))}
          </ul>
          <Text.bodyBold>Behavior</Text.bodyBold>
          <ul>
            {behavior.map(t => (
              <li key={t}>{t}</li>
            ))}
          </ul>
        </div>
      </div>
    </ExampleBox>
  );
};

export const CopingStrategies = () => {
  const colA = ['Going for a walk/run', 'Listening to music', 'Going online', 'Taking a shower'];
  const colB = ['Playing with a pet', 'Exercising', 'Engaging in a hobby', 'Reading'];

  return (
    <ExampleBox>
      <Text.bodyGrey>Examples of coping strategies</Text.bodyGrey>
      <div className="flex">
        <div className="mr2 flex-auto">
          <ul>
            {colA.map(t => (
              <li key={t}>{t}</li>
            ))}
          </ul>
        </div>
        <div className="flex-auto">
          <ul>
            {colB.map(t => (
              <li key={t}>{t}</li>
            ))}
          </ul>
        </div>
      </div>
    </ExampleBox>
  );
};

export const Distractions = () => {
  const setA = ['Gym', 'Park', 'Coffee Shop'];
  const setB = ['Library', 'Grocery Store', 'Movie Theater'];
  const people = [
    'My friend, _____ (333-333-1122)',
    'My mother (333-444-4444)',
    'My brother (222-222-2222)',
  ];

  return (
    <ExampleBox>
      <Text.bodyGrey>
        Examples of people that provide distraction (include phone number)
      </Text.bodyGrey>
      <ul>
        {people.map(t => (
          <li key={t}>{t}</li>
        ))}
      </ul>
      <Text.bodyGrey>Examples of social settings that provide distraction</Text.bodyGrey>
      <div className="flex">
        <div className="mr2 flex-auto">
          <ul>
            {setA.map(t => (
              <li key={t}>{t}</li>
            ))}
          </ul>
        </div>
        <div className="flex-auto">
          <ul>
            {setB.map(t => (
              <li key={t}>{t}</li>
            ))}
          </ul>
        </div>
      </div>
    </ExampleBox>
  );
};

export const CrisisContacts = () => {
  const people = [
    'Spouse/partner',
    'Sibling',
    'Parent',
    'Close friend',
    'Member of a social or religious organization',
  ];

  return (
    <ExampleBox>
      <Text.bodyGrey>Examples of people that provide support (include phone number)</Text.bodyGrey>
      <ul>
        {people.map(t => (
          <li key={t}>{t}</li>
        ))}
      </ul>
    </ExampleBox>
  );
};
