import { useAdminContinuityOfCareExportMutation } from '../../graphQL';
import { useEvents } from '../Events/EventsProvider';
import { useAsyncJob } from './useAsyncJob';

export const useCocExport = ({ userId }: { userId: number }) => {
  const metrics = useEvents();
  const [beginJobPoll, job] = useAsyncJob();
  const [beginJob] = useAdminContinuityOfCareExportMutation();

  const beginExport = async () => {
    const { data: initialJob } = await beginJob({ variables: { userId } });
    if (!initialJob) return;
    metrics.track(`coc.export.started`, {
      userId,
      jobId: initialJob.adminContinuityOfCareExport.id,
    });
    beginJobPoll(initialJob.adminContinuityOfCareExport.id);
  };

  return { beginExport, ...job };
};
