import React from 'react';
import { Text } from '../../globalStyles';
import { SafetyPlanData } from '../../graphQL';
import * as copy from './safetyPlan.copy';

type Props = {
  safetyPlan: SafetyPlanData;
};
export const ViewSafetyPlan = ({ safetyPlan }: Props) => {
  const { distractions, professionalContacts, crisisFacilities, crisisResources } = safetyPlan;

  return (
    <div>
      <Section>
        <Text.body>{copy.reference}</Text.body>
      </Section>
      <hr />
      <Section step={1} title="Making the environment safe">
        <Text.bodyBold>Access to firearms</Text.bodyBold>
        <IndentedText>
          <em>{safetyPlan.firearmAccess ? 'Yes' : 'No'}</em>
        </IndentedText>
        {safetyPlan.firearmAccessPlan && (
          <>
            <Text.bodyBold>Plan to limit access to firearms</Text.bodyBold>
            <IndentedText>
              <em>{safetyPlan.firearmAccessPlan}</em>
            </IndentedText>
          </>
        )}
        <Text.bodyBold>Access to lethal means</Text.bodyBold>
        <IndentedText>
          <em>{safetyPlan.dangerousMeans ? 'Yes' : 'No'}</em>
        </IndentedText>
        {safetyPlan.dangerousMeansList && (
          <>
            <Text.bodyBold>Accessible lethal means</Text.bodyBold>
            <IndentedText>
              <em>{safetyPlan.dangerousMeansList || 'Not Specified'}</em>
            </IndentedText>
          </>
        )}
        {safetyPlan.dangerousMeansPlan && (
          <>
            <Text.bodyBold>Plan to limit access to means</Text.bodyBold>
            <IndentedText>
              <em>{safetyPlan.dangerousMeansPlan || 'Not Specified'}</em>
            </IndentedText>
          </>
        )}
      </Section>
      <hr />
      <Section
        step={2}
        title="Warning signs (thoughts, images, mood, situations, behaviors) that a crisis may be developing"
      >
        <OrderedList list={safetyPlan.warningSigns} render={s => s} />
      </Section>
      <hr />
      <Section step={3} title="Coping strategies">
        <OrderedList list={safetyPlan.copingStrategies} render={s => s} />
      </Section>
      <hr />
      <Section step={4} title="People and social settings that provide distraction">
        <OrderedList list={distractions} render={s => `${s.name}, ${s.contact}`} />
      </Section>
      <hr />
      <Section step={5} title="Professionals, people or agencies I can contact during a crisis">
        <Text.bodyBold>Professionals</Text.bodyBold>
        <Text.body kind="danger">{copy.mantraReminder}</Text.body>
        <OrderedList list={professionalContacts} render={s => `${s.name}, ${s.contact}`} />
        <Text.bodyBold>Urgent Care/ Emergency Facilities</Text.bodyBold>
        <OrderedList
          list={crisisFacilities}
          render={s => `${s.name}, ${s.contact}, ${s.address}`}
        />
        <Text.bodyBold>Other Crisis Resources</Text.bodyBold>
        <OrderedList list={crisisResources} render={s => `${s.name}, ${s.contact}`} />
      </Section>
      <hr />
      <Section title="The one thing that is most important to me and worth living for">
        <IndentedText>{safetyPlan.reasonToLive || 'Not Specified'}</IndentedText>
      </Section>
    </div>
  );
};

const IndentedText: React.FC = ({ children }) => (
  <Text.body className="ml4 mv3">
    <em>{children}</em>
  </Text.body>
);

type SectionProps = {
  step?: number;
  title?: string;
};
const Section: React.FC<SectionProps> = ({ children, step, title }) => (
  <div className="pv3">
    {step && <Text.label>Step {step}</Text.label>}
    {title && <Text.h3 className="mb3">{title}</Text.h3>}
    {children}
  </div>
);

type ListProps<T> = {
  list: T[];
  render: (arg0: T) => React.ReactNode;
};
function OrderedList<T>({ list, render }: ListProps<T>) {
  return list.length === 0 ? (
    <IndentedText>Not Specified</IndentedText>
  ) : (
    <ol>
      {list.map((s, i) => (
        <em key={i}>
          <li>
            <Text.body>{render(s)}</Text.body>
          </li>
        </em>
      ))}
    </ol>
  );
}
