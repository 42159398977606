import React from 'react';
import { Text } from '../../globalStyles';
import { Icon } from '../Icons';

export const ReferralCreditsOverview = ({
  periodEnd,
  availableCredits,
  therapyCreditWeight,
  psychiatryCreditWeight,
}: {
  periodEnd?: string;
  availableCredits?: number;
  therapyCreditWeight?: number;
  psychiatryCreditWeight?: number;
}) => {
  if (
    !periodEnd ||
    !therapyCreditWeight ||
    !psychiatryCreditWeight ||
    availableCredits === undefined
  ) {
    return null;
  }
  return (
    <div className="bg-white ba b--white br3 pa3">
      <div className="flex">
        <Icon icon="iconsStarYellowSvg" size={24} alt="Referral Credits" className="mr2" />
        <div>
          <Text.bodyBold>
            Your school has {availableCredits} remaining referral credit
            {availableCredits === 1 ? '' : 's'}.
          </Text.bodyBold>
          <Text.bodySmall className="mt3">Usable until {periodEnd}</Text.bodySmall>
          <Text.bodySmall>
            <ul className="mt0">
              <li> {therapyCreditWeight} credits per Therapy referral</li>
              <li> {psychiatryCreditWeight} credits per Psychiatry referral</li>
            </ul>
          </Text.bodySmall>
        </div>
      </div>
    </div>
  );
};
