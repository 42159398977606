import { max, min } from 'lodash';
import { useState } from 'react';

// convert all dates to MS since epoch
type LooseDate = Date | number | string;
const MIN_DATE_PADDING = 86400000 * 14; // 2 weeks
const getMs = (date: LooseDate): number => {
  switch (typeof date) {
    case 'number':
      return date;
    case 'object':
      return date.getTime();
    default:
      return new Date(date).getTime();
  }
};
export const useTimeline = <T extends any>(
  startData: T,
  getRelevantDates: (data: T) => LooseDate[]
) => {
  const [data, setData] = useState<T>(startData);

  const now = new Date().getTime();
  const relevantDates = [...getRelevantDates(data), now].map(getMs);

  const [start, end] = (() => {
    // can assert because length 1 gaurenteed
    const firstRelevantEvent = min(relevantDates)!;
    const lastRelevantEvent = max(relevantDates)!;
    const padding = (lastRelevantEvent - firstRelevantEvent + MIN_DATE_PADDING) * 0.1;
    return [firstRelevantEvent - padding, lastRelevantEvent + padding];
  })();

  const distanceToTime = (distance: number) => distance * (end - start) + start;
  const timeToDistance = (date: LooseDate) => (getMs(date) - start) / (end - start);

  return { timeToDistance, distanceToTime, start, end, data, setData };
};
