import { Tab, Tabs } from 'baseui/tabs';
import React, { useState } from 'react';
import { useEvents } from '../../Components/Events/EventsProvider';
import { useCurrentProvider } from '../../Components/Permissions';
import { useQueryParams } from '../../Hooks';
import { Overrides } from '../Users/baseuiOverrides';
import { SettingsPageTitle } from './SettingsPageTitle';
import { settingsTabs } from './SettingsTabs';

const tabKeys = ['profile', 'notifications', 'availability', 'faq'] as const;
type TabkeysType = typeof tabKeys[number];

export function ProviderSettings() {
  const { track } = useEvents();
  const [queryParams, setParams] = useQueryParams();
  const providerContext = useCurrentProvider();

  const permissibleTabs = settingsTabs.filter(t => t.condition(providerContext));

  const defaultTab: TabkeysType = (() => {
    if (permissibleTabs.map(({ key }) => key).includes(queryParams.tab)) {
      return queryParams.tab;
    }
    setParams({ tab: permissibleTabs[0].key });
    return permissibleTabs[0].key;
  })();

  const [activeTab, setActiveTab] = useState<TabkeysType>(defaultTab);

  const handleActiveTabChanged = (newTab: TabkeysType) => {
    if (!permissibleTabs.some(t => t.key === newTab)) return;
    setActiveTab(newTab);
    setParams({ tab: newTab });
    track(`settings.tab.changed`, { tab: newTab });
  };

  return (
    <div>
      <SettingsPageTitle />
      <Tabs
        activeKey={activeTab}
        onChange={({ activeKey }) => handleActiveTabChanged(activeKey as TabkeysType)}
        overrides={Overrides.Tabs}
      >
        {permissibleTabs.map(sub => (
          <Tab title={sub.text} key={sub.key} overrides={Overrides.Tab}>
            <sub.component />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
