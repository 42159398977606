import { partition } from 'lodash';
import React, { useState } from 'react';
import { isMantraAdmin, useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { ListChannelFragment, UserDetailFragment } from '../../graphQL';
import { compareJsDates } from '../../utils';
import { Hr, ItemContainer, ListContainer } from './Styles';
import { UserCard } from './UserCard';

export enum ChannelType {
  CareTeam,
  AdminPrivate,
  Private,
}

type ListUser = {
  channel: ListChannelFragment;
  user: UserDetailFragment;
};

type ChannelListProps = {
  users: ListUser[];
  channelType: ChannelType;
  title: string;
  onClickMarkAsRead: (ids: number[]) => void;
};

const SHORTENED_LENGTH = 10;

export const ChannelList = ({ users, channelType, title, onClickMarkAsRead }: ChannelListProps) => {
  const [showMore, setShowMore] = useState(false);

  const { currentProvider } = useCurrentProvider();
  const isAdmin = isMantraAdmin(currentProvider);

  const sortedUsers = users.sort((a, b) =>
    compareJsDates({ a: a.channel.lastMessage?.createdAt, b: b.channel.lastMessage?.createdAt })
  );

  const [unreadUsers, allReadUsers] = partition(sortedUsers, u => !!u.channel.unreadMessageCount);
  const unreadToShow = SHORTENED_LENGTH - unreadUsers.length;
  const readUsers = showMore ? allReadUsers : allReadUsers.slice(0, unreadToShow);

  const markAsRead = () => onClickMarkAsRead(unreadUsers.map(u => u.channel.id));

  return (
    <div>
      <ListContainer className="ph4 pv4 ba bg-white br2 flex-auto mw6">
        <ItemContainer className="flex justify-between items-center">
          <Text.h2>{title}</Text.h2>
          {unreadUsers.length !== 0 && isAdmin && (
            <Text.linkButton kind="black" className="o-50" onClick={markAsRead}>
              Mark read
            </Text.linkButton>
          )}
        </ItemContainer>
        {users.length === 0 && <Text.body className="i">No Messages</Text.body>}
        {unreadUsers.length !== 0 && <Text.label className="mb3">Unread</Text.label>}
        {unreadUsers.map(u => (
          <UserCard
            key={u.channel.id}
            user={u.user}
            channelType={channelType}
            count={u.channel.unreadMessageCount}
            lastMsg={u.channel.lastMessage}
          />
        ))}
        {readUsers.length !== 0 && unreadUsers.length !== 0 && <Hr />}
        {readUsers.map(u => (
          <UserCard
            isRead
            key={u.channel.id}
            user={u.user}
            channelType={channelType}
            lastMsg={u.channel.lastMessage}
          />
        ))}
        {!showMore && allReadUsers.length > unreadToShow && (
          <Text.linkButton onClick={() => setShowMore(true)} className="mt3">
            Show More
          </Text.linkButton>
        )}
        {showMore && (
          <Text.linkButton onClick={() => setShowMore(false)} className="mt3">
            Show Less
          </Text.linkButton>
        )}
      </ListContainer>
    </div>
  );
};
