import moment from 'moment';
import React from 'react';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { usePatientsQuery } from '../../graphQL';
import { compareDates, compareNames, getFullName } from '../../modelUtils/users';
import { useProviderDrilldownContext } from './context';

export function PatientsTab() {
  const { getCopy } = useCurrentProvider();
  const { provider } = useProviderDrilldownContext();
  const { data, loading } = usePatientsQuery({
    variables: {
      providerId: provider.id,
    },
  });
  if (loading || !data) return <LoadingPage />;
  return (
    <div className="mh5 mv3">
      <ListView
        data={data.adminProvider.patients}
        getKey={patient => patient.id}
        columns={[
          { key: 'name', title: 'Name', render: getFullName, sort: compareNames },
          {
            key: 'enrollDate',
            title: 'Enrollment Date',
            render: p => moment(p.createdAt).format('MM/DD/YYYY'),
            sort: (a, b) => compareDates(a.createdAt?.toString(), b.createdAt?.toString()),
          },
        ]}
        initialSortColumn="enrollDate"
        initialSortDirection="desc"
        paginate={false}
        link={p => `/users/${p.id}`}
        empty={`No ${getCopy('patients')}`}
      />
    </div>
  );
}
