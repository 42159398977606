import React from 'react';
import { useCurrentProvider } from '../../../Components/Permissions';
import { PharmacyDetail } from '../../../Components/PharmacyDetail';
import { useAdminUserPharmaciesQuery } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { InTextSpinner, Styles, Text } from '../styles';
import { EditPharmacy } from '../Widgets/EditPharmacy';

export const PharmacySection = () => {
  const { appView } = useCurrentProvider();
  const { user } = useDrilldownContext();

  const skip = appView !== 'oz';

  const { data, loading, refetch } = useAdminUserPharmaciesQuery({
    variables: { userId: user.id },
    notifyOnNetworkStatusChange: true,
    skip,
  });
  const pharmacy = data?.adminUserPharmacies.find(i => i.primary);

  if (skip) return null;
  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Pharmacy</Text.h3>
        {loading && <InTextSpinner />}
        {!loading && <EditPharmacy onUpdate={refetch} />}
      </Styles.sidebarSectionHeader>
      {data && !pharmacy && <Text.body>No selected pharmacy</Text.body>}
      {!loading && pharmacy && <PharmacyDetail pharmacy={pharmacy} />}
    </Styles.sidebarSection>
  );
};
