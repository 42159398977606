import React from 'react';
import { colors, Text } from '../../globalStyles';

export const RequiredLabel: React.FC | string = ({ children }) => {
  return (
    <Text.bodyBold style={{ display: 'flex', alignItems: 'baseline' }}>
      {children}
      <Text.body style={{ color: colors.danger }}> *</Text.body>
    </Text.bodyBold>
  );
};
