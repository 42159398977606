import { times, omit } from 'lodash';
import { SafetyPlanData, SafetyPlanInput, Maybe, SafetyPlanContact } from '../../graphQL';

export const editableSafetyPlan = ({
  warningSigns,
  copingStrategies,
  distractions,
  crisisContacts,
  crisisResources,
  crisisFacilities,
  professionalContacts,
  ...plan
}: SafetyPlanData): SafetyPlanData => ({
  ...plan,
  warningSigns: ensureMinimalLength(warningSigns, '', 3),
  copingStrategies: ensureMinimalLength(copingStrategies, '', 3),
  distractions: ensureMinimalLength(distractions, blankContact, 4),
  crisisContacts: ensureMinimalLength(crisisContacts, blankContact, 4),
  crisisResources: ensureMinimalLength(crisisResources, blankContact, 3),
  crisisFacilities: ensureMinimalLength(crisisFacilities, blankFacility, 1),
  professionalContacts: ensureMinimalLength(professionalContacts, blankContact, 1),
});

const ensureMinimalLength = <T>(arr: T[], blank: T, min: number): T[] => {
  return arr.length > min ? arr : [...arr, ...times(min - arr.length, () => blank)];
};

export const formatPlanForSubmit = ({
  warningSigns,
  copingStrategies,
  distractions,
  crisisContacts,
  crisisResources,
  crisisFacilities,
  professionalContacts,
  ...plan
}: SafetyPlanData): SafetyPlanInput =>
  omit(
    {
      ...plan,
      warningSigns: warningSigns.filter(filterEmpty),
      copingStrategies: copingStrategies.filter(filterEmpty),
      distractions: distractions.filter(filterEmpty).map(mapContactInput),
      crisisContacts: crisisContacts.filter(filterEmpty).map(mapContactInput),
      crisisResources: crisisResources.filter(filterEmpty).map(mapContactInput),
      crisisFacilities: crisisFacilities.filter(filterEmpty).map(mapContactInput),
      professionalContacts: professionalContacts.filter(filterEmpty).map(mapContactInput),
    },
    '__typename'
  );

const filterEmpty = (arg: any) => {
  if (!arg) return false;
  for (const key of Object.keys(arg)) {
    if (arg[key]) return true;
  }
  return false;
};

const mapContactInput = ({ name, contact, address }: SafetyPlanContact) => ({
  name,
  contact,
  address,
});

export const blankContact: SafetyPlanContact = { name: '', contact: '' };
export const blankFacility: SafetyPlanContact = { name: '', contact: '', address: '' };

export const nullableYesNo = (value?: Maybe<boolean>) => {
  if (value === undefined || value === null) return undefined;
  return value ? 'Yes' : 'No';
};
