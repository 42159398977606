import React, { useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { visitNotes } from '../../utils';
import { Text, colorMap } from '../../globalStyles';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { MantraSvg } from '../Flair';
import { LoadingPage } from '../LoadingOverlay';
import { textTagMap } from '../NoteCopy';
import { WarningCompleteEligibilityForm, WarningOneSessionLeft } from './CocEligibility';
import { TabBodyProps } from '../PopoverTabs/PopoverTab';
import { PopoverTabFooter } from '../PopoverTabs/PopoverTabFooter';
import { Question } from './Question';
import * as Styles from './styles';
import { useMedicalNote } from './useMedicalNote';
import { useCurrentProvider } from '../Permissions';
import { useCocEligibility } from '../ContinuityOfCare/hooks/useCocEligibility';

type NoteProps = {
  tab: TabBodyProps;
  noteKey: string;
};

export const MedicalNotePopover = ({ noteKey, tab }: NoteProps) => {
  const { refetch, user } = useDrilldownContext();
  const provider = useCurrentProvider();
  const note = useMedicalNote({ userId: user.id, noteKey });
  const scrollRef = useRef<HTMLDivElement>(null);
  const [saving, setSaving] = useState(false);
  const careType = provider.currentProvider.careTypes[0];
  const { hasFilledOutEligibilityForm, showEligibilityModule } = useCocEligibility({ careType });

  // Show alerts in visit notes if provider needs to select continuity of care next steps
  const showEligibilityFormAlert =
    showEligibilityModule && visitNotes.includes(noteKey) && !hasFilledOutEligibilityForm;
  const nextSteps = user.continuityOfCareTransitions?.filter(i => i.careType === careType);
  const oneRemainingSession =
    user.careFlows.find(i => i.careType === careType)?.careSessionInfo?.remainingAppts === 1;
  const showNextStepsAlert =
    showEligibilityModule && !showEligibilityFormAlert && !nextSteps?.length && oneRemainingSession;

  // if the title is a free-form-note hide the default title because the user can input their own.
  const noteTitle = 'Free-Form Note';

  useEffect(() => {
    // refetch on unmount
    return () => void refetch();
  }, [refetch]);

  if (note.loading) {
    return (
      <div className="pv7">
        <LoadingPage />
      </div>
    );
  }

  if (!note.template) return null;

  const onClickFooterButton = async () => {
    try {
      setSaving(true);
      if (note.canSubmit) {
        await note.submit();
        tab.confirmExit();
      } else {
        await note.save();
        tab.minimize();
      }
    } finally {
      setTimeout(() => setSaving(false), 100);
    }
  };

  const TitleTag = note.template.titleTag ? textTagMap[note.template.titleTag] : Text.h1;

  return (
    <Styles.NotePopoverContainer>
      <Styles.NoteWrapper ref={scrollRef}>
        {(showEligibilityFormAlert || showNextStepsAlert) && (
          <Styles.Warning className="flex justify-between">
            <div style={{ marginTop: 1, marginRight: 12 }}>
              <MantraSvg icon="alert" fill={colorMap.warning[0]} size={20} />
            </div>
            {showEligibilityFormAlert && (
              <WarningCompleteEligibilityForm careType={careType} noteTab={tab} />
            )}
            {showNextStepsAlert && <WarningOneSessionLeft />}
          </Styles.Warning>
        )}
        {note.submitError && (
          <Styles.Error>{note.submitError.message ?? 'Something went wrong'}</Styles.Error>
        )}
        {note.template.title !== noteTitle && (
          <TitleTag className="mb4">{note.template.title}</TitleTag>
        )}
        {!!note.template.patientDetails?.length && (
          <div className="mb4">
            {note.template.patientDetails.map(details => (
              <div key={details.key} className={details.breakLine ? 'mb3' : ''}>
                <Text.bodyBold className="di">{details.key}:</Text.bodyBold>{' '}
                <Text.body className="di">{details.value}</Text.body>
              </div>
            ))}
          </div>
        )}
        <form onSubmit={e => e.preventDefault()}>
          <FormProvider {...note.formContext}>
            {note.questions.map(question => (
              <Question
                queueForSave={note.queueForSave}
                noteKey={noteKey}
                noteTitle={note?.template?.title || noteTitle}
                key={question.key}
                question={question}
                highlightActive={note.highlightActive}
              />
            ))}
          </FormProvider>
        </form>
      </Styles.NoteWrapper>
      <PopoverTabFooter
        buttons={[
          {
            loading: saving,
            disabled: saving,
            onClick: onClickFooterButton,
            children: note.canSubmit ? 'Submit' : 'Save & Close',
            kind: note.canSubmit ? 'primary' : 'outline_black',
          },
        ]}
        text={
          note.canSubmit
            ? 'If submitted, this note cannot be edited later.'
            : 'Changes are saved automatically.'
        }
        totalQuestions={note.totalRequiredQuestions}
        remainingQuestions={note.remainingRequiredQuestions}
        onScrollTrigger={note.scrollToRequiredQuestions}
      />
    </Styles.NotePopoverContainer>
  );
};
