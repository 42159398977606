import React, { useState } from 'react';
import { FinalButton } from '../FinalButton';
import { EditAppointmentModal, EditType } from './EditAppointmentModal';
import { AppointmentForLink } from './types';

export const statusToEditType = (status: string): EditType => {
  const statusMap: Record<string, EditType> = {
    complete: EditType.completed,
    'no show': EditType.noShow,
  };

  if (statusMap[status]) {
    return statusMap[status]!;
  }

  return EditType.openUnmarked;
};

interface Props {
  appointment: AppointmentForLink;
  iconOnly?: boolean;
  onEdit?: () => void;
}

export function EditAppointmentLink({ appointment, onEdit, iconOnly }: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClosed = (changed: boolean) => {
    setModalOpen(false);
    if (changed && onEdit) {
      onEdit();
    }
  };

  if (['cancelled', 'rescheduled'].includes(appointment.status)) return null;
  return (
    <>
      <FinalButton
        size="compact"
        onClick={() => setModalOpen(true)}
        className="tl"
        iconLeft={{ icon: 'iconsPencilSvg', size: 18, alt: 'Mark as' }}
        kind="minimal_gray"
      >
        {!iconOnly && 'Mark as...'}
      </FinalButton>
      {modalOpen && (
        <EditAppointmentModal
          appointment={appointment}
          onClose={handleModalClosed}
          status={statusToEditType(appointment.status)}
        />
      )}
    </>
  );
}
