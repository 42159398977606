import React, { useState } from 'react';
import { Icon, icons } from '../../Components/Icons';

/*
 * run "yarn icons" to index all the icons in src/assets
 */
export const DebugIcons = () => {
  const [search, setSearch] = useState('');
  const [backgroundColor, setBgColor] = useState('#fafafa');
  const iconsArr = Object.entries(icons);

  const lowerCaseSearch = search.toLowerCase();
  const filteredIcons = lowerCaseSearch
    ? iconsArr.filter(i => i.some(s => s.toLowerCase().includes(lowerCaseSearch)))
    : iconsArr;

  const textColor = getColorByBgColor(backgroundColor);

  return (
    <div>
      <div className="mb4 flex gap-3">
        <input
          placeholder="search for icons"
          value={search}
          onChange={e => setSearch(e.currentTarget.value)}
        />

        <input
          title="background color"
          type="color"
          value={backgroundColor}
          onChange={e => setBgColor(e.currentTarget.value)}
        />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(14rem,1fr))',
          gap: '1rem',
          width: '100%',
        }}
      >
        {filteredIcons.map(([name, path]) => {
          const fullpath = `src/assets${path}`;
          return (
            <div
              key={name}
              className="flex flex-column items-center pv3"
              style={{ backgroundColor, color: textColor }}
            >
              <Icon
                icon={name as any}
                size={48}
                className="pointer"
                onClick={() => window.open(path)}
              />
              <pre className="mv0 f5">{name}</pre>
              <pre className="mv0 f7">{fullpath}</pre>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export function getColorByBgColor(bgColor: string) {
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff';
}
