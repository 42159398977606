import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as qs from 'querystring';
import { useEmailUnsubscribeMutation } from '../../graphQL';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { UnexpectedError } from '../Shared';

export function Unsubscribe() {
  const location = useLocation();
  const query = qs.parse(location.search.replace(/^\?/, ''));
  const token = query.token as string;
  const [mutate, { data, loading, error }] = useEmailUnsubscribeMutation();

  useEffect(() => {
    mutate({ variables: { token } });
  }, [mutate, token]);

  if (loading) return <LoadingPage />;
  if (error) return <UnexpectedError />;
  if (data?.emailUnsubscribe === true) {
    return (
      <div className="center mv5 mh2">
        <h2 className="mb3">Unsubscribe successful</h2>
        <p>You have been successfully removed from receiving Mantra Health emails.</p>
      </div>
    );
  }
  return null;
}
