import { toaster } from 'baseui/toast';
import { useEffect, useRef } from 'react';

export const useVersionChecker = () => {
  const initialVersion = useRef<string>();

  const getVersion = async () => {
    try {
      const res = await fetch('/version.txt');
      const version = await res.text();
      return version;
    } catch {
      return null;
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    const interval = setInterval(async () => {
      const version = await getVersion();
      if (!version) return;
      if (!initialVersion.current) {
        initialVersion.current = version;
      } else if (version !== initialVersion.current) {
        toaster.info(
          'A new update has been released. Please refresh your browser for the best experience.',
          { autoHideDuration: 0 }
        );
        clearInterval(interval);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);
};
