import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { GenericSmallModal } from '../../Components/Modal';
import { useAdminMarkNoShowMutation } from '../../graphQL';
import { LoadingWidget } from '../Users/Widgets/Loading';
import * as Styles from './styles';
import { useVideoCall } from './useVideoCall';

export function NoShowButton({
  appointmentId,
  fullName,
  providerId,
}: {
  appointmentId: number;
  fullName: string;
  providerId: number;
}) {
  const [markCallNoShow, markCallNoShowMutation] = useAdminMarkNoShowMutation();
  const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { trackVideoCallNoShowConfirm } = useVideoCall({
    appointmentId,
    providerId,
  });

  if (!id) return null;
  const userId = Number(id);

  const confirmNoShow = async () => {
    // Log an emission that the no-show confirmation button was clicked.
    trackVideoCallNoShowConfirm();

    await markCallNoShow({ variables: { userId, appointmentId } });

    history.push(`/users/${id}/noShow`);
  };

  return (
    <>
      <FinalButton
        kind="minimal_danger"
        type="button"
        loading={markCallNoShowMutation.loading}
        onClick={() => setIsOpen(true)}
      >
        Mark no show
      </FinalButton>

      <GenericSmallModal onClose={() => setIsOpen(false)} isOpen={isOpen} autofocus>
        <Styles.waitingText>
          Confirm {fullName}
          <br /> as no-show.
        </Styles.waitingText>

        {markCallNoShowMutation.loading ? (
          <LoadingWidget />
        ) : (
          <div className="flex flex-column gap-3 w-100">
            <FinalButton kind="outline_danger" pill onClick={confirmNoShow}>
              Confirm No-Show
            </FinalButton>
            <FinalButton kind="outline_black" pill onClick={() => setIsOpen(false)}>
              Cancel
            </FinalButton>
          </div>
        )}
      </GenericSmallModal>
    </>
  );
}
