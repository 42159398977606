import React from 'react';
import { CareType } from '../../../graphQL';
import { Text } from '../../../globalStyles';
import { TabBodyProps } from '../../PopoverTabs/PopoverTab';
import { usePopoverTabContext } from '../../PopoverTabs/PopoverTabContainer';
import { EligibilityFormPopover } from '../../ContinuityOfCare/EligibilityFormPopover';

type WarningCompleteEligibilityFormProps = {
  noteTab: TabBodyProps;
  careType: CareType;
};

export const WarningCompleteEligibilityForm = ({
  noteTab,
  careType,
}: WarningCompleteEligibilityFormProps) => {
  const { pushTab } = usePopoverTabContext();

  return (
    <div>
      <p className="mt0">
        <b>End of sponsored care approaching for this patient. </b>
      </p>
      <p>
        Please complete the{' '}
        <Text.linkButton
          onClick={() => {
            noteTab.minimize();
            pushTab({
              title: 'Eligibility Form: Upcoming Break',
              tabKey: 'eligibility-form',
              renderBody: ({ tab: newTab }) => (
                <EligibilityFormPopover tab={newTab} careType={careType} />
              ),
            });
          }}
        >
          <b>eligibility form</b>
        </Text.linkButton>{' '}
        in order to determine next steps for this patient&apos;s care.
      </p>
    </div>
  );
};

export const WarningOneSessionLeft = () => (
  <div>
    <p className="mt0">
      <b>1 sponsored session remaining &mdash; no next steps selected.</b>
    </p>
    <p>
      Please do not book next appointment until next steps have been selected for this patient. If
      you have any questions, please reach out to Mantra Care Navigation.
    </p>
  </div>
);
