import moment from 'moment';
import { CareType, EnrollModalQuery } from '../../graphQL';

export function getReferralCreditBreakdown({
  wholeCampusCare,
  orgReferralCreditData,
  careTypes,
}: {
  wholeCampusCare: boolean;
  orgReferralCreditData: EnrollModalQuery['organization']['referralCredits'];
  careTypes: CareType[];
}) {
  let availableCredits: number | undefined;
  let therapyCreditWeight: number | undefined;
  let psychiatryCreditWeight: number | undefined;
  let referralPeriodEnd: string | undefined;
  let referralCreditCost: number | undefined;

  if (wholeCampusCare) {
    availableCredits = orgReferralCreditData?.availableCredits;
    therapyCreditWeight = orgReferralCreditData?.therapyCreditWeight;
    psychiatryCreditWeight = orgReferralCreditData?.psychiatryCreditWeight;
    referralPeriodEnd = orgReferralCreditData?.referralPeriodEnd
      ? moment(orgReferralCreditData.referralPeriodEnd).format('MM-DD-YYYY')
      : undefined;

    referralCreditCost = careTypes.reduce((acc, currCareType) => {
      let cost = 0;

      if (currCareType === CareType.Psychiatry) {
        cost = psychiatryCreditWeight!; // Won't be undefined
      } else if (currCareType === CareType.Therapy) {
        cost = therapyCreditWeight!; // Won't be undefined
      } else {
        throw Error('care type not supported with referral credits');
      }
      return acc + cost;
    }, 0);
  }
  return {
    availableCredits,
    referralCreditCost,
    referralPeriodEnd,
    psychiatryCreditWeight,
    therapyCreditWeight,
  };
}
