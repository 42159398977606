import { Notification } from 'baseui/notification';
import { isNil } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { FinalButton } from '../../../Components/FinalButton';
import {
  Checkbox as CheckboxBase,
  CheckboxProps,
  InputRHF,
  StateSelectRHF,
  ZipInputRHF,
} from '../../../Components/Form';
import { ImageUploadSelector } from '../../../Components/ImageUploadSelector';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { useImageUploader } from '../../../Hooks';
import { Text } from '../../../globalStyles';
import {
  OrganizationType,
  useAdminCompleteOrganizationPhotoUploadMutation,
  useAdminCreateOrganizationMutation,
} from '../../../graphQL';
import { CareTypePicker, MultiText, TimePicker, WeekdayPicker } from './Input';
import { OrgForm } from './interface';
import * as Styles from './styles';
import { formatAddress, formatDepartmentHours, handleEntitlements } from './util';

const required = 'This field is required.';

export const CreateOrganization = () => {
  const [orgId, setOrgId] = useState<number>();

  const [loading, setLoading] = useState(false);
  const [is24hours, setIs24Hours] = useState(false);
  const [requiresId, setRequiresId] = useState(false);
  const [ageRequirement, setAgeRequirement] = useState(false);

  const [updateLogo] = useAdminCompleteOrganizationPhotoUploadMutation();
  const [createOrg, { error: createError }] = useAdminCreateOrganizationMutation();

  const form = useForm<OrgForm>();

  const { upload, accepts, error, onDropRejected, onRetry } = useImageUploader({
    completeFunction: (key: string, args?: { organizationId: number }) =>
      updateLogo({ variables: { key, organizationId: args?.organizationId! } }),
  });

  useEffect(() => {
    form.register({ name: 'photo' });
    // zip input must be pre-registered
    form.register({ name: 'zip' });
  });

  const submit = form.handleSubmit(async vals => {
    const {
      fromDay,
      toDay,
      fromTime,
      toTime,
      minAge,
      careEntitlements = [],
      careTypes = [],
      address,
      city,
      state,
      zip,
      photo,
      ...rest
    } = vals;

    const departmentAddress = formatAddress({
      address,
      city,
      state,
      zip,
    });

    const entitlements = handleEntitlements({
      requiresId,
      minAge: ageRequirement && minAge ? Number(minAge) : undefined,
      careEntitlements,
    });

    const departmentHours = formatDepartmentHours(is24hours, {
      fromDay,
      toDay,
      fromTime,
      toTime,
    });

    try {
      setLoading(true);
      // for now, all organizations are universities
      const result = await createOrg({
        variables: {
          input: {
            ...rest,
            departmentAddress,
            entitlements,
            departmentHours,
            careTypes,
            type: OrganizationType.University,
          },
        },
      });
      const organizationId = result.data?.createOrganization?.id;
      if (!organizationId) {
        return;
      }
      if (photo) {
        await upload(photo, { organizationId });
      }
      setOrgId(organizationId);
    } catch {
      // @ts-ignore
    } finally {
      setLoading(false);
    }
  });

  if (!isNil(orgId)) {
    return <Redirect to={`/organizations/${orgId}/admin`} />;
  }

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Styles.Background>
      <Styles.Wrapper>
        <Text.h1>Create Organization</Text.h1>
        <FormProvider {...form}>
          <form onSubmit={submit}>
            {createError && (
              <Notification kind="negative">
                {createError.message.replace('GraphQL error:', '')}
              </Notification>
            )}
            <Text.h2 className="mv3 mh0">Basics</Text.h2>
            <Styles.FormSectionName>
              <InputRHF
                name="name"
                placeholder="Name"
                rules={{ required }}
                controlProps={{ label: 'Name' }}
              />
              <InputRHF
                name="abbreviation"
                placeholder="Abbreviation"
                rules={{ required }}
                controlProps={{ label: 'Abbreviation' }}
              />
            </Styles.FormSectionName>
            <InputRHF
              name="departmentName"
              placeholder="Department Name"
              rules={{ required }}
              controlProps={{ label: 'Department Name' }}
            />
            <Styles.FormSectionBasic>
              <MultiText
                title="Email Domains (e.g. mantrahealth.com)"
                name="emailDomains"
                text="Email Domain"
                control={form.control}
                errorCaption={
                  (form.errors.emailDomains as any)?.type === 'validate' ? required : undefined
                }
                rules={{ validate: e => e.length > 0 }}
              />
            </Styles.FormSectionBasic>
            <Text.h2 className="mv3 mh0">Address</Text.h2>
            <InputRHF
              name="address"
              placeholder="Address"
              rules={{ required }}
              controlProps={{ label: 'Address' }}
            />
            <InputRHF
              name="city"
              placeholder="City"
              rules={{ required }}
              controlProps={{ label: 'City' }}
            />
            <Styles.FormSectionBasic>
              <StateSelectRHF
                name="state"
                placeholder="State"
                controlProps={{ label: 'State' }}
                rules={{ required }}
              />
              <ZipInputRHF
                name="zip"
                placeholder="Zip"
                rules={{ required }}
                controlProps={{ label: 'Zip' }}
              />
            </Styles.FormSectionBasic>
            <Text.h2 className="mv3 mh0">Department Hours</Text.h2>
            <Checkbox
              title="Always Available (24/7)"
              onChange={() => setIs24Hours(!is24hours)}
              checked={is24hours}
            />
            {!is24hours && (
              <Styles.FormSectionAvailability>
                <WeekdayPicker
                  name="fromDay"
                  placeholder="From"
                  control={form.control}
                  errorCaption={form.errors.fromDay?.message}
                  rules={{ required: !is24hours && required }}
                />
                <WeekdayPicker
                  name="toDay"
                  placeholder="To"
                  control={form.control}
                  errorCaption={form.errors.toDay?.message}
                  rules={{ required: !is24hours && required }}
                />
                <TimePicker
                  name="fromTime"
                  control={form.control}
                  errorCaption={form.errors.fromTime?.message}
                  rules={{ required: !is24hours && required }}
                  defaultValue={moment().startOf('hour').toDate()}
                  step={30 * 60}
                />
                <TimePicker
                  name="toTime"
                  control={form.control}
                  errorCaption={form.errors.toTime?.message}
                  rules={{ required: !is24hours && required }}
                  defaultValue={moment().add(1, 'hour').startOf('hour').toDate()}
                  step={30 * 60}
                />
              </Styles.FormSectionAvailability>
            )}
            <Text.h2 className="mv3 mh0">Other</Text.h2>
            <Styles.FieldName>Organization Logo</Styles.FieldName>
            <div className="mb3">
              <ImageUploadSelector
                onSelect={file => form.setValue('photo', file ?? undefined)}
                accepts={accepts}
                errorMessage={error}
                onRetry={onRetry}
                onDropRejected={onDropRejected}
              />
            </div>
            <CareTypePicker
              title="Care Types"
              name="careTypes"
              control={form.control}
              errorCaption={(form.errors.careTypes as any)?.message}
              rules={{ required }}
            />
            <Checkbox
              title="Require Identification"
              onChange={() => setRequiresId(!requiresId)}
              checked={requiresId}
            />
            <Checkbox
              title="Minimum Age Requirement"
              onChange={() => setAgeRequirement(!ageRequirement)}
              checked={ageRequirement}
            />
            {ageRequirement && (
              <InputRHF
                name="minAge"
                placeholder="18"
                min={0}
                max={100}
                type="number"
                rules={{ required: ageRequirement && required }}
              />
            )}
            <div className="pt3">
              <FinalButton type="submit" kind="primary" data-cy="createOrgSubmit" className="w-100">
                Submit
              </FinalButton>
            </div>
          </form>
        </FormProvider>
      </Styles.Wrapper>
    </Styles.Background>
  );
};

export const Checkbox = ({ title, ...props }: { title: string } & CheckboxProps) => (
  <div className="mv4">
    <CheckboxBase {...props}>{title}</CheckboxBase>
  </div>
);
