import React from 'react';
import { Text, colors } from '../../../globalStyles';
import * as Styles from '../styles';
import { FinalButton } from '../../../Components/FinalButton';

interface EnrollOptionProps {
  title: string;
  label: string;
  description?: string;
  showCreditsRemaining?: boolean;
  availableCredits?: number;
  testId?: string; // testId is for cypress testing
  onClick: () => void;
}

export function EnrollOption({
  title,
  label,
  description,
  showCreditsRemaining,
  availableCredits,
  testId,
  onClick,
}: EnrollOptionProps) {
  return (
    <Styles.OptionContainer>
      <Styles.OptionContainerTitle>
        <Text.h2 className="ml2">{title}</Text.h2>
        <Text.label className="ml2 mt2">{label}</Text.label>
        {description && <Text.bodyGrey className="pt1 ml2 mt2">{description}</Text.bodyGrey>}
        {showCreditsRemaining && (
          <Text.h4
            style={{ color: availableCredits ? colors.success : colors.danger }}
            className="pt1 ml2"
          >
            {availableCredits || 0} Referral Credits Remaining
          </Text.h4>
        )}
      </Styles.OptionContainerTitle>
      <Styles.ButtonContainer>
        <FinalButton size="normal" kind="outline_black" data-cy={testId} onClick={onClick}>
          Select
        </FinalButton>
      </Styles.ButtonContainer>
    </Styles.OptionContainer>
  );
}
