import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useCustomNavBlocker = () => {
  const history = useHistory();
  const [dirty, setDirty] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [exitConfirmed, setExitConfirmed] = useState(false);
  const [nextPage, setNextPage] = useState<Location | null>(null);
  useEffect(() => {
    if (dirty && !exitConfirmed) {
      const unblock = history.block(args => {
        setNextPage(args);
        setShowConfirm(true);
        return false;
      });
      return unblock;
    }
    if (showConfirm && exitConfirmed && nextPage) {
      history.push(nextPage);
    }
  }, [dirty, exitConfirmed, setShowConfirm, history, nextPage, showConfirm]);

  return {
    setDirty,
    showConfirm,
    confirmExit: () => {
      setShowConfirm(false);
      setExitConfirmed(true);
    },
    cancelExit: () => {
      setShowConfirm(false);
      setNextPage(null);
    },
    clickClose: () => {
      if (dirty) {
        setShowConfirm(true);
      }
      return !dirty;
    },
    exitConfirmed,
  };
};
