import React from 'react';
import { Text, colors } from '../../globalStyles';
import { Styles } from '../../Pages/Users/styles';
import { InlineSVG } from '../Icons';

/**
 * Widget for warning that this referral goes over the limit
 */
export const ReferralCreditsUsageInsufficient = ({
  referralCost,
  availableCredits,
}: {
  referralCost?: number;
  availableCredits?: number;
}) => {
  if (!referralCost || !availableCredits) {
    return null;
  }
  return (
    <Styles.widget color={colors.danger} className="flex" style={{ marginBottom: 0 }}>
      <InlineSVG icon="alert-circle" kind="danger" size={24} className="mr2 mt1" />
      <div style={{ flex: 1 }}>
        <Text.bodyBold kind="danger">Insufficient referral credits remaining</Text.bodyBold>
        <Text.bodySmall>
          The cost of this referral ({String(referralCost)} credit{referralCost === 1 ? '' : 's'})
          exceeds the amount of remaining credits ({String(availableCredits)} credit
          {availableCredits === 1 ? '' : 's'}). Please ask your Administrator or authorized
          personnel to reach out to <ReferralCreditPartnerSuccessEmail /> to replenish referral
          credits.
        </Text.bodySmall>
      </div>
    </Styles.widget>
  );
};

export const ReferralCreditPartnerSuccessEmail = () => {
  return (
    <Text.externalLink
      href="mailto:partnersuccess@mantrahealth.com"
      rel="noopener noreferrer"
      target="_blank"
      className="b"
    >
      partnersuccess@mantrahealth.com
    </Text.externalLink>
  );
};
