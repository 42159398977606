import { StatefulCheckbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalHeader, ROLE } from 'baseui/modal';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MaskedPhoneInput } from '../../../Components/EzForm/MaskedInputs';
import { FinalButton } from '../../../Components/FinalButton';
import {
  phoneNumberPattern,
  validatePhoneNumber,
} from '../../../Components/FormValidations/Validators';
import { Notification } from '../../../Components/Notification';
import { Text } from '../../../globalStyles';
import {
  Contact,
  UpsertUserEmergencyContactMutationVariables,
  useDeleteEmergencyContactMutation,
  useUpsertUserEmergencyContactMutation,
} from '../../../graphQL';
import { borderRadius, stripPhoneNumber } from '../../../utils';
import { useDrilldownContext } from '../helpers';

interface EditContactProps {
  title: string;
  contact?: Pick<Contact, 'id' | 'name' | 'phoneNumber' | 'relationship' | 'primary'>;
  forcePrimary: boolean;
  setIsModalOpen: any;
  prefix: string;
}

type FormValues = Pick<
  UpsertUserEmergencyContactMutationVariables,
  'name' | 'relationship' | 'phoneNumber' | 'primary'
>;

export const EditContact = (props: EditContactProps) => {
  const {
    user: { id },
    refetch,
  } = useDrilldownContext();

  const [editContact, editContactMutation] = useUpsertUserEmergencyContactMutation();

  const { contact, title, forcePrimary, setIsModalOpen, prefix } = props;

  const defaultValues = ingestContact(contact, forcePrimary);

  const { handleSubmit, register, errors, getValues, reset, control } = useForm<FormValues>({
    defaultValues,
  });

  const submit = async (values: FormValues) => {
    try {
      await editContact({
        variables: {
          ...contact,
          ...values,
          phoneNumber: stripPhoneNumber(values.phoneNumber),
          userId: id,
        },
      });
      refetch();
      reset();
      setIsModalOpen(false);
    } catch (err) {
      // err is displayed in component
    }
  };

  const [deleteEmergencyContactMutation] = useDeleteEmergencyContactMutation();
  const deleteCurrent = async () => {
    if (props.contact && props.contact.id && !getValues().primary) {
      deleteEmergencyContactMutation({ variables: { id: props.contact!.id } });
      reset();
    }
  };

  useEffect(() => {
    register(
      { name: 'phoneNumber' },
      {
        required: 'This field is required',
        pattern: phoneNumberPattern,
        validate: {
          format: validatePhoneNumber,
        },
      }
    );
    register({ name: 'primary' }, { required: false });
  }, [register, contact]);

  return (
    <>
      <Modal
        isOpen
        onClose={() => setIsModalOpen(false)}
        closeable
        animate
        size="auto"
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              ...borderRadius('4px'),
              paddingLeft: '4rem',
              paddingRight: '4rem',
              paddingTop: '2rem',
              paddingBottom: '2rem',
            },
          },
        }}
        unstable_ModalBackdropScroll
      >
        <ModalHeader>
          <Text.h2>{title}</Text.h2>
          {Boolean(getValues().primary) && <Text.bodyGrey>Primary</Text.bodyGrey>}
        </ModalHeader>

        {editContactMutation.error && (
          <Notification kind="negative">{editContactMutation.error.message}</Notification>
        )}

        <ModalBody>
          <form onSubmit={handleSubmit(submit)}>
            <FormControl label={`${prefix}Name`} caption={errors.name && errors.name.message}>
              <Input
                type="text"
                name="name"
                inputRef={register({ required: 'This field is required' })}
                error={!!errors.name}
                size="default"
                placeholder="Name"
              />
            </FormControl>

            <FormControl
              label={`${prefix}Relationship`}
              caption={errors.relationship && errors.relationship.message}
            >
              <Input
                type="text"
                name="relationship"
                inputRef={register({ required: 'This field is required' })}
                error={!!errors.relationship}
                size="default"
                placeholder="Mother, Father, Friend etc"
              />
            </FormControl>

            <FormControl
              label={`${prefix}Number`}
              caption={errors.phoneNumber && errors.phoneNumber.message}
            >
              <MaskedPhoneInput
                name="phoneNumber"
                error={!!errors.phoneNumber}
                size="default"
                control={control}
                placeholder="(000) 000-0000"
              />
            </FormControl>

            <FormControl>
              <Controller
                name="primary"
                control={control}
                render={({ onChange, ...handler }) => (
                  <StatefulCheckbox
                    overrides={{ Root: { style: { padding: '0.75rem 0 0.75rem 0' } } }}
                    disabled={forcePrimary}
                    onChange={e => onChange(e.currentTarget.checked)}
                    initialState={{ checked: !!defaultValues.primary }}
                    {...handler}
                  >
                    Primary Emergency Contact?
                  </StatefulCheckbox>
                )}
              />
            </FormControl>

            <FinalButton
              className="w-100"
              loading={editContactMutation.loading}
              kind="primary"
              type="submit"
            >
              Save Emergency Contacts
            </FinalButton>
          </form>
          <div className="flex justify-center mt3">
            {Boolean(getValues().primary) || contact === undefined ? (
              <Text.bodyGrey style={{ cursor: 'not-allowed', userSelect: 'none' }}>
                Delete
              </Text.bodyGrey>
            ) : (
              <Text.linkButton kind="danger" onClick={() => deleteCurrent()}>
                Delete
              </Text.linkButton>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ingestContact = (contact: EditContactProps['contact'], forcePrimary: boolean) => {
  if (!contact) {
    return {};
  }
  return {
    id: contact.id,
    name: contact.name ?? '',
    relationship: contact.relationship ?? '',
    phoneNumber: contact.phoneNumber ?? '',
    primary: forcePrimary ? true : contact.primary,
  };
};
