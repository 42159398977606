import React, { useState } from 'react';
import { ReferralErrorCode, useFacultyHubReferralMutation } from '../../../graphQL';
import { getStylesheet } from '../../../styles';
import { Button, Heading, Input, Text, StatusFeedback, Layout } from '../../core';
import { IconAlertTriangle } from '../../icons/IconAlertTriangle';
import { IconPartyPopper } from '../../icons/IconPartyPopper';
import { PageContent } from '../pageCore';

const invalidEmailError = 'Please enter a valid .edu email address';
const inavlidOrgEmailError =
  'Invite not sent. The email entered does not belong to an eligible Mantra Care Hub partner institution.';

export const HubReferralPage = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [referralError, setReferralError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const [submitReferral, { loading }] = useFacultyHubReferralMutation({
    onCompleted: async ({ facultyHubReferral }) => {
      const responseType = facultyHubReferral?.__typename;

      if (responseType === 'ReferralError') {
        if (facultyHubReferral.errorCode === ReferralErrorCode.InvalidOrgEmail) {
          setReferralError(inavlidOrgEmailError);
          return;
        }
        if (facultyHubReferral.errorCode === ReferralErrorCode.InvalidEmail) {
          setReferralError(invalidEmailError);
          return;
        }

        setReferralError('An internal error occurred.');
        return;
      }

      if (responseType === 'FacultyHubReferralSuccess') {
        setShowSuccess(true);
      }
    },
  });

  const clearMessages = (): void => {
    setReferralError('');
    setShowSuccess(false);
  };

  const onEmailChange = (newEmail: string): void => {
    clearMessages();
    setSubmittedEmail('');

    setEmail(newEmail);
  };

  const onSubmit = (): void => {
    clearMessages();

    if (!isValidEduEmail(email)) {
      setReferralError(invalidEmailError);
      return;
    }

    setSubmittedEmail(email);
    setEmail('');

    void submitReferral({ variables: { email } });
  };

  return (
    <PageContent {...styles.page} testID="faculty-staff-referral-page">
      <Layout.VStack space={3}>
        <Heading.h3>Invite student via email</Heading.h3>

        <Text.para>
          Refer a student to the Mantra Care Hub by entering their university email address below.
        </Text.para>

        <Layout.VStack space={1}>
          <Text.paraSmall bold>Student's Email</Text.paraSmall>
          <Input
            autoCapitalize="none"
            keyboardType="email-address"
            onChangeText={onEmailChange}
            onSubmitEditing={onSubmit}
            placeholder="Enter student .edu email address"
            testID="input-email-faculty-staff-referral"
            value={email}
          />
        </Layout.VStack>

        {referralError && (
          <StatusFeedback variant="danger" testID="referral-error-status" Icon={IconAlertTriangle}>
            {referralError}
          </StatusFeedback>
        )}

        {showSuccess && (
          <StatusFeedback variant="success" testID="referral-success-status" Icon={IconPartyPopper}>
            Invite sent! <Text.para bold>{submittedEmail}</Text.para> will receive an invite email
            with more information and a link to sign up for the Mantra Care Hub.
          </StatusFeedback>
        )}

        <Button.primaryLarge
          isDisabled={loading}
          testID="button-submit-facult-staff-referral"
          onPress={onSubmit}
        >
          Send invite
        </Button.primaryLarge>
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  page: {
    alignItems: 'center',
    borderRadius: 4,
    justifyContent: 'center',
    padding: 4,
  },
});

const isValidEduEmail = (email: string): boolean => {
  // Regular expression for basic email validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // First, check if the email matches the regular email pattern
  const isValidEmail = emailRegex.test(email);

  // Then check if it ends with '.edu'
  const endsWithEdu = email.endsWith('.edu');

  return isValidEmail && endsWithEdu;
};
