import { Input, InputProps } from 'baseui/input';
import { StatefulMenu } from 'baseui/menu';
import React, { useEffect, useState, useRef } from 'react';
import { PharmacyFragment, useSearchPharmacyLazyQuery } from '../graphQL';
import { useDebounce } from '../Hooks';

const PLACEHOLDER = '123 Main St. New York, NY';

type SearchProps = {
  setPharmacy: (arg0?: PharmacyFragment) => void;
  pharmacy?: PharmacyFragment | null;
} & InputProps;

export const PharmacySearch = ({ pharmacy, setPharmacy, ...props }: SearchProps) => {
  const [search, { data }] = useSearchPharmacyLazyQuery();
  const ref = useRef<HTMLInputElement>(null);
  const [pharmacyInput, setPharmacyInput] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const query = useDebounce(pharmacyInput, 500);

  const pharmacyString = (p: PharmacyFragment) =>
    `${p.address1}, ${p.city}, ${p.state} ${p.zipCode}`;

  const onSelectPharmacy = (p: PharmacyFragment) => {
    setPharmacy(p);
    setPharmacyInput(pharmacyString(p));
    setShowOptions(false);
  };

  useEffect(() => {
    if (!showOptions || query.length <= 3 || !/\s/g.test(query)) return;
    search({ variables: { query } });
  }, [query, search, showOptions]);

  return (
    <div>
      <Input
        inputRef={ref}
        placeholder={PLACEHOLDER}
        value={pharmacyInput}
        clearable
        onChange={e => {
          setShowOptions(!!e.currentTarget.value);
          setPharmacyInput(e.currentTarget.value);
        }}
        {...props}
      />
      <StatefulMenu
        size="compact"
        items={data?.adminSearchPharmacy ?? []}
        onItemSelect={({ item }) => onSelectPharmacy(item)}
        overrides={{
          List: {
            style: {
              maxHeight: '400px',
            },
            props: {
              hidden: !showOptions,
              onFocus: (e: any) => {
                e.preventDefault();
                ref.current?.focus();
              },
            },
          },
          Option: {
            props: {
              getItemLabel: (item: PharmacyFragment) =>
                `${item.storeName}\n${pharmacyString(item)}`,
            },
          },
        }}
      />
    </div>
  );
};
