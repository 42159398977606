import React from 'react';
import { colors } from '../../globalStyles';
import { UtilizationPage } from '../Utilization/UtilizationPage';
import { Entitlement, useOrganizationTopLevelQuery } from '../../graphQL';
import { hasEntitlement } from '../../utils';
import { useOrganizationId } from './util';
import { ContractsPage } from '../Contracts/ContractsPage';
import { UnexpectedError } from '../Shared';
import { ErrorContainer } from '../../Components/ProviderNetwork/Styles';
import { Text } from '../../globalStyles';

export function OrganizationCareOps() {
  const id = useOrganizationId();
  const { data, loading, error } = useOrganizationTopLevelQuery({ variables: { id } });

  if (loading) {
    // Pages implement their own loading spinner
    return null;
  }

  if (error) {
    return <UnexpectedError />;
  }

  const usesDGM = data?.organization?.entitlements
    ? hasEntitlement(data.organization, Entitlement.DedicatedGroupModel)
    : false;

  const usesSessionsModel = data?.organization?.entitlements
    ? hasEntitlement(data.organization, Entitlement.SessionsModel)
    : false;

  if (usesDGM && usesSessionsModel) {
    return (
      <ErrorContainer className="w-50 flex justify center">
        <Text.bodySmall kind="danger">
          Organization cannot have both DGM and Sessions Model entitlements enabled.
        </Text.bodySmall>
      </ErrorContainer>
    );
  }

  if (!usesDGM && !usesSessionsModel) {
    return (
      <ErrorContainer className="w5 flex justify center">
        <Text.bodySmall kind="danger">
          Organization must have either DGM or Sessions Model entitlements enabled.
        </Text.bodySmall>
      </ErrorContainer>
    );
  }

  return (
    <>
      {usesDGM && (
        <div className="pv3" style={{ backgroundColor: colors.grey.lightBackground }}>
          <UtilizationPage />
        </div>
      )}
      {usesSessionsModel && (
        <div className="pv3" style={{ backgroundColor: colors.grey.lightBackground }}>
          <ContractsPage />
        </div>
      )}
    </>
  );
}
