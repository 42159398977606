import moment from 'moment';
import React from 'react';
import { MantraSvg } from '../../../Components/Flair';
import { Text } from '../../../globalStyles';
import { CareDashboardQuery } from '../../../graphQL';
import { getFullNameReversed } from '../../../modelUtils/users';
import { UnstyledLink } from '../../Shared';
import { DashboardTable } from '../DashTable';
import { EmptyState } from '../EmptyState';
import * as Styles from '../styles';

type Props = {
  notes: CareDashboardQuery['unsubmittedMedicalNotes'];
};

const emptyState = <EmptyState text="No unsubmitted notes" icon="note" />;

export const UnsubmittedNotes = ({ notes }: Props) => {
  return (
    <Styles.SectionContainer>
      <Text.h3>Unsubmitted Notes</Text.h3>
      <DashboardTable
        data={notes!}
        getKey={n => n.id}
        emptyState={emptyState}
        columns={[
          {
            render: note => (
              <UnstyledLink to={`/users/${note.user.id}?tab=notes`}>
                <div className="flex justify-start items-center">
                  <MantraSvg icon="note" kind="danger" />
                  <div className="ml2">
                    <Text.bodyBold>{note.title}</Text.bodyBold>
                    <Text.body>
                      {note.dos && `${moment(JSON.parse(note.dos)).format('L')}  •  `}
                      {getFullNameReversed(note.user)}
                    </Text.body>
                    <Text.captionGrey>
                      Last Saved {moment(note.updatedAt).calendar()}
                    </Text.captionGrey>
                  </div>
                </div>
              </UnstyledLink>
            ),
          },
        ]}
      />
    </Styles.SectionContainer>
  );
};
