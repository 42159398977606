import React from 'react';
import { Grid } from '../Grid';
import { Icon, InlineSVG } from '../Icons';
import { useCurrentProvider } from '../Permissions';
import { NavFooter } from './NavFooter';
import { iconMap, ItemId, useNavItems } from './NavItems';
import { CountContainer, Divider, NavLink, NavWrapper } from './Styles';

interface NavigationProps {
  isImpersonating?: boolean;
}

export const Navigation = ({ isImpersonating }: NavigationProps) => {
  const { appView, currentProvider } = useCurrentProvider();
  const { navItems, activeItem } = useNavItems();

  let organizationId: number | undefined;
  if (appView === 'mcp') {
    organizationId = currentProvider.organizations[0].id;
  }

  if (activeItem === ItemId.CrisisReports) {
    return null;
  }

  return (
    <NavWrapper className="dn flex-l w-25">
      <div>
        <div className="mh3 ph2 mv4">
          <InlineSVG
            kind={isImpersonating ? 'dangerBgLogo' : 'white'}
            icon="logo"
            title="Mantra Health logo"
          />
        </div>
        <NavContent navItems={navItems} activeItem={activeItem} />
      </div>
      <NavFooter organizationId={organizationId} />
    </NavWrapper>
  );
};

type NavContentProps = Pick<ReturnType<typeof useNavItems>, 'navItems' | 'activeItem'>;

export const NavContent = ({ navItems, activeItem }: NavContentProps) => {
  return (
    <nav data-cy="navigation">
      {navItems.map((item, i) => {
        const isActive = item.itemId === activeItem;
        const icon = iconMap[item.itemId];
        const { Count } = item;

        return (
          <React.Fragment key={item.itemId}>
            <NavLink
              data-cy={`navlink-${item.title.toLowerCase()}`}
              to={item.path}
              className={isActive ? 'active' : undefined}
            >
              <Grid gridTemplateColumns="2.25rem 1fr" alignItems="center">
                <Icon
                  icon={icon}
                  className={isActive ? 'o-100' : 'o-30'}
                  alt={item.title}
                  size={20}
                />
                <p className={`f6 ${isActive ? 'o-100' : 'o-50'}`}>{item.title}</p>
                {Count && <Count active={isActive} wrapper={CountContainer} />}
              </Grid>
            </NavLink>
            {item.divider && i < navItems.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </nav>
  );
};
