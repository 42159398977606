import React from 'react';
import { useParams } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMcpOrReferralUser, useCurrentProvider } from '../../Components/Permissions';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { PaddedPage, Text } from '../../globalStyles';
import { Permission, useProvidersQuery } from '../../graphQL';
import { McpList } from '../Provider/Lists';
import { UnstyledLink } from '../Shared';

export function StaffPage() {
  const { hasPermission } = useCurrentProvider();
  const params = useParams<{ organizationId: string }>();
  const organizationId = Number(params.organizationId);
  const { data } = useProvidersQuery();
  if (!data) return <LoadingPage />;

  // Only MCP users should appear in this view.
  const mcpUsers = data.providers.filter(isMcpOrReferralUser);

  return (
    <PaddedPage>
      <UniversityLogo />
      <div className="flex mb3">
        <Text.h1>Staff</Text.h1>
        {hasPermission(Permission.ProviderCreate) && (
          <UnstyledLink
            className="ml3"
            to={`/organizations/staff/add?organizationId=${organizationId}`}
          >
            <FinalButton pill iconLeft="iconsWhitePlusSvg" data-cy="addStaffButton">
              Add
            </FinalButton>
          </UnstyledLink>
        )}
      </div>
      <McpList data={mcpUsers} />
    </PaddedPage>
  );
}
