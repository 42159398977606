import { useState } from 'react';
import {
  UploadType,
  useAdminBeginUploadMutation,
  useAdminCompleteUploadMutation,
} from '../../../graphQL';
import { postUploadData } from '../../../utils';
import { MAX_UPLOAD_SIZE } from '../../../constants';
import { useEvents } from '../../../Components/Events/EventsProvider';

type FileUploadProps<T> = {
  userId: number;
  afterCompletion?: () => Promise<T> | T;
};

type CompleteArgs = {
  fileName: string;
  uploadType: UploadType;
  globallyShared: boolean;
};

const accepts = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];

export function useUserFileUpload<T>({ userId, afterCompletion }: FileUploadProps<T>) {
  const { track } = useEvents();
  const [beginUpload] = useAdminBeginUploadMutation();
  const [complete] = useAdminCompleteUploadMutation();

  const [error, setError] = useState<string>();
  const [progress, setProgress] = useState<number>();
  const [completeAsync, setCompleteAsync] =
    useState<(args: CompleteArgs) => ReturnType<typeof complete>>();

  const completeUploadFactory = (key: string) => {
    return async ({ fileName, uploadType, globallyShared }: CompleteArgs) => {
      const data = await complete({
        variables: {
          input: {
            key,
            userId,
            type: uploadType,
            name: fileName,
            globallyShared,
          },
        },
        refetchQueries: ['getAllUploadsOnUser'],
      });
      track('user.file.created', {
        userId,
        uploadType,
        uploadId: data.data?.adminCompleteUpload.id,
      });
      afterCompletion?.();
      return data;
    };
  };

  const reset = () => {
    setProgress(undefined);
    setError(undefined);
    setCompleteAsync(undefined);
  };

  const onRejected = (files: File[]) => {
    if (files.some(f => !accepts.includes(f.type))) {
      setError('Invalid file format');
    } else if (files.some(f => f.size > MAX_UPLOAD_SIZE)) {
      setError('File size is too large');
    } else {
      setError('Unknown error uploading file');
    }
  };

  const onAccepted = (files: File[]) => {
    reset();
    const f = files[0];
    if (!f) return;
    upload(f);
    return f.name;
  };

  const upload = async (file: File) => {
    try {
      setProgress(25);
      const beginResponse = await beginUpload({
        variables: {
          contentType: file.type,
          userId,
        },
      });
      if (!beginResponse?.data?.adminBeginUpload) {
        return setError('There was a problem uploading this file');
      }
      setProgress(66);
      const { fields, key, url } = beginResponse.data.adminBeginUpload;
      await postUploadData(file, url, fields);

      setCompleteAsync(() => completeUploadFactory(key));
      setProgress(100);
    } catch (e) {
      setError('There was a problem uploading this file');
      // rethrow so sentry can catch any issue
      throw e;
    }
  };

  return {
    error,
    progress,
    onRejected,
    onAccepted,
    onRetry: reset,
    accepts,
    maxSize: MAX_UPLOAD_SIZE,
    completeAsync,
  };
}
