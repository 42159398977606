import { first } from 'lodash';
import { CurrentProviderQuery, UploadType } from '../../../graphQL';
import { Upload } from './interfaces';

export const canOnlyUploadGeneric = (permissibleUploadTypes: UploadType[]) => {
  return permissibleUploadTypes.length === 1 && first(permissibleUploadTypes) === UploadType.Other;
};

export const wasCreatedByProvider = (
  provider: CurrentProviderQuery['currentProvider'],
  upload: Upload
) => {
  return provider.id === upload.createdByProvider?.id;
};
