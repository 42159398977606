import React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useAdminCallMetaDataForAppointmentQuery } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { UnexpectedError } from '../Shared';
import { DonePage } from './DonePage';
import { useVideoCall } from './useVideoCall';
import { VideoCallPage } from './VideoCallPage';

export const VideoCall = () => {
  const { id: userId, appointmentId } = useParams<{ id: string; appointmentId: string }>();

  const { data, loading, error } = useAdminCallMetaDataForAppointmentQuery({
    variables: { appointmentId: Number(appointmentId), userId: Number(userId) },
    skip: appointmentId === 'none',
  });

  const appointment = data?.adminAppointmentByUser;

  const { isOnCall, providerEndCall, providerRejoinCall } = useVideoCall({
    appointmentId: Number(appointment?.id),
    providerId: Number(appointment?.provider.id),
  });

  if (loading) return <LoadingPage />;

  if (error || !appointment) return <UnexpectedError />;

  if (appointmentId === 'none') {
    return <div className="f2 mt4 center">This patient has no upcoming appointment.</div>;
  }

  if (!isOnCall) {
    return (
      <DonePage
        name={getFullName(appointment.user)}
        returnToCall={providerRejoinCall}
        apptId={appointment.id}
        providerId={appointment.provider.id}
      />
    );
  }

  return <VideoCallPage appointment={appointment} providerEndCall={providerEndCall} />;
};

export default VideoCall;
