import { Checkbox } from 'baseui/checkbox';
import React, { useState } from 'react';
import { FinalButton } from '../../Components/FinalButton';
import { Input, Textarea } from '../../Components/Form';
import { RequiredLabel } from '../../Components/Form/RequiredLabel';
import { Toggle } from '../../Components/Form/Toggle';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  EntitlementDefinitionConfig,
  EntitlementDefinitionConfigSize,
  UpdateOrganizationEntitlementInput,
  useOrganizationEntitlementsPageQuery,
  useUpdateEntitlementsMutation,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';

type SettingsState = Record<
  string,
  { enabled: boolean; config: any; requiredField?: EntitlementDefinitionConfig['key'] }
>;

export function OrganizationEntitlements() {
  const organizationId = useOrganizationId();
  const [settings, setSettings] = useState<SettingsState>({});
  const [fieldError, setFieldError] = useState<string>('');
  const { data, loading, error } = useOrganizationEntitlementsPageQuery({
    variables: { organizationId },
    onCompleted: res => {
      const ents = res.organization.entitlements;
      const initialValues: SettingsState = res.entitlementDefinitions.reduce(
        (acc, val) => ({
          ...acc,
          [val.key]: {
            enabled: ents.some(i => i.key === val.key),
            config: ents.find(i => i.key === val.key)?.config ?? {},
            // does the entitlement have a required field?
            requiredField:
              res.entitlementDefinitions
                .find(ent => ent.key === val.key)
                ?.config.find(field => field.required)?.key ?? undefined,
          },
        }),
        {}
      );
      setSettings(initialValues);
    },
  });
  const [updateEntitlements, { data: updateData, loading: updateLoading }] =
    useUpdateEntitlementsMutation();

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const defs = data.entitlementDefinitions;

  const onSave = () => {
    const entitlements: UpdateOrganizationEntitlementInput[] = [];
    for (const def of defs) {
      const ent: UpdateOrganizationEntitlementInput = {
        key: def.key,
        enabled: settings[def.key].enabled,
        config: settings[def.key].config,
      };

      // if the entitlement is enabled and has a required field, check that it is present
      if (
        ent.enabled &&
        !!settings[def.key].requiredField &&
        ent.config &&
        !ent.config[settings[def.key].requiredField!]
      ) {
        setFieldError('Please fill out all required fields');
        return;
      }

      for (const key in ent.config) {
        if (def.config.find(i => i.key)?.type === 'number') {
          ent.config[key] = Number(ent.config[key]);
        }
      }
      entitlements.push(ent);
    }
    setFieldError('');
    updateEntitlements({ variables: { organizationId, entitlements } });
  };

  const readOnly = !!data.organization.parent;

  const isEntFieldRequired = (
    entConfig: EntitlementDefinitionConfig,
    component: React.ReactNode | string
  ) =>
    entConfig.required ? (
      <RequiredLabel>{component}</RequiredLabel>
    ) : (
      <Text.bodyBold>{component}</Text.bodyBold>
    );

  return (
    <div>
      <div className="mb4">
        <Text.h2>Entitlements</Text.h2>
        {data?.organization.parent && (
          <Text.body>
            Inherits entitlements from{' '}
            <a href={`/organizations/${data.organization.parent.id}/admin`}>
              {data.organization.parent.name ?? 'Parent'}
            </a>
          </Text.body>
        )}
      </div>
      {defs.map(def => (
        <div key={def.key} className="mb4">
          <div className="flex flex-row">
            <Checkbox
              disabled={readOnly}
              checked={settings[def.key]?.enabled}
              onChange={e =>
                setSettings(val => ({
                  ...val,
                  [def.key]: { ...val[def.key], enabled: e.currentTarget.checked },
                }))
              }
            />
            <div>
              <Text.bodyBold>{def.name}</Text.bodyBold>
              <Text.body>{def.description}</Text.body>
              {settings[def.key]?.enabled && (
                <div className="mt3">
                  {def.config.map(conf => {
                    const maxWidth =
                      conf.size === EntitlementDefinitionConfigSize.Large ? 900 : 300;

                    const onChange = (value: any) =>
                      setSettings(val => ({
                        ...val,
                        [def.key]: {
                          ...val[def.key],
                          config: { ...val[def.key].config, [conf.key]: value },
                        },
                      }));

                    return (
                      <div key={conf.key} className="mb2" style={{ maxWidth }}>
                        {conf.type === 'number' && (
                          <>
                            {conf.name}:{' '}
                            <Input
                              type="number"
                              disabled={readOnly}
                              placeholder={conf.placeholder || ''}
                              value={String(settings[def.key]?.config?.[conf.key] ?? '')}
                              onChange={e => onChange(e.target.value)}
                            />
                          </>
                        )}
                        {conf.type === 'boolean' && (
                          <Toggle
                            toggled={Boolean(settings[def.key]?.config?.[conf.key] ?? false)}
                            onChange={c => onChange(c)}
                          >
                            {conf.name}
                          </Toggle>
                        )}
                        {conf.type === 'textarea' && (
                          <>
                            {isEntFieldRequired(conf, <Text.bodyBold>{conf.name} </Text.bodyBold>)}
                            <Textarea
                              placeholder={conf.placeholder || ''}
                              value={String(settings[def.key]?.config?.[conf.key] ?? '')}
                              onChange={e => onChange(e.target.value)}
                              maxLength={conf?.maxLength || undefined}
                            />
                          </>
                        )}
                        {(conf.type === 'single-line' || conf.type === 'phone') && (
                          <>
                            {isEntFieldRequired(conf, conf.name)}
                            {conf.smallText && (
                              <Text.bodySmallGrey>{conf.smallText}</Text.bodySmallGrey>
                            )}
                            <Input
                              type="string"
                              disabled={readOnly}
                              placeholder={conf.placeholder || ''}
                              value={String(settings[def.key]?.config?.[conf.key] ?? '')}
                              onChange={e => onChange(e.target.value)}
                              maxLength={conf?.maxLength || undefined}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      {!readOnly && (
        <div className="flex flex-row items-center">
          <FinalButton onClick={onSave} loading={updateLoading}>
            Save
          </FinalButton>
          <Text.body className="ml3 red">{fieldError}</Text.body>
          {updateData && <Text.body className="ml3">Saved.</Text.body>}
        </div>
      )}
    </div>
  );
}
