import React, { useState } from 'react';
import { Text } from '../../globalStyles';
import { SafetyPlanData, useEditSafetyPlanMutation } from '../../graphQL';
import { useDrilldownContext, useSaveAndRefetch } from '../../Pages/Users/helpers';
import { EzMultirow, EzMultitext, PillCheckbox } from '../EzForm';
import { Textarea } from '../Form/Input';
import { TabBodyProps } from '../PopoverTabs/PopoverTab';
import { PopoverTabFooter } from '../PopoverTabs/PopoverTabFooter';
import { ConfirmExitModal } from './ConfirmExitModal';
import * as ContactRows from './ContactRows';
import * as Examples from './Examples';
import { Control, Title } from './FormComponents';
import * as copy from './safetyPlan.copy';
import * as Styles from './style';
import { editableSafetyPlan, formatPlanForSubmit, nullableYesNo } from './utils';

const GIVE = 'Give it to family member, close friend, or police';
const OTHER = 'Other (explain)';

const presetSelect = (preset?: string | null) => {
  if (!preset) return;
  return preset === GIVE ? GIVE : OTHER;
};

type Props = {
  userId: number;
  tab: TabBodyProps;
  safetyPlan: SafetyPlanData;
};
export const EditSafetyPlan = ({ userId, tab, safetyPlan }: Props) => {
  const { refetch } = useDrilldownContext();
  const [plan, setPlan] = useState<SafetyPlanData>(editableSafetyPlan(safetyPlan));
  const [select1, setSelect1] = useState(presetSelect(safetyPlan.firearmAccessPlan));
  const [select2, setSelect2] = useState(presetSelect(safetyPlan.dangerousMeansPlan));
  const { setDirty, showConfirm, cancelExit, confirmExit } = tab;
  const [save, { loading }] = useSaveAndRefetch(useEditSafetyPlanMutation(), refetch);

  const submit = async () => {
    setDirty(false);
    await save({ variables: { userId, plan: formatPlanForSubmit(plan) } });
    tab.closeTab();
  };

  const update = (newPlan: Partial<SafetyPlanData>) => {
    setDirty(true);
    setPlan(p => ({ ...p, ...newPlan }));
  };

  const { firearmAccess, firearmAccessPlan, dangerousMeans, dangerousMeansPlan } = plan;

  return (
    <Styles.PopoverContainer>
      {showConfirm && (
        <ConfirmExitModal onCancel={cancelExit} onDiscardChanges={confirmExit} onSubmit={submit} />
      )}
      <Styles.PopoverBody>
        <form onSubmit={e => e.preventDefault()}>
          <Text.h2 className="mb4">Student Safety Plan</Text.h2>
          <Text.body>{copy.reference}</Text.body>
          <Title
            step={1}
            title="Reducing the Potential for Use of Lethal Means"
            subtitle="Reducing access to any potentially lethal means in the environment around you has been shown to decrease the risk of suicide. "
          />
          <Control title="Do you have access to a firearm, such as a handgun, rifle or shotgun?">
            <PillCheckbox
              options={['Yes', 'No']}
              value={nullableYesNo(firearmAccess)}
              onChange={e => {
                setSelect1(undefined);
                update({ firearmAccess: e === 'Yes', firearmAccessPlan: '' });
              }}
              multi={false}
            />
          </Control>
          {firearmAccess && (
            <Control title="Restricting access to guns for patients experiencing suicidal thoughts has been shown to save lives. How can you safely store the firearm?">
              <PillCheckbox
                options={[GIVE, OTHER]}
                value={select1}
                onChange={e => {
                  setSelect1(e);
                  update({ firearmAccessPlan: e === OTHER ? '' : e });
                }}
                multi={false}
              />
            </Control>
          )}
          {firearmAccess && select1 === OTHER && (
            <Control title="Explain plan to safely store firearm">
              <Textarea
                value={firearmAccessPlan || ''}
                onChange={e => update({ firearmAccessPlan: e.target.value })}
              />
            </Control>
          )}
          <Control title="Are there other items (medications, knives, razors, other) that might be unsafe that you can put away? ">
            <PillCheckbox
              options={['Yes', 'No']}
              value={nullableYesNo(plan.dangerousMeans)}
              onChange={e => {
                setSelect2(undefined);
                update({
                  dangerousMeans: e === 'Yes',
                  dangerousMeansList: '',
                  dangerousMeansPlan: '',
                });
              }}
              multi={false}
            />
          </Control>
          {plan.dangerousMeans && (
            <Control title="What is/are the item(s)?">
              <Textarea
                value={plan.dangerousMeansList || ''}
                onChange={e => update({ dangerousMeansList: e.target.value })}
                placeholder="List unsafe items"
              />
            </Control>
          )}
          {plan.dangerousMeans && (
            <Control title="How can you safely store the item(s)?">
              <PillCheckbox
                options={[GIVE, OTHER]}
                value={select2}
                onChange={e => {
                  setSelect2(e);
                  update({ dangerousMeansPlan: e === OTHER ? '' : e });
                }}
                multi={false}
              />
            </Control>
          )}
          {dangerousMeans && select2 === OTHER && (
            <Control title="Explain plan to safely store dangerous items">
              <Textarea
                value={dangerousMeansPlan || ''}
                onChange={e => update({ dangerousMeansPlan: e.target.value })}
              />
            </Control>
          )}
          <Title step={2} title="Recognizing Warning Signs" />
          <Examples.WarningSigns />
          <Control title="What are some warning signs (thoughts, images, mood, situations, behaviors) that a crisis may be developing?">
            <EzMultitext
              placeholder={{ numbered: true, text: 'Warning Sign' }}
              value={plan.warningSigns || []}
              onChange={e => update({ warningSigns: e })}
            />
          </Control>
          <Title step={3} title="Using Internal Coping Strategies" />
          <Examples.CopingStrategies />
          <Control title="Now that you have identified the signs that are associated with a suicidal crisis, what are some activities that you can do on your own to take your mind off your problems?">
            <EzMultitext
              placeholder={{ numbered: true, text: 'Coping Strategy' }}
              value={plan.copingStrategies || []}
              onChange={e => update({ copingStrategies: e })}
            />
          </Control>
          <Title
            step={4}
            title="Utilizing Social Contacts that Can Serve as a Distraction from Suicidal Thoughts and Who May Offer Support"
            subtitle="If Steps 2 and 3 don’t work, what are social supports or situations that can help provide distraction? You don’t necessarily have to talk about your feelings, but talking to, or simply being with, these people can be helpful."
          />
          <Examples.Distractions />
          <Control title="What are some examples of people or situations that can help provide distraction?">
            <EzMultirow
              values={plan.distractions}
              setValues={v => update({ distractions: v })}
              blank={{ name: '', contact: '' }}
              component={ContactRows.Distraction}
            />
          </Control>
          <Title
            step={5}
            title="Contacting Family Members or Friends Who May Offer Help to Resolve a Crisis"
          />
          <Examples.CrisisContacts />
          <Control
            title="Who are some family members or friends who may offer help to resolve a crisis?"
            caption="If possible, someone close to you with whom this safety plan can be shared should be identified."
          >
            <EzMultirow
              values={plan.crisisContacts}
              setValues={v => update({ crisisContacts: v })}
              blank={{ name: '', contact: '' }}
              component={ContactRows.CrisisContact}
            />
          </Control>
          <Title
            step={6}
            title="Contacting Professionals and Agencies"
            subtitle="If the previous strategies (i.e., coping strategies, contacting friends or family members) are not effective, who are some professionals and resources you can contact when experiencing a crisis?"
          />
          <Control
            title="Professionals"
            caption="Therapist, counselor, clinician, etc."
            warning={copy.mantraReminder}
          >
            <EzMultirow
              values={plan.professionalContacts}
              setValues={v => update({ professionalContacts: v })}
              blank={{ name: '', contact: '' }}
              component={ContactRows.ProfessionalContact}
            />
          </Control>
          <Control
            title="Urgent Care / Emergency Facilities"
            caption="List local facilities that you can contact or visit when you’re experiencing a crisis. Please include at least one local emergency facility that is open 24/7."
          >
            <EzMultirow
              values={plan.crisisFacilities}
              setValues={v => update({ crisisFacilities: v })}
              blank={{ name: '', contact: '', address: '' }}
              component={ContactRows.Facility}
            />
          </Control>
          <Control
            title="Other Crisis Resources"
            caption="Hotlines, digital crisis resources, etc."
          >
            <EzMultirow
              values={plan.crisisResources}
              setValues={v => update({ crisisResources: v })}
              blank={{ name: '', contact: '' }}
              component={ContactRows.CrisisResource}
            />
          </Control>
          <Title title="Please name the one thing that is most important to you and worth living for: " />
          <Control>
            <Textarea
              value={plan.reasonToLive || ''}
              onChange={e => update({ reasonToLive: e.target.value })}
            />
          </Control>
        </form>
      </Styles.PopoverBody>
      <PopoverTabFooter buttons={[{ onClick: submit, children: 'Submit', loading }]} />
    </Styles.PopoverContainer>
  );
};
