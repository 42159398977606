import { useState } from 'react';
import { useConnectNowSessionDocumentLazyQuery } from '../../../graphQL';
import { MantraSpinner } from '../../../Components/LoadingOverlay';
import React from 'react';
import { colors, Text } from '../../../globalStyles';
import { Icon } from '../../../Components/Icons';
import { ColumnText } from './ColumnText';

export const DownloadFileButton = ({ healthieNoteId }: { healthieNoteId: string }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [getSessionDocument] = useConnectNowSessionDocumentLazyQuery({
    onCompleted: ({ connectNowSessionReport }) => {
      if (connectNowSessionReport?.document && connectNowSessionReport.document.expiringUrl) {
        window.open(connectNowSessionReport.document.expiringUrl, '_blank');
      }
      setIsLoading(false);
    },
  });

  const handleClick = () => {
    setIsLoading(true);
    getSessionDocument({ variables: { id: healthieNoteId } });
  };

  return isLoading ? (
    <MantraSpinner size={30} className="ml-auto mr5" />
  ) : (
    <div color={colors.link} className="flex items-center pointer ml-auto" onClick={handleClick}>
      <Icon icon="iconsDownloadBlueSvg" className="mr2" size={20} />
      <ColumnText>
        <Text.bold kind="link">Download</Text.bold>
      </ColumnText>
    </div>
  );
};
