import { useCurrentProvider } from '../Components/Permissions';
import { Organization } from '../graphQL';

type UseOrgParentChildCheckArgs = {
  organization?: Pick<Organization, 'id'>;
};
/**
 * Determines if organization in arguments matches any organization in current provider org family tree
 */
export const useOrgParentChildCheck = ({ organization }: UseOrgParentChildCheckArgs) => {
  const { currentProvider } = useCurrentProvider();

  let providerOrgMatchesCurrentProvider = false;
  if (organization && currentProvider?.organizations[0]) {
    const validOrgList: Number[] = [];
    validOrgList.push(currentProvider.organizations[0].id);
    currentProvider.organizations[0].children?.forEach(org => validOrgList.push(org.id));
    const currentProviderParentId = currentProvider.organizations[0].parent?.id;
    if (currentProviderParentId) validOrgList.push(currentProviderParentId);
    providerOrgMatchesCurrentProvider = validOrgList.includes(organization.id);
  }
  return { providerOrgMatchesCurrentProvider };
};
