import { FormControl } from 'baseui/form-control';
import { Input as BaseInput, InputProps as BaseInputProps } from 'baseui/input';
import { StatefulSelect, StatefulSelectProps as SelectProps } from 'baseui/select';
import { TimePicker as BaseTimePicker, TimePickerProps } from 'baseui/timepicker';
import moment from 'moment';
import React from 'react';
import { Control, Controller, ValidationRules } from 'react-hook-form';
import { EzMultitext } from '../../../Components/EzForm';
import { CareType } from '../../../graphQL';
import { OrgForm } from './interface';
import * as Styles from './styles';

type BaseProps = {
  title?: string;
  name: string;
  errorCaption?: string;
};

type RestrictedSelectProps = Omit<
  SelectProps,
  'id' | 'multi' | 'creatable' | 'options' | 'onChange'
>;

type RestrictedInputProps = Omit<BaseInputProps, 'id' | 'name'> & BaseProps;

type ControlledProps<A, B> = {
  control: Control<OrgForm>;
  rules?: ValidationRules;
  defaultValue?: A;
} & BaseProps &
  B;

export function Input({ name, title, errorCaption, ...props }: RestrictedInputProps) {
  return (
    <InputContainer name={name} title={title} errorCaption={errorCaption}>
      <BaseInput id={name} name={name} {...props} />
    </InputContainer>
  );
}

export function CareTypePicker<T>({
  title,
  name,
  control,
  defaultValue,
  errorCaption,
  rules,
  ...props
}: ControlledProps<T, RestrictedSelectProps>) {
  return (
    <InputContainer name={name} title={title} errorCaption={errorCaption}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        rules={rules}
        render={({ onChange, ...handler }) => (
          <StatefulSelect
            id={name}
            multi
            clearable
            creatable={false}
            options={[
              { id: CareType.Psychiatry, label: 'Psychiatry' },
              { id: CareType.Therapy, label: 'Therapy' },
            ]}
            onChange={({ value }) => onChange(value.map(v => v.id))}
            {...props}
            {...handler}
          />
        )}
      />
    </InputContainer>
  );
}

export function WeekdayPicker<T>({
  title,
  name,
  control,
  defaultValue,
  errorCaption,
  rules,
  ...props
}: ControlledProps<T, RestrictedSelectProps>) {
  return (
    <InputContainer name={name} title={title} errorCaption={errorCaption}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        rules={rules}
        render={({ onChange, ...handler }) => (
          <StatefulSelect
            id={name}
            multi={false}
            clearable={false}
            creatable={false}
            options={moment.weekdays().map((v, i) => ({ id: i, label: v }))}
            onChange={({ option }) => onChange(option?.label)}
            {...props}
            {...handler}
          />
        )}
      />
    </InputContainer>
  );
}

export function TimePicker<T>({
  title,
  name,
  control,
  defaultValue,
  errorCaption,
  rules,
  ...props
}: ControlledProps<T, TimePickerProps>) {
  return (
    <InputContainer name={name} title={title} errorCaption={errorCaption}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        rules={rules}
        render={handler => <BaseTimePicker {...handler} {...props} />}
      />
    </InputContainer>
  );
}

export function MultiText<T>({
  title,
  name,
  control,
  text = '',
  errorCaption,
  rules,
  ...props
}: ControlledProps<T, { text?: string }>) {
  return (
    <InputContainer name={name} title={title} errorCaption={errorCaption}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={[]}
        render={handler => (
          <EzMultitext
            placeholder={{ numbered: true, text }}
            removeLocation="right"
            {...props}
            {...handler}
          />
        )}
      />
    </InputContainer>
  );
}

const InputContainer: React.FC<BaseProps> = ({ name, title, errorCaption, children }) => (
  <div>
    {title && <Styles.FieldName htmlFor={name}>{title}</Styles.FieldName>}
    <FormControl caption={errorCaption}>{children}</FormControl>
  </div>
);
