import React, { useState } from 'react';
import { Input } from 'baseui/input';
import { Textarea } from 'baseui/textarea';
import { Controller } from 'react-hook-form';
import { colors, Text } from '../../globalStyles';
import { FormControl } from '../../Components/Form';
import { Divider } from './styles';
import { Icon } from '../../Components/Icons';
import { isMantraAdmin, MantraAdminOnly, useCurrentProvider } from '../../Components/Permissions';

type EditCampusAnnouncementProps = {
  title: string;
  disableSubmit: (value: string) => void;
  setContentChanged: (value: boolean) => void;
  orgName: string | undefined;
};

export function EditCampusAnnouncement({
  title,
  disableSubmit,
  setContentChanged,
  orgName,
}: EditCampusAnnouncementProps) {
  const [charCounter, setCharCounter] = useState(title.length);
  const { currentProvider } = useCurrentProvider();
  const MAX_CHARACTERS = 100;

  return (
    <div>
      <Text.h3 className="mbx">New Campus Update</Text.h3>
      {!!orgName && (
        <div className="flex items-center">
          <Icon icon="iconsUniSvg" size={13} />
          <Text.bodySmallGrey className="ml2">{orgName}</Text.bodySmallGrey>
        </div>
      )}
      <Divider />
      <FormControl name="title" label="Title" className="mt3 lh-copy">
        <Controller
          name="title"
          render={({ onChange, value }) => (
            <Input
              name="title"
              aria-label="Title"
              placeholder="Campus Update Title"
              value={value}
              disabled={!isMantraAdmin(currentProvider)}
              onChange={(e: React.BaseSyntheticEvent) => {
                const text = e.target.value;
                onChange(text);
                const charCount = text.length;
                charCount <= MAX_CHARACTERS && setCharCounter(charCount);
                setContentChanged(true);
                disableSubmit(text);
              }}
              maxLength={MAX_CHARACTERS}
              required
              overrides={{
                Input: {
                  style: {
                    color: colors.black,
                  },
                },
              }}
            />
          )}
        />
      </FormControl>
      <div className="mt2 mb4">
        <MantraAdminOnly>
          <Text.bodySmallGrey className="i">{`${
            MAX_CHARACTERS - charCounter
          } of ${MAX_CHARACTERS} characters remaining`}</Text.bodySmallGrey>
        </MantraAdminOnly>
      </div>
      <FormControl
        name="details"
        label="Full Update Details"
        subtitle="Users will need to expand campus updates to see the full body text."
        subtitleType="grayText"
      >
        <Controller
          name="details"
          render={({ onChange, value }) => (
            <Textarea
              name="details"
              aria-label="Details"
              placeholder="Full Update Details"
              value={value ?? ''}
              disabled={!isMantraAdmin(currentProvider)}
              onChange={(e: React.BaseSyntheticEvent) => {
                onChange(e.target.value);
                setContentChanged(true);
              }}
              overrides={{
                Input: {
                  style: {
                    minHeight: '250px',
                    color: colors.black,
                  },
                },
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
}
