import React, { useEffect, useRef, useState } from 'react';
import {
  useMarkPrivateChannelsMutation,
  useUserMessagesQuery,
  UserMessagesQuery,
} from '../../../graphQL';
import { useSidebarQuery } from '../../NavLayout/Counts';
import { MessageComposeArea } from './Compose';
import { MessagingView } from './MessagingView';

export type Message = UserMessagesQuery['user']['channel']['messages'][number];
type Props = {
  userId: number;
};
export const Messaging = ({ userId }: Props) => {
  const [refetchSidebar] = useSidebarQuery();
  const [markAsRead] = useMarkPrivateChannelsMutation({ refetchQueries: [refetchSidebar] });
  const pendingRef = useRef(new Set<string>());
  const [messages, setMessages] = useState<Message[]>([]);
  const { data, startPolling, stopPolling } = useUserMessagesQuery({ variables: { id: userId } });

  useEffect(() => {
    startPolling(4000);
    return stopPolling;
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (!data?.user.channel.unreadMessageCount) return;
    markAsRead({ variables: { channels: [data.user.channel.id] } });
  }, [data, markAsRead]);

  useEffect(() => {
    if (!data) return;
    for (const { id } of data.user.channel.messages) {
      pendingRef.current.delete(id);
    }
    setMessages(data.user.channel.messages);
  }, [data]);

  const onMessageSent = (newMsg: Message) => {
    pendingRef.current.add(newMsg.id);
    setMessages(m => [...m, newMsg]);
  };

  return (
    <div>
      <MessagingView messages={messages} />
      <MessageComposeArea userId={userId} onSend={onMessageSent} />
    </div>
  );
};
