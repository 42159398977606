import { useHistory } from 'react-router-dom';
import { useLogoutMutation } from '../graphQL';
import { clearAuthToken } from '../token';
import { removeLastActive } from '../utils';

type LogoutOptions = {
  reason: 'inactivity';
};

export const useLogout = (options?: LogoutOptions) => {
  const history = useHistory();
  const [logoutMutate] = useLogoutMutation({
    onCompleted: () => {
      clearAuthToken();
      removeLastActive();
      if (options?.reason === 'inactivity') {
        history.push('/login?loggedOutForInactivity=true');
      } else {
        history.push('/login');
      }
    },
  });
  return { logout: logoutMutate };
};
