import { useState, useEffect, useCallback, useRef } from 'react';

export function useAudio(url: string) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [playing, setPlaying] = useState(false);

  const play = useCallback(() => setPlaying(true), [setPlaying]);

  const eventCallback = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0.0;
    }
    setPlaying(false);
  };

  useEffect(() => {
    if (audioRef.current) {
      playing ? audioRef.current.play() : audioRef.current.pause();
    }
  }, [playing]);

  useEffect(() => {
    audioRef.current = new Audio(url);
    audioRef.current.volume = 0.5;
    audioRef.current.addEventListener('ended', eventCallback);
    return () => audioRef.current?.removeEventListener('ended', eventCallback);
  }, [url]);

  return { playing, play };
}
