import { ChevronDown, ChevronUp } from 'baseui/icon';
import { compact, flatten, intersection } from 'lodash';
import React, { HTMLProps, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from '../../globalStyles';
import { SectionFormat } from '../../graphQL';
import { CopyContent } from '../NoteCopy';
import { ExplanationOrNull } from './Explanations';
import { EditSection } from './formTypes';
import { QuestionHeaders } from './QuestionHeaders';
import { QuestionInfo } from './QuestionInfo';
import { Templates } from './Questions';
import * as Styles from './styles';
import { showDependentQuestion } from './util';

type Props = {
  question: EditSection;
  isChild?: boolean;
  noteKey: string;
  noteTitle: string;
  queueForSave: (keys: string[]) => void;
  highlightActive?: boolean;
};

export const Question = ({
  question,
  isChild,
  noteKey,
  noteTitle,
  queueForSave,
  highlightActive,
}: Props) => {
  const FormElement = Templates[question.format];
  const [closed, setClosed] = useState(question.closeable ? !!question.startClosed : false);

  const keysToSaveOnChange = compact([
    question.key,
    ...(question.hooks?.map(h => h.targetKey) ?? []),
    ...(question.children?.map(c => c.key) ?? []),
    question.format === SectionFormat.Appointment && 'appointmentId',
  ]);

  return (
    <NullIfDependsOn question={question}>
      <Styles.QuestionContainer
        id={question.key}
        bg={question.background}
        border={question.border}
        isChild={isChild}
      >
        <Title
          question={question}
          closed={closed}
          toggleClosed={() => setClosed(c => !c)}
          highlightActive={highlightActive}
        />
        {FormElement && (
          <>
            <FormElement
              name={question.key}
              question={question}
              noteKey={noteKey}
              noteTitle={noteTitle}
              queueForSave={() => queueForSave(keysToSaveOnChange)}
            />
            <Warning question={question} className="mt3" />
          </>
        )}
        {!!question.afterCopy?.length && (
          <div className="mb3 mt2">
            {question.afterCopy.map((c, i) => (
              <CopyContent copy={c} key={i} />
            ))}
          </div>
        )}
        <div className={closed ? 'dn' : ''}>
          {question.children?.map(c => (
            <Question
              queueForSave={queueForSave}
              key={c.key}
              noteKey={noteKey}
              noteTitle={noteTitle}
              question={c}
              isChild
            />
          ))}
        </div>
      </Styles.QuestionContainer>
    </NullIfDependsOn>
  );
};

const NullIfDependsOn: React.FC<Pick<Props, 'question'>> = ({ question, children }) => {
  const { dependsOn } = question;
  const { watch } = useFormContext();
  if (dependsOn) {
    const show = showDependentQuestion({ dependsOn, getValue: k => watch(k) });
    if (!show) return null;
  }
  return <>{children}</>;
};

type TitleProps = {
  question: EditSection;
  closed: boolean;
  toggleClosed: () => void;
  highlightActive?: boolean;
};
const Title = ({ question, closed, toggleClosed, highlightActive }: TitleProps) => {
  const context = useFormContext();
  const value = context.getValues()[question.key];
  const isRequired = question.required || question.children?.some(c => c.required);
  const highlight = highlightActive && isRequired && !value ? 'danger' : 'black';
  return (
    <div>
      <div className="mb3">
        <QuestionHeaders lookupKey={question.key} />
        {question.sectionTitle && <Text.h2 className="mt5">{question.sectionTitle}</Text.h2>}
      </div>
      <ExplanationOrNull lookupKey={question.key} className="mb3" />
      <div className="flex justify-between">
        {question.title && (
          <Text.bodyBold className="mb2" kind={highlight}>
            {question.number && `${question.number}. `}
            {question.title}
            {isRequired && (
              <>
                <Text.body kind="danger" as="span">
                  {' *'}
                </Text.body>
                {highlightActive && !value && (
                  <Text.bodySmall kind={highlight}>This question is required</Text.bodySmall>
                )}
              </>
            )}
          </Text.bodyBold>
        )}
        {question.closeable && (
          <Text.linkButton className="b" onClick={toggleClosed}>
            <span className="flex items-center">
              {closed ? 'Open ' : 'Close '}
              {closed ? <ChevronDown size={24} /> : <ChevronUp size={24} />}
            </span>
          </Text.linkButton>
        )}
      </div>
      <QuestionInfo lookupKey={question.key} className="mb3" />
      {!!question.beforeCopy?.length && (
        <div className="mb3">
          {question.beforeCopy.map((c, i) => (
            <CopyContent copy={c} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

interface WarningProps extends Pick<HTMLProps<HTMLDivElement>, 'className'> {
  question: EditSection;
}

const Warning = ({ question, ...rest }: WarningProps) => {
  const { key, warning } = question;
  const { watch } = useFormContext();

  if (!warning) return null;
  const { triggers, dependsKey, copy, background, border } = warning;
  const selectedVal = watch(dependsKey ?? key);

  if (!selectedVal) return null;

  if (intersection(flatten([selectedVal]), triggers).length <= 0) return null;

  return (
    <Styles.WarningContainer bg={background} border={border} {...rest}>
      {copy.map((c, i) => (
        <CopyContent copy={c} key={i} />
      ))}
    </Styles.WarningContainer>
  );
};
