import moment from 'moment';
import React, { useState } from 'react';
import { useCurrentProvider } from '../../../Components/Permissions';
import {
  MedicationsForUserQuery,
  useAdminDosespotLinkQuery,
  useMedicationsForUserQuery,
} from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { InTextSpinner, Styles, Text } from '../styles';
import { canGetDosespotLink } from '../Navigation/TopNav';
import { colors } from '../../../globalStyles';

export const MedicationSection = () => {
  const { currentProvider, hasPermission } = useCurrentProvider();
  const { user } = useDrilldownContext();

  const canGetDosespot = canGetDosespotLink({ currentProvider, hasPermission });

  const { data } = useAdminDosespotLinkQuery({
    variables: { userId: user.id },
    skip: !canGetDosespot,
  });

  const { data: medicationsQueryData, loading } = useMedicationsForUserQuery({
    variables: { userId: user.id },
  });

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <div className="flex items-center">
          <Text.h3>Medication </Text.h3>
          {loading && <InTextSpinner />}
        </div>

        {canGetDosespot && (
          <Text.linkButton
            onClick={async () => {
              if (!data || !data.adminDosespotLink) return;
              window.open(data.adminDosespotLink, '_blank');
            }}
          >
            Edit
          </Text.linkButton>
        )}
      </Styles.sidebarSectionHeader>
      {loading ? <div /> : <MedicationSideBarContent medicationsData={medicationsQueryData} />}
    </Styles.sidebarSection>
  );
};

interface MedicationSideBarContentProps {
  medicationsData?: MedicationsForUserQuery;
}

// Here are the prescriptionStatus's and what they are actually listed as in Dovespot (if different)
// If prescription is "Active" this means the patient will see it on their dashboard
// const prescriptionStatus = {
//   '0': 'Invalid', - '0' should never be passed in as a status
//   '1': 'Entered',
//   '2': 'Printed',
//   '3': 'Sending',
//   '4': 'Active',  - eRxSent
//   '5': 'Active',  - FaxSent
//   '6': 'Error',
//   '7': 'Deleted',
//   '8': 'Active', - Requested
//   '9': 'Active', - Edited
//   '10': 'EpcsError',
//   '11': 'EpcsSigned',
//   '12': 'Active', - ReadyToSign
//   '13': 'Active', - PharmacyVerified
// };

const prescriptionStatus = [
  'Invalid',
  'Entered',
  'Printed',
  'Sending',
  'Active',
  'Active',
  'Error',
  'Deleted',
  'Active',
  'Active',
  'EPCS Error',
  'EPCS Signed',
  'Active',
  'Active',
];

const MedicationSideBarContent = ({ medicationsData }: MedicationSideBarContentProps) => {
  const meds = medicationsData?.medicationsForUser;
  const [showMore, setShowMore] = useState(false);

  if (!meds || meds.length === 0) return <Text.body>No Medication</Text.body>;
  return (
    <div>
      <Styles.sidebarTable>
        <thead>
          <tr>
            <Text.label as="th">RX</Text.label>
            <Text.label as="th">DOSE</Text.label>
            <Text.label as="th">AMT</Text.label>
            <Text.label as="th">Written</Text.label>
            <Text.label as="th">Status</Text.label>
          </tr>
        </thead>
        <tbody>
          {meds
            .filter(m => showMore || m.medicationStatus === 'Active')
            .map(m => (
              <tr key={m.prescriptionId}>
                <td>{m.displayName}</td>
                <td>{m.strength}</td>
                <td>{m.quantity}</td>
                <td>{moment(m.writtenDate).format('MM/DD/YYYY')}</td>
                <td
                  style={{ color: m.status !== 6 && m.status !== 7 ? colors.black : colors.danger }}
                >
                  {prescriptionStatus[m.status]}
                </td>
              </tr>
            ))}
        </tbody>
      </Styles.sidebarTable>
      <Text.linkButton onClick={() => setShowMore(s => !s)}>
        {showMore ? 'Hide' : 'View'} history
      </Text.linkButton>
    </div>
  );
};
