import { random, sample } from 'lodash';
import {
  CareType,
  CreateTestUser,
  OrganizationsListQuery,
  ProviderFragment,
  ReferralSource,
} from '../../../graphQL';

type FactoryArgs = {
  psychiatrists: ProviderFragment[];
  therapists: ProviderFragment[];
  organizations: OrganizationsListQuery['organizations'];
};

export const presetFactory = ({
  therapists,
  psychiatrists,
  organizations,
}: FactoryArgs): Record<string, Partial<CreateTestUser>> => ({
  '[DTC] Intake, pre-checkout': {
    careTypes: [],
    referralSource: ReferralSource.Portal,
    numAssessments: 1,
    completeCheckout: false,
    numAppointments: 0,
  },
  '[DTC] Intake, post-checkout (account incomplete)': {
    numAssessments: 1,
    numAppointments: 0,
    careTypes: [CareType.Psychiatry, CareType.Therapy],
    referralSource: ReferralSource.Portal,
    completeCheckout: true,
    completeProfile: false,
  },
  '[DTC] Intake, post-checkout (account completed)': {
    numAssessments: 1,
    numAppointments: 0,
    careTypes: [CareType.Psychiatry, CareType.Therapy],
    referralSource: ReferralSource.Portal,
    completeCheckout: true,
    completeProfile: true,
  },
  '[DTC] Ongoing care': {
    numAssessments: random(3, 10),
    numAppointments: random(1, 10),
    careTypes: [CareType.Psychiatry, CareType.Therapy],
    referralSource: ReferralSource.Portal,
    completeCheckout: true,
    medicalHistory: true,
    completeProfile: true,
    psychiatristId: sample(psychiatrists)?.id,
    therapistId: sample(therapists)?.id,
    mockMessages: true,
    withPharmacy: true,
  },
  '[Student] Pre-intake (unregistered)': {
    numAssessments: 0,
    numAppointments: 0,
    careTypes: [],
    referralSource: ReferralSource.Mcp,
    organizationId: sample(organizations)?.id,
  },
  '[Student] Intake': {
    numAssessments: 1,
    numAppointments: 0,
    careTypes: [CareType.Psychiatry, CareType.Therapy],
    referralSource: ReferralSource.Mcp,
    organizationId: sample(organizations)?.id,
  },
  '[Student] Ongoing care': {
    numAssessments: random(3, 10),
    numAppointments: random(1, 10),
    careTypes: [CareType.Psychiatry, CareType.Therapy],
    referralSource: ReferralSource.Mcp,
    organizationId: sample(organizations)?.id,
    medicalHistory: true,
    completeProfile: true,
    psychiatristId: sample(psychiatrists)?.id,
    therapistId: sample(therapists)?.id,
    mockMessages: true,
    withPharmacy: true,
  },
});
