import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { MessageFragment, useSendCareTeamMessageMutation } from '../../../graphQL';
import { FinalButton } from '../../FinalButton';
import { Textarea } from '../../Form';

type Props = {
  userId: number;
  onSend?: (msg: MessageFragment) => void;
};
export const CareTeamComposeArea = ({ userId, onSend }: Props) => {
  const { current: id } = useRef(`patient_${userId}_careTeam_message`);
  const { register, handleSubmit, reset } = useForm<{ content: string }>();
  const [send, { loading, error }] = useSendCareTeamMessageMutation();

  const submit = handleSubmit(async ({ content }) => {
    const { data } = await send({ variables: { userId, content } });
    if (!data) return;
    onSend?.(data.sendCareTeamMessage);
    reset();
  });

  useEffect(() => {
    setTimeout(() => document.getElementById(id)?.focus(), 0);
  }, [id]);

  return (
    <div className="pa2">
      <form onSubmit={submit}>
        <div className="flex flex-column items-stretch">
          {error && <p className="tc">There was a problem sending your message at this time</p>}
          <Textarea
            id={id}
            ref={register({ required: true })}
            name="content"
            placeholder="Type your message here..."
            className="mb3"
          />
          <div className="flex justify-end">
            <FinalButton disabled={loading} type="submit">
              Send
            </FinalButton>
          </div>
        </div>
      </form>
    </div>
  );
};
