import React from 'react';
import { ReactNode } from 'react';

export const ColumnText = ({ children }: { children: ReactNode }): JSX.Element => {
  return <div className="helvetica f6 lh-title">{children}</div>;
};

export const ColumnTextBold = ({ children }: { children: ReactNode }): JSX.Element => {
  return <div className="helvetica f6 lh-title b">{children}</div>;
};

export const ColumnTextSmallGray = ({ children }: { children: ReactNode }): JSX.Element => {
  return <div className="helvetica f7 lh-title gray">{children}</div>;
};
