import { useExportNoteMutation } from '../../graphQL';
import { useEvents } from '../Events/EventsProvider';
import { useAsyncJob } from './useAsyncJob';

export const useExportNote = ({ noteId }: { noteId: number }) => {
  const metrics = useEvents();
  const [beginJobPoll, job] = useAsyncJob();
  const [beginJob, { error }] = useExportNoteMutation();

  const beginExport = async () => {
    metrics.track(`note.export.started`, { noteId });
    const { data: initialJob } = await beginJob({ variables: { noteId } });
    if (!initialJob) return;
    beginJobPoll(initialJob.adminCreateNoteExport.id);
  };

  return { beginExport, error, ...job };
};
