import { StatefulTooltip } from 'baseui/tooltip';
import React from 'react';
import { Icon, IconKey } from '../Icons';
import { ColorLookup, Text } from '../../globalStyles';

type WarningProps = {
  title: string;
  iconKey: IconKey;
  kind?: ColorLookup;
  tooltipContent?: string;
};

export function AppointmentWarningLabel({ title, iconKey, kind, tooltipContent }: WarningProps) {
  const Wrapper: React.FC = ({ children }) =>
    tooltipContent ? (
      <StatefulTooltip
        showArrow
        overrides={{
          Body: { style: { borderRadius: '4px' } },
          Inner: {
            style: { backgroundColor: '#192038', borderRadius: '4px' },
          },
        }}
        content={() => <div className="f7 pa1">{tooltipContent}</div>}
      >
        <div className="pointer">{children}</div>
      </StatefulTooltip>
    ) : (
      <>{children}</>
    );
  return (
    <Wrapper>
      <div className="flex flex-row item-center mb1">
        <Icon icon={iconKey} size={18} className="mr1" />
        <Text.caption kind={kind} className="fw5">
          {title}
        </Text.caption>
      </div>
    </Wrapper>
  );
}
