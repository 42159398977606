import React from 'react';
import { AppointmentControls } from '../../Components/Appointment/AppointmentControls';
import { JoinAppointmentButton } from '../../Components/Appointment/JoinAppointmentButton';
import { QuickEmailLink } from '../../Components/Flair';
import { Text } from '../../globalStyles';
import { AdminAppointmentsQuery } from '../../graphQL';
import { formatPhoneNumber, friendlyTimeZoneName } from '../../utils';
import * as Styles from './styles';

interface Props {
  appointment: AdminAppointmentsQuery['adminAppointments'][0];
  refetch: () => void;
}

export function AppointmentDetail({ appointment, refetch }: Props) {
  return (
    <Styles.AppointmentItemGridBottomRow>
      <div className="flex flex-column pr4 br b--light-gray">
        <AppointmentControls appointment={appointment} refetch={refetch} />

        {appointment.status === 'upcoming' && (
          <JoinAppointmentButton
            className="mt3"
            appointmentId={appointment.id}
            providerId={appointment.provider.id}
            userId={appointment.user.id}
          >
            Join Video
          </JoinAppointmentButton>
        )}
      </div>

      <div className="flex items-center pb3">
        <Styles.PatientDetailGrid>
          <div>
            <Text.label>Email</Text.label>
            <div className="flex items-center ">
              <p className="mr2">{appointment.user.email}</p>
              <QuickEmailLink user={appointment.user} />
            </div>
          </div>
          <div>
            <Text.label>Phone</Text.label>
            <div>{appointment.user.phone && formatPhoneNumber(appointment.user.phone)}</div>
          </div>
          {appointment.status === 'upcoming' && (
            <div>
              <Text.label>Patient Timezone</Text.label>
              <div>{friendlyTimeZoneName(appointment.user.timeZone)}</div>
            </div>
          )}
          {appointment.status !== 'upcoming' && (
            <div>
              <Text.label>Appointment Type</Text.label>
              <div>{appointment.description}</div>
            </div>
          )}
          <div>
            <Text.link to={`/users/${appointment.user.id}`}>View Patient Profile</Text.link>
          </div>
        </Styles.PatientDetailGrid>
      </div>
    </Styles.AppointmentItemGridBottomRow>
  );
}
