import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { KIND, Notification } from 'baseui/notification';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { Text } from '../../globalStyles';
import { useForgotProviderPasswordMutation } from '../../graphQL';
import { isLoggedIn } from '../../utils';
import { SessionPageWrapper } from './Shared';
import { Form, inputOverrides, LinkWrapper } from './Style';

export const ForgotPassword = () => {
  const { handleSubmit, register, errors } = useForm<{ email: string }>();
  const [forgotPassword, forgotPasswordMutation] = useForgotProviderPasswordMutation();

  if (isLoggedIn()) return <Redirect to="/users" />;
  if (forgotPasswordMutation.data) return <SentScreen />;

  const onSubmit = (values: { email: string }) => {
    const { email } = values;
    forgotPassword({ variables: { email } });
  };

  return (
    <SessionPageWrapper title="Forgot password">
      {forgotPasswordMutation.error && (
        <Notification
          kind={KIND.negative}
          overrides={{
            Body: { style: { display: 'flex', justifyContent: 'center', fontWeight: 'normal' } },
          }}
        >
          Something went wrong.
        </Notification>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControl caption={errors.email && errors.email.message}>
          <Input
            inputRef={register({ required: 'This field is required' })}
            name="email"
            type="email"
            error={!!errors.email}
            size="large"
            placeholder="Email Address"
            overrides={inputOverrides}
          />
        </FormControl>
        <FinalButton
          type="submit"
          className="w-100"
          kind="primary"
          loading={forgotPasswordMutation.loading}
        >
          Reset Password
        </FinalButton>
        <LinkWrapper>
          <Text.link to="/login">Back to Login</Text.link>
        </LinkWrapper>
      </Form>
    </SessionPageWrapper>
  );
};

const SentScreen = () => (
  <SessionPageWrapper title="Reset email sent">
    <Text.body>We&apos;ve sent you an email to reset your password</Text.body>
    <LinkWrapper>
      <Text.link to="/login">Back to Login</Text.link>
    </LinkWrapper>
  </SessionPageWrapper>
);
