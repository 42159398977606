import * as _ from 'lodash';
import { isNumber } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Avatar } from '../../../Components/Avatar';
import { CampusTeamSelection } from '../../../Components/CampusTeam/CampusTeamSelection';
import { parseCampusTeamToMemberInput } from '../../../Components/CampusTeam/campusTeamUtils';
import { EditCampusTeamMember } from '../../../Components/CampusTeam/types';
import { FinalButton } from '../../../Components/FinalButton';
import { BirthdateInputRHF, InputRHF, PhoneInputRHF, SelectRHF } from '../../../Components/Form';
import { Toggle } from '../../../Components/Form/Toggle';
import { icons } from '../../../Components/Icons';
import { Modal } from '../../../Components/Modal/Modal';
import { Notification } from '../../../Components/Notification';
import { useCurrentProvider } from '../../../Components/Permissions';
import { useProviderNetworkContext } from '../../../Components/ProviderNetwork/ProviderNetworkContext';
import { getDisplayableType } from '../../../Components/ProviderNetwork/providerNetworkUtils';
import { When } from '../../../Components/When';
import { Text } from '../../../globalStyles';
import {
  EnrollModalQuery,
  StateCodes,
  useAdminCreateUserMutation,
  useEnrollModalQuery,
} from '../../../graphQL';
import { stateOptions } from '../../../states';
import { stripPhoneNumber } from '../../../utils';
import { FormFields, Label } from './Common';
import { getGraphqlFormatErrors } from '../../../utils/graphql';

type SearchProvider = EnrollModalQuery['providersById'][number];

const required = 'This field is required.';

interface Props {
  onClose: () => void;
}

export function SelfPayEnrollModal({ onClose }: Props) {
  const {
    searchVariables: { state },
    selectedProviders,
  } = useProviderNetworkContext();
  const [showPreferredName, setShowPreferredName] = useState(false);
  const { currentProvider } = useCurrentProvider();
  const organizationId = currentProvider.organizations[0].id;
  const form = useForm<FormFields>({
    defaultValues: { geoState: state ?? undefined },
    reValidateMode: 'onSubmit',
  });
  const [showCareTeam, setShowCareTeam] = useState(false);
  const [createdPatientId, setCreatedPatientId] = useState<number | null>(null);
  const { data } = useEnrollModalQuery({
    variables: { organizationId, providerIds: selectedProviders.toArray() },
  });

  const [createUser, mutation] = useAdminCreateUserMutation({
    onCompleted: res => setCreatedPatientId(res.adminCreateUser.id),
  });
  const [campusTeam, setCampusTeam] = useState<EditCampusTeamMember[]>([]);
  const [campusTeamError, setCampusTeamError] = useState<string>('');

  const careTypes = _.uniq(_.flatten(data?.providersById.map(i => i.careTypes)));

  const formatErrors = useMemo(() => getGraphqlFormatErrors(mutation.error), [mutation.error]);

  const onSubmit = form.handleSubmit(values => {
    const campusTeamWithRelations = parseCampusTeamToMemberInput(campusTeam, setCampusTeamError);

    if (!campusTeamWithRelations) {
      return;
    }

    setCampusTeamError('');

    createUser({
      variables: {
        input: {
          organizationId: values.childOrganizationId ?? organizationId,
          firstName: values.firstName,
          lastName: values.lastName,
          preferredName: values.preferredName,
          email: values.email,
          phone: stripPhoneNumber(values.phone),
          birthDate: moment(values.birthDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          campusTeam: campusTeamWithRelations,
          careTypes,
          geoState: values.geoState as StateCodes,
          suggestedProviders: selectedProviders.toArray(),
        },
      },
    });
  });

  if (createdPatientId) {
    return (
      <EnrollSuccessModal
        id={createdPatientId}
        suggestedProviders={data?.providersById ?? []}
        onClose={onClose}
      />
    );
  }

  return (
    <Modal
      size="medium"
      isOpen
      onClose={onClose}
      style={{ content: { padding: 0, paddingTop: 0 } }}
    >
      <FormProvider {...form}>
        <div className="flex">
          <div className="flex-1" style={{ background: '#EFEFEF', padding: '55px 62px' }}>
            <Text.h2 className="mb4">
              Create student account to suggest the selected providers
            </Text.h2>
            {data?.providersById.map(provider => (
              <SuggestedProvider provider={provider} key={provider.id} />
            ))}
          </div>
          <div className="flex-1" style={{ padding: '55px 62px' }}>
            {mutation.error && (
              <Notification kind="negative">
                {mutation.error.message ?? 'An unexpected error occurred.'}
              </Notification>
            )}

            {formatErrors.input && (
              <Notification kind="negative">{formatErrors.input.join(' ')}</Notification>
            )}

            <div className="flex">
              <Label name="firstName" label="First Name" className="mb0">
                <InputRHF
                  name="firstName"
                  rules={{ required }}
                  controlProps={{ className: 'mb2' }}
                />
              </Label>
              <Label name="lastName" label="Last Name" className="ml3 mb0">
                <InputRHF
                  name="lastName"
                  rules={{ required }}
                  controlProps={{ className: 'mb2' }}
                />
              </Label>
            </div>
            {showPreferredName ? (
              <Label name="preferredName" label="Preferred Name" className="mb0">
                <InputRHF name="preferredName" />
              </Label>
            ) : (
              <Text.linkButton className="b mb3" onClick={() => setShowPreferredName(true)}>
                + Add Preferred Name
              </Text.linkButton>
            )}
            <Label name="birthDate" label="Date of Birth">
              <BirthdateInputRHF name="birthDate" controlProps={{ required: true }} />
            </Label>
            <Label name="email" label="Student University Email Address">
              <InputRHF name="email" rules={{ required }} />
            </Label>
            <Label name="geoState" label="Student Residential State">
              <SelectRHF
                name="geoState"
                options={stateOptions}
                rules={{
                  required,
                }}
              />
            </Label>
            <Label name="phone" label="Student Phone Number">
              <PhoneInputRHF name="phone" rules={{ required }} />
            </Label>
            {data && data.organization.children.length > 0 && (
              <Label name="childOrganizationId" label="Campus">
                <SelectRHF
                  name="childOrganizationId"
                  options={
                    data?.organization.children.map(org => ({ id: org.id, label: org.name })) ?? []
                  }
                  rules={{ required }}
                />
              </Label>
            )}
            <Toggle toggled={showCareTeam} onChange={setShowCareTeam}>
              <Text.bodyBold>
                Request to collaborate with this patient’s Mantra provider
              </Text.bodyBold>
              <Text.bodySmall className="mb3">
                Upon sign up, patient will be able to accept or deny request
              </Text.bodySmall>
            </Toggle>
            {showCareTeam && (
              <>
                <When isTruthy={isNumber(organizationId)}>
                  <CampusTeamSelection
                    campusTeam={campusTeam ?? []}
                    setCampusTeam={setCampusTeam}
                    organizationId={organizationId}
                    relationshipError={campusTeamError}
                  />
                </When>
              </>
            )}
            <FinalButton
              kind="primary"
              className="w-100"
              type="submit"
              onClick={onSubmit}
              loading={mutation.loading}
            >
              Submit
            </FinalButton>
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
}

const SuggestedProvider = ({ provider }: { provider: SearchProvider }) => (
  <div key={provider.id} className="flex mb3">
    <Avatar
      size="2.5rem"
      src={provider.portrait?.url ?? icons.assetsDefaultPicturePng}
      alt={provider.name}
    />
    <div className="flex-1 ml2">
      <Text.bodyBold>{provider.name}</Text.bodyBold>
      <Text.bodySmall>{getDisplayableType(provider)}</Text.bodySmall>
    </div>
  </div>
);

interface EnrollSuccessModalProps {
  id: number;
  suggestedProviders: SearchProvider[];
  onClose: () => void;
}

function EnrollSuccessModal({ id, suggestedProviders, onClose }: EnrollSuccessModalProps) {
  const history = useHistory();
  return (
    <Modal size="tiny" isOpen onClose={onClose}>
      <div className="mh2">
        <Text.h2>Account created with suggested providers</Text.h2>
        <Text.body className="mb4">
          Note: A provider will not be assigned to a patient until the first appointment is booked.
          Suggested providers will show up at the top of the booking options for whoever schedules
          this patient.
        </Text.body>
        <Text.label className="mb2">Suggested Providers</Text.label>
        <div className="mb4">
          {suggestedProviders.map(provider => (
            <SuggestedProvider key={provider.id} provider={provider} />
          ))}
          <FinalButton
            kind="outline_black"
            className="w-100 mb3"
            onClick={() => history.push(`/users/${id}`)}
          >
            Go to patient profile
          </FinalButton>
          <FinalButton kind="minimal_gray" className="w-100" onClick={() => history.push('/home')}>
            Go to dashboard
          </FinalButton>
        </div>
      </div>
    </Modal>
  );
}
