import { ChevronDown, ChevronUp } from 'baseui/icon';
import moment from 'moment';
import React from 'react';
import { AppointmentWarningLabel } from '../../Components/Appointment/AppointmentWarningLabel';
import { TextIcon } from '../../Components/Flair';
import { icons } from '../../Components/Icons';
import { PillLabel } from '../../Components/Labels';
import { PermsOnly } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { Permission } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { AppointmentDetail } from './AppointmentDetail';
import { AppointmentStatusIndicator } from './AppointStatusIndicator';
import * as Styles from './styles';
import { Appointment } from './types';

interface Props {
  appointment: Appointment;
  refetch: () => void;
}

export function AppointmentListItem({ appointment, refetch }: Props) {
  const { startMoment: start, endMoment: end, isBlocked, checkedInAt } = appointment;
  const didNotHappen: boolean = ['rescheduled', 'cancelled', 'no show'].includes(
    appointment.status
  );

  const isPast = appointment.endMoment.isBefore(moment());
  const daysUntil = start.clone().startOf('day').diff(moment().startOf('day'), 'days');
  const checkInMoment = checkedInAt ? moment(checkedInAt) : null;

  return (
    <Styles.AppointmentItemContainer isPast={isPast}>
      <Styles.Details>
        <summary>
          <Styles.AppointmentItemGridRow>
            <div
              className={`${
                didNotHappen ? 'o-50' : ''
              } flex flex-column justify-center items-start`}
            >
              <p className={`b f5${didNotHappen ? ' strike' : ''}`}>
                {start.format('h:mm')} - {end.format('h:mm a z')}
              </p>
              {!isPast && daysUntil <= 3 && !checkInMoment && (
                <AppointmentWarningLabel
                  title="Needs to check-in"
                  iconKey="iconsClipboardSvg"
                  kind="grayText"
                />
              )}
              {!isPast && !!checkInMoment && (
                <AppointmentWarningLabel
                  title="Checked in"
                  iconKey="iconsClipboardCheckSvg"
                  tooltipContent={`Completed ${checkInMoment.format('L')} at ${checkInMoment.format(
                    'h:mm a'
                  )}`}
                />
              )}
              {!isPast && isBlocked && (
                <AppointmentWarningLabel
                  title="Incomplete required tasks"
                  iconKey="iconsClockOrangeSvg"
                  kind="brandAlt"
                />
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex items-start">
                <Styles.Thumbnail
                  className={`mr2${didNotHappen ? ' o-50' : ''}`}
                  src={appointment.user.selfie?.url ?? icons.assetsDefaultPicturePng}
                />
                <div>
                  <div className="flex items-center flex-wrap">
                    <p className={`f5 b ${didNotHappen ? ' strike o-50' : ''}`}>
                      {getFullName(appointment.user)}
                    </p>
                    {appointment.user.organization && (
                      <p className="f7 b o-50" title={appointment.user.organization.name}>
                        <TextIcon className="ml2" preset="university">
                          {appointment.user.organization.abbreviation}
                        </TextIcon>
                      </p>
                    )}

                    {isInitialAppointment(appointment) && (
                      <PillLabel className="ml3">
                        <Text.label className="truncate">New Patient</Text.label>
                      </PillLabel>
                    )}
                  </div>
                  {!isPast && (
                    <>
                      <p>
                        {appointment.description} ({end.diff(start, 'minutes')} min)
                      </p>
                      <PermsOnly allowed={Permission.MantraAdmin}>
                        <p>
                          <strong>Provider:</strong> {appointment.provider?.name ?? 'Unknown'}
                        </p>
                      </PermsOnly>
                    </>
                  )}
                </div>
              </div>
              <div className="ml3 flex items-center">
                <AppointmentStatusIndicator status={appointment.status} />
                <div>
                  <div className="icon-open">
                    <ChevronUp size={36} color="#999999" />
                  </div>
                  <div className="icon-close">
                    <ChevronDown size={36} color="#999999" />
                  </div>
                </div>
              </div>
            </div>
          </Styles.AppointmentItemGridRow>
        </summary>
        <AppointmentDetail appointment={appointment} refetch={refetch} />
      </Styles.Details>
    </Styles.AppointmentItemContainer>
  );
}

export function isInitialAppointment(appt: Pick<Appointment, 'description' | 'appointmentType'>) {
  // Old appointments might still have "Intake Video Assessment" as their description
  return appt.description === 'Intake Video Assessment' || appt.appointmentType === 'intake';
}
