import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../FinalButton';
import { LateRescheduleAppointmentModal } from './RescheduleAppointmentModal';
import { AppointmentForLink } from './types';

interface Props {
  appointment: AppointmentForLink;
}

export function RescheduleAppointmentLink({ appointment }: Props) {
  const history = useHistory();
  const [lateRescheduleModalOpen, setLateRescheduleModalOpen] = useState(false);
  const isLate = moment(appointment.startTime).isBefore(moment().add(24, 'hours'));
  const onClick = () => {
    if (isLate) return setLateRescheduleModalOpen(true);
    // otherwise
    return history.push(`/book?rescheduleId=${appointment.id}&userId=${appointment.user.id}`);
  };
  return (
    <>
      <FinalButton
        size="compact"
        kind="minimal_gray"
        className="tl"
        iconLeft={{ icon: 'iconsBlackRescheduleSvg', size: 18 }}
        onClick={onClick}
      >
        Reschedule
      </FinalButton>
      {lateRescheduleModalOpen && (
        <LateRescheduleAppointmentModal
          appointment={appointment}
          onClose={() => setLateRescheduleModalOpen(false)}
        />
      )}
    </>
  );
}
