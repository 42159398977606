import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage, Text } from '../../globalStyles';
import {
  CareType,
  OrganizationCarePeriodModel,
  Permission,
  useOrganizationUtilizationQuery,
  UtilizationVirtualModel,
} from '../../graphQL';
import { compareJsDates } from '../../utils';
import { useOrganizationId } from '../Organizations/util';
import { PageTitle, UnexpectedError } from '../Shared';
import { CarePeriodUtilizationDashboard } from './CarePeriodUtilization';

export type CarePeriodSubset = Pick<
  OrganizationCarePeriodModel,
  'startDate' | 'endDate' | 'periodType'
>;

export type FrontendCarePeriodUtilization = Omit<UtilizationVirtualModel, 'carePeriod'> & {
  carePeriod: CarePeriodSubset;
};

export const UtilizationPage = () => {
  const organizationId = useOrganizationId();
  const { hasPermission, providerOrgUsesDedicatedGroupModel, appView } = useCurrentProvider();
  const [hasCurrentCarePeriod, setHasCurrentCarePeriod] = useState<boolean>(false);
  const { data, loading, error, refetch } = useOrganizationUtilizationQuery({
    variables: { organizationId },
  });

  const careTypes: CareType[] =
    data?.organization.careFlows.map((flow: { careType: CareType }) => flow.careType) ?? [];

  const carePeriodUtilizations: FrontendCarePeriodUtilization[] =
    data?.organization.organizationUtilization?.carePeriodUtilizations.sort((a, b) =>
      compareJsDates({ b: a.carePeriod.startDate, a: b.carePeriod.startDate })
    ) ?? [];

  useEffect(() => {
    if (carePeriodUtilizations && carePeriodUtilizations.length > 0) {
      if (moment(carePeriodUtilizations[0].carePeriod.startDate).isSameOrBefore(moment())) {
        setHasCurrentCarePeriod(true);
      }
    }
  }, [carePeriodUtilizations]);

  // Oz admin's will still be able to access the page even if the org doesn't have the entitlement
  // TODO: improve for Oz usecase
  if (
    (appView === 'mcp' && !providerOrgUsesDedicatedGroupModel()) ||
    !hasPermission(Permission.UtilizationView)
  ) {
    return <Redirect to="/" />;
  }
  if (loading || !data) return <LoadingPage />;
  if (error || !careTypes.length) return <UnexpectedError />;

  const isSuperOrg = data.organization.parent === null;

  return (
    <PaddedPage style={{ backgroundColor: '#F9F9F9', minWidth: '1050px', overflowX: 'scroll' }}>
      {/* anchor for logo */}
      <div className="relative">
        {/* {orgId && orgId > 0 && <UniversityLogo organizationId={orgId} right="0" />} */}
        <PageTitle className="mb3">Utilization</PageTitle>
        <div>{isSuperOrg}</div>

        <div className="mb4">
          {data?.organization.parent && (
            <Text.body>
              Inherits Care Ops from{' '}
              <a href={`/organizations/${data.organization.parent.id}/admin`}>
                {data.organization.parent.name ?? 'Parent'}
              </a>
            </Text.body>
          )}
        </div>
      </div>
      {hasCurrentCarePeriod && (
        <CarePeriodUtilizationDashboard
          carePeriod={carePeriodUtilizations[0].carePeriod}
          utilizationRecords={carePeriodUtilizations[0].weeklyUtilizationRecords}
          refetch={refetch}
          firstCarePeriod
          careTypes={careTypes}
          isSuperOrg={isSuperOrg}
        />
      )}
      {carePeriodUtilizations && (!hasCurrentCarePeriod || carePeriodUtilizations.length > 1) && (
        <>
          <Text.h3>Upcoming Care Periods</Text.h3>
          {carePeriodUtilizations.map((carePeriodUtilization, index) => {
            if (hasCurrentCarePeriod && index === 0) {
              return '';
            }
            return (
              <CarePeriodUtilizationDashboard
                key={index}
                carePeriod={carePeriodUtilization.carePeriod}
                utilizationRecords={carePeriodUtilization.weeklyUtilizationRecords}
                refetch={refetch}
                firstCarePeriod={false}
                careTypes={careTypes}
                isSuperOrg={isSuperOrg}
              />
            );
          })}
        </>
      )}
    </PaddedPage>
  );
};
