import React from 'react';
import { MantraSvg, MantraSvgKeys } from '../../Components/Flair';
import { Text } from '../../globalStyles';

type Props = {
  icon: MantraSvgKeys;
  text: string;
  linkTo?: string;
  linkText?: string;
};

export const EmptyState = ({ icon, text, linkText, linkTo }: Props) => {
  return (
    <div className="h4 flex flex-column items-center justify-center">
      <MantraSvg icon={icon} opacity={0.5} size={36} />
      <Text.bodyGrey>
        <em>{text}</em>
      </Text.bodyGrey>
      {linkText && linkTo && <Text.link to={linkTo}>{linkText}</Text.link>}
    </div>
  );
};
