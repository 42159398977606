import React from 'react';
import { useWatch } from 'react-hook-form';
import { InputRHF, ToggleRHF } from '../../../Components/Form';
import { Text } from '../../Users/styles';
import { setupToggleKeys } from './AccountSetup';
import { Control, Flag, InputFlag } from './types';

const binaryToggles: Flag[] = [
  {
    key: 'medicalHistory',
    label: 'Completed Medical History',
  },
  {
    key: 'withPharmacy',
    label: 'Selected Pharmacy',
  },
];

const inputToggles: InputFlag[] = [
  {
    key: 'numAppointments',
    label: 'Completed Appointments',
    type: 'number',
    min: 0,
  },
  {
    key: 'numAssessments',
    label: 'Completed Monthly Assessments',
    type: 'number',
    min: 0,
  },
];

export const PatientActivity = ({ control }: { control: Control }) => {
  const accountSetup = useWatch({ control, name: setupToggleKeys });
  const canDisplayTasks = Object.values(accountSetup).every(v => v!!);

  return (
    <div className={`mb4${canDisplayTasks ? '' : ' dn'}`}>
      <Text.h2 className="mb3">Patient Tasks</Text.h2>
      {binaryToggles.map(({ key, label }) => {
        return (
          <ToggleRHF className="mb3" key={key} name={key}>
            {label}
          </ToggleRHF>
        );
      })}
      {inputToggles.map(({ key, label, type, min }) => {
        return (
          <InputRHF
            className="mb3"
            key={key}
            name={key}
            type={type}
            min={min}
            controlProps={{ label }}
          />
        );
      })}
    </div>
  );
};
