import moment from 'moment';

type CrisisHoursRange = {
  startTime: Date;
  endTime: Date;
};

export function areCrisisHoursActive(crisisHours: CrisisHoursRange) {
  const now = moment();
  return now.isBetween(crisisHours.startTime, crisisHours.endTime);
}
