import { chain } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import {
  CareType,
  PeriodType,
  OrganizationCarePeriodsQuery,
  useOrganizationCarePeriodsQuery,
} from '../../../graphQL';
import { useDrilldownContext } from '../../../Pages/Users/helpers';
import {
  isMantraAdmin,
  isUserPsychiatrist,
  isUserTherapist,
  useCurrentProvider,
} from '../../Permissions';

type HookProps = {
  careType: CareType;
};

export const useCocEligibility = ({ careType }: HookProps) => {
  const { user } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();

  const [carePeriods, setCarePeriods] = useState<
    OrganizationCarePeriodsQuery['organization']['carePeriods']
  >([]);

  useOrganizationCarePeriodsQuery({
    variables: { organizationId: user.organization?.id! },
    notifyOnNetworkStatusChange: true,
    onCompleted: d => {
      setCarePeriods(d?.organization.carePeriods || []);
    },
    skip: !user.organization,
  });

  const currentCarePeriod = carePeriods?.length
    ? carePeriods.find(c => moment().isBetween(c.startDate, c.endDate, 'days', '[]'))
    : null;
  const nextCarePeriod = carePeriods?.length
    ? carePeriods.find(c => moment(c.startDate).isAfter(currentCarePeriod?.endDate, 'days'))
    : null;
  const carePeriodEnding = moment(currentCarePeriod?.startDate).diff(moment(), 'days') < 50;
  const firstDayOfBreak =
    nextCarePeriod && isPeriodBreak(nextCarePeriod)
      ? moment(nextCarePeriod.startDate).format('M/D/YY')
      : null;
  const lastDayOfBreak =
    nextCarePeriod && isPeriodBreak(nextCarePeriod)
      ? moment(nextCarePeriod.endDate).format('M/D/YY')
      : null;
  const lastDayOfTerm = currentCarePeriod
    ? moment(currentCarePeriod.endDate).format('M/D/YY')
    : null;
  const endingDueToBreak = carePeriodEnding && nextCarePeriod && isPeriodBreak(nextCarePeriod);

  const latestEligibilityResponse = chain(user)
    .get('continuityOfCareEligibility')
    .filter(e => e.careType === careType)
    .find(e => moment(e.updatedAt).isBefore(currentCarePeriod?.endDate))
    .value();

  const isProviderPsychiatrist =
    careType === CareType.Psychiatry && isUserPsychiatrist(user, currentProvider);

  const isProviderTherapist =
    careType === CareType.Therapy && isUserTherapist(user, currentProvider);

  const showEligibilityData =
    isProviderPsychiatrist || isProviderTherapist || isMantraAdmin(currentProvider)
      ? user.cocEligibility.find(e => e.careType === careType)
      : null;

  const hasNextStepsSelected = !!user?.continuityOfCareTransitions?.filter(
    c => c.careType === careType
  )?.length;

  const remainingSessions = user?.careFlows.find(c => c.careType === careType)?.careSessionInfo
    ?.remainingAppts;

  return {
    showEligibilityModule: !!showEligibilityData,
    showSponsoredForm: showEligibilityData?.paymentType === 'sponsored',
    hasFilledOutEligibilityForm: !!latestEligibilityResponse,
    latestEligibilityResponse,
    currentCarePeriod,
    nextCarePeriod,
    firstDayOfBreak,
    lastDayOfBreak,
    lastDayOfTerm,
    endingDueToBreak,
    hasNextStepsSelected,
    remainingSessions,
  };
};

type CarePeriod = OrganizationCarePeriodsQuery['organization']['carePeriods'][number];
const isPeriodBreak = (cp: Pick<CarePeriod, 'periodType'>) =>
  cp.periodType === PeriodType.SponsoredBreak || cp.periodType === PeriodType.NonSponsoredBreak;
