import { Tag } from 'baseui/tag';
import React, { useState } from 'react';
import { Contact } from '../../../graphQL';
import { formatPhoneNumber } from '../../../utils';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';
import { EditContact } from '../EditModals/EditContact';

export const EmergencyContacts = () => {
  const { user } = useDrilldownContext();

  const { emergencyContacts = [] } = user;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // omitted by directive
  if (!emergencyContacts) return null;
  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Emergency Contact</Text.h3>
        <Text.linkButton onClick={() => setIsModalOpen(true)}>Add</Text.linkButton>
        {isModalOpen && (
          <EditContact
            title="Edit Emergency Contact"
            contact={undefined}
            forcePrimary={emergencyContacts.length === 0}
            setIsModalOpen={setIsModalOpen}
            prefix="Emergency Contact "
          />
        )}
      </Styles.sidebarSectionHeader>
      {emergencyContacts.length === 0 && <Text.body>No emergency contacts listed</Text.body>}
      <div>
        {emergencyContacts
          .slice()
          .sort((a, b) => (b.primary ? 1 : 0) - (a.primary ? 1 : 0))
          .map(c => (
            <EmergencyContactCard key={c.id} contact={c} />
          ))}
      </div>
    </Styles.sidebarSection>
  );
};

type EmergencyContactCardProps = {
  contact: Pick<Contact, 'id' | 'name' | 'phoneNumber' | 'relationship' | 'primary'> | undefined;
};
function EmergencyContactCard({ contact }: EmergencyContactCardProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  if (!contact) return null;

  return (
    <Styles.undoVerticalMargin className="mb3">
      <div className="flex justify-between">
        <div className="flex items-center truncate">
          <p title={contact.name || undefined} className="mr2 truncate b">
            {contact.name}
          </p>
          {Boolean(contact.primary) && (
            <Tag kind="positive" closeable={false}>
              Primary
            </Tag>
          )}
        </div>
        <Text.linkButton onClick={() => setIsModalOpen(true)}>Edit</Text.linkButton>
        {isModalOpen && (
          <EditContact
            title="Edit Emergency Contact"
            contact={contact}
            forcePrimary={false}
            setIsModalOpen={setIsModalOpen}
            prefix="Emergency Contact "
          />
        )}
      </div>
      <Text.body>{contact.relationship}</Text.body>
      <Text.body>{contact.phoneNumber && formatPhoneNumber(contact.phoneNumber)}</Text.body>
    </Styles.undoVerticalMargin>
  );
}
