import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppView } from '../../graphQL';
import { FinalButton } from '../FinalButton';
import { Grid } from '../Grid';
import { Icon } from '../Icons';
import { McpOnly, OzOnly, PermsOnly, useCurrentProvider } from '../Permissions';
import { NavLink, UserFooter } from './Styles';

type NavFooterProps = {
  organizationId?: number;
};

export const NavFooter = ({ organizationId }: NavFooterProps) => {
  const history = useHistory();
  const { currentProvider, logout, appView } = useCurrentProvider();

  const providerFeedbackUrl = 'https://form.asana.com/?k=ShtaMxqJ4o0xQhvmVQ7c_w&d=947960270594192';
  const universityFeedbackUrl = `https://docs.google.com/forms/d/e/1FAIpQLSfvV-bWGk81Qfk5_mhzdb9PCG1Pi8Jfik-ngJ-Grj2icM-Q6A/viewform?entry.101741901=${currentProvider.email}&entry.624711273=${currentProvider.organizations[0]?.name}`;

  const viewToFeedbackUrl: Record<AppView, string> = {
    oz: providerFeedbackUrl,
    mcp: universityFeedbackUrl,
    referral: universityFeedbackUrl,
  };

  return (
    <div>
      <OzOnly>
        <NavLink to="/tutorial">
          <Grid gridTemplateColumns="2.25rem 1fr" alignItems="center">
            <Icon icon="iconsQuestionSvg" size={20} />
            <p className="o-50 f6">Tutorial</p>
          </Grid>
        </NavLink>
      </OzOnly>
      <McpOnly>
        <PermsOnly allowed="patientCreate">
          <div className="flex ph3 pv3 o-80">
            <FinalButton
              className="flex-auto"
              iconLeft="iconsWhitePlusSvg"
              kind="outline_white"
              pill
              onClick={() => history.push(`/organizations/${organizationId}/students/enroll`)}
            >
              Add Student
            </FinalButton>
          </div>
        </PermsOnly>
      </McpOnly>
      <NavLink as="a" href={viewToFeedbackUrl[appView]} target="_blank" rel="noopener">
        <Grid gridTemplateColumns="2.25rem 1fr" alignItems="center">
          <Icon icon="iconsPostsSvg" size={20} />
          <p className="o-50 f6">Feedback</p>
        </Grid>
      </NavLink>
      <UserFooter>
        <Grid gridTemplateColumns="2.25rem 1fr" className="ml2">
          <Icon size={20} style={{ filter: 'invert(100%)' }} icon="iconsUserSvg" />
          <div>
            <p className="f6 b mb2">{currentProvider.name}</p>
            <button
              type="button"
              className="f6 link white o-50 bg-inherit bw0 pointer pa0"
              onClick={logout}
            >
              Log Out
            </button>
          </div>
        </Grid>
      </UserFooter>
    </div>
  );
};
