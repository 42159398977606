import { isNil } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PatientStatus, Risk } from '../../../Components/Flair';
import { McpOnly } from '../../../Components/Permissions';
import { ProviderCard } from '../../../Components/Provider/AvatarCard';
import { CareType } from '../../../graphQL';
import { formatProviderName } from '../../../modelUtils/provider';
import { getFullPreferredName } from '../../../modelUtils/users';
import { formatPhoneNumber, stringifyParamsBy } from '../../../utils';
import { PatientInfoModal, RecordViewOnly, Restricted, useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';

export const PatientInfoSection = () => {
  const history = useHistory();
  const { user, hasTherapy, hasPsychiatry, events } = useDrilldownContext();
  const [viewMore, setViewMore] = useState(false);

  const {
    assessmentInfo,
    birthDate,
    phone,
    provider,
    therapist,
    email,
    primaryAddress,
    secondaryState,
    organizationIdentification,
  } = user;

  const address = primaryAddress ?? undefined;

  const birthDay = birthDate ? moment(birthDate).format('MM/DD/YYYY') : 'unspecified';
  const phoneNumber = phone ? formatPhoneNumber(phone) : 'unknown';

  const intakeScheduled = events.some(e => e.tag === 'schedule-initial-video-assessment');
  const therapyIntakeScheduled = events.some(
    e => e.tag === 'schedule-initial-therapy-consultation'
  );

  const suggestedProviders = (user.suggestedProviders ?? []).filter(p => {
    const showPsychSuggested = hasPsychiatry && !(provider || intakeScheduled);
    const showTherapySuggested = hasTherapy && !(therapist || therapyIntakeScheduled);
    return (
      (p.careTypes.includes(CareType.Therapy) && showTherapySuggested) ||
      (p.careTypes.includes(CareType.Psychiatry) && showPsychSuggested)
    );
  });

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Patient Info</Text.h3>
        <PatientInfoModal />
      </Styles.sidebarSectionHeader>
      <RecordViewOnly oz full>
        <Styles.spaceBetween>
          {user.taskStatus && (
            <div style={{ flex: 1 }}>
              <Text.label>STATUS</Text.label>
              <PatientStatus status={user.taskStatus}>
                <Text.body>{user.taskStatus}</Text.body>
              </PatientStatus>
            </div>
          )}
          {assessmentInfo && (
            <div style={{ flex: 1 }}>
              <Text.label>RISK LEVEL</Text.label>
              <Risk level={assessmentInfo.riskLevel}>
                <Text.body>{assessmentInfo.riskLevel || 'unspecified'}</Text.body>
              </Risk>
            </div>
          )}
        </Styles.spaceBetween>
      </RecordViewOnly>
      <Styles.spaceBetween>
        <div style={{ flex: 1 }}>
          <Text.label>DATE OF BIRTH</Text.label>
          <Text.body>{birthDay}</Text.body>
        </div>
        <Restricted>
          <div style={{ flex: 1 }}>
            <Text.label>PHONE</Text.label>
            <Text.body>{phoneNumber}</Text.body>
          </div>
        </Restricted>
      </Styles.spaceBetween>
      {(therapist || provider) && (
        <div className="mb3">
          <Text.label>MANTRA COLLABORATOR{provider && therapist && 's'}</Text.label>
          {provider && <ProviderCard provider={provider} />}
          {therapist && <ProviderCard provider={therapist} />}
        </div>
      )}
      <McpOnly>
        {!!suggestedProviders.length && (
          <div className="mb3">
            <Text.label className="mb2">Suggested Providers</Text.label>
            <div className="flex flex-column gap-1 mb2">
              {suggestedProviders.map(p => (
                <Text.bodyBold key={p.id} className="ma0 i truncate flex-auto">
                  {formatProviderName(p)}
                </Text.bodyBold>
              ))}
            </div>
            <Text.linkButton
              className="b"
              onClick={() =>
                history.push(
                  `/provider-network/suggested-providers?${stringifyParamsBy(
                    {
                      userId: user.id,
                      careType: user.careTypes[0],
                      state: user.primaryAddressState,
                    },
                    v => !isNil(v)
                  )}`
                )
              }
            >
              Add/Edit
            </Text.linkButton>
          </div>
        )}
      </McpOnly>
      {viewMore && (
        <div>
          {organizationIdentification && (
            <Styles.spaceBetween>
              <div>
                <Text.label>ORGANIZATION ID</Text.label>
                <Text.body>{organizationIdentification}</Text.body>
              </div>
            </Styles.spaceBetween>
          )}
          <Styles.spaceBetween>
            <div style={{ flex: 1 }}>
              <Text.label>LEGAL NAME</Text.label>
              <Text.body>{getFullPreferredName(user, 'f l')}</Text.body>
            </div>
          </Styles.spaceBetween>
          <Styles.spaceBetween>
            <div style={{ flex: 1 }}>
              <Text.label>EMAIL</Text.label>
              <Text.body>{email}</Text.body>
            </div>
          </Styles.spaceBetween>
          <Styles.spaceBetween>
            <div style={{ flex: 1 }}>
              <Text.label>ADDRESS</Text.label>
              {address?.line1 && (
                <div>
                  <Text.body>
                    {address.line1} {address.line2} <br />
                    {address.city}, {address.state} {address.zip}
                  </Text.body>
                </div>
              )}
              {!address?.line1 && address?.state && <Text.body>{address.state}</Text.body>}
              {!address && <Text.body>Not Specified</Text.body>}
            </div>
          </Styles.spaceBetween>
          {secondaryState && (
            <Styles.spaceBetween>
              <div style={{ flex: 1 }}>
                <Text.label>Secondary State</Text.label>
                <Text.body>{secondaryState}</Text.body>
              </div>
            </Styles.spaceBetween>
          )}
        </div>
      )}
      <Restricted>
        <Text.linkButton onClick={() => setViewMore(v => !v)}>
          View {viewMore ? 'less' : 'more'}
        </Text.linkButton>
      </Restricted>
    </Styles.sidebarSection>
  );
};
