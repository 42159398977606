import React from 'react';
import { capitalize } from 'lodash';
import { Modal as MantraModal, Text } from '../../../globalStyles';
import { DatePicker, FormControl, Input } from '../../../Components/Form';
import { FinalButton } from '../../../Components/FinalButton';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Modal } from '../../../Components/Modal/Modal';
import {
  CareType,
  Contract,
  useCreateContractMutation,
  useEditContractScopeMutation,
} from '../../../graphQL';
import { Nullable } from '../../../types';
import { useOrganizationId } from '../../Organizations/util';
import moment from 'moment';

type AddEditModalProps = {
  contract?: Nullable<Contract>;
  careTypes?: CareType[];
  isOpen: boolean;
  onClose: () => void;
  refetchContract: () => void;
};

export const AddEditContractModal = ({
  contract,
  careTypes,
  isOpen,
  onClose,
  refetchContract,
}: AddEditModalProps) => {
  const organizationId = useOrganizationId();

  const formContext = useForm({
    defaultValues: {
      startDate: contract?.scope.startDate,
      endDate: contract?.scope.endDate,
      therapySessions: contract?.scope.therapySessions ?? 0,
      therapyPatients: contract?.scope.therapyPatients ?? 0,
      psychiatrySessions: contract?.scope.psychiatrySessions ?? 0,
      psychiatryPatients: contract?.scope.psychiatryPatients ?? 0,
      submit: undefined,
    },
  });

  const [createContractMutation] = useCreateContractMutation({
    onCompleted: () => {
      onClose();
    },
    onError: error => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  const [editContractMutation] = useEditContractScopeMutation({
    onCompleted: () => {
      refetchContract();
      onClose();
    },
    onError: error => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  const types = careTypes?.map(type => type.toLowerCase());

  const {
    clearErrors,
    reset,
    trigger,
    formState: { errors },
  } = formContext;

  const submit = formContext.handleSubmit(async data => {
    clearErrors();

    const isValid = await trigger();

    if (!isValid) {
      return;
    }

    if (!data.startDate || !data.endDate) {
      formContext.setError('submit', {
        type: 'custom',
        message: 'Start Date and End Date are required',
      });
      return;
    }

    if (
      Number(data.therapyPatients) > Number(data.therapySessions) ||
      Number(data.psychiatryPatients) > Number(data.psychiatrySessions)
    ) {
      formContext.setError('submit', {
        type: 'custom',
        message: 'Student Capacity cannot be greater than Total Sessions',
      });
      return;
    }

    if (moment(data.startDate).isAfter(data.endDate)) {
      formContext.setError('submit', {
        type: 'custom',
        message: 'Start Date cannot be after End Date',
      });
      return;
    }

    const contractInput = {
      startDate: data.startDate,
      endDate: data.endDate,
      therapySessions: data.therapySessions ? Number(data.therapySessions) : 0,
      therapyPatients: data.therapyPatients ? Number(data.therapyPatients) : 0,
      psychiatrySessions: data.psychiatrySessions ? Number(data.psychiatrySessions) : 0,
      psychiatryPatients: data.psychiatryPatients ? Number(data.psychiatryPatients) : 0,
    };

    if (contract) {
      await editContractMutation({
        variables: {
          input: {
            id: contract.id,
            ...contractInput,
          },
        },
      });

      refetchContract();
    } else {
      await createContractMutation({
        variables: {
          input: {
            organizationId,
            ...contractInput,
          },
        },
      });

      refetchContract();
    }

    reset({
      ...contractInput,
    });

    return;
  });

  const careTypeInputs = types?.map(type => (
    <tr key={type}>
      <td className="tl pr5 ">{capitalize(type)}</td>
      <td className="pa2">
        <Controller
          name={`${type}Sessions`}
          render={({ onChange, value }) => (
            <Input
              className="tc"
              type="number"
              min={0}
              value={value}
              onChange={e => {
                const num = Number(e.currentTarget.value);
                if (!Number.isNaN(num)) {
                  clearErrors('submit');
                  onChange(num);
                }
              }}
            />
          )}
        />
      </td>
      <td className="pa2">
        <Controller
          name={`${type}Patients`}
          render={({ onChange, value }) => (
            <Input
              className="tc"
              type="number"
              min={0}
              value={value}
              onChange={e => {
                const num = Number(e.currentTarget.value);
                if (!isNaN(num)) {
                  clearErrors('submit');
                  onChange(num);
                }
              }}
            />
          )}
        />
      </td>
    </tr>
  ));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <MantraModal.body>
        <div className="mb4">
          <Text.h2>Add/Edit Contract Year</Text.h2>
        </div>
        <FormProvider {...formContext}>
          <div className="flex items-center justify-between">
            <Text.body className="nowrap">Contract Start Date:</Text.body>
            <div>
              <FormControl name="startDate">
                <Controller
                  name="startDate"
                  render={({ onChange, value }) => (
                    <DatePicker
                      value={value}
                      onChange={date => {
                        onChange(date);
                        clearErrors('submit');
                      }}
                      placeholder="MM/DD/YYYY"
                      iconRight="iconsCalendarCheckSvg"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex items-center justify-between mb4">
            <Text.body className="nowrap">Contract End Date:</Text.body>
            <div>
              <FormControl name="endDate">
                <Controller
                  name="endDate"
                  render={({ onChange, value }) => (
                    <DatePicker
                      value={value}
                      onChange={date => {
                        onChange(date);
                        clearErrors('submit');
                      }}
                      placeholder="MM/DD/YYYY"
                      iconRight="iconsCalendarCheckSvg"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="mb3">
            <Text.h3>Sessions Configuration</Text.h3>
          </div>
          <form onSubmit={submit}>
            <table className="w-100">
              <thead>
                <tr>
                  <th className="tl pr2 ">Care Type</th>
                  <th>Total Sessions</th>
                  <th>Student Capacity</th>
                </tr>
              </thead>
              <tbody>{careTypeInputs}</tbody>
            </table>

            {errors.submit && <Text.error className="pv2">{errors.submit.message}</Text.error>}

            <div>
              <FinalButton type="submit" className="w-100">
                Save Changes
              </FinalButton>
              <FinalButton
                className="w-100"
                kind="minimal_gray"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </FinalButton>
            </div>
          </form>
        </FormProvider>
      </MantraModal.body>
    </Modal>
  );
};
