import { FormControl } from 'baseui/form-control';
import { StatefulSelect } from 'baseui/select';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../../Components/FinalButton';
import { ToggleRHF } from '../../../Components/Form';
import { CenteredNotification } from '../../../Components/Notification';
import { PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import {
  CareType,
  CreateTestUser,
  Permission,
  ReferralSource,
  useAdminCreateTestUserMutation,
  useOrganizationsListQuery,
  useProvidersQuery,
} from '../../../graphQL';
import { AccountSetup } from './AccountSetup';
import { PatientActivity } from './PatientActivity';
import { PatientCare } from './PatientCare';
import { presetFactory } from './presets';
import { Flag } from './types';

const otherToggles: Flag[] = [{ key: 'mockMessages', label: 'Mock Messages' }];

export const CreateTestPatient = () => {
  const { featureFlags } = useCurrentProvider();
  if (!featureFlags.includes('SEED')) return null;
  return (
    <PermsOnly allowed={Permission.MantraAdmin}>
      <TestPatientForm />
    </PermsOnly>
  );
};

const TestPatientForm = () => {
  const history = useHistory();
  const form = useForm<CreateTestUser>();

  const { data: orgData } = useOrganizationsListQuery();
  const { data: providerData } = useProvidersQuery();

  const [createTestUser, { loading, error }] = useAdminCreateTestUserMutation({
    onCompleted: r => history.push(`/users/${r.adminCreateTestUser.id}`),
  });

  const submit = form.handleSubmit(args => {
    createTestUser({
      variables: {
        args: {
          ...args,
          referralSource: !args.organizationId ? ReferralSource.Portal : args.referralSource,
          completeCheckout: args.completeCheckout ?? args.completeProfile!!,
          therapistId:
            !!args.therapistId && args.careTypes.includes(CareType.Therapy)
              ? Number(args.therapistId)
              : null,
          psychiatristId:
            !!args.psychiatristId && args.careTypes.includes(CareType.Psychiatry)
              ? Number(args.psychiatristId)
              : null,
          organizationId: args.organizationId ? Number(args.organizationId) : null,
          numAssessments: args.numAssessments ? Number(args.numAssessments) : null,
          numAppointments: args.numAppointments ? Number(args.numAppointments) : null,
          defaultPaymentSource: args.defaultPaymentSource,
          careTypes: args.careTypes ?? [],
        },
      },
    });
  });

  const psychiatrists =
    providerData?.providers.filter(
      p => p.role === 'provider' && p.careTypes.includes(CareType.Psychiatry)
    ) ?? [];

  const therapists =
    providerData?.providers.filter(
      p => p.role === 'provider' && p.careTypes.includes(CareType.Therapy)
    ) ?? [];

  const organizations = orgData?.organizations ?? [];

  const PRESETS = presetFactory({
    psychiatrists,
    therapists,
    organizations,
  });

  return (
    <div className="mt3 mb5">
      <div className="mw6">
        {error && <CenteredNotification kind="negative">{error.message}</CenteredNotification>}
        <Text.h2 className="mb4">Create Test Patient</Text.h2>
        <FormProvider {...form}>
          <form onSubmit={submit}>
            <Text.label className="mb2">Presets</Text.label>
            <FormControl>
              <StatefulSelect
                clearable
                options={Object.keys(PRESETS).map(k => ({ label: k, id: k }))}
                onChange={e => {
                  const preset = e.option && PRESETS[e.option.id as string];
                  form.reset(preset ?? {});
                }}
              />
            </FormControl>
            <div className="w-100 mv4 bb b--gray" />
            <PatientCare
              control={form.control}
              therapists={therapists}
              psychiatrists={psychiatrists}
              organizations={organizations}
            />
            <AccountSetup />
            <PatientActivity control={form.control} />
            <div className="mb4">
              <Text.h2 className="mb3">Other</Text.h2>
              {otherToggles.map(({ key, label }) => (
                <ToggleRHF key={key} name={key}>
                  {label}
                </ToggleRHF>
              ))}
            </div>
            <div className="mt1">
              <FinalButton className="w-100" loading={loading} type="submit">
                Create
              </FinalButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
