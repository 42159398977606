import React from 'react';
import { CareType } from '../../../graphQL';
import { IColumn } from '../../../Components/UtilizationTable';

export const tooltipCopy = {
  sessionsMax: () => (
    <>
      <strong>
        <u>Max Sessions</u>
      </strong>
      : The set limit of bookable sessions for a given week that are available to be booked by
      students at this university. If the number of scheduled sessions reaches this maximum, no
      additional appointments will be available to book during the given week.
    </>
  ),
  sessionsUtilized: () => (
    <>
      <strong>
        <u>Sessions Utilized</u>
      </strong>
      : The total bookable sessions utilized in the week, which is the total of all appointments
      completed, no-showed by students, or cancelled/rescheduled by the request of the student less
      than 24h in advance.
    </>
  ),
  intakesMax: () => (
    <>
      <strong>
        <u>Max Intakes</u>
      </strong>
      : The limit of bookable intake visits for a given week that are available to be booked by
      students at this university. If the number of scheduled intakes reaches or exceeds this
      maximum, no additional intakes will be available to book during the given week.
    </>
  ),
  intakesUtilized: () => (
    <>
      <strong>
        <u>Intakes Utilized</u>
      </strong>
      : The total bookable intake visits utilized in the week, which is the total of all intakes
      completed, no-showed by students, or cancelled/rescheduled by the request of the student less
      than 24h in advance.
    </>
  ),
  sessionsScheduled: () => (
    <>
      <strong>
        <u>Sessions Scheduled</u>
      </strong>
      : The number of bookable sessions that are currently reserved for scheduled visits in a given
      week. This includes upcoming visits, or recent visits that have not yet been marked as
      completed, no-showed, or cancelled.
    </>
  ),
  intakesScheduled: () => (
    <>
      <strong>
        <u>Intakes Scheduled</u>
      </strong>
      : The number of bookable intakes that are currently reserved for scheduled intakes in a given
      week. This includes upcoming intakes, or recent intakes that have not yet been marked as
      completed, no-showed, or cancelled.
    </>
  ),
};

export const pastColumnCopy = (careType: CareType): IColumn[] => [
  {
    headings: [
      {
        heading: 'Week',
      },
    ],
  },
  {
    groupHeading: `${careType} Sessions`,
    headings: [
      {
        heading: 'Max Sessions',
        tooltip: tooltipCopy.sessionsMax,
      },
      {
        heading: 'Sessions Utilized',
        tooltip: tooltipCopy.sessionsUtilized,
      },
      {
        heading: 'Intakes Utilized',
        tooltip: tooltipCopy.intakesUtilized,
      },
    ],
  },
];

export const upcomingColumnCopy = (careType: CareType): IColumn[] => [
  {
    headings: [
      {
        heading: 'Week',
      },
    ],
  },
  {
    groupHeading: ``,
    headings: [
      {
        heading: 'Max Sessions',
        tooltip: tooltipCopy.sessionsMax,
      },
      {
        heading: 'Sessions Scheduled',
        tooltip: tooltipCopy.sessionsScheduled,
      },
    ],
  },
  {
    groupHeading: ``,
    headings: [
      {
        heading: 'Max Intakes',
        tooltip: tooltipCopy.intakesMax,
      },
      {
        heading: 'Intakes Scheduled',
        tooltip: tooltipCopy.intakesScheduled,
      },
    ],
  },
];

export const thisWeekColumnCopy = (careType: CareType): IColumn[] => [
  {
    headings: [
      {
        heading: 'Week',
      },
    ],
  },
  {
    groupHeading: `${careType} Sessions`,
    headings: [
      {
        heading: 'Max Sessions',
        tooltip: tooltipCopy.sessionsMax,
      },
      {
        heading: 'Sessions Utilized',
        tooltip: tooltipCopy.sessionsUtilized,
      },
      {
        heading: 'Sessions Scheduled',
        tooltip: tooltipCopy.sessionsScheduled,
      },
    ],
  },
  {
    groupHeading: `${careType} Intakes`,
    headings: [
      {
        heading: 'Max Intakes',
        tooltip: tooltipCopy.intakesMax,
      },
      {
        heading: 'Intakes Utilized',
        tooltip: tooltipCopy.intakesUtilized,
      },
      {
        heading: 'Intakes Scheduled',
        tooltip: tooltipCopy.intakesScheduled,
      },
    ],
  },
];
