import { compact, lowerCase, uniqBy } from 'lodash';
import React from 'react';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { PermsOnly } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { Permission, useDebugLoginAsUserMutation, useOrganizationUsersQuery } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';

export const DebugUsers = () => {
  const { data } = useOrganizationUsersQuery();
  const [loginAsUser] = useDebugLoginAsUserMutation();

  if (!data) return <LoadingPage />;
  const therapists = compact(uniqBy(data.users, u => u.therapist?.id).map(u => u.therapist));
  const psychiatrists = compact(uniqBy(data.users, u => u.provider?.id).map(u => u.provider));
  const orgs = compact(uniqBy(data.users, u => u.organization?.id).map(u => u.organization));

  return (
    <div>
      <ListView
        data={data.users}
        getKey={u => u.id}
        filters={[
          {
            key: 'therapist',
            placeholder: 'Therapist',
            options: therapists.map(p => ({ label: p.name, id: p.id })),
            test: (item, val) => item.therapist?.id === Number(val),
          },
          {
            key: 'psychiatrist',
            placeholder: 'Psychiatrist',
            options: psychiatrists.map(p => ({ label: p.name, id: p.id })),
            test: (item, val) => item.provider?.id === Number(val),
          },
          {
            key: 'organization',
            placeholder: 'Organization',
            options: orgs.map(p => ({ label: `${p.name} (${p.abbreviation})`, id: p.id })),
            test: (item, val) => item.organization?.id === Number(val),
          },
        ]}
        search={(i, val) => {
          const lower = lowerCase(val);
          return compact([i.taskStatus, i.customerId, i.email, i.firstName, i.lastName])
            .map(lowerCase)
            .some(test => test.includes(lower));
        }}
        columns={[
          {
            title: 'Overview',
            key: 'overview',
            render: u => (
              <div>
                <p className="b">{getFullName(u)}</p>
                <p>{u.customerId}</p>
                <p className="truncate">{u.email}</p>
              </div>
            ),
          },
          {
            title: 'Providers',
            key: 'providers',
            render: u => (
              <div>
                <p>Organization: {u.organization?.abbreviation ?? ''}</p>
                <p>Psychiatrist: {u.provider?.name}</p>
                <p>Therapist: {u.therapist?.name}</p>
              </div>
            ),
          },
          {
            title: 'Status',
            key: 'status',
            render: u => (
              <div>
                <p>Status: {u.taskStatus}</p>
                <p>CareStatus: {u.careStatus}</p>
              </div>
            ),
          },
          {
            title: 'Actions',
            key: 'actions',
            render: u => (
              <div className="flex flex-column items-start">
                <Text.link to={`/users/${u.id}`}>visit profile</Text.link>
                <PermsOnly allowed={Permission.MantraAdmin}>
                  <Text.linkButton
                    onClick={async () => {
                      const mutData = await loginAsUser({ variables: { userId: u.id } });
                      if (!mutData.data) return;
                      window.open(mutData.data.debugLoginAsUser, '_blank');
                    }}
                  >
                    login as
                  </Text.linkButton>
                </PermsOnly>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
