/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { toPercent } from './utils';

type ItemProps = {
  distance: number;
  containerRef: React.RefObject<HTMLDivElement>;
  bottom?: string;
  onDrop?: (dropPercent: number) => Promise<boolean>;
};
export const TimelineItem: React.FC<ItemProps> = ({
  distance,
  bottom,
  containerRef,
  children,
  onDrop,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [posX, setPosX] = useState(distance * (containerRef.current?.clientWidth ?? 1));
  const [isDragging, setIsDragging] = useState(false);

  const left = isDragging ? posX : toPercent(distance);

  useEffect(() => {
    if (isDragging) {
      const parentRect = containerRef.current?.getBoundingClientRect();
      if (!parentRect) return;

      const offset = parentRect.x + (ref.current?.clientWidth ?? 0);

      const onMove = (e2: MouseEvent) => {
        setPosX(e2.screenX - offset);
      };

      const onUp = async (e2: MouseEvent) => {
        const dropDistance = (e2.screenX - offset) / parentRect!.width;
        await onDrop?.(dropDistance);
        setIsDragging(false);
      };

      document.addEventListener('mouseup', onUp);
      document.addEventListener('mousemove', onMove);

      return () => {
        document.removeEventListener('mouseup', onUp);
        document.removeEventListener('mousemove', onMove);
      };
    }
  }, [isDragging, containerRef, onDrop]);

  return (
    <>
      <div className="absolute bg-black h-100" style={{ width: 1, left }} />
      <div
        ref={ref}
        style={{ bottom, left, cursor: 'col-resize', userSelect: 'none' }}
        onMouseDown={() => setIsDragging(true)}
        className="pl1 f7 absolute"
      >
        {children}
      </div>
    </>
  );
};
