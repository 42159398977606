import { toaster } from 'baseui/toast';
import { debounce, isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  MedicalNoteSectionInput,
  MedicalNoteTemplateFragment,
  SectionFormat,
  useGetOrCreateNoteMutation,
  useSaveNoteSectionsMutation,
  useSubmitNoteMutation,
} from '../../graphQL';
import { useEvents } from '../Events/EventsProvider';
import * as formUtil from './util';

type HookProps = {
  userId: number;
  noteKey: string;
  appointmentId?: number;
};

const SAVE_DEBOUNCE = 500;
const QUESTION_CALCULATE_DEBOUNCE = 200;

export const useMedicalNote = ({ userId, noteKey, appointmentId }: HookProps) => {
  const metrics = useEvents();
  const formContext = useForm<Record<string, string | string[] | undefined>>({
    shouldUnregister: true,
  });
  const [template, setTemplate] = useState<MedicalNoteTemplateFragment>();
  const [remainingRequiredQuestions, setRemainingRequiredQuestions] = useState(0);
  const [totalRequiredQuestions, setTotalRequiredQuestions] = useState(0);
  const [highlightActive, setHighlightActive] = useState(false);
  const [oldSections, setOldSections] = useState<MedicalNoteSectionInput[]>([]);

  const changedRef = useRef(new Set<string>());
  const [dispatchSave] = useSaveNoteSectionsMutation({ ignoreResults: true });
  const [dispatchSubmit, { error: submitError }] = useSubmitNoteMutation();
  const [getNote, { loading }] = useGetOrCreateNoteMutation({
    fetchPolicy: 'no-cache',
    variables: { userId, key: noteKey },
    onCompleted: ({ note }) => {
      const defaultValues = note.template.questions.reduce((acc, cur) => {
        for (const q of [cur, ...(cur.children ?? [])]) acc[q.key] = q.value && JSON.parse(q.value);
        return acc;
      }, {} as Record<string, any>);

      defaultValues.appointmentId = note.appointment?.id ?? appointmentId;
      formContext.reset(defaultValues);
      setTemplate(note.template);
    },
  });

  /** trigger mutation on mount */
  useEffect(() => void getNote(), [getNote]);

  const questions = useMemo(
    () => (template ? formUtil.formatQuestionsForEdit(template.questions) : []),
    [template]
  );

  type Question = typeof questions[number];

  // easier to extract values from children vs. having to recur through parent <> child graph
  const flattenQuestions = (q: Question[]) => {
    const children = q.map(i => i.children);
    return [...children, ...q].flat().filter(Boolean);
  };

  const flattenedQuestions = flattenQuestions(questions);
  type ParentOrChildQuestion = typeof flattenedQuestions[number];

  const eSign = questions.find(q => q.format === SectionFormat.Sign);
  const canSubmit = (!eSign || !!formContext.watch(eSign.key)) && remainingRequiredQuestions <= 0;

  const getSectionsForSave = () => {
    if (changedRef.current.size === 0) return [];

    const changedKeys = Array.from(changedRef.current);
    changedRef.current.clear();

    const changedValues = formContext.getValues(changedKeys);

    const sections: MedicalNoteSectionInput[] = changedKeys.map(key => ({
      key,
      value: changedValues[key] && JSON.stringify(changedValues[key]),
    }));

    return sections;
  };

  const hasValue = (value: string | string[] | undefined) =>
    Array.isArray(value) ? value.length > 0 : !!value;
  const isDisplayed = (question: any) => {
    const values = formContext.getValues();
    const { dependsOn } = question;
    return !dependsOn || formUtil.showDependentQuestion({ dependsOn, getValue: k => values[k] });
  };

  const questionHasValue = (q: ParentOrChildQuestion) => {
    if (!q) return false;
    const values = formContext.getValues();
    const value = values[q.key];
    return !hasValue(value);
  };

  const filterRemainingQuestions = () =>
    flattenedQuestions.filter(questionIsRequired).filter(questionHasValue);

  const submit = async () => {
    if (!canSubmit) return;
    const values = formContext.getValues();
    if (!template) return;
    for (const question of questions) {
      const value = values[question.key];
      if (questionIsRequired(question) && !hasValue(value)) {
        setHighlightActive(true);
        const displayed =
          !question.dependsOn ||
          formUtil.showDependentQuestion({
            dependsOn: question.dependsOn,
            getValue: k => values[k],
          });
        // trigger scroll after event loop
        window.setTimeout(() => {
          document.getElementById(question.key)?.scrollIntoView({ behavior: 'smooth' });
        }, 0);
        if (displayed) {
          return document.getElementById(question.key)?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    const sections = getSectionsForSave();
    const submitted = await dispatchSubmit({ variables: { uuid: template.uuid, sections } });

    metrics.track(`note.submitted`, { key: template.key, uuid: template.uuid, userId });
    toaster.positive(`Submitted ${template.title ?? 'Medical Note'}`, {});
    return submitted;
  };

  const save = async () => {
    if (!template) return;
    const sections = getSectionsForSave();
    if (sections.length === 0) return;

    if (!isEqual(sections, oldSections)) {
      setOldSections(sections);
      await dispatchSave({ variables: { uuid: template.uuid, sections } });
    }
  };

  const triggerDependentSelections = (keys: any) => {
    for (const key of keys) {
      const question = questions.find(q => q.key === key);
      if (!question || !question.selectDependentValues) return;
      const values = formContext.getValues();
      const value = values[question.key] as string;
      const matchingValue = question.selectDependentValues.find(v =>
        v.dependsValues?.includes(value)
      );
      if (typeof value === 'string' && matchingValue) {
        formContext.setValue(
          matchingValue.targetKey,
          matchingValue?.targetSelectValue || undefined
        );
      }
    }
  };

  const debouncedSave = debounce(save, SAVE_DEBOUNCE);

  const queueForSave = (keys: string[]) => {
    // coupled to keystroke - performance adjustments
    recalculateRemainingQuestions();
    triggerDependentSelections(keys);
    for (const key of keys) changedRef.current.add(key);
    debouncedSave();
  };

  const scrollToRequiredQuestions = () => {
    setHighlightActive(true);
    const firstUnfinishedRequiredQuestion = filterRemainingQuestions()[0];

    window.setTimeout(() => {
      if (firstUnfinishedRequiredQuestion) {
        document
          .getElementById(firstUnfinishedRequiredQuestion.key)
          ?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  const calculateTotalRequiredQuestions = (noteQuestions: ParentOrChildQuestion[]) => {
    return noteQuestions.filter(questionIsRequired);
  };

  const questionIsRequired = (question: ParentOrChildQuestion) => {
    const values = formContext.getValues();

    // complex logic
    if (question && question.requiredIf) {
      return (
        // every dependency listed in dependentQuestions must be satisfied
        question.requiredIf.dependentQuestions.every(dependentQuestion => {
          const dependentQuestionValue = values[dependentQuestion.targetKey];

          if (dependentQuestion.requiredIfEmpty) {
            return !dependentQuestionValue || dependentQuestionValue?.length < 1;
          }
          return dependentQuestion.requiredIfValues?.some((item: string) =>
            dependentQuestionValue?.includes(item)
          );
        }) && isDisplayed(question)
      );
    }

    // simple logic
    return question?.required && isDisplayed(question);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recalculateRemainingQuestions = useCallback(
    debounce(() => {
      const requiredQuestions = calculateTotalRequiredQuestions(flattenedQuestions).length;

      const remainingQuestions = filterRemainingQuestions().length;
      setRemainingRequiredQuestions(remainingQuestions);
      setTotalRequiredQuestions(requiredQuestions);
    }, QUESTION_CALCULATE_DEBOUNCE),
    [setRemainingRequiredQuestions, formContext, questions]
  );

  // update count of remaining required questions on render
  useEffect(() => {
    recalculateRemainingQuestions();
  }, [recalculateRemainingQuestions]);

  return {
    questions,
    formContext,
    loading,
    template,
    canSubmit,
    submitError,
    save,
    submit,
    queueForSave,
    totalRequiredQuestions,
    remainingRequiredQuestions,
    scrollToRequiredQuestions,
    highlightActive,
  };
};
