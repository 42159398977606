import React, { ReactNode, useMemo } from 'react';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useOrganizationId } from '../Organizations/util';
import { UnexpectedError } from '../Shared';
import { ContractDashboard } from './components/ContractDashboard';
import { NoContractAlert } from './components/NoContractAlert';
import { Error } from '../Provider/Availability/Styles'; // Find better Error component
import { Text } from '../../globalStyles';
import {
  ContractScope,
  ListContractsItemModel,
  useGetContractQuery,
  useListContractsQuery,
  useOrganizationCareFlowsQuery,
} from '../../graphQL';

export type ContractPeriodSubset = Pick<ContractScope, 'startDate' | 'endDate'>;

export const ContractsPage = () => {
  const organizationId = useOrganizationId();

  const {
    data: organizationData,
    error: organizationError,
    loading: organizationLoading,
  } = useOrganizationCareFlowsQuery({ variables: { organizationId } });

  // To be put inside of context
  const {
    data: contractListData,
    loading: contractListLoading,
    error: contractListError,
    refetch: refetchContractList,
  } = useListContractsQuery({ variables: { organizationId }, skip: !organizationId });

  const currentContractId = useMemo(() => {
    if (contractListData?.listContracts.contracts) {
      return determineCurrentContract(contractListData?.listContracts.contracts);
    }
  }, [contractListData?.listContracts]);

  const {
    data: contractData,
    loading: contractLoading,
    error: contractError,
    refetch,
  } = useGetContractQuery({
    variables: { organizationId, contractId: currentContractId ?? 0 },
    skip: !currentContractId,
  });

  const careTypes = useMemo(() => {
    return organizationData?.organization.careFlows
      .map(flow => flow.careType)
      .sort((a, b) => b.localeCompare(a));
  }, [organizationData?.organization.careFlows]);

  const loading = contractListLoading || contractLoading || organizationLoading;
  const hasError = !!contractListError || !!contractError || !!organizationError;
  // End context.

  const editable = useMemo(() => {
    return true;
  }, [contractData?.organization.parent]);

  if (loading) return <LoadingPage />;
  // Note, contractData will exist and be a state error if it's the errors we're handling below
  if (hasError) return <UnexpectedError />;

  // Handle 0 contracts case
  if (!currentContractId) {
    return (
      <ContractPageWrapper>
        <NoContractAlert careTypes={careTypes} refetchContract={refetchContractList} />
      </ContractPageWrapper>
    );
  }

  // 0 contract case handled, AND no contract data? Error.
  if (!contractData || !careTypes) {
    return <Error>Could not determine contract data</Error>;
  }
  const contract = contractData.getContract;

  // Handle getContract state errors / type narrow for contract
  const { __typename: responseType } = contract;
  if (responseType === 'GetContractError') {
    // TODO: Handle codes individually here as necessary
    return <Error>{contract.message}</Error>;
  }

  return (
    <ContractPageWrapper>
      <ContractDashboard
        careTypes={careTypes}
        contract={contract}
        editable={editable}
        contractRefetch={refetch}
      />
    </ContractPageWrapper>
  );
};

const ContractPageWrapper = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div style={{ minWidth: '1050px' }}>
      <div className="relative mb3">
        <Text.h2 className="mb3">Care Ops Configuration</Text.h2>

        <Text.body className="mb4>">
          <span className="b">Model Type: </span> Sessions Model
        </Text.body>
      </div>

      {children}
    </div>
  );
};

/**
 * Pick the current contract, or the future contract closest to today
 * Note: this assumes item dates do not overlap
 */
function determineCurrentContract(contracts: ListContractsItemModel[]): number | undefined {
  if (!contracts.length) {
    return undefined;
  }
  const sortedContracts = contracts
    .slice()
    .sort(
      (contract1, contract2) =>
        new Date(contract1.startDate).getTime() - new Date(contract2.startDate).getTime()
    );
  const currentTime = new Date().getTime();
  const currentContract = sortedContracts.find(contract => {
    const startTime = new Date(contract.startDate).getTime();
    const endTime = new Date(contract.endDate).getTime();
    return startTime <= currentTime && endTime >= currentTime;
  });

  if (currentContract) {
    return currentContract.id;
  }

  // Else no current contract, select the contract in the future closest to today
  const futureContracts = sortedContracts.filter(
    contract => new Date(contract.startDate).getTime() > currentTime
  );
  if (futureContracts.length) {
    return futureContracts[0]?.id;
  }
  return undefined;
}
