import { useState } from 'react';
import { events, useEvents } from '../../Components/Events/EventsProvider';

type VideoCallEventKeys = keyof typeof events.videoCall;
type VideoCallEventTypes = typeof events.videoCall[VideoCallEventKeys];

type RejoinArgs = {
  appointmentId: number;
  providerId: number;
};

export const useVideoCall = ({ appointmentId, providerId }: RejoinArgs) => {
  const { track: trackEvent } = useEvents();

  const [isOnCall, setIsOnCall] = useState(true);

  const trackVideoCallEvents = (
    eventType: VideoCallEventTypes,
    eventData: Partial<RejoinArgs> = {}
  ) => {
    try {
      trackEvent(eventType, {
        appointmentId,
        providerId,
        ...eventData,
      });
    } catch (trackError) {
      // Do nothing with the error.
    }
  };

  const endCall = () => {
    setIsOnCall(false);
  };

  const joinCall = () => {
    setIsOnCall(true);
  };

  const providerEndCall = () => {
    // Log an emission that the end call button was clicked.
    trackVideoCallEvents(events.videoCall.leaveButtonClicked);

    endCall();
  };

  const providerRejoinCall = () => {
    // Log an emission that the rejoin button was clicked.
    trackVideoCallEvents(events.videoCall.rejoinButtonClicked);

    joinCall();
  };

  const trackVideoCallJoinButtonClicked = () => {
    // Log an emission that the join button was clicked.
    trackVideoCallEvents(events.videoCall.joinButtonClicked);
  };

  const trackVideoCallJoined = (eventData: Partial<RejoinArgs> = {}) => {
    // Log an emission that the rejoin button was clicked.
    trackVideoCallEvents(events.videoCall.joined, eventData);
  };

  const trackVideoCallLeft = (eventData: Partial<RejoinArgs> = {}) => {
    // Log an emission that the rejoin button was clicked.
    trackVideoCallEvents(events.videoCall.left, eventData);
  };

  const trackVideoCallNoShowConfirm = () => {
    // Log an emission that the rejoin button was clicked.
    trackVideoCallEvents(events.videoCall.noShowConfirmButtonClicked);
  };

  return {
    isOnCall,
    endCall,
    joinCall,
    providerRejoinCall,
    providerEndCall,
    trackVideoCallJoinButtonClicked,
    trackVideoCallJoined,
    trackVideoCallLeft,
    trackVideoCallNoShowConfirm,
  };
};
