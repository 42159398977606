import { Modal, ModalBody, ModalHeader, ROLE } from 'baseui/modal';
import { Notification } from 'baseui/notification';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconButton } from '../../../Components/Buttons';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { isMantraAdmin, isMcpUser, useCurrentProvider } from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import { UploadType } from '../../../graphQL';
import { borderRadius } from '../../../utils';
import { useDrilldownContext } from '../helpers';
import { useUserFileUpload } from './hook';
import {
  ShareableCheck,
  Upload,
  UploadName,
  uploadTypeCopy,
  uploadTypeLabels,
  UploadTypeSelect,
} from './Input';
import * as Styles from './styles';
import { canOnlyUploadGeneric } from './utils';

interface FileUploadProps<T> {
  closeModal: () => void;
  refetch?: () => Promise<T> | T;
}

type Form = {
  uploadName: string;
  type: UploadType;
  nameOverride?: string;
  shareable?: boolean;
};

const required = 'This field is required.';

export function FileUpload<T>({ closeModal, refetch }: FileUploadProps<T>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user, permissibleUploadTypes } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const justGeneric = canOnlyUploadGeneric(permissibleUploadTypes);
  const { register, control, handleSubmit, errors, watch, setValue } = useForm<Form>();

  // roles
  const isAdmin = isMantraAdmin(currentProvider);
  const isMcp = isMcpUser(currentProvider);

  const {
    progress,
    onAccepted,
    onRejected,
    onRetry,
    accepts,
    error: uploadError,
    maxSize,
    completeAsync,
  } = useUserFileUpload({
    userId: user.id,
    afterCompletion: refetch,
  });

  const submit = handleSubmit(async ({ type, nameOverride, shareable }) => {
    const uploadType = justGeneric ? UploadType.Other : type;
    try {
      setLoading(true);
      setError('');
      if (completeAsync) {
        await completeAsync({
          fileName: nameOverride?.trim() || uploadName,
          uploadType,
          globallyShared: !!shareable || isMcp,
        });
      }
      closeModal();
    } catch {
      setError('Upload unsuccessful. Please try again.');
    } finally {
      setLoading(false);
    }
  });

  // permissions
  const { uploadName, type, shareable } = watch(['uploadName', 'type', 'shareable']);
  const submittable = uploadName && (type || justGeneric);
  const canEditType = !justGeneric;
  const allowShare = type === UploadType.Other && isAdmin;
  const typeCopy = canEditType && !allowShare && type && uploadTypeCopy[type];

  return (
    <Modal
      isOpen
      onClose={closeModal}
      closeable
      animate
      size="auto"
      role={ROLE.dialog}
      unstable_ModalBackdropScroll
      overrides={{
        Dialog: {
          style: {
            ...borderRadius('4px'),
            paddingLeft: '4rem',
            paddingRight: '4rem',
            paddingTop: '2rem',
            paddingBottom: '2rem',
          },
        },
      }}
    >
      <ModalHeader>
        <Text.h2 className="mb4">New Upload</Text.h2>
      </ModalHeader>
      <ModalBody>
        {error && <Notification kind="negative">{error}</Notification>}
        <form style={{ width: '325px' }} onSubmit={submit}>
          <Upload
            name="uploadName"
            control={control}
            onRetry={onRetry}
            onUpload={onAccepted}
            onRejected={onRejected}
            errorMessage={uploadError}
            accept={accepts}
            maxSize={maxSize}
            rules={{ required }}
          />
          <UploadProgress
            progress={progress}
            fileName={uploadName}
            onAbandon={() => {
              onRetry();
              setValue('uploadName', undefined);
            }}
          />
          <UploadTypeSelect
            name="type"
            control={control}
            errorCaption={errors.type?.message}
            rules={{ required }}
            options={permissibleUploadTypes.map(v => ({
              id: v,
              label: uploadTypeLabels[v],
            }))}
            display={canEditType}
          />
          {typeCopy && (
            <Text.bodySmall kind="black" className="mb4" style={{ lineHeight: 'normal' }}>
              {typeCopy}
            </Text.bodySmall>
          )}
          <ShareableCheck
            name="shareable"
            control={control}
            errorCaption={errors.shareable?.message}
            display={allowShare}
            checked={!!shareable}
          />
          <UploadName name="nameOverride" inputRef={register()} optional />
          <FinalButton
            className="w-100"
            loading={loading}
            kind="primary"
            type="submit"
            disabled={!submittable}
          >
            Add to Patient Profile
          </FinalButton>
        </form>
      </ModalBody>
    </Modal>
  );
}

const UploadProgress = ({
  progress,
  fileName,
  onAbandon,
}: {
  progress?: number;
  fileName?: string;
  onAbandon: () => void;
}) => {
  if (isNil(progress)) return null;
  const normalized = Math.min(Math.max(progress, 0), 100);
  return (
    <div className="mv3">
      {normalized < 100 ? (
        <>
          <div className="mb2 flex flex-row">
            <Icon icon="iconsTickedLoaderSvg" alt="Loader" className="mr2" />
            {fileName && <Text.bodySmallGrey className="truncate b">{fileName}</Text.bodySmallGrey>}
          </div>
          <Styles.ProgressContainer>
            <Styles.Progress progress={normalized} />
          </Styles.ProgressContainer>
        </>
      ) : (
        <div className="mb2 flex flex-row justify-between items-center">
          <div className="nowrap overflow-hidden flex flex-row" style={{ flex: 1 }}>
            <Icon icon="iconsCircleCheckSvg" className="mr2" alt="Upload Complete" />
            {fileName && <Text.bodySmall className="truncate b">{fileName}</Text.bodySmall>}{' '}
          </div>
          <IconButton onClick={onAbandon}>
            <Icon icon="iconsTrashCanSvg" alt="Trash" />
          </IconButton>
        </div>
      )}
    </div>
  );
};
