import { capitalize } from 'lodash';
import React from 'react';
import { FinalButton } from '../../../../Components/FinalButton';
import { Notification } from '../../../../Components/Notification';
import { WizardRenderProps } from '../../../../Components/Wizard';
import { Text } from '../../../../globalStyles';
import { useAdminScheduleAppointmentMutation } from '../../../../graphQL';
import { getFullName } from '../../../../modelUtils/users';
import { paymentSourceCopy } from '../../../Users/copy';
import { WizardContentWithBack } from '../../Common';
import { WizardData } from '../types';

export function Confirm({ data, prevStep, nextStep }: WizardRenderProps<WizardData>) {
  const [scheduleAppt, { error, loading }] = useAdminScheduleAppointmentMutation();

  const start = data.appointment!.startTime;
  const end = data.appointment!.endTime;

  const onConfirm = async () => {
    await scheduleAppt({
      variables: {
        start: start.toDate(),
        end: end.toDate(),
        userId: data.user.id,
        providerId: data.appointment!.provider.id,
        appointmentType: data.appointmentTemplate!.appointmentType,
        organizationId: data.appointment!.organizationId,
        isFeeForServiceTime: data.appointment?.isFeeForServiceTime,
      },
    });
    nextStep();
  };

  return (
    <WizardContentWithBack data-cy="confirm-appointment" onBack={() => prevStep()}>
      {error && <Notification kind="negative">Internal error, please try again</Notification>}
      <Text.h2>Confirm appointment</Text.h2>
      <Text.body>Please verify all details before booking appointment</Text.body>
      <div className="flex flex-column gap-3 mb4">
        <div>
          <Text.label>PATIENT</Text.label>
          <Text.bodyBold>{getFullName(data.user)}</Text.bodyBold>
        </div>
        <div>
          <Text.label>TYPE</Text.label>
          <Text.bodyBold>{capitalize(data.appointmentTemplate!.appointmentType)}</Text.bodyBold>
        </div>
        <div>
          <Text.label>PROVIDER</Text.label>
          <Text.bodyBold>{data.appointment!.provider.name}</Text.bodyBold>
        </div>
        <div>
          <Text.label>DURATION</Text.label>
          <Text.bodyBold>{data.appointmentTemplate!.duration} min</Text.bodyBold>
        </div>
        <div>
          <Text.label>DATE</Text.label>
          <Text.bodyBold>{start.format('dddd, MMMM Do')}</Text.bodyBold>
        </div>
        <div>
          <Text.label>TIME</Text.label>
          <Text.bodyBold>
            {start.format('hh:mma')} - {end.format('hh:mma')}
          </Text.bodyBold>
        </div>
        <div>
          <Text.label>PAYMENT SOURCE</Text.label>
          <Text.bodyBold>
            {paymentSourceCopy[data.paymentSource!]}
            {data.insuranceDetails
              ? ` (${data.insuranceDetails.payerName} ${data.insuranceDetails.memberId})`
              : ''}
          </Text.bodyBold>
        </div>
      </div>
      {
        // div below is for safari browser edgecase
      }
      <div>
        <FinalButton kind="primary" className="w-100" onClick={onConfirm} loading={loading}>
          Book Appointment
        </FinalButton>
      </div>
    </WizardContentWithBack>
  );
}
