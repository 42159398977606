import { capitalize } from 'lodash';
import moment from 'moment';
import { useCurrentProvider } from '../../../Components/Permissions';
import { hasAnySelfPayFlow } from '../../../Components/ProviderNetwork/providerNetworkUtils';
import { WizardStep } from '../../../Components/Wizard';
import {
  CareType,
  NetworkReferralUserQuery,
  PaymentSource,
  useNetworkReferralUserQuery,
  usePayersQuery,
} from '../../../graphQL';
import { useQueryParams } from '../../../Hooks';
import { Nullable } from '../../../types';
import { AppointmentType } from '../types';
import { Confirm } from './Steps/Confirm';
import { InsuranceStep } from './Steps/InsuranceStep';
import { ProviderBooking } from './Steps/ProviderBooking';
import { InsuranceDetails, WizardData } from './types';

let steps: WizardStep<WizardData>[] = [
  {
    name: 'Insurance',
    render: InsuranceStep,
  },
  {
    name: 'Schedule',
    render: ProviderBooking,
  },
  {
    name: 'Confirm',
    render: Confirm,
  },
];

export const useSelfPayReferralBooking = () => {
  const { currentProvider } = useCurrentProvider();
  const [queryParams] = useQueryParams();
  const careType = (queryParams.careType || null) as Nullable<CareType>;
  const userId = Number(queryParams.userId) || null;
  const apptType = (queryParams.type || 'intake') as AppointmentType;

  const {
    data: payerData,
    loading: payersLoading,
    error: payersError,
  } = usePayersQuery({
    fetchPolicy: 'cache-first',
  });

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useNetworkReferralUserQuery({
    variables: { id: userId! },
    skip: !userId,
  });

  const user = userData?.adminUser;
  const payers = payerData?.payers ?? [];

  const loading = userLoading || payersLoading;
  const error = payersError || userError ? 'Internal error' : undefined;

  if (!careType || !user) {
    return { error: 'Bad parameters', loading, steps };
  }

  const appointmentTemplate = user.appointmentTemplates.find(
    t => t.careType === careType && t.appointmentType === apptType
  );

  if (!appointmentTemplate) {
    return { error: `${capitalize(careType)} not active for this patient`, steps };
  }

  const [paymentSource, insuranceDetails, eligibilityVerified] =
    getExistingInsuranceDetailsIfUnrestricted(user, careType);

  // If patient has insurance that has been verified, then we can skip the insurance step.
  if (eligibilityVerified) {
    steps = steps.filter(s => s.name !== 'Insurance');
  }

  const wizardData: WizardData = {
    payers,
    careType,
    user,
    apptType,
    appointmentTemplate,
    paymentSource,
    insuranceDetails,
  };

  const isEligible =
    user.careFlows.some(
      f =>
        f.careType === careType &&
        f.organizationCareFlow?.defaultPaymentSource === PaymentSource.SelfPay
    ) &&
    // is the organization a "minnesota" style org
    hasAnySelfPayFlow(currentProvider.organizations[0]);

  return {
    loading,
    wizardData,
    steps,
    isEligible,
    error,
  };
};

export const getExistingInsuranceDetailsIfUnrestricted = (
  user: NetworkReferralUserQuery['adminUser'],
  careType: CareType
) => {
  const {
    birthDate,
    careFlows,
    firstName,
    lastName,
    insuranceDetailsIfBookingForSelfPayPatient: userInsurance,
  } = user;
  const paymentSource = careFlows.find(f => f.careType === careType)?.paymentSource;

  if (!userInsurance || !userInsurance.payer || !userInsurance.memberId || !birthDate) {
    return [null, null, null] as const;
  }

  const details: InsuranceDetails = {
    firstName,
    lastName,
    birthDate: moment(birthDate).format('MM/DD/YYYY'),
    payerId: userInsurance.payer.id,
    payerName: userInsurance.payer.name,
    memberId: userInsurance.memberId,
    state: userInsurance.state,
  };

  if (paymentSource === PaymentSource.Insurance && userInsurance.eligibilityVerified) {
    return [PaymentSource.Insurance, details, userInsurance.eligibilityVerified] as const;
  }

  if (paymentSource === PaymentSource.OonInsurance && !userInsurance.inNetwork) {
    return [PaymentSource.OonInsurance, details, userInsurance.eligibilityVerified] as const;
  }

  // Patient has submitted insurance info, but it has not been verified as eligible
  if (
    paymentSource === PaymentSource.SelfPay &&
    userInsurance &&
    !userInsurance.eligibilityVerified
  ) {
    return [PaymentSource.SelfPay, details, userInsurance.eligibilityVerified] as const;
  }

  return [null, null, null] as const;
};
