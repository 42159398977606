import React, { useState } from 'react';
import { Modal } from 'baseui/modal';
import { toaster } from 'baseui/toast';
import { ApolloQueryResult } from '@apollo/client';
import { Styles } from '../styles';
import { Text, Modal as ModalStyle } from '../../../globalStyles';
import { FinalButton } from '../../../Components/FinalButton';
import { NarrowDivider } from './Styles';
import {
  AdminScreenUserResultQuery,
  useAdminScreenUserResultQuery,
  useAdminUpdateScreenUserResultMutation,
} from '../../../graphQL';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { UnexpectedError } from '../../Shared';
import { useDrilldownContext } from '../helpers';
import { isNil } from 'lodash';

type MarkForFitModalProps = {
  userId: number;
  showModal: boolean;
  setShowModal: (flag: boolean) => void;
  refetch: () => Promise<ApolloQueryResult<AdminScreenUserResultQuery>>;
};

export const Triage = () => {
  const { user } = useDrilldownContext();
  const { data, error, refetch, loading } = useAdminScreenUserResultQuery({
    variables: { userId: user.id },
  });
  const [showModal, setShowModal] = useState(false);

  if (loading) return <LoadingPage />;
  if (error) return <UnexpectedError />;

  if (isNil(data?.adminScreenUserResult)) return null;

  // Show Triage if the triage flow task is done and initial assessment is not done.
  // or if screenedOut from the hub flow
  const shouldShowTriage =
    (!!user.tasks?.find(({ ref, done }) => ref === 'initial-questionnaire-assessment' && !done) &&
      !!user.tasks?.find(({ ref, done }) => ref === 'triage-flow' && done)) ||
    data?.adminScreenUserResult.screenedOutFromHub;

  if (
    !shouldShowTriage ||
    !data ||
    (user.careFlows.length !== 0 && user.careFlows[0].careStatus === 'ScreenedOut')
  )
    return null;

  const screenedOut = data.adminScreenUserResult.screenedOut;

  return (
    <Styles.widget>
      <Text.h3 className="mb3">Self-Refer Triage</Text.h3>
      <div className="flex flex-row justify-between">
        <div>
          <div className="flex flex-row">
            <Text.h4 className="mr2">Current Status:</Text.h4>
            <Text.h4 kind={screenedOut ? 'orange' : 'success'}>
              {screenedOut ? 'Under Clinical Review' : 'Fit for Care'}
            </Text.h4>
          </div>
          {screenedOut ? (
            <Text.body>
              Student is currently blocked from continuing to sign up for Mantra care. {'\n'}Further
              manual triage needed.
            </Text.body>
          ) : (
            <Text.body>Student may continue to sign up for Mantra care.</Text.body>
          )}
        </div>
        {screenedOut && (
          <FinalButton
            kind="outline_black"
            className="h3"
            iconLeft="iconsBlackThumbUpSvg"
            onClick={() => setShowModal(true)}
          >
            Mark as &lsquo;Fit for Care&rsquo;{' '}
          </FinalButton>
        )}
      </div>
      {screenedOut && (
        <>
          <NarrowDivider />
          <Text.h4>If NOT Fit for Care:</Text.h4>
          <ul>
            <li>
              <Text.body>Leave triage status as &lsquo;Under Clinical Review&rsquo;</Text.body>
            </li>
            <li>Create Task for Care Navigation</li>
            <li>
              Once CN refers this student out, they will mark this account as &lsquo;Screened
              Out&rsquo;
            </li>
          </ul>
        </>
      )}
      <MarkForFitModal
        userId={user.id}
        showModal={showModal}
        setShowModal={setShowModal}
        refetch={refetch}
      />
    </Styles.widget>
  );
};

const MarkForFitModal = ({ userId, showModal, setShowModal, refetch }: MarkForFitModalProps) => {
  const [update, updateMutation] = useAdminUpdateScreenUserResultMutation();
  const onSubmit = async () => {
    await update({
      variables: {
        userId,
        input: { screenedOut: false, screenedOutFromHub: false },
      },
    });
    if (refetch) await refetch();
    setShowModal(false);
    toaster.positive("Marked as 'Fit for Care'", { autoHideDuration: 3000 });
  };

  const onCancel = () => setShowModal(false);

  return (
    <>
      <Modal isOpen={showModal} onClose={onCancel} unstable_ModalBackdropScroll autoFocus={false}>
        <ModalStyle.body className="flex flex-column flex-start">
          <Text.h1Bold>Mark student as &lsquo;Fit for Care&rsquo;</Text.h1Bold>
          <Text.body>This action cannot be undone.</Text.body>
          <Text.body className="mb3">
            If you continue, an email will be sent to the student prompting them to continue signing
            up for care.
          </Text.body>
          <FinalButton
            kind="outline_black"
            onClick={onSubmit}
            aria-label="confirm"
            className="mt2"
            loading={updateMutation.loading}
          >
            Yes, confirm as &lsquo;Fit for Care&rsquo;
          </FinalButton>
          <FinalButton onClick={onCancel} className="mt2 mb4" kind="white" aria-label="Cancel">
            Cancel
          </FinalButton>
        </ModalStyle.body>
      </Modal>
    </>
  );
};
