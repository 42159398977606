import React, { useEffect, useState } from 'react';
import { Modal } from 'baseui/modal';
import { FormProvider, useForm } from 'react-hook-form';
import { ApolloQueryResult } from '@apollo/client';
import { toaster } from 'baseui/toast';
import { Modal as ModalStyle } from '../../globalStyles';
import { FinalButton } from '../../Components/FinalButton';
import {
  OrganizationAnnouncementsByDaysQuery,
  OrganizationAnnouncementsByOrgIdQuery,
  useCreateOrganizationAnnouncementMutation,
  useEditOrganizationAnnouncementMutation,
  useOrganizationTopLevelQuery,
} from '../../graphQL';
import { EditCampusAnnouncement } from './EditCampusAnnouncement';
import { ConfirmCancelModal } from './ConfirmCancelModal';
import { RowInfoProps } from './CampusAnnouncements';
import { MantraAdminOnly } from '../../Components/Permissions';

type CampusAnnouncementModalProps = {
  organizationId: number;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  refetching?: boolean;
  refetch?: () => Promise<
    ApolloQueryResult<OrganizationAnnouncementsByOrgIdQuery | OrganizationAnnouncementsByDaysQuery>
  >;
  rowInfo?: RowInfoProps;
  setSelectedRow?: (value: RowInfoProps) => void;
};

export const CampusAnnouncementModal = ({
  showModal,
  setShowModal,
  refetching,
  refetch,
  organizationId,
  rowInfo,
  setSelectedRow,
}: CampusAnnouncementModalProps) => {
  const formDefaultValues: RowInfoProps = { title: '', details: null };
  const modalForm = useForm({ defaultValues: formDefaultValues });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [contentChanged, setContentChanged] = useState(false);
  const [create, createMutation] = useCreateOrganizationAnnouncementMutation();
  const [edit, editMutation] = useEditOrganizationAnnouncementMutation();
  const orgName = useOrganizationTopLevelQuery({ variables: { id: organizationId } }).data
    ?.organization.name;

  useEffect(() => {
    if (rowInfo) {
      setExistingRowInfo(rowInfo);
      disableSubmit(rowInfo.title);
    } else {
      setDisableSubmitBtn(true);
    }
  }, [rowInfo]);

  const onSubmit = modalForm.handleSubmit(async values => {
    await create({
      variables: {
        input: { ...values, organizationId },
      },
    });
    if (refetch) await refetch();
    resetForm();
    setShowModal(false);
    toaster.positive('Campus update has been posted', { autoHideDuration: 3000 });
  });

  const saveEdit = modalForm.handleSubmit(async values => {
    if (rowInfo && rowInfo.id) {
      await edit({
        variables: {
          id: rowInfo.id,
          input: { ...values },
        },
      });
      modalForm.reset(formDefaultValues);
      if (refetch) await refetch();
      setShowModal(false);
      toaster.positive('Campus update has been posted', { autoHideDuration: 3000 });
    } else {
      toaster.negative('Something went wrong', { autoHideDuration: 3000 });
    }
  });

  const onCancel = () => {
    const { title, details } = modalForm.getValues();
    if ((title || details) && contentChanged) {
      setShowConfirmModal(true);
    }
    setShowModal(false);
    !!setSelectedRow && setSelectedRow(null);
  };

  const onDiscard = () => {
    setShowConfirmModal(false);
    resetForm();
  };

  const onConfirmCancel = () => {
    setExistingRowInfo(modalForm.getValues());
    setShowConfirmModal(false);
    setShowModal(true);
    setDisableSubmitBtn(false);
  };

  const setExistingRowInfo = (row: RowInfoProps | null) => {
    modalForm.setValue('title', row?.title);
    modalForm.setValue('details', row?.details);
  };

  const disableSubmit = (title: string) => {
    const charCount = title.length;
    charCount > 0 && setDisableSubmitBtn(false);
    charCount === 0 && setDisableSubmitBtn(true);
  };

  const resetForm = () => {
    modalForm.reset(formDefaultValues);
    !!setSelectedRow && setSelectedRow(null);
    setContentChanged(false);
  };

  return (
    <>
      <Modal isOpen={showModal} onClose={onCancel} unstable_ModalBackdropScroll autoFocus={false}>
        <ModalStyle.body>
          <FormProvider {...modalForm}>
            <EditCampusAnnouncement
              title={modalForm.getValues().title ?? ''}
              disableSubmit={disableSubmit}
              setContentChanged={setContentChanged}
              orgName={orgName}
            />
            <MantraAdminOnly>
              <div className="flex flex-column items-center">
                <FinalButton
                  onClick={rowInfo ? saveEdit : onSubmit}
                  loading={createMutation.loading || editMutation.loading || refetching}
                  disabled={disableSubmitBtn || !contentChanged}
                  aria-label="Add"
                  className="mt2"
                >
                  {rowInfo ? 'Save Changes' : 'Send Campus Update'}
                </FinalButton>
                <FinalButton
                  onClick={onCancel}
                  className="mt2 mb4"
                  kind="white"
                  aria-label="Cancel"
                >
                  Cancel
                </FinalButton>
              </div>
            </MantraAdminOnly>
          </FormProvider>
        </ModalStyle.body>
      </Modal>
      <ConfirmCancelModal
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        onDiscard={onDiscard}
        onConfirmCancel={onConfirmCancel}
      />
    </>
  );
};
