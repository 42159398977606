import React, { useState } from 'react';
import { McpOnly, OzOnly, PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { Permission } from '../../../graphQL';
import { Restricted, useDrilldownContext } from '../helpers';
import { StyledSidebar } from '../styles';
import * as InfoSection from './AssessmentInfoDisplay';
import { AssessmentsSection } from './Assessments';
import { BillingInfoSection } from './BillingInfo';
import { CampusAnnouncementSection } from './CampusAnnouncement';
import { CampusTeamSection } from './CampusTeam';
import { CareFlowsSection } from './CareFlows';
import { ActiveConsentNotification } from './ConsentNotification';
import { EditProviderTask } from './EditProviderTask';
import { EmergencyContacts } from './EmergencyContacts';
import { LimitedRecordAlert } from './LimitedRecordAlert';
import { MedicationSection } from './Medication';
import { NonPHISidebar } from './NonPHISidebar';
import { PatientInfoSection } from './PatientInfo';
import { PharmacySection } from './Pharmacy';

const overrideMap = {
  'edit-task': EditProviderTask,
} as const;
export type SidebarOverride = keyof typeof overrideMap;

export const useSidebarController = () => {
  const [override, setOverride] = useState<[SidebarOverride, any] | null>(null);
  const reset = () => setOverride(null);
  const set = <T extends SidebarOverride>(key: T, props: Parameters<typeof overrideMap[T]>[0]) => {
    setOverride([key, props]);
  };

  return { override, set, reset };
};

export const Sidebar = () => {
  const { sidebarController } = useDrilldownContext();
  const { hasPermission } = useCurrentProvider();
  if (!hasPermission(Permission.Phi)) return <NonPHISidebar />;

  const override = (() => {
    if (!sidebarController.override) return null;
    const [key, props] = sidebarController.override;
    const Component = overrideMap[key];
    return <Component {...props} />;
  })();

  return (
    <>
      {override}
      <StyledSidebar data-hidden={!!sidebarController.override}>
        <CampusAnnouncementSection />
        <CampusTeamSection />
        <PatientInfoSection />
        <CareFlowsSection />
        <PermsOnly allowed={Permission.MantraAdmin}>
          <BillingInfoSection />
        </PermsOnly>
        <InfoSection.Diagnoses />
        <MedicationSection />
        <Restricted>
          <InfoSection.OtherMedication />
          <InfoSection.Allergies />
          <OzOnly>
            <PharmacySection />
          </OzOnly>
          <InfoSection.WellnessGoals />
          <AssessmentsSection />
          <InfoSection.AdditionalProviders />
          <EmergencyContacts />
        </Restricted>
        <McpOnly>
          <LimitedRecordAlert />
          <ActiveConsentNotification />
        </McpOnly>
      </StyledSidebar>
    </>
  );
};
