import React from 'react';
import { Timeline } from '../../../Components/Timeline/Timeline';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';
import { Tabkeys } from '../types';

export const UserTimeline = () => {
  const { user, events, setActiveTab } = useDrilldownContext();

  return (
    <Styles.widget style={{ paddingBottom: 0 }}>
      <Text.h2>Timeline</Text.h2>
      <Timeline
        events={events}
        user={user}
        onClickEvent={event => setActiveTab(event.tab as Tabkeys)}
      />
    </Styles.widget>
  );
};
