import moment from 'moment';
import React from 'react';
import { useCocExport } from '../../../Components/AsyncJobs/useCocExport';
import { useGetAllNotesForUserQuery } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { InTextSpinner, Styles, Text } from '../styles';

export const ExportableRecords = () => {
  const { user } = useDrilldownContext();
  const { data } = useGetAllNotesForUserQuery({ variables: { id: user.id } });
  const { beginExport, status } = useCocExport({ userId: user.id });

  if (!data) return null;
  const continuityNote = data.adminUser.notes.find(n => n.key === 'continuity-of-care');
  return (
    <Styles.widget>
      <Text.h2 className="mb3">Exportable Records</Text.h2>
      {/* if External Referral: Case Summary note is less than 30 days old */}
      {continuityNote?.submittedAt &&
      moment().diff(moment(continuityNote.submittedAt), 'd') < 30 ? (
        <div>
          <div className="flex justify-between items-center">
            <Text.body>
              {moment(continuityNote.updatedAt).format('L')} - Continuity of Care Record
            </Text.body>
            {status && status !== 'complete' ? (
              <InTextSpinner />
            ) : (
              <Text.linkButton disabled={!!status} onClick={beginExport}>
                Export
              </Text.linkButton>
            )}
          </div>
        </div>
      ) : (
        <Text.body>
          <em>No Exportable Records</em>
        </Text.body>
      )}
    </Styles.widget>
  );
};
