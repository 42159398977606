import { toaster } from 'baseui/toast';
import { compact, isNil } from 'lodash';
import moment from 'moment';
import React from 'react';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon, IconKey } from '../../../Components/Icons';
import { Link } from '../../../Components/Links';
import { MedicalNotePopover } from '../../../Components/MedicalNote/PopoverNote';
import { usePopoverTabContext } from '../../../Components/PopoverTabs/PopoverTabContainer';
import { hasSelfPayFlow } from '../../../Components/ProviderNetwork/providerNetworkUtils';
import { Text } from '../../../globalStyles';
import {
  CareType,
  PaymentSource,
  useGetAllNotesForUserQuery,
  useMarkReferralCompleteMutation,
  useUserReferralStatusQuery,
} from '../../../graphQL';
import { careTypeToProviderTitle } from '../../../modelUtils/provider';
import { stringifyParamsBy } from '../../../utils';
import { Restricted, useDrilldownContext } from '../helpers';
import { Styles } from '../styles';

export const McpOnboarding = () => {
  const { user, hasPsychiatry, hasTherapy, events } = useDrilldownContext();

  const completedInitialPsychTask = user.tasks?.find(
    t => t.ref === 'complete-initial-video-assessment'
  )?.done;
  const completedInitialTherapyTask = user.tasks?.find(
    t => t.ref === 'complete-initial-therapy-call'
  )?.done;

  if (
    (!hasTherapy || completedInitialTherapyTask) &&
    (!hasPsychiatry || completedInitialPsychTask)
  ) {
    return null;
  }

  const completeReferral =
    user.referralComplete ||
    events
      .filter(e => e.tag === 'provider:note:complete')
      .some(n => n.data?.key === 'student-referral');

  const intakeScheduled = events.some(e => e.tag === 'schedule-initial-video-assessment');
  const therapyIntakeScheduled = events.some(
    e => e.tag === 'schedule-initial-therapy-consultation'
  );

  const shouldSuggestPsychiatrists =
    user.organization &&
    hasSelfPayFlow(user.organization, [CareType.Psychiatry]) &&
    !user.suggestedProviders?.some(p => p.careTypes[0] === CareType.Psychiatry);

  const shouldSuggestTherapists =
    user.organization &&
    hasSelfPayFlow(user.organization, [CareType.Therapy]) &&
    !user.suggestedProviders?.some(p => p.careTypes[0] === CareType.Therapy);

  const mcpIntakeWidgets = compact([
    hasPsychiatry && !intakeScheduled && shouldSuggestPsychiatrists && (
      <SuggestedProvider careType={CareType.Psychiatry} key="suggestedPsych" />
    ),
    hasTherapy && !therapyIntakeScheduled && shouldSuggestTherapists && (
      <SuggestedProvider careType={CareType.Therapy} key="suggestedTherapy" />
    ),
    hasPsychiatry && !intakeScheduled && (
      <IncompleteConsultation careType={CareType.Psychiatry} key="incompletePsych" />
    ),
    hasTherapy && !therapyIntakeScheduled && (
      <IncompleteConsultation careType={CareType.Therapy} key="incompleteTherapy" />
    ),
  ]);

  const referralWidgets = compact([
    (completeReferral && intakeScheduled) || (
      <Text.label key="referralKind">University Referral</Text.label>
    ),
    !completeReferral && <IncompleteReferral key="incompleteReferral" />,
  ]);

  const renderRestricted = <Restricted>{referralWidgets}</Restricted>;

  if (
    (!mcpIntakeWidgets.length && !renderRestricted) ||
    (!mcpIntakeWidgets.length && referralWidgets.length)
  ) {
    return null;
  }

  return (
    <Styles.widget>
      <Text.h3>Incomplete</Text.h3>
      <div className="flex flex-column gap-2">
        {mcpIntakeWidgets}
        {renderRestricted}
      </div>
    </Styles.widget>
  );
};

export const ReferralPortalOnboarding = () => {
  const { user } = useDrilldownContext();
  const { data: userReferralStatusQueryData } = useUserReferralStatusQuery({
    variables: {
      patientId: user.id,
    },
  });

  const userReferralStatus = userReferralStatusQueryData?.userReferralStatus;

  if (!userReferralStatus) {
    return null;
  }

  const hasTherapy = user.careTypes.includes(CareType.Therapy);
  const hasPsychiatry = user.careTypes.includes(CareType.Psychiatry);

  const needsPsychiatry =
    hasPsychiatry && !userReferralStatus.psychUpcoming && !userReferralStatus.psychComplete;
  const needsTherapy =
    hasTherapy && !userReferralStatus.therapyComplete && !userReferralStatus.therapyUpcoming;

  if (!needsTherapy && !needsPsychiatry) return null;

  return (
    <Styles.widget>
      {needsPsychiatry && <IncompleteConsultation careType={CareType.Psychiatry} />}
      {needsTherapy && <IncompleteConsultation careType={CareType.Therapy} />}
    </Styles.widget>
  );
};

export const OzOnboarding = () => {
  const { user } = useDrilldownContext();
  const { tasks } = user;

  if (!tasks?.length || tasks.every(t => t.done)) {
    return null;
  }

  return (
    <Styles.widget>
      <Text.h3>Incomplete</Text.h3>
      <div className="flex flex-column gap-2">
        <div>
          <Text.label className="mb2">Patient Intake</Text.label>
          {tasks?.map(t => {
            const display = taskMap[t.ref];
            if (!display) return null;
            return (
              <Styles.listDiv key={t.ref} vPadding="narrow">
                <Icon icon={display.icon} alt={t.title} className="mr2" />
                <div className="ph3">
                  <Text.bodyBold>{display.title}</Text.bodyBold>
                  {t.done ? (
                    <Text.bodyGrey>
                      Completed {moment(t.updatedAt).format('M/D/YYYY hh:mmaz')}
                    </Text.bodyGrey>
                  ) : (
                    <Text.body kind="danger">Incomplete</Text.body>
                  )}
                </div>
              </Styles.listDiv>
            );
          })}
        </div>
      </div>
    </Styles.widget>
  );
};

const taskMap: Record<string, { title: string; icon: IconKey } | undefined> = {
  'referred-account-setup': {
    icon: 'iconsUserSvg',
    title: 'Finish Account Setup',
  },
  'basic-account-creation': {
    icon: 'iconsUserSvg',
    title: 'Finish Account Setup',
  },
  'monthly-scales': {
    icon: 'iconsBlackMbcSvg',
    title: 'Complete Intake Assessment',
  },
  'initial-questionnaire-assessment': {
    icon: 'iconsBlackMbcSvg',
    title: 'Complete Intake Assessment',
  },
  'medical-history': {
    icon: 'iconsBlackNoteSvg',
    title: 'Fill out Medical History',
  },
  'verify-identity': {
    icon: 'iconsUserSvg',
    title: 'ID Verification',
  },
  'pharmacy-selection': {
    icon: 'iconsBlackRxSvg',
    title: 'Select Pharmacy Option',
  },
  'schedule-initial-video-assessment': {
    icon: 'iconsBlackApptSvg',
    title: 'Schedule Initial Psychiatric Consultation',
  },
  'complete-initial-video-assessment': {
    title: 'Complete Initial Psychiatric Consultation',
    icon: 'iconsBlackVideoSvg',
  },
  'schedule-initial-therapy-consultation': {
    icon: 'iconsBlackApptSvg',
    title: 'Schedule Initial Therapy Consultation',
  },
  'complete-initial-therapy-call': {
    title: 'Complete Initial Therapy Consultation',
    icon: 'iconsBlackVideoSvg',
  },
  'triage-flow': {
    icon: 'iconsBlackMbcSvg',
    title: 'Complete Self-Refer Triage Screening 1',
  },
};

const IncompleteReferral = () => {
  const { user, refetch } = useDrilldownContext();
  const { pushTab } = usePopoverTabContext();
  const [markNoteComplete] = useMarkReferralCompleteMutation();
  const { data } = useGetAllNotesForUserQuery({
    variables: { id: user.id },
  });

  const referral = data?.adminUser.notes.find(f => f.key === 'student-referral');

  const handleClick = () =>
    pushTab({
      tabKey: 'student-referral',
      title: 'Referral',
      renderBody: props => <MedicalNotePopover noteKey="student-referral" {...props} />,
    });

  const submitMarkAsComplete = async () => {
    try {
      await markNoteComplete({ variables: { userId: user.id } });
      await refetch();
      toaster.positive('Referral marked as complete.', {});
    } catch {
      toaster.negative('Referral could not be marked as complete. Please try again.', {});
    }
  };

  return (
    <Styles.listDiv>
      <Icon icon="iconsBlackNoteSvg" alt="Incomplete Note" className="mr2" />
      <div className="w-100 flex justify-between">
        <div className="ph3">
          <Text.bodyBold>Complete Referral Note</Text.bodyBold>
          <Text.body kind={referral ? 'basic' : 'danger'}>
            {referral ? `Started ${moment(referral.updatedAt).format('M/D/YYYY')}` : 'Not Started'}
          </Text.body>
        </div>
        <div className="flex flex-row items-center">
          <Text.linkButtonSmall
            kind="grayText"
            type="button"
            className="mr4"
            onClick={submitMarkAsComplete}
          >
            Mark as Complete
          </Text.linkButtonSmall>
          <FinalButton className="w4" kind="outline_black" onClick={handleClick}>
            {referral ? 'Continue' : 'Start'}
          </FinalButton>
        </div>
      </div>
    </Styles.listDiv>
  );
};

const IncompleteConsultation = ({ careType }: { careType: CareType }) => {
  const { user } = useDrilldownContext();
  const label = careType === CareType.Psychiatry ? 'Psychiatric' : 'Therapy';

  const isSelfPay =
    user.organization?.careFlows.find(i => i.careType === careType)?.defaultPaymentSource ===
    PaymentSource.SelfPay;

  const scheduleVia = isSelfPay
    ? `/provider-network/book?userId=${user.id}&careType=${careType}`
    : `/book?userId=${user.id}&referral=1&careType=${careType}`;

  return (
    <Styles.listDiv>
      <Icon icon="iconsBlackApptSvg" alt="Appointment" className="mr2" />
      <div className="w-100 flex justify-between">
        <div className="ph3">
          <Text.bodyBold>{`Schedule Initial ${label} Consultation`}</Text.bodyBold>
          <Text.bodyGrey>
            Optional for staff. Patient can book appointments from their Mantra Patient Portal.
          </Text.bodyGrey>
        </div>
        <Link to={scheduleVia} className="link">
          <FinalButton kind="outline_black" className="w4">
            Schedule
          </FinalButton>
        </Link>
      </div>
    </Styles.listDiv>
  );
};

const SuggestedProvider = ({ careType }: { careType: CareType }) => {
  const providerTitle = careTypeToProviderTitle[careType];
  const { user } = useDrilldownContext();
  const state = user.primaryAddressState;
  const params = stringifyParamsBy({ state, careType, userId: user.id }, v => !isNil(v));
  return (
    <Styles.listDiv>
      <Icon icon="iconsBlackStarSvg" size={24} alt="Suggested Providers" className="mr2" />
      <div className="w-100 flex justify-between">
        <div className="ph3">
          <Text.bodyBold>Select Suggested {providerTitle}(s)</Text.bodyBold>
          <Text.bodyGrey>
            Optional. Suggested Providers will be featured during patient sign-up.
          </Text.bodyGrey>
        </div>
        <Link className="link" to={`/provider-network/suggested-providers?${params}`}>
          <FinalButton className="w4" kind="outline_black">
            Select
          </FinalButton>
        </Link>
      </div>
    </Styles.listDiv>
  );
};
