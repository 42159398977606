import { Modal } from 'baseui/modal';
import { pick } from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { FinalButton } from '../../../Components/FinalButton';
import { MultiSelectRHF } from '../../../Components/Form';
import { isMantraAdmin, useCurrentProvider } from '../../../Components/Permissions';
import { useAdminEditUserMutation, useOrganizationProvidersQuery } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Text } from '../styles';
import { pickDefaultValues } from './EditStudentAsMcpUser';
import { EditModalContainer } from './editModals';
import * as Styles from '../../Students/styles';

interface EditPatientInfoProps {
  closeModal: () => void;
}

export const EditMantraCollaborators = ({ closeModal }: EditPatientInfoProps) => {
  const { track } = useEvents();
  const { user, refetch } = useDrilldownContext();
  const formContext = useForm({
    defaultValues: pick(pickDefaultValues(user), 'mantraAdminCareTeam'),
  });

  const [editUser, { loading: mutationLoading }] = useAdminEditUserMutation({
    onCompleted: editUserData =>
      track('user.info.edited', { userId: editUserData.adminEditUser.id, careTeamInfo: true }),
  });

  const submit = formContext.handleSubmit(async values => {
    await editUser({ variables: { editUser: { id: user.id, ...values } } });
    closeModal();
    refetch();
  });

  return (
    <Modal isOpen onClose={closeModal} unstable_ModalBackdropScroll size="default">
      <EditModalContainer className="pv4">
        <Text.h2 className="mb4">Edit Collaboration Team</Text.h2>

        <FormProvider {...formContext}>
          <form onSubmit={submit}>
            <MantraCollabFields />

            <FinalButton
              type="submit"
              className="w-100 mt3"
              kind="primary"
              loading={mutationLoading}
            >
              Save Changes
            </FinalButton>
          </form>
        </FormProvider>
      </EditModalContainer>
    </Modal>
  );
};

export const MantraCollabFields = () => {
  const { currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();

  const userOrganization = Number(user.organization?.id);
  const { data: orgData, loading } = useOrganizationProvidersQuery({
    variables: { id: userOrganization, withMantraAdmins: isMantraAdmin(currentProvider) },
  });

  if (loading || !orgData) return null;

  return (
    <>
      {isMantraAdmin(currentProvider) && (
        <MultiSelectRHF
          name="mantraAdminCareTeam"
          options={orgData.mantraAdmins?.map(c => ({ id: c.id, label: c.name })) ?? []}
          controlProps={{
            className: isMantraAdmin(currentProvider) ? undefined : 'dn',
            label: (
              <>
                <Styles.FieldName htmlFor="mantraAdminCareTeam">
                  Other Mantra Collaborators
                </Styles.FieldName>
                <Styles.FieldHint>
                  Mantra Admin users who collaborate on this patient with other care team members.
                </Styles.FieldHint>
              </>
            ),
          }}
        />
      )}
    </>
  );
};
