import {
  CampusTeamMemberInput,
  CampusTeamRelationship,
  UniversityRelationship,
} from '../../graphQL';
import { MustHave } from '../../types';
import { EditCampusTeamMember } from './types';

export const isCampusCollaborator = (rel: Pick<CampusTeamRelationship, 'relationshipType'>) => {
  return rel.relationshipType === UniversityRelationship.UniversityCollaborator;
};

export function assertCampusTeamHasRelationshipTypes(
  campusTeam: EditCampusTeamMember[]
): asserts campusTeam is MustHave<EditCampusTeamMember, 'relationshipType'>[] {
  const isInvalid = campusTeam.some(provider => !provider.relationshipType);

  if (isInvalid) {
    throw new Error('Invalid relationship type');
  }
}

export const parseCampusTeamToMemberInput = (
  campusTeam: EditCampusTeamMember[],
  onError: (errorMessage: string) => void
): CampusTeamMemberInput[] | undefined => {
  try {
    assertCampusTeamHasRelationshipTypes(campusTeam);
  } catch (verifyError) {
    onError('Select a collaboration type for campus team members.');
    return;
  }

  return campusTeam.map(provider => {
    return {
      providerId: provider.id,
      relationshipType: provider.relationshipType,
    };
  });
};
