import moment from 'moment';
import React, { useState } from 'react';
import { IconButton } from '../../../Components/Buttons';
import { Icon } from '../../../Components/Icons';
import { Tab, Tabs } from '../../../Components/Tabs';
import { Text } from '../../../globalStyles';
import { CareType, Contract } from '../../../graphQL';
import * as Styles from '../../Home/styles';
import { ContractPeriodSubset } from '../ContractsPage';
import { Nullable } from '../../../types';
import { ContractUtilizationDashboard } from './ContractUtilizationDashboard';
import { ContractCareTypeUtilization } from './ContractCareTypeUtilization';
import { AddEditContractModal } from './AddEditContractModal';

interface CarePeriodTextProps {
  contractPeriod: ContractPeriodSubset;
}

const ContractPeriodText = ({ contractPeriod }: CarePeriodTextProps) => {
  return <>{formatContractPeriodText(contractPeriod)}</>;
};

const formatContractPeriodText = (contractPeriod: ContractPeriodSubset) => {
  return `${moment(contractPeriod.startDate).format('M/D/YYYY')}
    - ${moment(contractPeriod.endDate).format('M/D/YYYY')}`;
};

const TabsComponent = ({
  activeTab,
  setActiveTab,
  tabs,
}: {
  activeTab: string | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  tabs: Tab<string>[];
}) => {
  return (
    <Tabs
      initialTab={activeTab}
      config={{ tabpanel: ({ children }) => <div className="pa4">{children}</div> }}
      onChange={tab => setActiveTab(tab)}
      tabs={tabs}
    />
  );
};

export const ContractDashboard = ({
  contract,
  careTypes,
  editable,
  contractRefetch,
}: {
  contract: Nullable<Contract>;
  careTypes: CareType[];
  editable: boolean;
  contractRefetch: () => void;
}) => {
  const [activeTab, setActiveTab] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  if (!contract) {
    return null;
  }

  const tabs: Tab<string>[] = careTypes.map(careType => ({
    title: careType,
    key: careType.toLowerCase(),
    render: () => {
      return (
        <>
          <ContractCareTypeUtilization careType={careType} contract={contract} />
          <ContractUtilizationDashboard
            careType={careType}
            editable={editable}
            utilizationRecords={
              careType === CareType.Therapy ? contract.therapyWeeks : contract.psychiatryWeeks
            }
            refetch={contractRefetch}
          />
        </>
      );
    },
  }));

  return (
    <Styles.SectionContainer>
      <div className="mt3 mb4 flex items-end">
        <Text.h2>
          Contract Period:{' '}
          <ContractPeriodText
            contractPeriod={{
              startDate: contract.scope.startDate,
              endDate: contract.scope.endDate,
            }}
          />
        </Text.h2>

        {/* Add Logic to Open Modal */}
        <IconButton className="ml-auto">
          <div className=" flex flex-row items-center">
            <Icon icon="iconsBlackEditSvg" size={16} className="mr2" />
            <Text.bodyBold onClick={() => setIsOpen(true)}>Edit Contract</Text.bodyBold>
          </div>
        </IconButton>
        <AddEditContractModal
          careTypes={careTypes}
          contract={contract}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          refetchContract={contractRefetch}
        />
      </div>
      <TabsComponent activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
    </Styles.SectionContainer>
  );
};
