import { capitalize } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { icons } from '../../Components/Icons';
import { useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { AppView, User } from '../../graphQL';
import * as Styles from './styles';

interface Props {
  user: Pick<User, 'firstName' | 'preferredName' | 'lastName' | 'email' | 'id' | 'careTypes'>;
}

export function ReferralSuccessPage({ user }: Props) {
  const { careTypes, email, firstName, preferredName, id } = user;
  const careType = careTypes[0];
  const history = useHistory();
  const { appView } = useCurrentProvider();
  const studentListRoute = appView === AppView.Referral ? 'referrals' : 'students';

  return (
    <Styles.Background>
      <Styles.FinishedWrapper>
        <Styles.CheckIcon src={icons.iconsCircleCheckSvg} alt="Circle Check" />
        <Text.h3 className="mt3">Student account created.</Text.h3>
        <Text.body className="mt3 mb4">
          {preferredName ?? firstName} will receive an email at {email} containing a unique link to{' '}
          <b>complete the setup of their Mantra Health account.</b>
        </Text.body>
        <FinalButton
          kind="outline_black"
          className="w-100"
          onClick={() => history.push(`/book?userId=${id}&referral=1`)}
        >
          Next: Book {capitalize(careType)} Appointment
        </FinalButton>
        <FinalButton
          kind="minimal_gray"
          className="w-100 mt3"
          onClick={() => history.push(`/${studentListRoute}`)}
        >
          Skip
        </FinalButton>
      </Styles.FinishedWrapper>
    </Styles.Background>
  );
}
