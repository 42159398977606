import React from 'react';
import { Icon } from '../../../Components/Icons';
import { Text } from '../../../globalStyles';
import { AddEditContractModal } from './AddEditContractModal';
import { CareType } from '../../../graphQL';

type NoContractAlertProps = {
  careTypes?: CareType[];
  refetchContract: () => void;
};

export const NoContractAlert = ({ careTypes, refetchContract }: NoContractAlertProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="bg-white pa3 br2 bl bw3 b--red " style={{ minWidth: '240px' }}>
      <div className="flex justify-between">
        <div className="flex">
          <Icon icon="iconsAlertSvg" size={20} className="ph2 pt1" />
          <Text.body>No contracts inputted for this organization.</Text.body>
        </div>
        <Text.linkButton onClick={() => setIsOpen(true)}>Add Contract</Text.linkButton>
        <AddEditContractModal
          careTypes={careTypes}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          refetchContract={refetchContract}
        />
      </div>
    </div>
  );
};
