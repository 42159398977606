export const getSubscriberProps = (fitMode: 'contain' | 'cover'): OT.SubscriberProperties => ({
  insertMode: 'append',
  showControls: false,
  fitMode,
  width: '100%',
  height: '100%',
});

export const getPublisherProps = (fitMode: 'contain' | 'cover'): OT.PublisherProperties => ({
  insertMode: 'append',
  showControls: false,
  facingMode: 'user',
  name: 'camera',
  width: '100%',
  height: '100%',
  fitMode,
});
