import React from 'react';
import { useHistory } from 'react-router-dom';
import { useVideoCall } from '../../Pages/VideoCall/useVideoCall';
import { FinalButton } from '../FinalButton';

type Props = {
  appointmentId: number;
  className?: string;
  children: React.ReactNode;
  providerId: number;
  userId: number;
};

export function JoinAppointmentButton({
  appointmentId,
  className,
  children,
  providerId,
  userId,
}: Props) {
  const history = useHistory();
  const { trackVideoCallJoinButtonClicked } = useVideoCall({
    appointmentId,
    providerId,
  });

  const onJoinClick = () => {
    // Log an emission that the join button was clicked.
    trackVideoCallJoinButtonClicked();

    history.push(`/users/${userId}/video/${appointmentId}`);
  };

  return (
    <>
      <FinalButton
        className={className}
        kind="primary"
        onClick={onJoinClick}
        data-testid="join-appointment-button"
      >
        {children}
      </FinalButton>
    </>
  );
}
