import { groupBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { TextIcon } from '../../../Components/Flair';
import { ProviderCard } from '../../../Components/Provider/AvatarCard';
import { Text } from '../../../globalStyles';
import { CareDashboardQuery, TimeAllocationPurpose } from '../../../graphQL';
import { guessTzShorthand } from '../../../utils';
import { isCheckinOnly, isIntakeOnly } from '../../Provider/util';
import * as Styles from '../styles';

type Props = {
  allocations: CareDashboardQuery['adminTimeAllocationsToday'];
  admin?: boolean;
};

export const Availability = ({ allocations, admin }: Props) => {
  const grouped = groupBy(allocations, 'provider.id');

  if (allocations.length === 0) return null;

  const timezone = guessTzShorthand();

  return (
    <Styles.SectionContainer>
      <Text.captionGrey>{moment().format('M/D/YYYY')}</Text.captionGrey>
      <Text.h3>Today&apos;s Availability</Text.h3>
      {Object.values(grouped).map(group => (
        <div key={group[0].provider.id}>
          {admin && <ProviderCard query="?tab=availability" provider={group[0].provider} />}
          {group.map(alloc => {
            const start = moment(alloc.startTime);
            const end = moment(alloc.endTime);
            const adminTime = alloc.purpose === TimeAllocationPurpose.Admin;
            let formattedTime = `${start.format('h:mm a')} - ${end.format('h:mm a')} ${timezone}`;
            if (adminTime) {
              formattedTime = `(${formattedTime})`;
            } else if (isIntakeOnly(alloc)) {
              formattedTime = `[IT] ${formattedTime}`;
            } else if (isCheckinOnly(alloc)) {
              formattedTime = `[FU] ${formattedTime}`;
            }

            let formattedOrg = alloc.organization?.abbreviation ?? 'Mantra Health';
            if (alloc.childOrganizations && alloc.childOrganizations?.length > 0) {
              formattedOrg = `${formattedOrg} (${alloc.childOrganizations.length}/${alloc.organization?.childrenCount})`;
            }

            return (
              <Styles.AllocationRow key={alloc.id}>
                <Text.bodySmall className={adminTime ? 'i' : ''}>{formattedTime}</Text.bodySmall>
                <Text.bodySmallGrey>
                  {alloc.organization ? (
                    <TextIcon preset="university">{formattedOrg}</TextIcon>
                  ) : (
                    formattedOrg
                  )}
                </Text.bodySmallGrey>
              </Styles.AllocationRow>
            );
          })}
        </div>
      ))}
    </Styles.SectionContainer>
  );
};
