/* eslint-disable no-case-declarations */
import * as OT from '@opentok/client';
import { noop, uniq } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CalendarEvent, User, VideoCallDataFragment } from '../../graphQL';
import { Nullable } from '../../types';
import { useEvents } from '../Events/EventsProvider';
import { useCurrentProvider } from '../Permissions';
import { RootVideoOverlay } from './RootVideoOverlay';
import { useAudio } from './UseAudio';
import { useVideoCall } from '../../Pages/VideoCall/useVideoCall';
import { getPublisherProps, getSubscriberProps } from './videoConfig';
import { otCallback } from './videoUtil';

type VideoUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'preferredName'>;
type VideoAppointment = Pick<CalendarEvent, 'id' | 'videoToken' | 'videoSessionId'>;

export interface VideoContextInterface {
  isSessionConnected: boolean;
  isPatientConnected: boolean;
  session?: OT.Session;
  showingPopover: boolean;
  setShowingPopover: (showing: boolean) => void;
  joinCall: (videoAppointment: VideoAppointment, videoUser: VideoUser) => void;
  publishingVideo: boolean;
  setPublishingVideo: (publishing: boolean) => void;
  publishingAudio: boolean;
  setPublishingAudio: (publishing: boolean) => void;
  disconnect: () => void;
  likenessConfirmed: boolean;
  setLikenessConfirmed: (confirmed: boolean) => void;
}

export const VideoContext = React.createContext<VideoContextInterface | null>(null);

export const useVideoContext = () => {
  const context = useContext(VideoContext);
  if (!context) throw new Error('Video context does not exist!');
  return context;
};

export type VideoState = ReturnType<typeof defaultState>;
type VideoConfig = { videoDeviceId: string; audioDeviceId: string };
type CallEvents =
  | 'started'
  | 'ended'
  | 'patientJoined'
  | 'providerJoined'
  | 'audioUpdated'
  | 'cameraUpdated';

type IVideoContext = VideoState & {
  sessionState: VideoCallDataFragment | null;
  joinCall: (appt: VideoCallDataFragment) => void;
  setState: (updates: Partial<VideoState>) => void;
  trackCall: (event: CallEvents) => void;
  disconnect: () => void;
  getSession: () => Nullable<OT.Session>;
  openPathWithPopover: (path: string) => void;
  // This is only available to call host
  removeProviderFromCall?: (id: number) => void;
  isLikenessConfirmed: boolean;
};
export const VideoContextV2 = React.createContext<IVideoContext | null>(null);

const apiKey = process.env.REACT_APP_OPENTOK_API_KEY!;

const defaultState = () => ({
  showingPopover: false,
  publishingAudio: true,
  publishingVideo: true,
  isSessionConnected: false,
  isUserConnected: false,
  isLikenessConfirmed: false,
  config: null as VideoConfig | null,
  additionalProviders: [] as number[],
});

const debugLog = (...args: any[]) => {
  // eslint-disable-next-line no-console
  if (window.location.hostname.includes('localhost')) console.log(...args);
};

export function VideoProvider({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const { currentProvider } = useCurrentProvider();
  const { track: trackMetrics } = useEvents();
  const { play: playJoinCallSound } = useAudio('/sounds/joincall.wav');

  const [sessionState, setSessionState] = useState<VideoCallDataFragment | null>(null);
  const [state, rawSetState] = useState(defaultState());

  const setState = useCallback(
    (updates: Partial<VideoState>) => rawSetState(s => ({ ...s, ...updates })),
    [rawSetState]
  );

  const { trackVideoCallJoined, trackVideoCallLeft } = useVideoCall({
    appointmentId: Number(sessionState?.id),
    providerId: currentProvider.id,
  });

  const trackCall = useCallback(
    (event: CallEvents) =>
      sessionState &&
      trackMetrics('video.call', {
        providerId: currentProvider.id,
        userId: sessionState.user.id,
        event,
        appointmentId: sessionState.id,
        distinct_id: `(provider:${currentProvider.id}, user:${sessionState.user.id})`,
      }),
    [sessionState, currentProvider.id, trackMetrics]
  );

  const sessionRef = React.useRef<OT.Session | null>(null);
  const publisherRef = React.useRef<OT.Publisher | null>(null);

  /**
   * When the state is set to a valid appointment, initialize an opentok session
   * and listen for events from openTok directly
   */
  useEffect(() => {
    if (!sessionState) return;
    debugLog('initializing call with', sessionState);
    const { videoSessionId, videoToken, user } = sessionState;

    sessionRef.current = OT.initSession(apiKey, videoSessionId);
    let userSub: OT.Subscriber | undefined;

    sessionRef.current.connect(videoToken, err => {
      if (err) throw new Error(err.message);
      setState({ isSessionConnected: true });
    });

    /**
     * The `connectionCreated` event is emitted when somebody connects to the video session
     * This is called for anybody who tries to connect, including the current user
     */
    sessionRef.current.on('connectionCreated', event => {
      const { actorType, id } = JSON.parse(event.connection.data);
      debugLog('connection created', { actorType, id });

      if (actorType === 'user') {
        if (id !== user.id) return;
        setState({ isUserConnected: true });
      } else if (actorType === 'provider') {
        if (id === currentProvider.id) {
          trackVideoCallJoined({ appointmentId: sessionState.id });
          return;
        }

        rawSetState(s => ({ ...s, additionalProviders: uniq([...s.additionalProviders, id]) }));
      }
    });

    /**
     * This is called when somebody other than the current user opens a stream
     * This should always come after they connect.
     * This will not get called for people who don't have cameras or invalid browser permissions
     */
    sessionRef.current.on('streamCreated', event => {
      const { actorType, id } = JSON.parse(event.stream.connection.data);
      debugLog('stream created', { actorType, id });

      // only support one user for now
      if (actorType === 'user') {
        if (id !== user.id) return;
        if (event.stream.streamId === userSub?.stream?.streamId) return;
        if (userSub?.stream) sessionRef.current?.unsubscribe(userSub);

        userSub = sessionRef.current?.subscribe(
          event.stream,
          'patientView',
          getSubscriberProps('contain'),
          err => {
            if (err) throw new Error(err.message);
            trackCall('patientJoined');
            debugLog('user joined');
            playJoinCallSound();
          }
        );
      } else if (actorType === 'provider') {
        debugLog('provider joined', { id });
        // this assumes the target DOM node has been created by `connectionCreated`
        sessionRef.current?.subscribe(
          event.stream,
          `provider-view-${id}`,
          getSubscriberProps('cover'),
          err => {
            if (err) throw new Error(err.message);
            trackCall('providerJoined');
            debugLog('provider joined');
            playJoinCallSound();
          }
        );
      }
    });

    /**
     * `connectionDestroyed` is called when someone exits the page or closes their browser
     */
    sessionRef.current.on('connectionDestroyed', event => {
      const { actorType, id } = JSON.parse(event.connection.data);

      if (actorType === 'user') {
        if (id !== user.id) return;
        setState({ isUserConnected: false });
      } else if (actorType === 'provider') {
        rawSetState(s => ({
          ...s,
          additionalProviders: s.additionalProviders.filter(i => i !== id),
        }));
      }
    });

    /**
     * `streamDestroyed` is called when someone exits the page or closes their browser
     * or sometimes when they switch audio/video devices
     */
    sessionRef.current.on('streamDestroyed', event => {
      if (userSub?.stream?.streamId === event.stream.streamId) {
        sessionRef.current?.unsubscribe(userSub);
      }
    });

    // when session is changed or removed
    return () => {
      publisherRef.current?.destroy();
      publisherRef.current = null;
      sessionRef.current?.off();
      sessionRef.current?.disconnect();
      sessionRef.current = null;
      rawSetState(defaultState());
      trackVideoCallLeft({ appointmentId: sessionState.id });
      trackCall('ended');
      debugLog('call ended');
    };
  }, [sessionState, currentProvider.id, setState, rawSetState, playJoinCallSound, trackCall]);

  /**
   * Listen for custom events once the session is connected
   */
  useEffect(() => {
    if (!state.isSessionConnected) return;

    sessionRef.current?.on('signal', cb => {
      debugLog(cb);
      if (!cb.data) return;
      const data = JSON.parse(cb.data);
      switch (cb.type.replace('signal:', '')) {
        case 'remove-provider':
          if (data.id !== currentProvider.id) return;
          history.push('/call-dismissed');
          break;
        case 'end-call':
          // @TODO
          break;

        default:
          break;
      }
    });
    return () => {};
  }, [currentProvider.id, history, state.isSessionConnected]);

  /**
   * Start publishing our video when the session connects.
   */
  useEffect(() => {
    if (!state.isSessionConnected) return;
    if (!state.config) {
      return void navigator.mediaDevices
        .getUserMedia({ audio: true, video: { facingMode: 'user' } })
        .then(stream => {
          playJoinCallSound();
          trackCall('started');
          setState({
            config: {
              audioDeviceId: stream.getAudioTracks()[0].getSettings().deviceId!,
              videoDeviceId: stream.getVideoTracks()[0].getSettings().deviceId!,
            },
          });
        });
    }

    if (publisherRef.current) {
      return;
    }

    publisherRef.current = OT.initPublisher('providerView', {
      ...getPublisherProps('cover'),
      publishVideo: state.publishingVideo,
      publishAudio: state.publishingAudio,
      videoSource: state.config.videoDeviceId,
    });
    sessionRef.current?.publish(publisherRef.current, err => {
      if (err) throw new Error(err.message);
    });

    // eslint-disable-next-line
  }, [state.config, state.isSessionConnected]);

  // Update the audio source if we change audio sources.
  useEffect(() => {
    if (state.config?.audioDeviceId) {
      publisherRef.current?.setAudioSource(state.config?.audioDeviceId);
    }
  }, [state.config?.audioDeviceId]);

  useEffect(() => {
    if (state.config?.videoDeviceId) {
      publisherRef.current?.setVideoSource(state.config.videoDeviceId);
    }
  }, [state.config?.videoDeviceId]);

  // Publish/unpublish video/audio if toggled.
  useEffect(() => {
    publisherRef.current?.publishVideo(state.publishingVideo);
  }, [state.publishingVideo]);
  useEffect(() => {
    publisherRef.current?.publishAudio(state.publishingAudio);
  }, [state.publishingAudio]);

  // if the popover is open, save the current call to sessionStorage so it can be
  // automatically reconnected if the user refreshes the page
  useEffect(() => {
    if (sessionState && state.showingPopover) {
      sessionStorage.setItem('popover-video-session', JSON.stringify(sessionState));
      return () => sessionStorage.removeItem('popover-video-session');
    }
  }, [sessionState, state.showingPopover]);

  useEffect(() => {
    const rawSessionState = sessionStorage.getItem('popover-video-session');
    if (!rawSessionState) return;
    try {
      const activeAppt = JSON.parse(rawSessionState) as VideoCallDataFragment;
      setState({ showingPopover: true });
      setSessionState(activeAppt);
    } catch (err) {
      // do nothing in the case of invalid JSON somehow being there
    }
  }, [setSessionState, setState]);

  const openPathWithPopover = (path: string) => {
    setState({ showingPopover: true });
    setTimeout(() => history.push(path), 0);
  };

  const isCallHost = !!sessionState && currentProvider.id === sessionState.provider.id;

  const removeProvider = (id: number) => {
    if (!isCallHost) return;
    sessionRef.current?.signal(
      { type: 'remove-provider', data: JSON.stringify({ id }) },
      otCallback()
    );
  };

  const disconnect = () => {
    if (isCallHost) sessionRef.current?.signal({ type: 'end-call', data: '{}' }, noop);
    setSessionState(null);
  };

  const value: IVideoContext = {
    ...state,
    sessionState,
    joinCall: setSessionState,
    setState,
    trackCall,
    openPathWithPopover,
    disconnect,
    getSession: () => sessionRef.current,
    removeProviderFromCall: isCallHost ? removeProvider : undefined,
  };

  return (
    <VideoContextV2.Provider value={value}>
      <RootVideoOverlay />
      {children}
    </VideoContextV2.Provider>
  );
}

export const useVideoContextV2 = () => {
  const context = useContext(VideoContextV2);
  if (!context) throw new Error('Video context v2 does not exist!');
  return context;
};
