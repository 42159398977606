import React from 'react';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { colors } from '../../globalStyles';
import { ReportScope, useOrganizationReportQuery } from '../../graphQL';
import { OrganizationDashboardContent } from '../Reporting/Dashboard';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';

export function OrganizationReporting() {
  const organizationId = useOrganizationId();
  const { data, loading, error } = useOrganizationReportQuery({ variables: { organizationId } });

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  return (
    <div className="pv3" style={{ backgroundColor: colors.grey.lightBackground }}>
      <OrganizationDashboardContent
        data={data.organization.reporting}
        organizationId={organizationId}
        scope={ReportScope.Org}
      />
    </div>
  );
}
