import React, { useState } from 'react';
import { Icon } from '../../../Components/Icons';
import { Notification } from '../../../Components/Notification';
import { useCurrentProvider } from '../../../Components/Permissions';
import { TwoFactor } from '../../../Components/TwoFactor';
import { Text } from '../../../globalStyles';
import {
  useAdminSendTwoFactorCodeMutation,
  useAdminVerifyTwoFactorPhoneMutation,
} from '../../../graphQL';
import { setDeviceToken } from '../../../token';
import { LinkWrapper } from '../../Session/Style';
import { NotificationContainer } from '../styles';

export const TwoFactorVerifyWidget = () => {
  const { currentProvider, featureFlags, refetch } = useCurrentProvider();
  const { twoFactorConfig } = currentProvider;

  const [verify, setVerify] = useState(false);

  const [sendTwoFactorCode, { error: sendError }] = useAdminSendTwoFactorCodeMutation();

  const [verifyPhone, { error: verifyError }] = useAdminVerifyTwoFactorPhoneMutation({
    onCompleted: ({ adminVerifyTwoFactorPhone }) => {
      setDeviceToken(adminVerifyTwoFactorPhone);
    },
  });

  const hasTwoFactorEnabledButUnverified =
    twoFactorConfig && twoFactorConfig.enabled && !twoFactorConfig.phoneVerified;

  if (
    !hasTwoFactorEnabledButUnverified ||
    !featureFlags.includes('PROVIDER_2FACTOR_VERIFICATION')
  ) {
    return null;
  }

  const phoneLastFour = twoFactorConfig.phone.slice(twoFactorConfig.phone.length - 4);

  const onVerify = (code: string) =>
    verifyPhone({ variables: { phone: twoFactorConfig.phone, code } });

  return (
    <NotificationContainer className="flex flex-row items-center">
      {!verify && verifyError && (
        <Notification kind="negative">Invalid or expired code</Notification>
      )}
      {!verify && sendError && <Notification kind="negative">{sendError.message}</Notification>}
      {!verify && (
        <>
          <Icon icon="iconsAlertSvg" size={16} className="mr3" />
          <div className="flex flex-row items-center justify-between w-100">
            <Text.body className="mr1">
              Verify your phone number for two factor authentication.
            </Text.body>
            <Text.linkButton
              onClick={async () => {
                setVerify(true);
                await sendTwoFactorCode();
              }}
            >
              click to verify
            </Text.linkButton>
          </div>
        </>
      )}
      {verify && (
        <div className="center w-100 mw6">
          <TwoFactor
            phoneLastFour={phoneLastFour}
            resendCode={sendTwoFactorCode}
            onVerify={async c => {
              await onVerify(c);
              await refetch();
              setVerify(false);
            }}
          />
          <LinkWrapper>
            <Text.linkButton onClick={() => setVerify(false)}>Back</Text.linkButton>
          </LinkWrapper>
        </div>
      )}
    </NotificationContainer>
  );
};
