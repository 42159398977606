import React from 'react';
import { CareType } from '../../graphQL';
import { IColumn } from '../../Components/UtilizationTable';

export const tooltipCopy = {
  maxHours: () => (
    <>
      <b>
        <u>Max Hours</u>
      </b>
      : The set limit of bookable hours for a given week that are available to be booked by students
      at this university. If the number of scheduled hours reaches this maximum, no additional
      appointments will be available to book during the given week.
    </>
  ),
  hoursUtilized: () => (
    <>
      <b>
        <u>Hours Utilized</u>
      </b>
      : The total bookable hours utilized in the week, which is the total of all appointments
      completed, no-showed by students, or cancelled/rescheduled by the request of the student less
      than 24h in advance.
    </>
  ),
  maxIntakes: () => (
    <>
      <b>
        <u>Max Intakes</u>
      </b>
      : The limit of bookable intake visits for a given week that are available to be booked by
      students at this university. If the number of scheduled intakes reaches or exceeds this
      maximum, no additional intakes will be available to book during the given week.
    </>
  ),
  intakesUtilized: () => (
    <>
      <b>
        <u>Intakes Utilized</u>
      </b>
      : The total bookable intake visits utilized in the week, which is the total of all intakes
      completed, no-showed by students, or cancelled/rescheduled by the request of the student less
      than 24h in advance.
    </>
  ),
  hoursScheduled: () => (
    <>
      <b>
        <u>Hours Scheduled</u>
      </b>
      : The number of bookable hours that are currently reserved for scheduled visits in a given
      week. This includes upcoming visits, or recent visits that have not yet been marked as
      completed, no-showed, or cancelled.
    </>
  ),
  intakesScheduled: () => (
    <>
      <b>
        <u>Intakes Scheduled</u>
      </b>
      : The number of bookable intakes that are currently reserved for scheduled intakes in a given
      week. This includes upcoming intakes, or recent intakes that have not yet been marked as
      completed, no-showed, or cancelled.
    </>
  ),
};

export const pastColumnCopy = (careType: CareType): IColumn[] => [
  {
    headings: [
      {
        heading: 'Week',
      },
    ],
  },
  {
    groupHeading: `Bookable ${careType} Hours`,
    headings: [
      {
        heading: 'Max Hours',
        tooltip: tooltipCopy.maxHours,
      },
      {
        heading: 'Hours Utilized',
        tooltip: tooltipCopy.hoursUtilized,
      },
    ],
  },
  {
    groupHeading: `Bookable ${careType} Intakes`,
    headings: [
      {
        heading: 'Max Intakes',
        tooltip: tooltipCopy.maxIntakes,
      },
      {
        heading: 'Intakes Utilized',
        tooltip: tooltipCopy.intakesUtilized,
      },
    ],
  },
];

export const upcomingColumnCopy = (careType: CareType): IColumn[] => [
  {
    headings: [
      {
        heading: 'Week',
      },
    ],
  },
  {
    groupHeading: `Bookable ${careType} Hours`,
    headings: [
      {
        heading: 'Max Hours',
        tooltip: tooltipCopy.maxHours,
      },
      {
        heading: 'Hours Scheduled',
        tooltip: tooltipCopy.hoursScheduled,
      },
    ],
  },
  {
    groupHeading: `Bookable ${careType} Intakes`,
    headings: [
      {
        heading: 'Max Intakes',
        tooltip: tooltipCopy.maxIntakes,
      },
      {
        heading: 'Intakes Scheduled',
        tooltip: tooltipCopy.intakesScheduled,
      },
    ],
  },
];

export const thisWeekColumnCopy = (careType: CareType): IColumn[] => [
  {
    headings: [
      {
        heading: 'Week',
      },
    ],
  },
  {
    groupHeading: `Bookable ${careType} Hours`,
    headings: [
      {
        heading: 'Max Hours',
        tooltip: tooltipCopy.maxHours,
      },
      {
        heading: 'Hours Utilized',
        tooltip: tooltipCopy.hoursUtilized,
      },
      {
        heading: 'Hours Scheduled',
        tooltip: tooltipCopy.hoursScheduled,
      },
    ],
  },
  {
    groupHeading: `Bookable ${careType} Intakes`,
    headings: [
      {
        heading: 'Max Intakes',
        tooltip: tooltipCopy.maxIntakes,
      },
      {
        heading: 'Intakes Utilized',
        tooltip: tooltipCopy.intakesUtilized,
      },
      {
        heading: 'Intakes Scheduled',
        tooltip: tooltipCopy.intakesScheduled,
      },
    ],
  },
];
