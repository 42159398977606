import { flatMap, uniq, uniqBy } from 'lodash';
import React from 'react';
import { ListView, SynchronousColumn } from '../../Components/ListView';
import { Text } from '../../globalStyles';
import { ProvidersQuery } from '../../graphQL';
import { isProviderSuperAdmin, prettifyRole } from '../../modelUtils/provider';

type Provider = ProvidersQuery['providers'][number];

const search = (provider: Provider, value: string) =>
  !value ||
  [provider.name.toLowerCase(), provider.email?.toLowerCase()].some(nameOrEmail =>
    nameOrEmail?.includes(value.toLowerCase())
  );

const nameCol: SynchronousColumn<Provider> = {
  title: 'Name',
  key: 'name',
  sort: (a, b) => a.name.localeCompare(b.name),
  width: '12rem',
  render: provider => {
    const isSuperAdmin = isProviderSuperAdmin(provider);
    let role = '--';
    if (isSuperAdmin) role = 'Multi-Campus Admin';
    else if (provider.role) role = prettifyRole(provider.role);
    return (
      <div data-cy="provider-name">
        <Text.bodyBold className="mb0">{provider.name}</Text.bodyBold>
        <Text.body className="mt0" kind="grayText">
          {role}
        </Text.body>
      </div>
    );
  },
};

type ListProps = {
  data: Provider[];
};

export const McpList = ({ data }: ListProps) => {
  const uniqCampuses = uniqBy(flatMap(data.map(p => p.organizations)), 'id').sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <ListView
      data={data}
      persist
      getKey={p => p.id}
      search={search}
      link={p => `/providers/${p.id}`}
      paginate={false}
      initialSortColumn="name"
      initialSortDirection="asc"
      columns={[
        nameCol,
        {
          title: 'Campus',
          key: 'campus',
          render: provider => provider.organizations[0]?.name ?? '--',
        },
        {
          title: 'Role',
          key: 'role',
          render: p => p.classification,
        },
        {
          title: 'Email',
          key: 'email',
          render: p => p.email,
        },
      ]}
      filters={[
        {
          key: 'campus',
          test: (p, value) => p.organizations.some(o => o.id === Number(value)),
          options: uniqCampuses.map(c => ({ label: c.name, id: c.id })),
          placeholder: 'Filter by campus',
        },
      ]}
    />
  );
};

export const OzList = ({ data }: ListProps) => {
  const uniqOrgs = uniqBy(flatMap(data.map(p => p.organizations)), 'id');
  const uniqStates = uniq(flatMap(data.map(p => p.geoStates)));

  return (
    <ListView
      data={data}
      getKey={p => p.id}
      search={search}
      link={p => `/providers/${p.id}`}
      paginate={false}
      persist
      initialSortColumn="name"
      initialSortDirection="asc"
      columns={[
        nameCol,
        {
          title: 'Care Types',
          key: 'caretypes',
          render: p => (p.careTypes.length ? p.careTypes.join(', ') : '--'),
        },
        {
          title: 'Organizations',
          key: 'orgs',
          render: p =>
            p.organizations.length ? (
              <div>
                {p.organizations.map(o => (
                  <p key={o.id}>{o.name}</p>
                ))}
              </div>
            ) : (
              '--'
            ),
        },
        {
          title: 'Licensure',
          key: 'licensure',
          width: '8rem',
          render: p => (p.geoStates.length ? p.geoStates.join(', ') : '--'),
        },
      ]}
      filters={[
        {
          key: 'orgs',
          test: (p, value) => p.organizations.some(o => o.id === Number(value)),
          options: uniqOrgs.map(c => ({ label: c.name, id: c.id })),
          placeholder: 'Organization',
        },
        {
          key: 'licensure',
          test: (p, value) => p.geoStates.some(s => s === value),
          options: uniqStates.map(s => ({ label: s, id: s })),
          placeholder: 'Licensure',
        },
      ]}
    />
  );
};
