import * as qs from 'querystring';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal as MantraModal, Text } from '../../globalStyles';
import { FinalButton } from '../FinalButton';
import { Modal } from '../Modal/Modal';
import { AppointmentForLink } from './types';

interface Props {
  appointment: AppointmentForLink;
  onClose: () => void;
}

export function LateRescheduleAppointmentModal({ appointment, onClose }: Props) {
  const history = useHistory();

  const goToRescheduleFactory = ({ withOutOfPolicy }: { withOutOfPolicy?: boolean } = {}) => {
    const queryParams: Record<string, number | string> = {
      rescheduleId: appointment.id,
      userId: appointment.user.id,
    };
    if (withOutOfPolicy) {
      queryParams.outOfPolicy = Number(withOutOfPolicy);
    }
    return () => history.push(`/book?${qs.encode(queryParams)}`);
  };

  return (
    <Modal size="tiny" isOpen onClose={onClose}>
      <MantraModal.body>
        <Text.h2 className="mb4">This appointment is less than 24 hours away.</Text.h2>
        <Text.body className="mb3">
          Patient cancellation and reschedule requests made within 24 hours of the appointment time
          are considered in violation of our cancelation policy.
        </Text.body>
        <Text.body className="mb4">
          <strong>
            If a provider requested this reschedule or the request was made prior to the 24 hour
            window:
          </strong>{' '}
          Continue with rescheduling within policy.
        </Text.body>
        <FinalButton
          kind="primary"
          className="w-100 mb2"
          onClick={goToRescheduleFactory({ withOutOfPolicy: true })}
        >
          Reschedule out-of-policy
        </FinalButton>
        <FinalButton kind="outline_black" className="w-100" onClick={goToRescheduleFactory()}>
          Reschedule within policy
        </FinalButton>
        <MantraModal.closeLink onClick={onClose}>Keep Appointment</MantraModal.closeLink>
      </MantraModal.body>
    </Modal>
  );
}
