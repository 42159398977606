import { StyledSpinnerNext } from 'baseui/spinner';
import { last } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Icon } from '../../../Components/Icons';
import {
  PatientRelationshipToSubscriber,
  useAdminUserPaymentInfoQuery,
  useRecheckInsuranceEligibilityMutation,
} from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { InTextSpinner, Styles, Text } from '../styles';
import { EditInsuranceDetails } from '../EditModals/EditInsuranceDetails';

export const BillingInfoSection = () => {
  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <div className="flex items-center">
          <Text.h3>Billing &amp; Insurance</Text.h3>
        </div>
      </Styles.sidebarSectionHeader>
      <InsuranceDetails />
      <PaymentMethod />
    </Styles.sidebarSection>
  );
};

function InsuranceDetails() {
  const { user, refetch } = useDrilldownContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [recheck, recheckMutation] = useRecheckInsuranceEligibilityMutation();
  const insurance = user.insuranceDetails;
  const inNetwork = !!insurance?.payer?.inNetworkStates.includes(insurance?.state);

  const handleRecheckClicked = async () => {
    await recheck({ variables: { userId: user.id } });
    refetch();
  };

  return (
    <div className="mb4">
      {modalOpen && <EditInsuranceDetails onClose={() => setModalOpen(false)} />}
      <div className="mb1 flex justify-between items-center">
        <Text.label>Insurance Details</Text.label>
        <Text.linkButton onClick={() => setModalOpen(true)}>Edit</Text.linkButton>
      </div>
      {insurance ? (
        <>
          <Text.bodyBold>
            {insurance.payer?.name ?? 'Other / Not Listed'} ({insurance.state})
          </Text.bodyBold>
          <Text.body>{insurance.memberId}</Text.body>
          {insurance.patientRelationshipToSubscriber !== PatientRelationshipToSubscriber.Self && (
            <Text.body>
              Policyholder: {insurance.subscriberFirstName} {insurance.subscriberLastName} (
              {moment(insurance.subscriberDateOfBirth!).format('M/D/YYYY')})
            </Text.body>
          )}
          {typeof insurance.deductibleRemainingCents === 'number' ? (
            <Text.body>
              ${Math.round(insurance.deductibleRemainingCents / 100).toLocaleString()} remaining on
              deductible
            </Text.body>
          ) : (
            <Text.body>No known deductible</Text.body>
          )}
          {insurance.lastChecked && (
            <Text.body>Last checked {moment(insurance.lastChecked).format('M/D/YYYY')}</Text.body>
          )}
          {!inNetwork && <Text.bodyBold>Out of Network</Text.bodyBold>}
          {inNetwork && !insurance.eligibilityVerified && (
            <Text.body kind="danger">Eligibility not verified</Text.body>
          )}
          {inNetwork && insurance.eligibilityVerified && insurance.noMentalHealthBenefits && (
            <Text.bodyBold>No mental health benefits</Text.bodyBold>
          )}
          {recheckMutation.loading ? (
            <StyledSpinnerNext size={18} />
          ) : (
            <Text.linkButton onClick={handleRecheckClicked}>Recheck eligibility</Text.linkButton>
          )}
        </>
      ) : (
        <Text.body>None</Text.body>
      )}
    </div>
  );
}

function PaymentMethod() {
  const { user } = useDrilldownContext();
  const { data, error } = useAdminUserPaymentInfoQuery({ variables: { id: user.id } });
  if (error) return <Text.body>Something went wrong</Text.body>;
  if (!data) return <InTextSpinner />;
  const { paymentInfo } = data.adminUser;

  const lastInvoice = last(data.adminUser.invoices);
  return (
    <div>
      <Text.label className="mb1">Payment Method</Text.label>
      {paymentInfo ? (
        <div className="flex justify-between">
          <div>
            <Text.body>
              {paymentInfo.cardType} ending in {paymentInfo.lastFour}
            </Text.body>
            <Text.bodySmall>Expires {paymentInfo.expirationDate}</Text.bodySmall>
            {lastInvoice && (
              <Text.bodySmall className="i">
                Last billed ${(lastInvoice.amount / 100).toFixed(2)} on{' '}
                {moment(lastInvoice.createdAt).format('L')}
              </Text.bodySmall>
            )}
          </div>
          <div>
            <a target="_blank" rel="noopener noreferrer" href={paymentInfo.url}>
              <Icon icon="assetsStripeSvg" alt="Stripe" className="w3 o-50" />
            </a>
          </div>
        </div>
      ) : (
        <Text.body>None</Text.body>
      )}
    </div>
  );
}
