import { ChevronRight } from 'baseui/icon';
import { chain } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { McpOrAdminOnly, useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage, Text } from '../../globalStyles';
import { CareType, Permission, ReferralListQuery, useReferralListQuery } from '../../graphQL';
import {
  compareDates,
  compareLastNames,
  getFullName,
  getFullNameReversed,
} from '../../modelUtils/users';
import { ArrayElement } from '../../types';
import { PageTitle } from '../Shared';

type ReferralUser = ArrayElement<ReferralListQuery['referrals']>;

const dateFormat = 'l LT';

const columnReferralDate = {
  key: 'createdAt',
  title: 'Referral Date',
  render: (user: ReferralUser) => <div>{moment(user.createdAt).format(dateFormat)}</div>,
  sort: (a: ReferralUser, b: ReferralUser) =>
    compareDates(a.createdAt?.toString(), b.createdAt.toString()),
};

const columnName = {
  key: 'name',
  title: 'Name',
  render: (user: ReferralUser) => (
    <div>
      <Text.bodyBold data-cy="name" className="ttc">
        {getFullNameReversed(user)}
      </Text.bodyBold>
      <Text.captionGrey className="ttc">
        {user.onHoldOrActiveCareTypes.join(' & ')}
      </Text.captionGrey>
    </div>
  ),
  sort: compareLastNames,
};

const columnCampus = {
  key: 'campus',
  title: 'Campus',
  render: (user: ReferralUser) => user.organization?.name,
};

const columnReferrer = {
  key: 'referrer',
  title: 'Referrer',
  render: (user: ReferralUser) => user.referringProvider?.name,
};

const columnInitialAppointment = {
  key: 'appts',
  title: 'Initial Appointment',
  width: '2fr',
  render: (user: ReferralUser) => {
    const links = [
      {
        careType: 'Therapy',
        show: user.onHoldOrActiveCareTypes.includes(CareType.Therapy),
        isIncomplete: !user.therapyUpcoming && !user.therapyComplete,
        isScheduled: user.therapyUpcoming?.startTime,
        isCompleted: user.therapyComplete?.startTime,
      },
      {
        careType: 'Psychiatry',
        show: user.onHoldOrActiveCareTypes.includes(CareType.Psychiatry),
        isIncomplete: !user.psychUpcoming && !user.psychComplete,
        isScheduled: user.psychUpcoming?.startTime,
        isCompleted: user.psychComplete?.startTime,
      },
    ].filter(({ show }) => show);

    return (
      <div>
        {links.map(({ careType, isScheduled, isCompleted, isIncomplete }) => (
          <div key={careType}>
            <Text.bodySmallBold as="span">{careType}: </Text.bodySmallBold>
            {isScheduled && (
              <>
                <Text.bodySmallBold as="span" kind="grayText">
                  Scheduled
                </Text.bodySmallBold>
                <McpOrAdminOnly>
                  <Text.caption as="span" kind="grayText" className="ml3 f6">
                    {moment(isScheduled).format(dateFormat)}
                  </Text.caption>
                </McpOrAdminOnly>
              </>
            )}
            {!isScheduled && isCompleted && (
              <>
                <Text.bodySmallBold as="span" kind="successDark">
                  Completed
                </Text.bodySmallBold>
                <McpOrAdminOnly>
                  <Text.caption as="span" kind="grayText" className="ml3">
                    {moment(isCompleted).format(dateFormat)}
                  </Text.caption>
                </McpOrAdminOnly>
              </>
            )}
            {isIncomplete && (
              <>
                <Text.bodySmallBold as="span" kind="danger">
                  Incomplete
                </Text.bodySmallBold>
                <Text.linkCaption
                  data-cy="referrer-schedule"
                  to={`/book?userId=${user.id}&careType=${careType}`}
                  className="ml3 inline-flex items-center b"
                >
                  Schedule now <ChevronRight size={22} />
                </Text.linkCaption>
              </>
            )}
          </div>
        ))}
      </div>
    );
  },
};

/**
 * Gets all the existing values of the referrer field for the filter.
 */
const getReferringProviders = (referrals?: ReferralListQuery['referrals']) => {
  if (!referrals || !referrals.length) {
    return [];
  }

  const noItem = { id: '0', name: 'No Referrer' };

  return chain(referrals)
    .map(({ referringProvider }) => referringProvider || noItem)
    .uniqBy('id')
    .map(({ id, name }) => ({ id: String(id), label: name }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .value();
};

/**
 * Gets all the existing values of the campus field for the filter.
 */
const getCampuses = (referrals?: ReferralListQuery['referrals']) => {
  if (!referrals || !referrals.length) {
    return [];
  }

  const noItem = { id: '0', name: 'No Campus' };

  return chain(referrals)
    .map(({ organization }) => organization || noItem)
    .uniqBy('id')
    .map(({ id, name }) => ({ id: String(id), label: name }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .value();
};

export function ReferralsPage() {
  const history = useHistory();
  const { organizationId } = useParams<{ organizationId: string }>();
  const { currentProvider, hasPermission, appView } = useCurrentProvider();
  const isReferrer = appView === 'referral';
  const showAllFromOrg = hasPermission(Permission.ReferralsShowOrg);
  const isFromSuperOrg = currentProvider.organizations.some(org => org.children.length);

  const showReferrer = showAllFromOrg;
  const showCampus = showAllFromOrg && isFromSuperOrg;

  const { data: referralsData } = useReferralListQuery({
    variables: {
      fetchReferrer: showReferrer,
      fetchOrganization: showCampus,
    },
  });

  const columns = useMemo(
    () => [
      columnReferralDate,
      columnName,
      { ...columnCampus, hidden: !showCampus },
      { ...columnReferrer, hidden: !showReferrer },
      { ...columnInitialAppointment, hidden: isReferrer },
    ],
    [showCampus, showReferrer]
  );

  const filters = useMemo(
    () => [
      {
        key: 'campus',
        placeholder: 'Campus',
        test: (user: ReferralUser, value: string) => String(user.organization?.id || 0) === value,
        options: getCampuses(referralsData?.referrals),
        hidden: !showCampus,
      },
      {
        key: 'referrer',
        placeholder: 'Referrer',
        test: (user: ReferralUser, value: string) =>
          String(user.referringProvider?.id || 0) === value,
        options: getReferringProviders(referralsData?.referrals),
        hidden: !showReferrer,
      },
    ],
    [referralsData?.referrals, showCampus, showReferrer]
  );

  if (!referralsData) {
    return <LoadingPage />;
  }

  return (
    <PaddedPage>
      <UniversityLogo />
      <div className="flex flex-row items-center mb3">
        <PageTitle className="mb3">Referrals</PageTitle>
        <div className="ml3">
          <FinalButton
            pill
            iconLeft="iconsWhitePlusSvg"
            onClick={() => history.push(`/organizations/${organizationId}/students/enroll`)}
            data-cy="addStudentButton"
          >
            Add
          </FinalButton>
        </div>
      </div>
      <ListView
        data={referralsData.referrals}
        persist
        columns={columns}
        getKey={user => user.id}
        link={user => `/users/${user.id}`}
        search={(user, value) => {
          const lowerCaseValue = value.toLowerCase();
          return [
            getFullName(user).toLowerCase(),
            user.customerId?.toLowerCase() ?? '',
            user.email?.toLowerCase() ?? '',
          ].some(searchable => searchable.includes(lowerCaseValue));
        }}
        filters={filters}
        initialSortColumn="createdAt"
        initialSortDirection="desc"
      />
    </PaddedPage>
  );
}
