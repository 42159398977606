import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMcpOrReferralUser } from '../../Components/Permissions';
import { useOrganizationStaffQuery } from '../../graphQL';
import { prettifyRole } from '../../modelUtils/provider';
import { UnexpectedError } from '../Shared';
import { OrganizationAddStaffModal } from './OrganizationAddStaffModal';
import { useOrganizationId } from './util';
import * as Styles from '../Staff/styles';

export function OrganizationStaff() {
  const id = useOrganizationId();
  const { data, loading, error, refetch } = useOrganizationStaffQuery({ variables: { id } });
  const [showAddModal, setShowAddModal] = useState(false);
  const history = useHistory();

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const staffList = data.organization.providers
    .filter(isMcpOrReferralUser)
    .sort((a, b) => a.name.localeCompare(b.name));

  const onCloseAddModal = (complete: boolean) => {
    if (complete) refetch();
    setShowAddModal(false);
  };

  return (
    <div>
      <div className="mb4">
        <Styles.FormGrid style={{ maxWidth: '500px' }}>
          <FinalButton
            kind="primary"
            pill
            iconLeft="iconsWhitePlusSvg"
            data-cy="addStaffButton"
            onClick={() => history.push(`/organizations/staff/add?organizationId=${id}`)}
          >
            Add Staff
          </FinalButton>
          <FinalButton
            kind="outline_black"
            pill
            iconLeft="iconsBlackPlusSvg"
            onClick={() => setShowAddModal(true)}
          >
            Bulk Add Staff
          </FinalButton>
        </Styles.FormGrid>
      </div>
      {showAddModal && <OrganizationAddStaffModal onClose={onCloseAddModal} organizationId={id} />}
      <ListView
        data={staffList}
        getKey={i => i.id}
        search={(item, query) => item.name.toLowerCase().includes(query.toLowerCase())}
        initialSortColumn="name"
        initialSortDirection="asc"
        onClick={item => history.push(`/providers/${item.id}`)}
        columns={[
          {
            key: 'name',
            title: 'Name',
            render: staff => staff.name,
            sort: (a, b) => a.name.localeCompare(b.name),
          },
          {
            key: 'role',
            title: 'Account Type',
            render: staff => staff.role && prettifyRole(staff.role),
          },
          {
            key: 'classification',
            title: 'Role',
            render: staff => staff.classification,
          },
          {
            key: 'university',
            title: 'University',
            render: staff => staff.organizations[0]?.name,
          },
        ]}
      />
    </div>
  );
}
