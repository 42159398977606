import React from 'react';
import { Availability } from '../Provider/Availability/Availability';
import { useCurrentProvider } from '../../Components/Permissions';

export const CurrentProviderAvailability = () => {
  const providerContext = useCurrentProvider();

  return (
    <div>
      <Availability provider={providerContext.currentProvider} />
    </div>
  );
};
