import React from 'react';
import moment from 'moment-timezone';
import { StyledSidebar, Styles, Text } from '../styles';
import { useDrilldownContext } from '../helpers';

export function NonPHISidebar() {
  const { user } = useDrilldownContext();
  return (
    <StyledSidebar>
      <Styles.sidebarSection>
        <Styles.sidebarSectionHeader>
          <Text.h3>Patient Info</Text.h3>
        </Styles.sidebarSectionHeader>
        <div className="mt3 mb4">
          <Text.label className="mb1">Date of Referral</Text.label>
          <Text.body>{moment(user.createdAt).format('M/D/YYYY')}</Text.body>
        </div>
        <div className="mt3 mb4">
          <Text.label className="mb1">Email</Text.label>
          <Text.body>{user.email}</Text.body>
        </div>
        <div className="mt3 mb4">
          <Text.label>Current Plan(s)</Text.label>
          {user.careFlows.map(flow => (
            <Styles.PlanContainer key={flow.id}>
              <Text.bodyBold>{flow.careType}</Text.bodyBold>
            </Styles.PlanContainer>
          ))}
        </div>
      </Styles.sidebarSection>
    </StyledSidebar>
  );
}
