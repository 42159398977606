import React from 'react';
import { useParams } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { CenteredOverlay, Text } from '../../globalStyles';
import { useCrisisCalendarEventQuery } from '../../graphQL';
import { UnstyledLink } from '../Shared';
import { VideoCallBlocked } from './JoinCallBlocked';
import { useVideoCall } from './useVideoCall';
import { VideoCallPage } from './VideoCallPage';

export const JoinVideoCall = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useCrisisCalendarEventQuery({ variables: { crisisId: id } });

  const appointment = data?.maybeEvent?.data;

  const { isOnCall, providerEndCall, providerRejoinCall } = useVideoCall({
    appointmentId: Number(appointment?.id),
    providerId: Number(appointment?.provider?.id),
  });

  if (loading) return <LoadingPage />;

  if (!data || !appointment || data.maybeEvent.blockedReason) {
    return <VideoCallBlocked title={data?.maybeEvent.blockedReason} body={data?.maybeEvent.body} />;
  }

  if (!isOnCall) {
    return <DonePage userId={appointment.user.id} rejoin={providerRejoinCall} />;
  }

  return <VideoCallPage appointment={appointment} providerEndCall={providerEndCall} />;
};

type DonePageProps = {
  rejoin: () => void;
  userId: number;
};
const DonePage = ({ userId, rejoin }: DonePageProps) => {
  return (
    <CenteredOverlay>
      <div className="flex flex-column tc gap-3">
        <Text.h1 className="ph3">You left the call</Text.h1>
        <FinalButton kind="outline_black" onClick={rejoin}>
          Rejoin Call
        </FinalButton>
        <UnstyledLink to={`/users/${userId}`}>
          <FinalButton kind="primary" className="w-100" onClick={rejoin}>
            Go to patient chart
          </FinalButton>
        </UnstyledLink>
      </div>
    </CenteredOverlay>
  );
};
