import { capitalize, flatten } from 'lodash';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AsyncListView } from '../../Components/ListView/AsyncListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMantraProvider, useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage } from '../../globalStyles';
import {
  Permission,
  SortDirection,
  useAdminUsersListFiltersQuery,
  usePaginatedUsersQuery,
} from '../../graphQL';
import { formatProviderName } from '../../modelUtils/provider';
import { compareLastNames } from '../../modelUtils/users';
import { PageTitle, UnexpectedError } from '../Shared';
import { ProviderUsersList } from './ProviderUsersList';
import {
  CareFlowStatusColumn,
  careStatusOptions,
  EnrollmentColumn,
  NameColumn,
  nextStepsOptions,
  SponsoredCareColumn,
  taskStatusOptions,
} from './Shared';
import { UserSearch } from './UserSearch';

// N.B. This component has some legacy stuff from when it was the users view for other roles too,
// but now it's only used by mantra admins.
// TODO: Clean up the stuff that isn't relevant anymore.

export const UsersPage = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { hasPermission, currentProvider } = useCurrentProvider();

  if (currentProvider.role === 'provider') {
    return <ProviderUsersList />;
  }

  const navMap: Record<string, { hidden?: boolean; text: string; C: React.FC }> = {
    '/users': { text: 'Patients', C: List },
    '/usersearch': {
      text: 'All Accounts',
      hidden: !hasPermission(Permission.MantraAdmin),
      C: UserSearch,
    },
  };

  const navArr = Object.entries(navMap).filter(([, v]) => !v.hidden);
  const hasOptions = navArr.length > 1;

  const { C } = navMap[pathname];
  return (
    <PaddedPage data-cy="usersPage">
      <div className="flex flex-row items-center mb3">
        {navArr.map(([k, v]) => (
          <PageTitle
            key={k}
            role={hasOptions ? 'link' : undefined}
            className={`${hasOptions ? 'pointer' : ''} mr4 ${pathname === k ? '' : 'o-50'}`}
            onClick={() => history.push(k)}
          >
            {v.text}
          </PageTitle>
        ))}
      </div>
      <C />
    </PaddedPage>
  );
};

const List = () => {
  const { refetch } = usePaginatedUsersQuery({ variables: { params: {} } });
  const { currentProvider, hasPermission, appView } = useCurrentProvider();
  const { data, loading, error } = useAdminUsersListFiltersQuery({
    skip: !hasPermission(Permission.MantraAdmin),
  });

  const loadData = useCallback(
    async (uiState, prevPage) => {
      const dtc = uiState.filterValues?.organization === 'DTC';
      const res = await refetch({
        params: {
          cursor: prevPage?.cursor,
          search: uiState.debouncedSearchValue,
          dtc,
          organizationId: dtc ? null : Number(uiState.filterValues?.organization) || null,
          sortDirection:
            uiState.sortDirection === 'desc' ? SortDirection.Descending : SortDirection.Ascending,
          sortField: uiState.sortColumn,
          careStatus: uiState.filterValues?.careStatus,
          taskStatus: uiState.filterValues?.taskStatus,
          nextSteps: uiState.filterValues?.nextSteps,
          providerId: uiState.filterValues?.provider
            ? Number(uiState.filterValues?.provider)
            : null,
          limit: uiState.limit,
        },
      });
      if (!res.data) throw new Error('Error fetching');
      return res.data?.paginatedUsers;
    },
    [refetch]
  );

  if (loading) return <LoadingPage />;
  if (error) return <UnexpectedError />;

  const showSponsoredCare = appView === 'oz';
  // org options to use for providers, who can't access the list of all orgs
  const providerOrgs = [
    ...currentProvider.organizations,
    ...flatten(currentProvider.organizations.map(i => i.children)),
  ];
  const organizationFilterOptions = [
    { id: 'DTC', label: 'DTC' },
    ...(data ? data.organizations : providerOrgs)
      .map(i => ({ id: i.id, label: i.name }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];
  const providerFilterOptions = (data ? data.providers : [])
    .map(i => ({ id: i.id, label: formatProviderName(i) }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <AsyncListView
      fetchData={loadData}
      persist
      fontSize={showSponsoredCare ? 'f6' : undefined}
      analyticsName="users-page-list"
      columns={[
        {
          key: 'name',
          title: 'Name',
          sort: compareLastNames,
          hidden: !hasPermission(Permission.Pii),
          render: user => <NameColumn user={user} showEmail />,
          sortable: true,
        },
        {
          key: 'createdAt',
          title: 'Enrollment',
          render: user => <EnrollmentColumn user={user} />,
          width: '12rem',
          sortable: true,
        },
        {
          key: 'sponsoredCare',
          title: 'Sponsored Care',
          hidden: !showSponsoredCare,
          width: '1.5fr',
          render: user => <SponsoredCareColumn user={user} />,
        },
        {
          key: 'userStatus',
          title: 'Status',
          hidden: isMantraProvider(currentProvider),
          render: user => user.taskStatus,
        },
        {
          key: 'careFlowStatus',
          title:
            currentProvider.careTypes.length === 1
              ? `${capitalize(currentProvider.careTypes[0])} Status`
              : 'Status',
          hidden: !isMantraProvider(currentProvider),
          render: user => <CareFlowStatusColumn user={user} />,
        },
      ]}
      getKey={user => user.id}
      link={user => `/users/${user.id}`}
      searchable
      initialSortColumn="name"
      initialSortDirection="asc"
      filters={[
        {
          key: 'organization',
          options: organizationFilterOptions,
          placeholder: 'Organization',
        },
        {
          key: 'taskStatus',
          options: taskStatusOptions,
          placeholder: 'Current Status',
          hidden: isMantraProvider(currentProvider),
        },
        {
          key: 'careStatus',
          options: careStatusOptions,
          placeholder: 'Current Status',
          hidden: !isMantraProvider(currentProvider),
        },
        {
          key: 'nextSteps',
          options: nextStepsOptions,
          placeholder: 'Next Steps',
          hidden: !showSponsoredCare,
        },
        {
          key: 'provider',
          options: providerFilterOptions,
          placeholder: 'Provider',
          hidden: isMantraProvider(currentProvider),
        },
      ]}
    />
  );
};
