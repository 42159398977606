import moment from 'moment';
import React from 'react';
import { MantraSvg } from '../../../Components/Flair';
import { Text } from '../../../globalStyles';
import { CareDashboardQuery } from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { UnstyledLink } from '../../Shared';
import { DashboardTable } from '../DashTable';
import { EmptyState } from '../EmptyState';
import * as Styles from '../styles';

type Props = {
  plans: CareDashboardQuery['safetyPlans'];
};

const emptyState = <EmptyState text="No incomplete safety plans" icon="safety" />;

export const IncompletePlans = ({ plans }: Props) => {
  if (!plans) return null;
  return (
    <Styles.SectionContainer>
      <Text.h3>Incomplete Safety Plans</Text.h3>
      <DashboardTable
        data={plans.reverse()}
        getKey={p => p.id}
        emptyState={emptyState}
        columns={[
          {
            render: plan => (
              <UnstyledLink to={`/users/${plan.user.id}?tab=safety-plan`}>
                <div className="flex justify-start items-center">
                  <MantraSvg icon="safety" kind="danger" />
                  <div className="ml2">
                    <Text.bodyBold>{getFullName(plan.user)}</Text.bodyBold>
                    <Text.captionGrey>
                      Activated on {moment(plan.createdAt).format('L')}
                    </Text.captionGrey>
                  </div>
                </div>
              </UnstyledLink>
            ),
          },
        ]}
      />
    </Styles.SectionContainer>
  );
};
