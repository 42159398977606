/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { Icon, IconKey } from '../../../Components/Icons';
import { Tooltip } from '../../../Components/Tooltip';
import {
  GetConsentHistoryQuery,
  ModifyExistingConsentMutationVariables,
  ModifyExistingRequestMutationVariables,
  useGetConsentHistoryQuery,
  useModifyExistingConsentMutation,
  useModifyExistingRequestMutation,
} from '../../../graphQL';
import { useTimeline } from '../../../Hooks';
import { useDrilldownContext } from '../helpers';
import { TimelineItem } from './TimelineItem';
import { toPercent } from './utils';

export const ConsentTimeline = () => {
  const { user } = useDrilldownContext();
  const { data } = useGetConsentHistoryQuery({ variables: { userId: user.id } });

  if (!data) return null;
  return <Timeline data={data} />;
};

type ConsentHook = ReturnType<typeof useConsentData>;
const useConsentData = (startData: GetConsentHistoryQuery) => {
  const { user } = useDrilldownContext();
  return useTimeline(startData, d => [
    user.createdAt,
    ...d.consents.map(c => c.endDate),
    ...d.requests.map(r => r.createdAt),
    ...d.appointments.map(a => a.startTime),
  ]);
};

const TooltipContent = () => (
  <>
    <p>This experimental tool shows a patients data sharing history.</p>
    <p>- You can move stuff around by clicking and dragging.</p>
    <p>- You can add a consent revocation by clicking on an unrevoked consent period.</p>
    <p>- You can remove a consent revocation by dragging it outside of its period.</p>
  </>
);

type TimelineProps = {
  data: GetConsentHistoryQuery;
};
const Timeline = ({ data }: TimelineProps) => {
  const hook = useConsentData(data);

  return (
    <section>
      <div className="flex items-center justify-center mb2">
        <span className="mr2 f5">Data Sharing Timeline</span>
        <Tooltip content={<TooltipContent />} />
      </div>
      <div className="bt bb bw1">
        <OrganizationLane hook={hook} />
        <CareFlowLanes hook={hook} />
        <TimelineDisplay hook={hook} />
        <InfoLane hook={hook} />
        <DateLane hook={hook} />
      </div>
    </section>
  );
};

type LaneProps = {
  hook: ConsentHook;
};
const DateLane = ({ hook }: LaneProps) => (
  <div className="flex justify-between">
    {[hook.start, (hook.start + hook.end) / 2, hook.end].map(t => (
      <div key={t} className="pa1 f7">
        {moment(t).format('L')}
      </div>
    ))}
  </div>
);

const CareFlowLanes = ({ hook }: LaneProps) => (
  <>
    {Object.values(_.groupBy(hook.data.careFlows, 'careType')).map(careFlows => (
      <div className="h2 relative w-100 bb" key={careFlows[0].careType}>
        {careFlows.map(c => {
          const start = new Date(c.validStart).getTime();
          const end = c.validEnd ? new Date(c.validEnd).getTime() : hook.end;
          const text = `${c.careStatus} ${c.paymentSource} ${c.careType}`;

          return (
            <div
              key={c.id}
              className="absolute h-100 bg-light-pink bl flex items-center justify-center"
              title={text}
              style={{
                left: toPercent(hook.timeToDistance(start)),
                width: toPercent(hook.timeToDistance(end) - hook.timeToDistance(start)),
              }}
            >
              <div className="f7 truncate ph2">{text}</div>
            </div>
          );
        })}
      </div>
    ))}
  </>
);

const OrganizationLane = ({ hook }: LaneProps) => {
  if (!hook.data.adminUser.organization) return null;
  return (
    <div className="w-100 relative h2 bb" style={{ backgroundColor: '#bd98322e' }}>
      {hook.data.adminUser.organization.carePeriods.map(p => {
        const startTime = new Date(p.startDate).getTime();
        const endTime = new Date(p.endDate).getTime();

        return (
          <React.Fragment key={p.id}>
            <div
              className="h-100 absolute bl br flex justify-center items-center"
              title={`${moment(startTime).format('L')} - ${moment(endTime).format('L')}`}
              style={{
                left: toPercent(hook.timeToDistance(startTime)),
                width: toPercent(hook.timeToDistance(endTime) - hook.timeToDistance(startTime)),
                backgroundColor: '#40962130',
              }}
            >
              <div className="f7 truncate">
                {hook.data.adminUser.organization?.abbreviation ?? 'Org'} care period
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const eventMap: Record<string, string> = {
  'scales:complete:initial': 'completed initial scales',
  'scales:complete:monthly': 'completed monthly scales',
};
type InfoLaneItem = {
  text: string;
  time: number | string | Date;
  icon: IconKey;
};
const InfoLane = ({ hook }: LaneProps) => {
  const { user, events } = useDrilldownContext();
  const [front, setFront] = useState(-1);

  // we can add more here as needed
  const displayEvents = useMemo<InfoLaneItem[]>(
    () => [
      { text: 'Patient Created', time: user.createdAt, icon: 'iconsUserSvg' },
      { text: 'Today', time: new Date(), icon: 'iconsGlobeSvg' },
      ...hook.data.appointments.map<InfoLaneItem>(a => ({
        text: `${a.description ?? a.appointmentType} (${a.status})`,
        time: a.startTime,
        icon: 'iconsBlackApptSvg',
      })),
      ...events
        .filter(e => e.tag in eventMap)
        .map<InfoLaneItem>(e => ({
          text: eventMap[e.tag],
          time: e.createdAt,
          icon: 'iconsInfoSvg',
        })),
    ],
    [hook.data.appointments, user.createdAt, events]
  );

  return (
    <div className="relative w-100" style={{ height: '3rem' }}>
      {displayEvents.map(({ text, time, icon }, i) => {
        const distance = hook.timeToDistance(time);
        const inverted = distance > 0.85;
        const isActive = front === i;

        return (
          <div
            onMouseLeave={() => setFront(-1)}
            onMouseEnter={() => setFront(i)}
            key={i}
            className="absolute bg-white ba br3 mt1 ml1"
            style={{
              zIndex: isActive ? 1 : 0,
              borderTopLeftRadius: inverted ? undefined : 0,
              borderTopRightRadius: inverted ? 0 : undefined,
              left: inverted ? undefined : toPercent(distance),
              right: inverted ? toPercent(1 - distance) : undefined,
            }}
          >
            {isActive ? (
              <div className="ph2 f7" style={{ pointerEvents: 'none' }}>
                <span className="b">{text}</span>
                <br />
                {moment(time).format('L')}
              </div>
            ) : (
              <div className="ph1">
                <Icon icon={icon} size={14} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const textTracks = {
  top: '66%',
  mid: '33%',
  bot: '3%',
};
const TimelineDisplay = ({ hook }: LaneProps) => {
  const { refetch } = useDrilldownContext();
  const containerRef = useRef<HTMLDivElement>(null);

  const [mutateRequest] = useModifyExistingRequestMutation({
    onCompleted: result => {
      hook.setData(({ requests, ...d }) => ({
        ...d,
        requests: requests.map(req => (req.id === result.request.id ? result.request : req)),
      }));
      refetch();
    },
  });
  const [mutateConsent] = useModifyExistingConsentMutation({
    onCompleted: result => {
      hook.setData(({ consents, ...d }) => ({
        ...d,
        consents: consents.map(con => (con.id === result.consent.id ? result.consent : con)),
      }));
      refetch();
    },
  });

  const updateConsent = async (
    existing: GetConsentHistoryQuery['consents'][number],
    updates: Partial<ModifyExistingConsentMutationVariables>
  ) => {
    const variables = {
      ..._.pick(existing, ['id', 'startDate', 'endDate', 'revokedAt']),
      ...updates,
    };
    const startDate = new Date(variables.startDate);
    const endDate = new Date(variables.endDate);
    const revokedDate = variables.revokedAt && new Date(variables.revokedAt);
    if (startDate > endDate) return false;
    if (revokedDate && (revokedDate > endDate || revokedDate < startDate)) {
      variables.revokedAt = null;
    }
    await mutateConsent({ variables });
    return true;
  };

  const updateRequest = async (
    existing: GetConsentHistoryQuery['requests'][number],
    updates: Pick<ModifyExistingRequestMutationVariables, 'date'>
  ) => {
    const variables = { ..._.pick(existing, ['id', 'pending']), ...updates };
    await mutateRequest({ variables });
    return true;
  };

  return (
    <div className="w-100 bg-light-gray relative bb" style={{ height: '6rem' }} ref={containerRef}>
      {hook.data.consents.map(c => {
        const startDistance = hook.timeToDistance(c.startDate);
        const endDistance = hook.timeToDistance(c.endDate);
        const revokedDistance = c.revokedAt && hook.timeToDistance(c.revokedAt);

        return (
          <React.Fragment key={c.id}>
            <div
              className="h-100 absolute"
              onClick={e => {
                // clicking on consent adds revoked
                const parentRect = containerRef.current?.getBoundingClientRect();
                if (!parentRect || c.revokedAt) return;
                const addDistance = (e.pageX - parentRect.x) / parentRect.width;
                updateConsent(c, { revokedAt: new Date(hook.distanceToTime(addDistance)) });
              }}
              style={{
                left: toPercent(startDistance),
                width: toPercent(_.min([endDistance, revokedDistance])! - startDistance),
                backgroundColor: '#40962130',
              }}
            />
            {revokedDistance && endDistance > revokedDistance && (
              <div
                className="h-100 absolute"
                style={{
                  left: toPercent(revokedDistance),
                  width: toPercent(endDistance - revokedDistance),
                  backgroundColor: '#ff11003d',
                }}
              />
            )}
          </React.Fragment>
        );
      })}
      <div
        className="absolute bg-light-blue h-100"
        title="Today"
        style={{ width: '.3rem', left: toPercent(hook.timeToDistance(new Date())) }}
      />
      {hook.data.consents.map(c => (
        <React.Fragment key={c.id}>
          <TimelineItem
            bottom={textTracks.top}
            distance={hook.timeToDistance(c.startDate)}
            containerRef={containerRef}
            onDrop={p => updateConsent(c, { startDate: new Date(hook.distanceToTime(p)) })}
          >
            Consent Starts
            <br />
            {moment(c.startDate).format('L')}
          </TimelineItem>
          <TimelineItem
            bottom={textTracks.bot}
            distance={hook.timeToDistance(c.endDate)}
            containerRef={containerRef}
            onDrop={p => updateConsent(c, { endDate: new Date(hook.distanceToTime(p)) })}
          >
            Consent Ends
            <br />
            {moment(c.endDate).format('L')}
          </TimelineItem>
          {c.revokedAt && (
            <TimelineItem
              bottom={textTracks.mid}
              distance={hook.timeToDistance(c.revokedAt)}
              containerRef={containerRef}
              onDrop={p => updateConsent(c, { revokedAt: new Date(hook.distanceToTime(p)) })}
            >
              Consent Revoked
              <br />
              {moment(c.revokedAt).format('L')}
            </TimelineItem>
          )}
        </React.Fragment>
      ))}
      {hook.data.requests.map(r => (
        <TimelineItem
          key={r.id}
          bottom={textTracks.mid}
          distance={hook.timeToDistance(r.createdAt)}
          containerRef={containerRef}
          onDrop={p => updateRequest(r, { date: new Date(hook.distanceToTime(p)) })}
        >
          Consent Requested
          <br />
          {moment(r.createdAt).format('L')}
        </TimelineItem>
      ))}
    </div>
  );
};
