import React from 'react';
import { DemiboldP, ShadowedPillButton } from '../../Components/Buttons';
import { ConfirmLikenessContainerV1 } from './styles';

interface ConfirmLikenessDialogProps {
  disabled: boolean;
  setConfirmed: () => void;
  name: string;
  selfieUrl?: string;
}

// this is a legacy component and is only used in VideoCallPage.tsx if cscPartTwo feature flag is turned off
// this will be removed in the future
export function ConfirmLikenessDialogV1({
  disabled,
  setConfirmed,
  name,
  selfieUrl,
}: ConfirmLikenessDialogProps) {
  return (
    <ConfirmLikenessContainerV1>
      <img
        src={selfieUrl}
        alt="profile"
        style={{
          display: 'inline-block',
          height: '100%',
        }}
      />
      <div className="flex flex-column justify-center items-center w-100 h-100 ph3">
        <DemiboldP className="mb3">{name}</DemiboldP>
        <ShadowedPillButton disabled={disabled} onClick={setConfirmed}>
          CONFIRM LIKENESS
        </ShadowedPillButton>
      </div>
    </ConfirmLikenessContainerV1>
  );
}
