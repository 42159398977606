import qs from 'querystring';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { SetNewPassword } from './SetNewPassword';

export function ResetPassword() {
  const location = useLocation();
  const query = qs.parse(location.search.slice(1));
  const { email, token } = query;
  return <SetNewPassword email={email as string} token={token as string} />;
}
