import qs from 'querystring';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useAdminPasswordlessLoginMutation } from '../../graphQL';
import { setAuthToken } from '../../token';
import { UnexpectedError } from '../Shared';

export function PasswordlessHandler() {
  const [login, { data, called, error }] = useAdminPasswordlessLoginMutation();
  const location = useLocation();
  const history = useHistory();
  const queryString = qs.parse(location.search.replace(/^\?/, ''));
  const { email, token } = queryString;

  useEffect(() => {
    if (typeof email !== 'string' || typeof token !== 'string' || called) return;
    login({ variables: { email, token } });
  }, [email, token, login, called]);

  useEffect(() => {
    if (!data?.adminPasswordlessLogin) return;
    setAuthToken(data.adminPasswordlessLogin);
    history.push('/');
  }, [data, history]);

  if (!email || !token || error) return <UnexpectedError />;
  return <LoadingPage />;
}
