import moment from 'moment';
import React, { useState } from 'react';
import { Text } from '../../../globalStyles';
import { useForceNextScalesToNowMutation } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Styles } from '../styles';

export const SendScales = () => {
  const { user, refetch } = useDrilldownContext();
  const [scalesSent, setScalesSent] = useState(false);
  const [mutate, { error }] = useForceNextScalesToNowMutation({ variables: { userId: user.id } });

  const updateScales = async () => {
    if (!scalesSent) await mutate();
    if (error) return;
    setScalesSent(true);
    refetch();
  };

  let scalesStatus = 'No upcoming scales currently scheduled.';
  if (user.needsScales) {
    scalesStatus = 'Next assessment was sent to patient; waiting for them to complete it.';
  } else if (user.nextScales) {
    scalesStatus = `Next assessment will be sent ${moment(user.nextScales).format('MM/DD/YYYY')}`;
  }
  return (
    <Styles.widget>
      <h3 className="f3">Next Monthly Assessment</h3>
      <div className="flex items-center">
        <p>{scalesStatus}</p>
        {user.nextScales && !user.needsScales && (
          <Text.linkButton className="ml3" onClick={updateScales} disabled={scalesSent}>
            {scalesSent ? 'Sent' : 'Send Now'}
          </Text.linkButton>
        )}
      </div>
    </Styles.widget>
  );
};
