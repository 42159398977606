import React from 'react';
import { Text, colors } from '../../globalStyles';
import { Styles } from '../../Pages/Users/styles';
import { InlineSVG } from '../Icons';
import { ReferralCreditPartnerSuccessEmail } from './ReferralCreditsUsageInsufficient';

/** Widget that warns user they are out of credits before referral form begins  */
export const ReferralCreditsInsufficient = () => {
  return (
    <Styles.widget color={colors.danger} className="flex" style={{ marginBottom: 0 }}>
      <InlineSVG icon="alert-circle" kind="danger" size={24} className="mr2 mt1" />
      <div style={{ flex: 1 }}>
        <Text.bodyBold kind="danger">Insufficient referral credits remaining</Text.bodyBold>
        <Text.bodySmall>
          You've reached the maximum number of allowed referrals. Please ask your Administrator or
          authorized personnel to reach out to <ReferralCreditPartnerSuccessEmail />.
        </Text.bodySmall>
      </div>
    </Styles.widget>
  );
};
