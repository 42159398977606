import React from 'react';
import { EditTaskAside } from '../../Tasks/TaskAside';
import { IEditTask } from '../../Tasks/tasks.context';
import { useDrilldownContext } from '../helpers';
import { StyledSidebar } from '../styles';
import { ErrorContainer } from '../../../Components/ProviderNetwork/Styles';
import { Text } from '../../../globalStyles';

type EditProviderTaskProps = {
  editing: IEditTask;
  defaultValues?: Record<string, any>;
  refetchList?: () => void;
};
export const EditProviderTask = ({
  editing,
  defaultValues,
  refetchList,
}: EditProviderTaskProps) => {
  const { sidebarController, user } = useDrilldownContext();

  if (!user) {
    return (
      <ErrorContainer>
        <Text.bodySmall kind="danger">
          You need a have a user selected first to create a task
        </Text.bodySmall>
      </ErrorContainer>
    );
  }

  return (
    <StyledSidebar>
      <EditTaskAside
        key={String(editing)}
        defaultValues={defaultValues}
        lockUser={user}
        editing={editing}
        onSuccess={() => refetchList?.()}
        onClose={() => sidebarController.reset()}
      />
    </StyledSidebar>
  );
};
