import { Avatar } from 'baseui/avatar';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { prettifyRole } from '../../../modelUtils/provider';
import { nameSorter } from '../../../utils';
import { Text } from '../../Users/styles';
import { DashbaordListView } from '../DashboardList';
import { WidgetContainer } from '../Styles';
import { WidgetProps } from './interface';

export const ReferralsListWidget = ({ data }: WidgetProps) => {
  const metrics = useEvents();
  const history = useHistory();
  const referrals = data.careTeamReferrals;
  if (referrals.length === 0) return null;

  return (
    <WidgetContainer>
      <Text.h2 className="ml2">Referrals</Text.h2>
      <DashbaordListView
        centered
        data={referrals}
        getKey={d => d.provider.id}
        onClickRow={u => {
          metrics.track(`reporting.provider-referral.provider.clicked`, {
            providerId: u.provider.id,
          });
          history.push(`/providers/${u.provider.id}`);
        }}
        analyticsName="referrals"
        columns={[
          {
            title: 'Staff Member',
            render: ({ provider }) => (
              <div className="flex flex-row">
                <Avatar
                  size="32px"
                  src={provider.portrait?.url}
                  name={provider.name}
                  overrides={{
                    Root: { style: { minWidth: '32px' } },
                    Avatar: { style: { border: '1px solid white' } },
                  }}
                />
                <div className="ml2">
                  <h3>{provider.name}</h3>
                  <Text.label>{prettifyRole(provider.role)}</Text.label>
                </div>
              </div>
            ),
            sort: (a, b) => nameSorter(a.provider, b.provider),
          },
          {
            title: 'Referrals Made',
            render: d => <p style={{ margin: '0 auto' }}>{d.referralsMade}</p>,
            sort: (a, b) => b.referralsMade - a.referralsMade,
            initial: 'desc',
          },
          {
            title: 'Active Patients',
            render: d => <p>{d.activePatients}</p>,
            sort: (a, b) => b.activePatients - a.activePatients,
          },
        ]}
      />
    </WidgetContainer>
  );
};
