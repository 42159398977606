import { ChevronRight } from 'baseui/icon';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import { useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage, Text } from '../../globalStyles';
import { CareType, ReferralListQuery, useReferralListQuery } from '../../graphQL';
import {
  compareDates,
  compareLastNames,
  getFullName,
  getFullNameReversed,
} from '../../modelUtils/users';
import { ArrayElement } from '../../types';
import { PageTitle } from '../Shared';

type ReferralUser = ArrayElement<ReferralListQuery['referrals']>;

export function ReferrerList() {
  const { data } = useReferralListQuery({
    variables: {
      fetchReferrer: false,
      fetchOrganization: false,
    },
  });
  const history = useHistory();
  const location = useLocation();
  const organizationId = location.pathname.split('/')[2];
  const { appView } = useCurrentProvider();
  const isReferrer = appView === 'referral';

  const columnInitialAppointment = {
    key: 'appts',
    title: 'Initial Appointment',
    render: (user: ReferralUser) => {
      const links = [
        {
          name: 'Therapy',
          show: user.onHoldOrActiveCareTypes.includes(CareType.Therapy),
          done: !!(user.therapyComplete || user.therapyUpcoming),
        },
        {
          name: 'Psychiatry',
          show: user.onHoldOrActiveCareTypes.includes(CareType.Psychiatry),
          done: !!(user.psychComplete || user.psychUpcoming),
        },
      ].filter(l => l.show);

      return (
        <div>
          {links.map(l => (
            <div key={l.name}>
              {l.done ? (
                <p>{l.name} Booked</p>
              ) : (
                <Text.link
                  data-cy="referrer-schedule"
                  to={`/book?userId=${user.id}&careType=${l.name}`}
                  className="flex items-center b"
                >
                  Schedule {l.name} <ChevronRight size={22} />
                </Text.link>
              )}
            </div>
          ))}
        </div>
      );
    },
  };

  if (!data) return <LoadingPage />;
  return (
    <PaddedPage>
      <UniversityLogo />
      <div className="flex flex-row items-center mb3">
        <PageTitle className="mb3">Students</PageTitle>
        <div className="ml3">
          <FinalButton
            pill
            iconLeft="iconsWhitePlusSvg"
            onClick={() => history.push(`/organizations/${organizationId}/students/enroll`)}
            data-cy="addStudentButton"
          >
            Add
          </FinalButton>
        </div>
      </div>
      <ListView
        data={data.referrals}
        persist
        columns={[
          {
            key: 'name',
            title: 'Name',
            render: user => (
              <div>
                <Text.bodyBold data-cy="name" className="ttc">
                  {getFullNameReversed(user)}
                </Text.bodyBold>
                <Text.captionGrey className="ttc">
                  {user.onHoldOrActiveCareTypes.join(' & ')}
                </Text.captionGrey>
              </div>
            ),
            sort: compareLastNames,
          },
          {
            key: 'createdAt',
            title: 'Date Referred',
            render: user => <div>{moment(user.createdAt).format('L')}</div>,
            sort: (a, b) => compareDates(a.createdAt?.toString(), b.createdAt?.toString()),
          },
          {
            key: 'email',
            title: 'Email',
            render: user => <div data-cy="email">{user.email}</div>,
            sort: (a, b) =>
              a.email?.toLowerCase().localeCompare(b.email?.toLowerCase() ?? '') ?? -1,
          },
          { ...columnInitialAppointment, hidden: isReferrer },
        ]}
        getKey={user => user.id}
        link={user => `/users/${user.id}`}
        search={(user, value) => {
          const lowerCaseValue = value.toLowerCase();
          return [
            getFullName(user).toLowerCase(),
            user.customerId?.toLowerCase() ?? '',
            user.email?.toLowerCase() ?? '',
          ].some(i => i.includes(lowerCaseValue));
        }}
        initialSortColumn="createdAt"
        initialSortDirection="desc"
      />
    </PaddedPage>
  );
}
