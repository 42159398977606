import moment from 'moment-timezone';
import React, { useState } from 'react';
import { FinalButton } from '../FinalButton';
import { CancelAppointmentModal, LateCancelAppointmentModal } from './CancelAppointmentModal';
import { AppointmentForLink } from './types';

interface Props {
  appointment: AppointmentForLink;
  onCancel?: () => void;
}

export function CancelAppointmentLink({ appointment, onCancel }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClosed = (cancelled: boolean) => {
    setModalOpen(false);
    if (cancelled && onCancel) {
      onCancel();
    }
  };
  const isLate = moment(appointment.startTime).isBefore(moment().add(24, 'hours'));
  return (
    <>
      <FinalButton
        kind="minimal_danger"
        size="compact"
        iconLeft={{ icon: 'iconsRedXSvg', size: 18 }}
        className="tl"
        onClick={() => setModalOpen(true)}
      >
        Cancel
      </FinalButton>
      {modalOpen &&
        (isLate ? (
          <LateCancelAppointmentModal appointment={appointment} onClose={handleModalClosed} />
        ) : (
          <CancelAppointmentModal appointment={appointment} onClose={handleModalClosed} />
        ))}
    </>
  );
}
