import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { ReferralPartners, SortDirection, usePaginatedIopReferralsQuery } from '../../graphQL';
import { UnexpectedError, UnstyledLink } from '../Shared';
import moment from 'moment';
import { compareLastNames, getFullPreferredName } from '../../modelUtils/users';
import { AsyncListView } from '../../Components/ListView/AsyncListView';

export function IopPage() {
  const { appView, currentProvider } = useCurrentProvider();
  const history = useHistory();

  const { refetch, loading, error, data } = usePaginatedIopReferralsQuery({
    variables: {
      params: {
        partner: ReferralPartners.CharlieHealth,
        sortDirection: 'Descending',
        sortField: 'date',
      },
    },
  });

  const loadData = useCallback(
    async (uiState, prevPage) => {
      const res = await refetch({
        params: {
          partner: ReferralPartners.CharlieHealth,
          offset: prevPage?.cursor ? Number(prevPage.cursor) : 0,
          search: uiState.debouncedSearchValue,
          sortDirection:
            uiState.sortDirection === 'desc' ? SortDirection.Descending : SortDirection.Ascending,
          sortField: uiState.sortColumn,
          limit: uiState.limit,
        },
      });
      if (!res.data) throw new Error('Error fetching');
      return res.data?.paginatedIopReferrals;
    },
    [refetch]
  );

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  function formatTime(time: Date) {
    return moment(time).tz(moment.tz.guess()).format('M/D/YYYY hh:mmA z');
  }

  return (
    <div className="ma5">
      <div className="flex flex-row justify-between">
        <div className="flex flex-column mb4">
          <Text.h1>Intensive Outpatient Program</Text.h1>
          <Text.bodySmallGrey>
            Provided by Charlie Health. Updated daily. It may take up to 24 hours for new referrals
            to appear in this list.
          </Text.bodySmallGrey>
        </div>
        {appView === 'mcp' && (
          <UnstyledLink
            className="ml3"
            to={`/organizations/${currentProvider.organizations[0].id}/students/enroll-option-iop`}
          >
            <FinalButton pill iconLeft="iconsWhitePlusSvg" data-cy="createOrgButton">
              Refer Student to IOP
            </FinalButton>
          </UnstyledLink>
        )}
      </div>
      <AsyncListView
        fetchData={loadData}
        persist
        fontSize={'f6'}
        analyticsName="iop-referrals-list"
        getKey={referral => referral.id}
        searchable
        // searchPlaceholder="Search by name"
        onClick={referral => referral.userId && history.push(`/users/${referral.userId}`)}
        initialSortColumn={'date'}
        initialSortDirection="desc"
        columns={[
          {
            key: 'date',
            title: 'Referral Date',
            render: referral => formatTime(referral.referralDateTime),
            sort: (a, b) => {
              const dateA = new Date(a.referralDateTime);
              const dateB = new Date(b.referralDateTime);
              return dateA.getTime() - dateB.getTime();
            },
          },
          {
            key: 'name',
            title: 'Name',
            render: referral => (
              <div className="flex flex-column">
                <Text.bold>
                  {getFullPreferredName({
                    firstName: referral.firstName,
                    preferredName: referral.preferredName ?? '',
                    lastName: referral.lastName,
                  })}
                </Text.bold>
                <Text.bodySmallGrey>{referral.email}</Text.bodySmallGrey>
              </div>
            ),
            sort: compareLastNames,
          },
          {
            key: 'organization',
            title: 'Organization',
            hidden: appView !== 'oz',
            render: referral => referral.organizationName,
            sort: (a, b) => a.organizationName!.localeCompare(b.organizationName!),
          },
          {
            key: 'status',
            title: 'Status',
            render: referral => referral.status,
          },
        ]}
      />
    </div>
  );
}
