import { compact } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { isCampusCollaborator } from '../../../Components/CampusTeam/campusTeamUtils';
import { TextIcon } from '../../../Components/Flair';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { CareTeamMessageView } from '../../../Components/Messaging/careTeam/CareTeamMessageView';
import { CareTeamComposeArea } from '../../../Components/Messaging/careTeam/Compose';
import { useSidebarQuery } from '../../../Components/NavLayout/Counts';
import {
  MantraAdminOnly,
  McpOnly,
  McpOrAdminOnly,
  OzOnly,
  useCurrentProvider,
} from '../../../Components/Permissions';
import { ProviderCard } from '../../../Components/Provider/AvatarCard';
import { WarningWithIcon } from '../../../Components/Warning';
import { colors } from '../../../globalStyles';
import {
  EditableUserFields,
  Permission,
  useMarkCareTeamChannelsMutation,
  UserRecordViewStatus,
  useUserCareTeamChannelQuery,
} from '../../../graphQL';
import { EditCareTeamInfo } from '../EditModals/EditCareTeamInfo';
import { CareTypeEditModal, MantraCollaboratorsModal, useDrilldownContext } from '../helpers';
import { CollaborationStatus } from '../Sidebar/CollaborationStatus';
import { Styles, Text } from '../styles';

export const CareTeamTab = () => {
  const { hasPermission } = useCurrentProvider();
  const [refetchSidebar] = useSidebarQuery();
  const [markAsRead] = useMarkCareTeamChannelsMutation({ refetchQueries: [refetchSidebar] });
  const [isCampusTeamModalOpen, setIsCampusTeamModalOpen] = useState<boolean>(false);
  const { user, userView } = useDrilldownContext();
  const { organization } = user;
  const {
    data: chatChannelData,
    error,
    refetch,
  } = useUserCareTeamChannelQuery({
    variables: { userId: user.id },
  });
  const { currentProvider, appView } = useCurrentProvider();

  const mantraCollaborators = compact(user.mantraAdminCareTeam);
  const assignedProviders = compact([user.provider, user.therapist]);
  const campusTeamNoMonitors =
    user?.campusTeam?.filter(({ relationshipType }) => relationshipType !== 'universityMonitor') ??
    [];

  const readOnlyReason = useMemo(() => {
    if (userView.recordViewStatus === UserRecordViewStatus.Restricted && appView !== 'oz')
      return (
        <p className="o-50 pa4 i">
          Due to limited record access, collaboration team messaging is read-only.{' '}
        </p>
      );

    if (userView.recordViewStatus === UserRecordViewStatus.Restricted && appView === 'oz')
      return (
        <p className="pa4">
          <span className="b">Care Team Collaboration Inactive</span>
          <br />
          Access to collaborate with this patient’s campus collaboration team during periods of
          self-pay is not permitted. <Text.linkButton>Learn more</Text.linkButton>
        </p>
      );

    // This check covers Assigned Provider / Mantra Collaborator (Oz Admin or Oz Provider) sending
    const canProviderOrMantraCollabSend = [...mantraCollaborators, ...assignedProviders].some(
      p => p?.id === currentProvider.id
    );
    // This check covers Campus Team and collabator vs monitor (MCP Provider) sending
    const canCampusCollaboratorSend = user.campusTeam?.some(
      rel => rel.provider.id === currentProvider.id && isCampusCollaborator(rel)
    );
    const canMessagePatient = canProviderOrMantraCollabSend || canCampusCollaboratorSend;

    if (!canMessagePatient)
      return (
        <>
          <McpOnly>
            <p className="pa4 i">
              <span className="b">Note: </span>You must be a collaborating campus team member to
              send a message.&nbsp;
              <Text.linkButton
                as="span"
                className="b i"
                onClick={() => setIsCampusTeamModalOpen(true)}
              >
                Edit Campus Team Access
              </Text.linkButton>
            </p>
          </McpOnly>
          <MantraAdminOnly>
            <p className="pa4 i">
              <span className="b">Note: </span>You must be a Mantra Collaborator to send a
              message.&nbsp;
              <MantraCollaboratorsModal />
            </p>
          </MantraAdminOnly>
        </>
      );

    return null;
  }, [
    userView.recordViewStatus,
    mantraCollaborators,
    assignedProviders,
    currentProvider.id,
    appView,
  ]);

  useEffect(() => {
    if (!chatChannelData?.user.channel.unreadMessageCount) return;
    // don't mark as read if the provider doesn't have Mantra Admin permission, is not uni-admin, and has a read only reason
    if (
      !hasPermission(Permission.MantraAdmin) &&
      currentProvider.role !== 'universityAdmin' &&
      readOnlyReason
    )
      return;
    markAsRead({ variables: { channels: [chatChannelData.user.channel.id] } });
  }, [chatChannelData, markAsRead, readOnlyReason, hasPermission]);

  const ozProviderHasNoCampusCollaborators = useMemo(() => {
    return (
      assignedProviders.some(p => p.id === currentProvider.id) &&
      !user.campusTeam?.some(rel => isCampusCollaborator(rel))
    );
  }, [assignedProviders, currentProvider, user.campusTeam]);

  // We're relying on the API canProviderViewCareTeamMessages to handle who can access collab chat
  // TODO: Find a better way to expose that API functionality to collab chat than this ugly graphQLErrors thing
  const canAccessCollabChat = !error?.graphQLErrors.some(
    i => i.extensions?.exception.name === 'CollaborationOnlyException'
  );

  if (!canAccessCollabChat) {
    return (
      <div className="w-50 center pv5">
        <McpOnly>
          <div className="f4 tc">
            You must be a <span className="b">collaborating</span> team member to message this
            patient&apos;s provider(s)
          </div>
        </McpOnly>
        <OzOnly>
          <div className="f4 tc">
            {error?.message ?? 'Provider does not have permission to access this channel'}
          </div>
        </OzOnly>
        <McpOrAdminOnly>
          <Text.link
            as="div"
            className="b w-100 mt4 tc"
            kind="primary"
            onClick={() => setIsCampusTeamModalOpen(true)}
          >
            Edit Campus Team Access
          </Text.link>
          {isCampusTeamModalOpen && (
            <EditCareTeamInfo
              closeModal={() => {
                refetch();
                setIsCampusTeamModalOpen(false);
              }}
            />
          )}
        </McpOrAdminOnly>
      </div>
    );
  }
  if (error) return <Styles.widget>{`${error.message}` || 'Something went wrong'}</Styles.widget>;
  if (!chatChannelData) return <LoadingPage />;

  return (
    <Styles.providerMessagingTab>
      <div className="flex h-100">
        <div className="w5 mr4">
          <Text.h3 className="mv3">Collaborators</Text.h3>
          <div className="mb3">
            <div className="flex justify-between">
              <Text.h4 kind="grayText">Assigned Providers</Text.h4>
              <MantraAdminOnly>
                <CareTypeEditModal text="Edit" />
              </MantraAdminOnly>
            </div>
            {assignedProviders.length > 0 ? (
              assignedProviders.map((p, index) => (
                <div key={index}>
                  <ProviderCard provider={p} organization={p.organizations[0]} />
                </div>
              ))
            ) : (
              <Text.body className="i">None</Text.body>
            )}
          </div>
          <div className="mb3">
            <div className="flex justify-between">
              <Text.h4 kind="grayText">Mantra Collaborators</Text.h4>
              <MantraCollaboratorsModal />
            </div>
            {mantraCollaborators.length > 0 ? (
              mantraCollaborators.map(p => (
                <div>
                  <ProviderCard provider={p} organization={p.organizations[0]} />
                </div>
              ))
            ) : (
              <Text.body className="i">None</Text.body>
            )}
          </div>
          <Text.h4 kind="grayText">Campus Collaborators</Text.h4>
          {organization && (
            <Text.bodySmallGrey className="mb2">
              <TextIcon opacity={0.6} preset="university">
                {organization.name}
              </TextIcon>
            </Text.bodySmallGrey>
          )}
          {user.editableFields.includes(EditableUserFields.CareTeam) && (
            <McpOrAdminOnly>
              <Text.bodySmall className="i">
                To update Campus Collaborators,
                <Text.link as="span" kind="primary" onClick={() => setIsCampusTeamModalOpen(true)}>
                  &nbsp;edit Campus Team access
                </Text.link>
              </Text.bodySmall>
            </McpOrAdminOnly>
          )}
          <div className="pb3 mb2">
            {campusTeamNoMonitors.length > 0 ? (
              campusTeamNoMonitors.map(campusTeamMember => (
                <ProviderCard
                  key={campusTeamMember.provider.name}
                  provider={campusTeamMember.provider}
                  organization={campusTeamMember.provider.organizations[0]}
                />
              ))
            ) : (
              <Text.body className="i">None</Text.body>
            )}
          </div>
          {isCampusTeamModalOpen && (
            <EditCareTeamInfo closeModal={() => setIsCampusTeamModalOpen(false)} />
          )}
          <OzOnly>
            <div className="pt4" style={{ borderTop: `1px solid ${colors.grey.hr}` }}>
              <CollaborationStatus />
            </div>
          </OzOnly>
        </div>
        <div className="flex-auto">
          <Styles.providerMessagingViewV2>
            <CareTeamMessageView
              messages={chatChannelData.user.channel.messages}
              providers={chatChannelData.user.channel.uniqProviders}
            />
            {ozProviderHasNoCampusCollaborators && (
              <WarningWithIcon>
                <Styles.boldText>Note:</Styles.boldText> There are no campus collaborators currently
                assigned to this patient. Past collaborators will not receive message notifications
              </WarningWithIcon>
            )}
            {readOnlyReason || <CareTeamComposeArea onSend={() => refetch()} userId={user.id} />}
          </Styles.providerMessagingViewV2>
        </div>
      </div>
    </Styles.providerMessagingTab>
  );
};
