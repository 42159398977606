import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Messaging } from '../../../Components/Messaging/patient/Messaging';
import { MessagingView } from '../../../Components/Messaging/patient/MessagingView';
import { useSidebarQuery } from '../../../Components/NavLayout/Counts';
import { useCurrentProvider } from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import { Permission, useMarkPrivateChannelsMutation, useUserMessagesQuery } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Styles } from '../styles';

export const MessagesTab = () => {
  const { user } = useDrilldownContext();
  const { currentProvider, hasPermission } = useCurrentProvider();
  const therapistId = user.therapist?.id;
  const psychiatristId = user.provider?.id;

  if (!psychiatristId && !therapistId) {
    return <Text.h2 className="w-100 tc">Patient has no assigned Provider.</Text.h2>;
  }

  if ([psychiatristId, therapistId].includes(currentProvider.id))
    return (
      <Styles.widget>
        <Messaging userId={user.id} />
      </Styles.widget>
    );
  if (hasPermission(Permission.MantraAdmin)) return <AdminView />;
  return <p className="tc">You do not have permission to message this patient.</p>;
};

const AdminView = () => {
  const [refetchSidebar] = useSidebarQuery();
  const { user } = useDrilldownContext();
  const { provider, therapist } = user;
  const [active, setActive] = useState(provider || therapist);
  const [markAsRead] = useMarkPrivateChannelsMutation({ refetchQueries: [refetchSidebar] });
  const { data } = useUserMessagesQuery({
    variables: { id: user.id, providerId: active?.id! },
    skip: !active,
  });

  useEffect(() => {
    if (!data?.user.channel.unreadMessageCount) return;
    markAsRead({ variables: { channels: [data.user.channel.id] } });
  }, [data, markAsRead]);

  return (
    <Styles.widget>
      <div className="mb3">
        {compact([provider, therapist]).map(p => (
          <button
            key={p.id}
            type="button"
            onClick={() => setActive(p)}
            className="pointer bg-white bw0 mr3 outline-0"
          >
            <Text.h3 role="link" className={`pointer tl ${active?.id === p.id ? '' : 'o-50'}`}>
              {p.name}
            </Text.h3>
            <Text.label className="tl">{p.careTypes.join(', ')}</Text.label>
          </button>
        ))}
      </div>
      {active && data && <MessagingView provider={active} messages={data.user.channel.messages} />}
      <p className="tc mt4">Only assigned providers can message patients directly</p>
    </Styles.widget>
  );
};
