import { toaster } from 'baseui/toast';
import { isUndefined } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Dot } from '../../Components/Flair';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { PermsOnly } from '../../Components/Permissions';
import { PaddedPage } from '../../globalStyles';
import { Permission, useUsersVerificationListQuery } from '../../graphQL';
import { compareNames, getFullName } from '../../modelUtils/users';
import { compareJsDates } from '../../utils';
import { PageTitle, UnexpectedError } from '../Shared';
import { verificationColorMap, sortByStatus, sortBySubmission, VerifiableUser } from './utils';
import { VerificationModal } from './VerificationModal';

export const VerificationPage = () => {
  const [currentUser, setCurrentUser] = useState<VerifiableUser | undefined>(undefined);
  const { data, loading, refetch } = useUsersVerificationListQuery();

  const handleClose = (accepted?: boolean) => {
    setCurrentUser(undefined);
    if (!isUndefined(accepted)) {
      refetch();
      accepted ? toaster.positive('ID Accepted', {}) : toaster.negative('ID Rejected', {});
    }
  };

  if (loading) return <LoadingPage />;
  if (!data) return <UnexpectedError />;

  return (
    <PermsOnly allowed={Permission.MantraAdmin}>
      <PaddedPage>
        <PageTitle>ID Verification</PageTitle>
        {currentUser && (
          <VerificationModal user={currentUser} onClose={accepted => handleClose(accepted)} />
        )}
        <ListView
          data={data.users.sort(sortBySubmission)}
          getKey={user => user.id}
          columns={[
            {
              key: 'patient',
              title: 'Patient',
              sort: compareNames,
              render: user => getFullName(user),
            },
            {
              key: 'idCard',
              title: 'Submitted ID',
              sort: (a, b) =>
                compareJsDates({
                  a: a.identificationCard?.createdAt,
                  b: b.identificationCard?.createdAt,
                }),
              render: user =>
                user.identificationCard
                  ? moment(user.identificationCard.createdAt).fromNow()
                  : 'Never',
            },
            {
              key: 'status',
              title: 'Status',
              sort: sortByStatus,
              render: user => {
                const status = user.verificationStatus;
                return <Dot color={verificationColorMap[status]}>{status}</Dot>;
              },
            },
          ]}
          filters={[
            {
              key: 'status',
              placeholder: 'All Users',
              test: (user, value) => user.verificationStatus === value,
              options: [
                { id: 'unverified', label: 'Unverified Users' },
                { id: 'verified', label: 'Verified Users' },
                { id: 'rejected', label: 'Rejected Users' },
                { id: 'incomplete', label: 'Incomplete Users' },
              ],
              defaultOptionId: 'unverified',
              width: '18rem',
              iconLeft: 'iconsClipboardSvg',
            },
          ]}
          onClick={user => setCurrentUser(user as VerifiableUser)}
        />
      </PaddedPage>
    </PermsOnly>
  );
};
