import { ChartConfiguration } from 'chart.js';
import { Dictionary, keys } from 'lodash';
import { HELVETICA } from '../../../../globalStyles/text';
import { CategoryBreakdown } from './utils';

export const colorsMain = {
  primary: '#74B7FF',
  secondary: '#C1DFFF',
  tooltipBackground: '#192038',
  tooltipBody: '#FFFFFF',
  ticks: '#999999',
} as const;

export const colorsAlternative = {
  primary: '#FBBFA4',
  secondary: '#FDE3D7',
  tooltipBackground: '#192038',
  tooltipBody: '#FFFFFF',
  ticks: '#999999',
} as const;

type ConfigArgs = {
  breakdown: Dictionary<CategoryBreakdown>;
  colors: Record<keyof typeof colorsMain, string>;
  labelMaxLen?: number;
  onClick?: (category: string) => void;
};

export const getChartConfig = ({
  breakdown,
  colors,
  labelMaxLen = 25,
  onClick,
}: ConfigArgs): ChartConfiguration<'bar'> => {
  const labels = keys(breakdown).sort((k1, k2) => {
    const v1 = breakdown[k1].additionalPercent + breakdown[k1].primaryPercent;
    const v2 = breakdown[k2].additionalPercent + breakdown[k2].primaryPercent;
    return v2 - v1;
  });

  return {
    type: 'bar',
    data: {
      labels,
      datasets: [
        {
          backgroundColor: colors.primary,
          barThickness: 'flex',
          maxBarThickness: 80,
          data: labels.map(l => breakdown[l].primaryPercent),
          label: 'primaryPercentages',
          barPercentage: 1,
          categoryPercentage: 0.7,
          borderRadius: 1.5,
          // @ts-ignore
          borderSkipped: false,
        },
        {
          backgroundColor: colors.secondary,
          barThickness: 'flex',
          maxBarThickness: 80,
          data: labels.map(l => breakdown[l].additionalPercent),
          barPercentage: 1,
          categoryPercentage: 0.7,
          borderRadius: 1.5,
          // @ts-ignore
          borderSkipped: false,
        },
      ],
    },
    options: {
      onHover: (event, el) => {
        if (onClick) {
          const target = event!.native!.target! as any;
          target.style.cursor = el[0] ? 'pointer' : 'default';
        }
      },
      onClick: (event, activeElement) => {
        if (onClick && event.type === 'click') {
          const element = activeElement[0];
          if (element) {
            onClick(labels[element.index]);
          }
        }
      },
      // @ts-ignore
      indexAxis: 'y',
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false,
            lineWidth: 2,
          },
          min: 0,
          beginAtZero: true,
          ticks: {
            callback: v => `${v}%`,
            stepSize: 20,
            autoSkip: true,
            font: { size: 13, family: HELVETICA, weight: '700' },
            color: colors.ticks,
          },
          stacked: true,
        },
        y: {
          title: {
            display: false,
          },
          grid: {
            display: false,
            lineWidth: 0,
          },
          ticks: {
            font: { size: 13, family: HELVETICA, weight: '500' },
            color: 'black',
            callback: (_, i) => {
              const v = labels[i];
              return v.length > labelMaxLen ? `${v.substr(0, labelMaxLen)}...` : v;
            },
          },
          stacked: true,
        },
      },
      layout: {
        padding: {
          bottom: 32,
          top: 32,
        },
      },
      plugins: {
        tooltip: {
          mode: 'index',
          bodyFont: { family: HELVETICA, size: 13 },
          displayColors: false,
          backgroundColor: colors.tooltipBackground,
          borderColor: colors.tooltipBody,
          cornerRadius: 4,
          padding: { top: 12, bottom: 12, left: 24, right: 24 },
          callbacks: {
            label: ({ label, dataset }) => {
              const { primaryPercent, additionalPercent } = breakdown[label];
              return dataset.label === 'primaryPercentages'
                ? `Primary diagnosis of ${primaryPercent.toFixed(1)}% of students`
                : `Present in ${(primaryPercent + additionalPercent).toFixed(1)}% of students`;
            },
            footer: () => (onClick ? ['Click for breakdown'] : []),
          },
        },
        legend: { display: false },
      },
    },
  };
};
