import { isString } from 'lodash';
import qs from 'querystring';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { isMantraAdmin, useCurrentProvider } from '../../Components/Permissions';
import { AppView } from '../../graphQL';
import { getTerms } from './utils';

export const TermsOfUse = () => {
  const { appView, currentProvider } = useCurrentProvider();

  const { search } = useLocation();
  const { type } = qs.parse(search.replace(/^\?/, ''));

  const termsView = (isString(type) && idToAppView[type]) || appView;
  const terms = getTerms(termsView);
  const hasViewOrIsAdmin = termsView === appView || isMantraAdmin(currentProvider);

  useEffect(() => {
    // open the terms if possible
    if (terms && hasViewOrIsAdmin) {
      window.open(terms, '_self');
    }
  }, [terms, hasViewOrIsAdmin]);

  return terms && hasViewOrIsAdmin ? null : <Redirect to="/" />;
};

const idToAppView: Record<string, AppView> = {
  university: 'mcp',
  referral: 'referral',
};
