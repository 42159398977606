export enum Flag {
  Seed = 'SEED',
  Provider2FactorVerification = 'PROVIDER_2FACTOR_VERIFICATION',
  DebugTools = 'DEBUG_TOOLS',
  AdminOverrideAppointment = 'ADMIN_OVERRIDE_APPOINTMENT',
  CocR2 = 'COC22_R2',
  MCPShowDGM = 'MCP_SHOW_DGM',
  CscPartTwo = 'CSC_PART_TWO',
  DemoReportingNumbers = 'DEMO_REPORTING_NUMBERS',
}
