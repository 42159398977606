import { FormControl } from 'baseui/form-control';
import { Modal, ModalBody, ModalHeader, ROLE } from 'baseui/modal';
import { Notification } from 'baseui/notification';
import React, { useState } from 'react';
import { FinalButton } from '../../../Components/FinalButton';
import { PharmacySearch } from '../../../Components/PharmacySearch';
import { PharmacyFragment, useSetPharmacyMutation } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Text } from '../styles';

export const EditPharmacy = ({ onUpdate }: { onUpdate: () => void }) => {
  const [updatePharmacy, updatePharmacyMutation] = useSetPharmacyMutation();
  const [pharmacy, setPharmacy] = useState<PharmacyFragment>();
  const { user } = useDrilldownContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!pharmacy) return;
    await updatePharmacy({ variables: { userId: user.id, pharmacyId: pharmacy.pharmacyId } });
    setIsModalOpen(false);
    onUpdate();
  };

  return (
    <>
      <Text.linkButton onClick={() => setIsModalOpen(true)}>Edit</Text.linkButton>
      <Modal
        unstable_ModalBackdropScroll
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeable
        animate
        size="auto"
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              borderRadius: '4px',
              paddingLeft: '6rem',
              paddingRight: '6rem',
              paddingTop: '4rem',
              paddingBottom: '4rem',
            },
          },
        }}
      >
        <ModalHeader>
          <Text.h3>Edit Pharmacy</Text.h3>
        </ModalHeader>

        <ModalBody>
          <form style={{ width: '325px' }} onSubmit={submit}>
            <FormControl>
              <PharmacySearch size="compact" pharmacy={pharmacy} setPharmacy={setPharmacy} />
            </FormControl>

            {updatePharmacyMutation.error && (
              <Notification kind="negative">
                There was a problem adding the local pharmacy
              </Notification>
            )}

            <FinalButton
              type="submit"
              disabled={!pharmacy}
              className="w-100"
              loading={updatePharmacyMutation.loading}
              kind="primary"
            >
              Submit
            </FinalButton>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};
