import React from 'react';
import { FinalButton } from '../../Components/FinalButton';
import { Text } from '../../globalStyles';
import { ConfirmLikenessContainerV2 } from './styles';

interface ConfirmLikenessDialogProps {
  disabled: boolean;
  setConfirmed: () => void;
  name: string;
  selfieUrl?: string;
}

export function ConfirmLikenessDialogV2({
  disabled,
  setConfirmed,
  selfieUrl,
}: ConfirmLikenessDialogProps) {
  return (
    <ConfirmLikenessContainerV2>
      <img
        src={selfieUrl}
        alt="profile"
        style={{
          display: 'inline-block',
          minHeight: '150px',
          minWidth: '150px',
          maxHeight: '150px',
          maxWidth: '150px',
          borderRadius: '3px 0 0 3px',
        }}
      />

      <div className="flex flex-column w-150 h-100 ph4 pv3">
        <Text.bodySmallBold className="mb3">Confirm Patient Likeness</Text.bodySmallBold>

        <FinalButton
          disabled={disabled}
          onClick={setConfirmed}
          kind="primary"
          style={{ width: '94px', height: '52' }}
        >
          Confirm
        </FinalButton>
      </div>
    </ConfirmLikenessContainerV2>
  );
}
