import React from 'react';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { OzOnly } from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import { TicketView, useTicketsQuery } from '../../../graphQL';
import { TaskTable } from '../../Tasks/TaskTable';
import * as Styles from '../styles';

export const AssignedTasks = () => {
  const { data } = useTicketsQuery({ variables: { view: TicketView.AssignedToMe } });

  if (!data)
    return (
      <Styles.SectionContainer>
        <LoadingPage />
      </Styles.SectionContainer>
    );
  return (
    <Styles.SectionContainer>
      <div className="flex justify-between mb3">
        <div>
          <Text.h3>Your Tasks</Text.h3>
          <Text.bodyGrey>Tasks assigned to you </Text.bodyGrey>
        </div>
        <div>
          <OzOnly>
            <Text.link to="/tasks">see all</Text.link>
          </OzOnly>
        </div>
      </div>
      <div className="nl3 nr3 nb3">
        <TaskTable data={data} noFilters />
      </div>
    </Styles.SectionContainer>
  );
};
