import React from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Timeline } from '../../Components/Timeline/Timeline';
import { useAllEventsQuery } from '../../graphQL';
import { PageTitle, UnexpectedError } from '../Shared';

export function Activity() {
  const { data, loading, error, fetchMore } = useAllEventsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { before: null },
    fetchPolicy: 'cache-and-network', // needed for fetchMore to work
  });
  const history = useHistory();

  if (loading && !data) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const onLoadMore = () => {
    const last = data.allEvents[data.allEvents.length - 1];
    fetchMore({
      variables: { before: last.createdAt },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, allEvents: [...prev.allEvents, ...fetchMoreResult.allEvents] };
      },
    });
  };

  return (
    <div className="ma4">
      <PageTitle className="mb4">Activity</PageTitle>
      <Timeline
        events={data.allEvents}
        onClickEvent={event => history.push(`/users/${event.user!.id}?tab=${event.tab}`)}
        verbose
      />
      <FinalButton className="mt3" onClick={onLoadMore} loading={loading}>
        Load more
      </FinalButton>
    </div>
  );
}
