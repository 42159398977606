import React from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMantraProvider, useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import { Permission, useOrganizationsListQuery } from '../../graphQL';
import { UnexpectedError, UnstyledLink } from '../Shared';
import { formatActivityStatus } from './util';

export function Organizations() {
  const { hasPermission } = useCurrentProvider();
  const { data, loading, error } = useOrganizationsListQuery();
  const history = useHistory();

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const { organizations } = data;

  return (
    <div className="ma5">
      <div className="flex mb4">
        <Text.h1>Organizations</Text.h1>
        {hasPermission(Permission.MantraAdmin) && (
          <UnstyledLink className="ml3" to="/organizations/create">
            <FinalButton pill iconLeft="iconsWhitePlusSvg" data-cy="createOrgButton">
              Create
            </FinalButton>
          </UnstyledLink>
        )}
      </div>
      <ListView
        data={organizations}
        getKey={org => org.id}
        columns={[
          {
            key: 'name',
            title: 'Organization Name',
            render: org => org.name,
            sort: (a, b) => a.name.localeCompare(b.name),
          },
          {
            key: 'careType',
            title: 'Care Type',
            render: org => org.careFlows.map(i => i.careType).join(', '),
          },
          {
            key: 'providers',
            title: 'Mantra Providers',
            render: org =>
              org.providers
                .filter(isMantraProvider)
                .map(i => i.name)
                .join(', '),
          },
          {
            key: 'status',
            title: 'Status',
            render: org => formatActivityStatus(org),
            sort: (a, b) => a.activityStatus.localeCompare(b.activityStatus),
          },
        ]}
        search={(org, value) => org.name.toLowerCase().includes(value.toLowerCase())}
        searchPlaceholder="Search"
        filters={[
          {
            key: 'status',
            placeholder: 'Status',
            options: [
              { id: 'active', label: 'Active' },
              { id: 'on-hold', label: 'On Hold' },
            ],
            test: (org, value) => org.activityStatus === value,
          },
        ]}
        paginate={false}
        initialSortColumn="name"
        initialSortDirection="asc"
        onClick={org => history.push(`/organizations/${org.id}/admin`)}
      />
    </div>
  );
}
