import React, { useState } from 'react';
import { FinalButton } from '../../../Components/FinalButton';
import { GenericSmallModal } from '../../../Components/Modal';
import { usePanelRef } from '../../../Components/NavLayout';
import { OzOnly, PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { Permission, TicketView, useTicketsQuery } from '../../../graphQL';
import { TaskTable } from '../../Tasks/TaskTable';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';

export const ProviderTasks = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTicketAssignee, setActiveTicketAssignee] = useState('');
  const { currentProvider, hasPermission } = useCurrentProvider();
  const { user, sidebarController } = useDrilldownContext();
  const { data, refetch: refetchList } = useTicketsQuery({
    variables: { view: TicketView.Unresolved, userId: user.id },
  });
  const panel = usePanelRef();

  if (!data) return null;
  return (
    <Styles.widget>
      <GenericSmallModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <p className="mb4">
          {`You do not have permission to view this task. The task assignee is: `}
          {activeTicketAssignee}
        </p>
        <div className="flex justify-center">
          <FinalButton kind="outline_black" type="button" onClick={() => setIsModalOpen(false)}>
            Close
          </FinalButton>
        </div>
      </GenericSmallModal>

      <div className="flex justify-between align-stretch mb3">
        <h3 className="f3">Tasks</h3>
        <div className="flex items-center gap-3">
          <OzOnly>
            <Text.link to="/tasks">see all</Text.link>
          </OzOnly>
          <PermsOnly allowed={Permission.TicketCreate}>
            <FinalButton
              pill
              iconLeft="iconsWhitePlusSvg"
              onClick={() => {
                sidebarController.set('edit-task', { editing: 'new', refetchList });
                panel?.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Create New Task
            </FinalButton>
          </PermsOnly>
        </div>
      </div>
      <TaskTable
        data={data}
        hideUser
        showBlockedPrefix
        onClick={t => {
          /** THE FOLLOWING LOGIC WAS CHANGED ON THE BACKEND... THIS NO LONGER MAKES ANY SENSE */
          const canView =
            hasPermission(Permission.MantraAdmin) ||
            [t.assignee?.id, t.requester?.id].includes(currentProvider.id);

          if (canView) {
            sidebarController.set('edit-task', { editing: t.id, refetchList });
            panel?.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            setIsModalOpen(true);
            setActiveTicketAssignee(t?.assignee?.name || 'unknown');
          }
        }}
      />
    </Styles.widget>
  );
};
