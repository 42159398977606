import React from 'react';
import { FormControl, FormControlProps } from '../../../Components/Form';

export type FormFields = {
  firstName: string;
  lastName: string;
  preferredName?: string;
  birthDate: string;
  email: string;
  geoState: string;
  phone: string;
  childOrganizationId: number;
  uniqueId: string;
};

export const Label = ({ label, name, className, ...props }: FormControlProps) => (
  <FormControl
    name={name}
    className={className}
    {...props}
    label={
      <label htmlFor={name} className="f6 db mb1">
        {label}
      </label>
    }
  />
);
