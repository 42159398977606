import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ColorfulPillButton } from '../../Components/Buttons';
import { colors } from '../../globalStyles';
import * as Styles from './styles';
import { NoShowButton } from './NoShowButton';

interface DonePageProps {
  name: string;
  returnToCall: () => void;
  apptId: number;
  providerId: number;
}

export function DonePage({ name, returnToCall, apptId, providerId }: DonePageProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  if (!id) return null;

  return (
    <Styles.afterCall>
      <div>
        <Styles.waitingText>You left the call.</Styles.waitingText>
        <ColorfulPillButton
          color={colors.primary}
          backgroundColor={colors.white}
          hoverBackgroundColor={colors.grey.light}
          borderColor={colors.grey.border}
          onClick={returnToCall}
        >
          Rejoin call
        </ColorfulPillButton>
        <ColorfulPillButton
          onClick={() => history.push(`/users/${id}`)}
          backgroundColor={colors.primary}
        >
          Go to patient chart
        </ColorfulPillButton>
      </div>
      <NoShowButton appointmentId={apptId} fullName={name} providerId={providerId} />
    </Styles.afterCall>
  );
}
