import { Datepicker } from 'baseui/datepicker';
import { Modal } from 'baseui/modal';
import { TimePicker } from 'baseui/timepicker';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Modal as MantraModal, Text } from '../../globalStyles';
import { useModifyAppointmentMutation } from '../../graphQL';
import { FinalButton } from '../FinalButton';
import { Input } from '../Form/Input';
import { useCurrentProvider } from '../Permissions';
import { AppointmentForLink } from './types';

interface Props {
  appointment: AppointmentForLink;
  onModified?: () => void;
}

export function ModifyAppointmentLink({ appointment, onModified }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClosed = (modified: boolean) => {
    setModalOpen(false);
    if (modified) {
      onModified?.();
    }
  };
  return (
    <>
      <FinalButton
        size="compact"
        kind="minimal_gray"
        className="tl"
        iconLeft={{ icon: 'iconsSettingsSvg', size: 18, alt: 'Modify' }}
        onClick={() => setModalOpen(true)}
      >
        Modify
      </FinalButton>
      {modalOpen && (
        <ModifyAppointmentModal appointment={appointment} onClose={handleModalClosed} />
      )}
    </>
  );
}

interface ModalProps {
  appointment: AppointmentForLink;
  onClose: (modified: boolean) => void;
}

interface FormFields {
  startDate: Date;
  startTime: Date;
  duration: number;
}

function ModifyAppointmentModal({ appointment, onClose }: ModalProps) {
  const { featureFlags } = useCurrentProvider();
  const form = useForm<FormFields>({
    defaultValues: {
      startDate: new Date(appointment.startTime),
      startTime: new Date(appointment.startTime),
      duration: moment(new Date(appointment.endTime)).diff(
        new Date(appointment.startTime),
        'minutes'
      ),
    },
  });
  const [mutate, mutation] = useModifyAppointmentMutation();

  const handleSubmit = form.handleSubmit(async values => {
    const time = moment(values.startTime ?? appointment.startTime);
    const start = moment(values.startDate ?? appointment.startTime)
      .hour(time.hour())
      .minute(time.minute())
      .second(0);
    const end = start.clone().add(values.duration, 'minutes');
    await mutate({
      variables: {
        id: appointment.id,
        start: start.toDate(),
        end: end.toDate(),
      },
    });
    onClose(true);
  });

  return (
    <Modal isOpen onClose={() => onClose(false)} autoFocus={false} unstable_ModalBackdropScroll>
      <MantraModal.body>
        <FormProvider {...form}>
          {featureFlags.includes('ADMIN_OVERRIDE_APPOINTMENT') && (
            <div className="mb3">
              <Text.h3>Start Time</Text.h3>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }}>
                <Controller
                  name="startDate"
                  render={({ onChange, value }) => (
                    <Datepicker
                      value={value}
                      onChange={val => onChange(Array.isArray(val) ? val[0].date : val.date)}
                    />
                  )}
                />
                <Controller
                  name="startTime"
                  render={({ onChange, value }) => (
                    <TimePicker value={value} onChange={val => onChange(val)} />
                  )}
                />
              </div>
            </div>
          )}
          <div className="mb4">
            <Text.h3>Duration (minutes)</Text.h3>
            <Controller
              name="duration"
              render={({ onChange, value }) => (
                <Input
                  value={value !== 0 ? value : ''}
                  onChange={e => {
                    const num = Number(e.currentTarget.value);
                    if (!isNaN(num)) {
                      onChange(num);
                    }
                  }}
                />
              )}
            />
          </div>
          <FinalButton onClick={handleSubmit} loading={mutation.loading}>
            Save
          </FinalButton>
        </FormProvider>
      </MantraModal.body>
    </Modal>
  );
}
