import { toaster } from 'baseui/toast';
import { Modal, ModalBody } from 'baseui/modal';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../../Components/FinalButton';
import { useSoftDeleteUserMutation } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Text } from '../styles';

type SoftDeleteUserModalProps = {
  showSoftDeleteModal: boolean;
  setShowSoftDeleteModal: (value: boolean) => void;
};

export const DeleteUserModal = ({
  showSoftDeleteModal,
  setShowSoftDeleteModal,
}: SoftDeleteUserModalProps) => {
  const { user } = useDrilldownContext();
  const history = useHistory();

  const [softDeleteUser] = useSoftDeleteUserMutation({
    variables: {
      userId: user.id,
    },
  });

  const softDelete = async () => {
    try {
      await softDeleteUser();
      history.push('/');
      toaster.positive(`${user.id} has been soft-deleted`, {});
    } catch (error) {
      toaster.negative(`error soft-deleting ${user.id}`, {});
    }
  };

  return (
    <Modal isOpen={showSoftDeleteModal} onClose={() => setShowSoftDeleteModal(false)}>
      <ModalBody className="pa4">
        <>
          <Text.h1Bold>Soft delete this student account?</Text.h1Bold>
          <Text.bodyBold>This action cannot be undone.</Text.bodyBold>
        </>
        <>
          <Text.bodyBold kind="danger" className="mt4">
            Soft deleting an account will automatically:
          </Text.bodyBold>
          <Text.body>
            <ul className="h">
              <li>
                <Text.bodyBold>
                  Alter account email and phone number to invalid values
                  <span className="normal">
                    , so that the original contact info may be used in future accounts, if needed.
                  </span>
                </Text.bodyBold>
              </li>
              <li>
                <Text.bodyBold>Delete Dosespot ID.</Text.bodyBold>
              </li>
              <li>
                <Text.bodyBold>Cancel any upcoming appointments.</Text.bodyBold>
              </li>
              <li>
                <Text.bodyBold>Delete all un-submitted medical notes.</Text.bodyBold>
              </li>
              <li>
                <Text.bodyBold>Log deletion event in our database.</Text.bodyBold>
              </li>
            </ul>
          </Text.body>
        </>

        <Text.bodyBold className="mt3 mb4">
          Reminder that soft deleting a user still keeps the user details in out database for
          compliance, but will remove this account from Mantra platforms (Oz, MCP, Pt Portal).
        </Text.bodyBold>
        <FinalButton className="w-100" onClick={softDelete} kind="danger">
          Confirm deletion of account
        </FinalButton>
        <FinalButton
          className="w-100"
          onClick={() => setShowSoftDeleteModal(false)}
          kind="minimal_gray"
        >
          Close
        </FinalButton>
      </ModalBody>
    </Modal>
  );
};
