import { Input as BaseInput } from 'baseui/input';
import { Select } from 'baseui/select';
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { EzMultitext } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { InputRHF } from '../../Components/Form';
import { Textarea } from '../../Components/Form/Input';
import { ImageUploadSelector } from '../../Components/ImageUploadSelector';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMantraProvider, isMcpUser, PermsOnly } from '../../Components/Permissions';
import { PharmacyDetail } from '../../Components/PharmacyDetail';
import { PharmacySearch } from '../../Components/PharmacySearch';
import { ProviderCard } from '../../Components/Provider/AvatarCard';
import { colors, Text } from '../../globalStyles';
import {
  OrganizationOverviewQuery,
  PharmacyFragment,
  useAdminCompleteOrganizationPhotoUploadMutation,
  useOrganizationOverviewQuery,
  useSetSafeOperatingHandbookUrlMutation,
  useSetSuggestedPharmacyIdMutation,
  useSuggestedPharmacyQuery,
  useUpdateAppointmentTemplatesMutation,
  useUpdateOrganizationMutation,
} from '../../graphQL';
import { useImageUploader } from '../../Hooks';
import { formatProviderName } from '../../modelUtils/provider';
import { formatPhoneNumber } from '../../utils';
import { UnexpectedError } from '../Shared';
import { OrgTabProps, useOrganizationId } from './util';

export function OrganizationOverview({ setActiveTab, refetchHeader }: OrgTabProps) {
  const id = useOrganizationId();
  const { data, loading, error, refetch } = useOrganizationOverviewQuery({ variables: { id } });

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const org = data.organization;
  const mantraProviders = org.providers.filter(isMantraProvider);

  return (
    <div className="pv3" style={{ backgroundColor: colors.grey.lightBackground }}>
      <Basics org={org} refetch={refetch} refetchHeader={refetchHeader} />
      <Department org={org} refetch={refetch} />
      <Director org={org} refetch={refetch} />
      <EmailDomains org={org} refetch={refetch} />
      <div className="mb4">
        <EditableTitle onEdit={() => setActiveTab('careTypes')}>Care Types</EditableTitle>
        <Text.body>{org.careFlows.map(i => i.careType).join(', ')}</Text.body>
      </div>
      {mantraProviders.length > 0 && (
        <div className="mb4 w-50">
          <Text.label>Mantra Providers</Text.label>
          {mantraProviders.map(provider => (
            <ProviderCard provider={provider} key={provider.id} />
          ))}
        </div>
      )}
      <AppointmentLengths org={org} refetch={refetch} />
      <SuggestedPharmacy />
      <PermsOnly allowed="mantraAdmin">
        <SafeOperatingHandbookUrl org={org} refetch={refetch} />
      </PermsOnly>
    </div>
  );
}

function EditableTitle({ children, onEdit }: { children: React.ReactNode; onEdit: () => void }) {
  return (
    <div className="flex flex-row">
      <Text.label>{children}</Text.label>
      <Text.linkButtonSmall
        className="ml4"
        onClick={onEdit}
        data-testid={`${String(children).toLowerCase()}-edit-button`}
      >
        (edit)
      </Text.linkButtonSmall>
    </div>
  );
}

function SaveCancelButtons({
  onSave,
  onCancel,
  saving,
}: {
  onSave: () => void;
  onCancel: () => void;
  saving?: boolean;
}) {
  return (
    <div className="flex flex-row">
      <div className="mr3">
        <FinalButton kind="primary" onClick={onSave} loading={saving}>
          Save
        </FinalButton>
      </div>
      <FinalButton kind="minimal_black" onClick={onCancel}>
        Cancel
      </FinalButton>
    </div>
  );
}

interface SectionProps {
  org: OrganizationOverviewQuery['organization'];
  refetch: () => Promise<any>;
}

interface BasicsFields {
  name: string;
  abbreviation: string;
  logoUpload: File | null;
}

function Basics({ org, refetch, refetchHeader }: SectionProps & { refetchHeader: () => void }) {
  const fields = ['name', 'abbreviation'] as const;
  const [editing, setEditing] = useState(false);
  const [update, updateMutation] = useUpdateOrganizationMutation();
  const [updateLogo] = useAdminCompleteOrganizationPhotoUploadMutation();
  const form = useForm<BasicsFields>({ defaultValues: { ...pick(org, fields), logoUpload: null } });
  const {
    upload,
    progress: uploadProgress,
    accepts,
    onDropRejected,
    error,
  } = useImageUploader({
    completeFunction: (key: string) => updateLogo({ variables: { key, organizationId: org.id } }),
  });

  const onCancel = () => {
    form.reset();
    setEditing(false);
  };
  const onSave = form.handleSubmit(async values => {
    if (values.logoUpload) {
      await upload(values.logoUpload);
    }
    await update({ variables: { update: { ...pick(values, fields), id: org.id } } });
    await Promise.all([refetch(), refetchHeader()]);
    setEditing(false);
  });

  return (
    <div className="mb4">
      <EditableTitle onEdit={() => setEditing(val => !val)}>Basics</EditableTitle>
      {editing ? (
        <FormProvider {...form}>
          <div style={{ maxWidth: 600 }}>
            <InputRHF name="name" placeholder="Organization Name" />
            <InputRHF name="abbreviation" placeholder="Organization Abbreviation" />
            <Controller
              name="logoUpload"
              render={({ onChange }) => (
                <ImageUploadSelector
                  initialUrl={org.logo?.url}
                  onSelect={file => onChange(file)}
                  accepts={accepts}
                  errorMessage={error}
                  onDropRejected={onDropRejected}
                />
              )}
            />
            <SaveCancelButtons
              onSave={onSave}
              onCancel={onCancel}
              saving={updateMutation.loading || (uploadProgress > 0 && uploadProgress < 100)}
            />
          </div>
        </FormProvider>
      ) : (
        <div>
          <Text.bodyBold>
            {org.name} ({org.abbreviation})
          </Text.bodyBold>
          {org.logo ? (
            <img src={org.logo.url} style={{ maxWidth: 150 }} alt="Logo" />
          ) : (
            <Text.body>No logo</Text.body>
          )}
        </div>
      )}
    </div>
  );
}

function SafeOperatingHandbookUrl({ org, refetch }: SectionProps) {
  const [editing, setEditing] = useState(false);
  const [setUrl, { loading }] = useSetSafeOperatingHandbookUrlMutation();
  const form = useForm<{ url: string }>({
    defaultValues: { url: org.safeOperatingHandbookUrl ?? '' },
  });

  const onSubmit = form.handleSubmit(async ({ url }) => {
    await setUrl({ variables: { organizationId: org.id, url: url.length ? url : null } });
    await refetch();
    setEditing(false);
  });

  const onCancel = () => {
    form.reset();
    setEditing(false);
  };

  useEffect(() => {
    form.setValue('url', org.safeOperatingHandbookUrl ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org.safeOperatingHandbookUrl]);

  return (
    <div className="mb4">
      <EditableTitle onEdit={() => setEditing(v => !v)}>Safe Operating Handbook Url</EditableTitle>
      {editing && (
        <FormProvider {...form}>
          <form onSubmit={onSubmit} style={{ maxWidth: 400 }} className="mt2">
            <InputRHF name="url" placeholder="Safe Operating Handbook Url" />
            <SaveCancelButtons onSave={onSubmit} onCancel={onCancel} saving={loading} />
          </form>
        </FormProvider>
      )}

      {!editing && (
        <>
          {org.safeOperatingHandbookUrl ? (
            <Text.externalLink href={org.safeOperatingHandbookUrl} target="_blank">
              View
            </Text.externalLink>
          ) : (
            <Text.body>None</Text.body>
          )}
        </>
      )}
    </div>
  );
}

function Department({ org, refetch }: SectionProps) {
  const fields = ['departmentName', 'departmentAddress', 'departmentHours'];
  const [editing, setEditing] = useState(false);
  const [update, updateMutation] = useUpdateOrganizationMutation();
  const form = useForm({
    defaultValues: pick(org, ...fields),
  });
  const onSubmit = form.handleSubmit(async values => {
    await update({ variables: { update: { ...values, id: org.id } } });
    await refetch();
    setEditing(false);
  });
  const onCancel = () => {
    form.reset();
    setEditing(false);
  };
  useEffect(() => {
    Object.entries(pick(org, ...fields)).forEach(([name, value]) =>
      form.setValue(name, value as any)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);
  return (
    <div className="mb4">
      <EditableTitle onEdit={() => setEditing(val => !val)}>Department</EditableTitle>
      {editing ? (
        <FormProvider {...form}>
          <form style={{ maxWidth: 600 }} className="mt2">
            <InputRHF name="departmentName" placeholder="Department Name" />
            <Textarea
              ref={form.register}
              name="departmentAddress"
              placeholder="Department Address"
              className="mb2"
            />
            <InputRHF name="departmentHours" placeholder="Department Hours" />
            <SaveCancelButtons
              onSave={onSubmit}
              onCancel={onCancel}
              saving={updateMutation.loading}
            />
          </form>
        </FormProvider>
      ) : (
        <>
          <Text.bodyBold>{org.departmentName}</Text.bodyBold>
          <Text.body style={{ whiteSpace: 'pre' }}>{org.departmentAddress}</Text.body>
          <Text.body style={{ whiteSpace: 'pre' }} className="mt3">
            {org.departmentHours}
          </Text.body>
        </>
      )}
    </div>
  );
}

function Director({ org, refetch }: SectionProps) {
  const [editing, setEditing] = useState(false);
  const [newDirectorId, setNewDirectorId] = useState<number | null>(org.director?.id ?? null);
  const [update, updateMutation] = useUpdateOrganizationMutation();
  const options = org.providers.filter(isMcpUser).map(i => ({ id: i.id, label: i.name })) ?? [];
  const onSave = async () => {
    await update({ variables: { update: { id: org.id, directorId: newDirectorId } } });
    await refetch();
    setEditing(false);
  };
  const onCancel = () => {
    setNewDirectorId(org.director?.id ?? null);
    setEditing(false);
  };
  return (
    <div className="mb4">
      <EditableTitle onEdit={() => setEditing(val => !val)}>Main Contact</EditableTitle>
      {editing ? (
        <div style={{ maxWidth: 400 }}>
          <div className="mt2 mb2">
            <Select
              options={options}
              value={
                options.find(i => i.id === newDirectorId)
                  ? [options.find(i => i.id === newDirectorId)!]
                  : []
              }
              onChange={({ option }) => setNewDirectorId((option?.id as number) ?? null)}
              maxDropdownHeight="300"
              clearable={false}
              placeholder="Director"
            />
          </div>
          <SaveCancelButtons onSave={onSave} onCancel={onCancel} saving={updateMutation.loading} />
        </div>
      ) : (
        <>
          {!org.director && <Text.body>None</Text.body>}
          {org.director && (
            <>
              <Text.bodyBold>{formatProviderName(org.director)}</Text.bodyBold>
              <Text.body>{org.director.classification}</Text.body>
              {org.director.phone && (
                <Text.body>Phone: {formatPhoneNumber(org.director.phone)}</Text.body>
              )}
              <Text.body>Email: {org.director.email}</Text.body>
            </>
          )}
        </>
      )}
    </div>
  );
}

function EmailDomains({ org, refetch }: SectionProps) {
  const [editing, setEditing] = useState(false);
  const [update, updateMutation] = useUpdateOrganizationMutation();
  const [domains, setDomains] = useState(org.emailDomains);
  useEffect(() => setDomains(org.emailDomains), [org]);
  const onSave = async () => {
    await update({ variables: { update: { id: org.id, emailDomains: domains } } });
    await refetch();
    setEditing(false);
  };
  const onCancel = () => {
    setDomains(org.emailDomains);
    setEditing(false);
  };
  return (
    <div style={{ maxWidth: 500 }} className="mb4">
      <EditableTitle onEdit={() => setEditing(val => !val)}>Email Domains</EditableTitle>
      {editing ? (
        <div className="mt2">
          <EzMultitext
            value={domains}
            onChange={setDomains}
            placeholder={{ text: 'Domain', numbered: true }}
          />
          <SaveCancelButtons onSave={onSave} onCancel={onCancel} saving={updateMutation.loading} />
        </div>
      ) : (
        <div style={{ whiteSpace: 'pre' }}>{org.emailDomains.join('\n')}</div>
      )}
    </div>
  );
}

function AppointmentLengths({ org, refetch }: SectionProps) {
  const [editing, setEditing] = useState(false);
  const [update, updateMutation] = useUpdateAppointmentTemplatesMutation();
  const [templates, setTemplates] = useState(org.appointmentTemplates);
  const onSave = async () => {
    await update({
      variables: {
        organizationId: org.id,
        templates: templates.map(t => pick(t, 'appointmentType', 'careType', 'duration')),
      },
    });
    await refetch();
    setEditing(false);
  };
  const onCancel = () => {
    setTemplates(org.appointmentTemplates);
    setEditing(false);
  };
  return (
    <div className="mb4" style={{ maxWidth: 500 }}>
      <EditableTitle onEdit={() => setEditing(true)}>Appointment Lengths</EditableTitle>
      {editing ? (
        <div>
          {templates.map((template, idx) => (
            <div className="flex flex-row items-center mb1" key={template.id}>
              <label className="mr2" htmlFor={`${template.careType}-${template.appointmentType}`}>
                {template.description}:
              </label>
              <div style={{ width: 60 }}>
                <BaseInput
                  onChange={e => {
                    const duration = Number(e.currentTarget.value);
                    setTemplates(val => [
                      ...val.slice(0, idx),
                      { ...val[idx], duration },
                      ...val.slice(idx + 1),
                    ]);
                  }}
                  value={template.duration.toString()}
                  id={`${template.careType}-${template.appointmentType}`}
                />
              </div>
              <div className="ml1">mins</div>
            </div>
          ))}
          <SaveCancelButtons onCancel={onCancel} onSave={onSave} saving={updateMutation.loading} />
        </div>
      ) : (
        org.appointmentTemplates.map(template => (
          <Text.body key={template.id}>
            {template.description}: {template.duration} min
          </Text.body>
        ))
      )}
    </div>
  );
}

function SuggestedPharmacy() {
  const id = useOrganizationId();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState<PharmacyFragment | undefined | null>();
  const { data, loading, error, refetch } = useSuggestedPharmacyQuery({
    variables: { organizationId: id },
    onCompleted: res => setSelectedPharmacy(res.organization.suggestedPharmacy),
  });
  const [setSuggestedPharmacyId] = useSetSuggestedPharmacyIdMutation();

  const handleSave = async () => {
    try {
      setSaving(true);
      await setSuggestedPharmacyId({
        variables: {
          organizationId: id,
          pharmacyId: selectedPharmacy?.pharmacyId ?? null,
        },
      });
      await refetch();
    } finally {
      setSaving(false);
      setEditing(false);
    }
  };

  let main: React.ReactNode;
  if (loading) {
    main = <LoadingPage />;
  } else if (error || !data) {
    main = <UnexpectedError />;
  } else if (editing) {
    main = (
      <div>
        <div className="mt2 mb3 mw6">
          <PharmacySearch pharmacy={selectedPharmacy} setPharmacy={ph => setSelectedPharmacy(ph)} />
        </div>
        <FinalButton kind="primary" onClick={handleSave} loading={saving}>
          Save
        </FinalButton>
      </div>
    );
  } else if (data.organization.suggestedPharmacy) {
    main = <PharmacyDetail pharmacy={data.organization.suggestedPharmacy} />;
  } else {
    main = <Text.body>None</Text.body>;
  }

  return (
    <div className="mb4">
      <EditableTitle onEdit={() => setEditing(true)}>Suggested Pharmacy</EditableTitle>
      {main}
    </div>
  );
}
