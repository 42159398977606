import React from 'react';
import { useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage } from '../../globalStyles';
import { Permission } from '../../graphQL';
import { NeedsOutreach } from './NeedsOutreach';

export const NeedsOutreachPage = () => {
  const { hasPermission } = useCurrentProvider();
  if (!hasPermission(Permission.MantraAdmin)) return null; // possibly redirect instead

  return (
    <PaddedPage>
      <div className="fl w-100">
        <NeedsOutreach />
      </div>
    </PaddedPage>
  );
};
