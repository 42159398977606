import moment from 'moment';
import * as qs from 'querystring';
import React from 'react';
import { InlineSVG } from '../../../Components/Icons';
import { getLabeledDiagnosisCode } from '../../../Components/MedicalNote/diagnosisCodes';
import { MantraModalBodyOld } from '../../../Components/Modal';
import { Modal, ModalProps } from '../../../Components/Modal/Modal';
import { usePanelRef } from '../../../Components/NavLayout';
import { useCurrentProvider } from '../../../Components/Permissions';
import * as MantraModal from '../../../globalStyles/modal';
import { Provider } from '../../../graphQL';
import { hasName } from '../../../utils';
import { useDrilldownContext } from '../helpers';
import { Text } from '../styles';
import { UserType } from '../types';

const JOT_FORM_BASE_URL = 'https://hipaa.jotform.com/213124174097048';

type UserLabModalProps = Omit<ModalProps, 'size' | 'children'> & {
  onClose: () => void;
};

export const UserLabModal = ({ onClose, ...props }: UserLabModalProps) => {
  const { user, sidebarController, careNavigators } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const panel = usePanelRef();

  return (
    <Modal size="tiny" onClose={onClose} {...props}>
      <MantraModalBodyOld>
        <Text.h2 className="mb4">How to request labs</Text.h2>
        <div className="mb4">
          <Text.label>STEP ONE</Text.label>
          <Text.h3>Fill out &amp; submit lab order form</Text.h3>
          <Text.bodySmall>
            Form will open in a new tab with pre-filled details for this patient.
          </Text.bodySmall>
          <div className="flex flex-row gap-2 items-center">
            <Text.externalLink
              className="b"
              kind="primary"
              target="_blank"
              href={generateJotFormLink(currentProvider, user)}
            >
              Open form
            </Text.externalLink>
            <InlineSVG
              style={{ marginTop: 1 }}
              icon="external-link"
              kind="primary"
              className="pointer"
              width={16}
              height={16}
            />
          </div>
        </div>
        <div className="mb4">
          <Text.label>STEP TWO</Text.label>
          <Text.h3>Create task for Care Navigation</Text.h3>
          <Text.bodySmall>
            Use the right-hand sidebar to complete the lab order task, so a Mantra Care Navigator
            can facilitate the lab ordering process.
          </Text.bodySmall>
          <div className="flex flex-row gap-2 items-center">
            <Text.linkButton
              className="b"
              kind="primary"
              onClick={() => {
                sidebarController.set('edit-task', {
                  editing: 'new',
                  defaultValues: {
                    title: 'Lab Order Request',
                    assigneeId: careNavigators[0]?.id,
                  },
                });
                panel?.scrollTo({ top: 0, behavior: 'smooth' });
                onClose();
              }}
            >
              Create task
            </Text.linkButton>
            <InlineSVG
              style={{ marginTop: 1 }}
              icon="chevron-right"
              kind="primary"
              className="pointer"
              width={12}
              height={12}
            />
          </div>
        </div>
        <MantraModal.closeLink className="b" onClick={onClose}>
          Close
        </MantraModal.closeLink>
      </MantraModalBodyOld>
    </Modal>
  );
};

const generateJotFormLink = (
  provider: Pick<Provider, 'firstName' | 'lastName' | 'postNominalTitles' | 'npi'>,
  user: UserType
) => {
  const { primaryDiagnosis, additionalDiagnoses } = user.assessmentInfo ?? {};
  const todayMoment = moment();
  const dobMoment = user.birthDate && moment(user.birthDate);

  const queryParams: Record<string, string | number> = {
    patientName: `${user.firstName}+${user.lastName}`,
    // moment month is zero based
    'dateOf[month]': todayMoment.month(),
    'dateOf[day]': todayMoment.date(),
    'dateOf[year]': todayMoment.year(),
    providerFull: `${provider.firstName}+${provider.lastName}`,
  };
  if (dobMoment) {
    // moment month is zero based
    queryParams['patientDob[month]'] = dobMoment.month() + 1;
    queryParams['patientDob[day]'] = dobMoment.date();
    queryParams['patientDob[year]'] = dobMoment.year();
  }
  if (hasName(provider)) {
    queryParams.providerFull = `${provider.firstName}+${provider.lastName}`;
  }
  if (provider.postNominalTitles) {
    queryParams.licensureCredentials = provider.postNominalTitles;
  }
  if (provider.npi) {
    queryParams.npi = provider.npi;
  }

  const icd10: string[] = [];

  // If there's a primary diagnosis, it should be listed before all secondary
  if (primaryDiagnosis) {
    icd10.push(getLabeledDiagnosisCode(primaryDiagnosis, '+'));
  }
  if (additionalDiagnoses) {
    additionalDiagnoses.forEach(d => {
      icd10.push(getLabeledDiagnosisCode(d, '+'));
    });
  }

  // If there's no primary diagnosis or secondary diagnoses it should be empty
  if (icd10.length) {
    queryParams.icd10 = icd10.join(', ');
  }

  return `${JOT_FORM_BASE_URL}?${qs.encode(queryParams)}`;
};
