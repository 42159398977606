import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { Text } from '../../globalStyles';
import { useAdminUserQuery } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';

export const NoShow = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data } = useAdminUserQuery({
    // should be its own query
    // set restricted to true since we only query the name here.
    variables: { id: Number(id), phi: true, full: false, restricted: true, assessmentInfo: false },
  });

  const name = data ? getFullName(data.adminUser) : undefined;

  return (
    <div className="flex flex-column justify-center items-center mt5">
      <Text.h1>No-show confirmed</Text.h1>
      {name && <Text.body>{name} will be notified to reschedule the appointment.</Text.body>}
      <FinalButton pill onClick={() => history.push(`/users/${id}`)} className="mt4">
        Return to patient profile
      </FinalButton>
    </div>
  );
};
