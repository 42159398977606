import { Modal } from 'baseui/modal';
import { pick } from 'lodash';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { CheckboxRHF, InputRHF, MultiSelectRHF, Select, SelectRHF } from '../../Components/Form';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  PayerFragment,
  PayerStateAddressOverrideInput,
  StateCodes,
  useAllPayersQuery,
  useCreateOrUpdatePayerMutation,
} from '../../graphQL';
import { stateOptions } from '../../states';
import { publicUrl } from '../../utils';
import { UnexpectedError } from '../Shared';

export function PayersPage() {
  const { data, loading, error, refetch } = useAllPayersQuery();
  const [editingPayer, setEditingPayer] = useState<PayerFragment | 'new' | undefined>();

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const closeModal = () => {
    refetch();
    setEditingPayer(undefined);
  };

  return (
    <div>
      <ListView
        additionalControls={
          <div>
            <FinalButton onClick={() => setEditingPayer('new')}>New Payer</FinalButton>
          </div>
        }
        data={data.allPayers}
        getKey={payer => payer.id}
        paginate={false}
        initialSortColumn="name"
        initialSortDirection="asc"
        columns={[
          {
            title: 'Name',
            key: 'name',
            sort: (a, b) => a.name.localeCompare(b.name),
            render: payer => payer.name,
          },
          {
            title: 'In-Network States',
            key: 'inNetworkStates',
            render: payer => payer.inNetworkStates.join(', '),
          },
          {
            title: 'Visible',
            key: 'visible',
            render: payer => (payer.visible ? 'Visible' : 'Not Visible'),
          },
        ]}
        filters={[
          {
            key: 'visible',
            placeholder: 'Visibility',
            options: [
              { id: 'visible', label: 'Visible' },
              { id: 'not visible', label: 'Not Visible' },
            ],
            test: (payer, value) => payer.visible === (value === 'visible'),
          },
        ]}
        onClick={payer => setEditingPayer(payer)}
      />
      {editingPayer && (
        <EditPayerModal
          onClose={closeModal}
          payer={editingPayer === 'new' ? undefined : editingPayer}
        />
      )}
    </div>
  );
}

interface ModalProps {
  payer?: PayerFragment;
  onClose: () => void;
}

function EditPayerModal({ payer, onClose }: ModalProps) {
  const [save, mutation] = useCreateOrUpdatePayerMutation({
    onCompleted: () => onClose(),
  });

  const form = useForm<Omit<PayerFragment, 'id'>>({
    defaultValues: {
      name: '',
      inNetworkStates: [],
      tradingPartnerId: '',
      standardPayerId: '',
      useAddressForAllStates: null,
      billToProviderName: false,
      visible: true,
      ...payer,
      stateAddressOverrides:
        payer?.stateAddressOverrides.map(i => pick(i, 'overrideForState', 'stateToUseInstead')) ??
        [],
    },
  });

  const handleSave = form.handleSubmit(values => {
    // Payer IDs (the numeric ones, anyway) are at least 5 digits long, but many of them start with 0,
    // and spreadsheet software tends to strip the 0 from the beginning, so let's add that if it's missing
    let { standardPayerId } = values;
    if (standardPayerId.length < 5 && /^\d/.test(standardPayerId)) {
      standardPayerId = standardPayerId.padStart(5, '0');
    }

    save({
      variables: {
        params: {
          id: payer?.id ?? null,
          // pass missing values as null rather than undefined so they get sent to the server
          ...Object.entries(values).reduce(
            (acc, [key, value]) => ({ ...acc, [key]: value ?? null }),
            {} as Omit<PayerFragment, 'id'>
          ),
          standardPayerId,
        },
      },
    });
  });

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <FormProvider {...form}>
        <form className="mt5 mb4 mh4" onSubmit={handleSave}>
          <Text.h2>{payer ? `Editing ${payer.name}` : 'Creating new payer'}</Text.h2>
          <label htmlFor="name">
            <Text.body className="mt4 mb2">Name</Text.body>
          </label>
          <InputRHF
            id="name"
            name="name"
            controlProps={{ unwrap: true }}
            rules={{ required: true }}
          />
          <label htmlFor="visible">
            <Text.body className="mt4 mb1">Visible</Text.body>
          </label>
          <Text.bodySmall className="mb2">
            Check this box to make this payer visible to users, which will allow them to select it
            when entering insurance details.
          </Text.bodySmall>
          <CheckboxRHF name="visible" />

          <label htmlFor="inNetworkStates">
            <Text.body className="mt4 mb2">In-Network States</Text.body>
          </label>
          <MultiSelectRHF
            name="inNetworkStates"
            controlProps={{ unwrap: true }}
            options={stateOptions}
          />
          <label htmlFor="tradingPartnerId">
            <Text.body className="mt4 mb1">Trading Partner ID</Text.body>
          </label>
          <Text.bodySmall className="mb2">
            This is used internally. Find the payer in{' '}
            <a href={publicUrl('assets/payers.csv')} target="_blank" rel="noopener noreferrer">
              this spreadsheet
            </a>{' '}
            and enter the value from column A (“CHC Payer ID”) here.
          </Text.bodySmall>
          <InputRHF
            name="tradingPartnerId"
            id="tradingPartnerId"
            controlProps={{ unwrap: true }}
            rules={{ required: true }}
          />
          <label htmlFor="standardPayerId">
            <Text.body className="mt4 mb1">Standard Payer ID</Text.body>
          </label>
          <Text.bodySmall className="mb2">
            This is used internally. Use the same row from the spreadsheet above, but enter the
            value from column B (“Payer ID”).
          </Text.bodySmall>
          <InputRHF
            name="standardPayerId"
            id="standardPayerId"
            controlProps={{ unwrap: true }}
            rules={{ required: true }}
          />
          <Text.body className="mt4 mb1">Shared State Address</Text.body>
          <Text.bodySmall className="mb2">
            If we should use the same state address for Wellround’s address in all states, select
            that state here. If we should use the state-by-state addresses, leave this field blank.
          </Text.bodySmall>
          <SelectRHF
            options={stateOptions}
            controlProps={{ unwrap: true }}
            name="useAddressForAllStates"
          />
          <Text.body className="mt4 mb1">State Address Overrides</Text.body>
          <Text.bodySmall className="mb2">
            Add additional state-specific address overrides here. For example, if you choose
            Connecticut as the state to override and Delaware as the state to replace it with, when
            a claim is submitted for Connecticut, the Wellround address for Delaware will be used
            instead. This takes priority over the “shared state address” option above.
          </Text.bodySmall>
          {form.errors.stateAddressOverrides && (
            <Text.bodySmall kind="danger" className="mb2">
              {/* this error seems to be typed incorrectly? */}
              {(form.errors.stateAddressOverrides as any).message}
            </Text.bodySmall>
          )}
          <Controller
            name="stateAddressOverrides"
            rules={{
              validate: {
                filledOut: (addressOverrides: PayerStateAddressOverrideInput[]) =>
                  addressOverrides.every(i => i.overrideForState && i.stateToUseInstead) ||
                  'Please pick a state from each dropdown or remove unwanted overrides.',
                unique: (addressOverrides: PayerStateAddressOverrideInput[]) =>
                  new Set(addressOverrides.map(i => i.overrideForState)).size ===
                    addressOverrides.length || 'You have overridden the same state more than once.',
              },
            }}
            render={({ onChange, value }) => (
              <EzMultirow
                values={value}
                setValues={onChange}
                blank={{ overrideForState: '' as StateCodes, stateToUseInstead: '' as StateCodes }}
                component={StateAddressOverride}
                testIdPrefix="stateAddressOverrides"
              />
            )}
          />
          <Text.body className="mt4 mb1">Bill to Provider Name</Text.body>
          <Text.bodySmall className="mb2">
            If claims should be billed to the provider’s name instead of to Wellround’s name
            (usually because we have individual contracts with this payer instead of a group
            contract), check this box.
          </Text.bodySmall>
          <CheckboxRHF name="billToProviderName" />

          <div className="mt4">
            <FinalButton type="submit" onClick={handleSave} loading={mutation.loading}>
              Save
            </FinalButton>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

function StateAddressOverride({
  value,
  onChange,
  index,
}: ComponentArgs<PayerStateAddressOverrideInput>) {
  return (
    <div className="flex-1">
      <div className="flex flex-row items-center">
        <label htmlFor={`overrideForState-${index}`} style={{ minWidth: 140 }}>
          Override state:
        </label>
        <Select
          id={`overrideForState-${index}`}
          options={stateOptions}
          onChange={(overrideForState: any) => onChange({ ...value, overrideForState })}
          value={value?.overrideForState}
          clearable={false}
        />
      </div>
      <div className="flex flex-row items-center mt1">
        <label htmlFor={`stateToUseInstead-${index}`} style={{ minWidth: 140 }}>
          Replace with:
        </label>
        <Select
          id={`stateToUseInstead-${index}`}
          options={stateOptions}
          onChange={(stateToUseInstead: any) => onChange({ ...value, stateToUseInstead })}
          value={value?.stateToUseInstead}
          clearable={false}
        />
      </div>
    </div>
  );
}
