import { get } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { Risk, Warning } from '../../../Components/Flair';
import { Tooltip } from '../../../Components/Tooltip';
import { MonitorUserFragment } from '../../../graphQL';
import { compareLastNames, getFullNameReversed } from '../../../modelUtils/users';
import { Text } from '../../Users/styles';
import { DashbaordListView } from '../DashboardList';
import { WidgetContainer } from '../Styles';
import { WidgetProps } from './interface';

export const PatientMonitoringWidget = ({ data }: WidgetProps) => {
  if (data.patientMonitoring.length === 0) return null;

  return (
    <WidgetContainer>
      <div className="flex flex-row items-center mb2">
        <Text.h2 className="mh2">Patient Monitoring</Text.h2>
        <Tooltip
          content={[
            <p className="mb2" key={1}>
              Active patients, as indicated by their provider within the most recently submitted
              medical or therapy note, that: have a risk level of &ldquo;high&rdquo; or
              &ldquo;imminent&rdquo;, have a suicidal plan or expressed suicidal intent, or have an
              active safety plan.
            </p>,
            <p key={2}>
              <b>Caution</b>: high risk patients may exist outside of this list
            </p>,
          ]}
        />
      </div>
      <PatientMonitoringTable patients={data.patientMonitoring} />
    </WidgetContainer>
  );
};

type TableProps = {
  patients: MonitorUserFragment[];
};

export const PatientMonitoringTable = ({ patients }: TableProps) => {
  const history = useHistory();
  const metrics = useEvents();

  return (
    <DashbaordListView
      data={patients}
      getKey={d => d.id}
      onClickRow={u => {
        metrics.track(`reporting.patient-monitoring.user.clicked`, {
          userId: u.id,
          customerId: u.customerId,
        });
        history.push(`/users/${u.id}`);
      }}
      analyticsName="patient-monitoring"
      columns={[
        {
          title: 'Patient Name',
          render: p => <h3>{getFullNameReversed(p)}</h3>,
          sort: compareLastNames,
        },
        {
          title: 'Risk',
          render: ({ assessmentInfo }) => (
            <Risk level={assessmentInfo.riskLevel}>{assessmentInfo.riskLevel || '--'}</Risk>
          ),
          initial: 'desc',
          sort: heirarchy('assessmentInfo.riskLevel', [
            'Imminent',
            'High',
            'Intermediate',
            'Low',
            'Very Low',
          ]),
        },
        {
          title: 'Suicidality',
          render: ({ assessmentInfo }) =>
            assessmentInfo.suicidal ? (
              <Warning>{assessmentInfo.suicidal.join(', ')}</Warning>
            ) : (
              '--'
            ),
          sort: heirarchy('assessmentInfo.suicidal', ['Plan', 'Intent', 'Ideation', 'Not present']),
        },
        {
          title: 'Safety Plan',
          render: ({ safetyPlan }) => {
            const red = safetyPlan && !safetyPlan.complete;
            let text = '--';
            if (safetyPlan) text = safetyPlan.complete ? 'Complete' : 'Incomplete';
            return <span className={red ? 'red' : undefined}>{text}</span>;
          },
          sort: (a, b) => {
            if (a.safetyPlan && b.safetyPlan) {
              if (a.safetyPlan.complete && b.safetyPlan.complete) return 0;
              if (a.safetyPlan.complete) return 1;
              return -1;
            }
            if (a.safetyPlan) return -1;
            return 1;
          },
        },
      ]}
    />
  );
};

const heirarchy =
  <T extends any, K extends string>(path: string, order: K[]) =>
  (a: T, b: T) => {
    const aProp = get(a, path) as K;
    const bProp = get(b, path) as K;

    if (aProp && bProp) {
      const aIndex = order.indexOf(aProp);
      const bIndex = order.indexOf(bProp);
      return aIndex - bIndex;
    }
    if (aProp) return -1;
    return 1;
  };
