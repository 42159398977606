import React from 'react';
import { EmailTable } from '../../Components/Emails/EmailTable';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useProviderEmailsQuery, useSetProviderSubscribedMutation } from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useProviderDrilldownContext } from './context';
import * as Styles from './Styles';

export function EmailsTab() {
  const { provider, refetch } = useProviderDrilldownContext();
  const { data, loading, error } = useProviderEmailsQuery({
    variables: { providerId: provider.id },
  });
  const [setSubscribed] = useSetProviderSubscribedMutation();
  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const handleSetSubscribed = async (subscribed: boolean) => {
    await setSubscribed({ variables: { providerId: provider.id, subscribed } });
    refetch();
  };

  return (
    <Styles.Container>
      <EmailTable
        emails={data.providerEmails}
        unsubscribedAt={provider.unsubscribedAt}
        onSetSubscribed={handleSetSubscribed}
      />
    </Styles.Container>
  );
}
