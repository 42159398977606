import React from 'react';
import { FormControl, Select } from '../../Components/Form';
import { SVGKey } from '../../Components/Icons';
import { Modal } from '../../Components/Modal/Modal';
import { VideoPageIconButton } from '../../Components/Video/VideoComponents';
import { useVideoContextV2 } from '../../Components/Video/VideoProvider';
import { Text } from '../../globalStyles';

export const VideoDevicePicker = () => {
  const [expanded, setExpanded] = React.useState(false);
  const { config, setState, trackCall } = useVideoContextV2();

  // config is set to null when a call is ended
  React.useEffect(() => {
    if (!config) setExpanded(false);
  }, [config, setExpanded]);

  if (!config) return null;
  return (
    <div>
      <Modal
        style={{ content: { overflow: 'visible' } }}
        size="tiny"
        onClose={() => setExpanded(false)}
        isOpen={expanded}
      >
        <Text.h2 className="mb3">Video-Call Settings</Text.h2>
        <DevicePicker
          label="Video Device"
          kind="videoinput"
          selectedDeviceId={config.videoDeviceId}
          icon="video"
          onSelect={id => {
            setState({ config: { ...config, videoDeviceId: id } });
            trackCall('cameraUpdated');
          }}
        />
        <DevicePicker
          label="Audio Device"
          kind="audioinput"
          selectedDeviceId={config.audioDeviceId}
          icon="mic"
          onSelect={id => {
            setState({ config: { ...config, audioDeviceId: id } });
            trackCall('audioUpdated');
          }}
        />
      </Modal>
      <VideoPageIconButton
        toggled
        onClick={() => setExpanded(true)}
        icon={{ icon: 'options', kind: 'white' }}
      />
    </div>
  );
};

interface DevicePickerProps {
  kind: 'audioinput' | 'videoinput';
  selectedDeviceId: string | null;
  onSelect: (id: string) => void;
  icon: SVGKey;
  label: string;
}

export function DevicePicker({ kind, selectedDeviceId, label, icon, onSelect }: DevicePickerProps) {
  const [mediaDevices, setMediaDevices] = React.useState<MediaDeviceInfo[]>([]);
  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      setMediaDevices(devices.filter(i => i.kind === kind));
    });
  }, [kind]);

  const deviceIndex = mediaDevices.findIndex(i => i.deviceId === selectedDeviceId);
  const device = mediaDevices[deviceIndex];
  const options = mediaDevices.map(i => ({ label: i.label, id: i.deviceId }));
  const selected = options.find(i => i.id === device?.deviceId)?.id;
  return (
    <FormControl name={kind} label={label}>
      <Select
        name={kind}
        svgLeft={{ icon, kind: 'grayText' }}
        options={options}
        value={selected}
        onChange={e => onSelect(e as string)}
        clearable={false}
      />
    </FormControl>
  );
}
