import { isNil } from 'lodash';
import React from 'react';
import { useCurrentProvider } from '../../../Components/Permissions';
import { Icon } from '../../../Components/Icons';
import { Tooltip } from '../../../Components/Tooltip';
import { Flag } from '../../../featureFlags';
import { Text } from '../../../globalStyles';
import { ReportScope } from '../../../graphQL';
import { Nullable } from '../../../types';
import { BigText, CircleContainer, WidgetContainer } from '../Styles';
import { WidgetProps } from './interface';

type Statistics = {
  title: (s?: ReportScope) => string;
  icon: JSX.Element;
  getVal: (d: WidgetProps['data']) => Nullable<string | number>;
  hide?: (s?: ReportScope) => boolean;
  type?: string;
  tooltip?: string;
};

const statistics: Statistics[] = [
  {
    title: s =>
      `Current Active ${s === ReportScope.Dtc || s === ReportScope.All ? 'Patients' : 'Students'}`,
    icon: <Icon icon="iconsUsersSvg" size={24} />,
    getVal: d => d.activeStudentsCount,
  },
  {
    title: _ => 'Referral Completion Rate',
    type: '%',
    icon: <Icon icon="iconsCheckSvg" size={24} />,
    getVal: d => {
      if (!isNil(d.referralCompletionRate)) {
        return (d.referralCompletionRate * 100).toFixed(1);
      }
    },
    hide: s => s !== ReportScope.All && s !== ReportScope.Org,
    tooltip:
      'The percentage of referred students that have completed a first appointment. Note: Students with an upcoming intake, or an intake that is not yet completed by the provider, are excluded.',
  },
  {
    title: s => `Time from ${s === ReportScope.Dtc ? 'Signup' : 'Referral'} to First Visit`,
    type: 'days',
    icon: <Icon icon="iconsClockSvg" size={24} />,
    getVal: d => {
      if (!isNil(d.averageReferralToVisitTime)) {
        return (d.averageReferralToVisitTime / (24 * 60 * 60)).toFixed(1);
      }
    },
  },
];

export const PatientTickers = ({ data, scope }: WidgetProps) => {
  const { featureFlags } = useCurrentProvider();
  if (featureFlags.includes(Flag.DemoReportingNumbers)) {
    data.referralCompletionRate = 0.952; // 95.2%
    data.averageReferralToVisitTime = 293760; // 3.4 days
  }

  const visibleStats = statistics.filter(s => !(s.hide && s.hide(scope)));
  return (
    <WidgetContainer>
      <div
        className={`flex flex-wrap-m ${
          visibleStats.length > 2 ? 'justify-between' : 'justify-around'
        }`}
      >
        {visibleStats.map((s, i) => (
          <Statistic
            key={i}
            title={s.title(scope)}
            icon={s.icon}
            metricType={s.type}
            value={s.getVal(data)}
            tooltip={s.tooltip}
          />
        ))}
      </div>
    </WidgetContainer>
  );
};

type StatisticProps = {
  title: string;
  icon: JSX.Element;
  value?: number | string | null;
  metricType?: string;
  tooltip?: string;
};

const Statistic = ({ value, metricType, title, icon, tooltip }: StatisticProps) => (
  <div className="flex flex-row pa2">
    <CircleContainer className="mr3">{icon}</CircleContainer>
    <div>
      <BigText className="di mr2">{value ?? '-'}</BigText>
      {metricType && (
        <Text.h2 className="di" style={{ fontWeight: 'normal' }}>
          {metricType}
        </Text.h2>
      )}
      <div className="flex flex-row items-center">
        <Text.bodyBold className="mr2">{title}</Text.bodyBold>
        {tooltip && <Tooltip content={tooltip} />}
      </div>
    </div>
  </div>
);
