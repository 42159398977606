import React from 'react';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { Text } from '../../globalStyles';
import { useOrganizationId } from '../Organizations/util';
import * as Styles from './styles';

interface Props {
  email: string;
  firstName: string;
  preferredName?: string;
}

export function ResentActivationPage({ email, firstName, preferredName }: Props) {
  const history = useHistory();
  const organizationId = useOrganizationId();
  return (
    <Styles.Background>
      <Styles.FinishedWrapper>
        <Text.h3>Account activation details resent</Text.h3>
        <Text.body className="mt3">
          {preferredName ?? firstName} will receive an email at {email} containing a unique link to{' '}
          <b>complete the setup of their Mantra Health account.</b>
        </Text.body>
        <Text.body className="mt3 mb4">
          <b>Note:</b> Since this student was already referred, you cannot schedule an appointment
          for them, and they will <u>not</u> show up in your student list if you were not the
          original referrer.
        </Text.body>
        <FinalButton
          kind="outline_black"
          className="w-100"
          onClick={() => history.push(`/organizations/${organizationId}/students`)}
        >
          Go to student list
        </FinalButton>
      </Styles.FinishedWrapper>
    </Styles.Background>
  );
}
