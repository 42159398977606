import { useCurrentProvider } from '../../Components/Permissions';
import { AppView, Permission } from '../../graphQL';
import { hasConnectNow } from '../../Components/Organization/organizationUtils';

export const useShowConnectNowCallReports = () => {
  const { currentProvider, appView, hasPermission } = useCurrentProvider();
  return (
    hasPermission(Permission.MantraAdmin) ||
    (appView === AppView.Mcp && hasConnectNow(currentProvider.organizations[0]))
  );
};
