import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentProvider } from '../../Components/Permissions';
import { AppView } from '../../graphQL';

export function Tutorial() {
  const { appView } = useCurrentProvider();
  if (appView !== AppView.Oz) return <Redirect to="/" />;
  return (
    <iframe
      title="Tutorial"
      src="https://www.loom.com/embed/4b93be0a2da941e788c0ab656cab1d3d"
      frameBorder="0"
      allowFullScreen
      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
    />
  );
}
