import { CareType } from '../../../graphQL';
import { useDrilldownContext } from '../../../Pages/Users/helpers';
import { hasAtLeastNRemainingSessions } from '../../../utils';

type HookArgs = {
  careType: CareType;
};

export const useSessionsEnding = ({ careType }: HookArgs) => {
  const { user } = useDrilldownContext();
  const sessionsEnding = !hasAtLeastNRemainingSessions(user, careType, 3);
  return sessionsEnding;
};
