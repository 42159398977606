import React, { useEffect, useRef, useState } from 'react';
import { ListViewConfig, useListViewUi, ListViewUiProps } from './utils';
import { ListViewInterface } from './ListViewInterface';

interface PaginatedResponse<T> {
  items: T[];
  cursor: string;
  hasMore: boolean;
  count?: number | null | undefined;
  limitCountExceeded?: boolean | null | undefined;
}

export type AsyncListViewFetcher<T> = (
  ui: ListViewUiProps<T>,
  prev?: PaginatedResponse<T>
) => Promise<PaginatedResponse<T>>;

interface AsyncListViewProps<T> extends ListViewConfig<T> {
  fetchData: AsyncListViewFetcher<T>;
}

export function AsyncListView<T>({ fetchData, ...config }: AsyncListViewProps<T>) {
  const uiState = useListViewUi(config);
  const { filterValues, debouncedSearchValue, sortColumn, sortDirection, currentPageNum, setPage } =
    uiState;
  const [pages, setPages] = useState<PaginatedResponse<T>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setPages([]);
    setPage(1);
    (async () => {
      setIsLoading(true);
      const data = await fetchData(uiState);
      if (mountedRef.current) {
        setPages([data]);
        setIsLoading(false);
      }
    })();
    // When the filters/search/sort are adjusted, clear the existing pages and refetch them
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, filterValues, debouncedSearchValue, sortColumn, sortDirection]);

  const page = pages[currentPageNum - 1];
  useEffect(() => {
    if (currentPageNum > 1 && !page) {
      (async () => {
        setIsLoading(true);
        const data = await fetchData(uiState, pages[currentPageNum - 2]);
        if (mountedRef.current) {
          setPages(val => [...val, data]);
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <ListViewInterface
      data={page?.items ?? []}
      isLoading={isLoading}
      hasNextPage={Boolean(page?.hasMore)}
      count={page?.count ?? undefined}
      countExceeded={page?.limitCountExceeded ?? undefined}
      {...config}
      {...uiState}
    />
  );
}
