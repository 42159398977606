import moment from 'moment';
import { Entitlement, OrganizationEntitlementInput } from '../../../graphQL';
import { OrgForm } from './interface';

export const formatAddress = ({
  address,
  city,
  state,
  zip,
}: {
  address: string;
  city: string;
  state: string;
  zip: string;
}) => {
  return `${address}\n${city}, ${state} ${zip}`;
};

type DepartmentHoursArgs = Pick<OrgForm, 'fromDay' | 'toDay' | 'fromTime' | 'toTime'>;

export const formatDepartmentHours = (
  is24Hrs: boolean,
  args: DepartmentHoursArgs,
  timeFormat = 'h:mma'
) => {
  const { fromDay, toDay, fromTime, toTime } = args;
  if (is24Hrs) {
    return '24/7';
  }
  if (fromDay && toDay && fromTime && toTime) {
    const t1 = moment(fromTime).format(timeFormat);
    const t2 = moment(toTime).format(timeFormat);
    return `${fromDay} - ${toDay}, ${t1} - ${t2}`;
  }
  if (fromDay && toDay) {
    return `${fromDay} - ${toDay}`;
  }
  return '';
};

type EntitlementArgs = {
  requiresId?: boolean;
  minAge?: number;
  careEntitlements: Entitlement[];
};
export const handleEntitlements = (args: EntitlementArgs) => {
  const entitlements = new Array<OrganizationEntitlementInput>();
  const { requiresId, minAge, careEntitlements } = args;

  if (requiresId) {
    entitlements.push({ key: Entitlement.OrgIdentification });
  }
  if (minAge) {
    entitlements.push({ key: Entitlement.CustomMinAge, config: { years: minAge } });
  }
  return entitlements.concat(careEntitlements.map(e => ({ key: e })));
};
