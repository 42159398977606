import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import { TabBodyProps } from '../PopoverTabs/PopoverTab';
import { FinalButton } from '../FinalButton';

interface ConfirmExitModalProps {
  tab: TabBodyProps;
}

export const ConfirmExitModal = ({ tab }: ConfirmExitModalProps) => {
  return (
    <Modal isOpen={tab.showConfirm} onClose={() => tab.cancelExit()}>
      <ModalHeader>
        <h4 className="f4 mb3">Close without submitting?</h4>
      </ModalHeader>
      <ModalBody>
        <p className="mb4">
          If you close without submitting, all the changes you have made will be lost.
        </p>
        <div className="flex items-center justify-around gap-3">
          <FinalButton kind="outline_black" onClick={() => tab.confirmExit()}>
            Discard Changes
          </FinalButton>
          <FinalButton kind="primary" onClick={() => tab.cancelExit()}>
            Return to Assessment
          </FinalButton>
        </div>
      </ModalBody>
    </Modal>
  );
};
