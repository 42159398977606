import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { FinalButton } from '../../Components/FinalButton';

type ConfirmCancelModalProps = {
  showConfirmModal: boolean;
  setShowConfirmModal: (value: boolean) => void;
  onDiscard: () => void;
  onConfirmCancel: () => void;
};

export const ConfirmCancelModal = ({
  showConfirmModal,
  setShowConfirmModal,
  onDiscard,
  onConfirmCancel,
}: ConfirmCancelModalProps) => {
  return (
    <Modal isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
      <ModalHeader>
        <h4 className="f4 mb3">Discard unsaved changes?</h4>
      </ModalHeader>
      <ModalBody>
        <p className="mb4">
          You have unsaved changes that will be lost if you continue. Are you sure you want to
          continue?
        </p>
        <div className="flex items-center justify-around gap-3">
          <FinalButton kind="danger" onClick={onDiscard} aria-label="Discard">
            Discard Changes
          </FinalButton>
          <FinalButton kind="white" onClick={onConfirmCancel} aria-label="Cancel">
            Cancel
          </FinalButton>
        </div>
      </ModalBody>
    </Modal>
  );
};
