import moment from 'moment-timezone';
import React from 'react';
import { UserRecordViewStatus } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Text } from '../styles';
import { Tooltip } from '../../../Components/Tooltip';

export function CollaborationStatus() {
  const { user, userView } = useDrilldownContext();

  const collaborationActive = userView.recordViewStatus !== UserRecordViewStatus.Restricted;
  const collaborationTooltip = collaborationActive
    ? 'This patient has consented to allowing their provider(s) to collaborate with their campus collaborators, and vice versa.'
    : "This patient has not consented to campus collaboration at this time. If for any reason you need to contact this patient's collaboration team, reach out to Mantra's care navigator.";

  let recordVisibility = '';
  let recordTooltip = '';
  if (userView.recordViewStatus === UserRecordViewStatus.Full) {
    recordVisibility = 'Full';
    recordTooltip =
      "This patient's campus collaborators can currently see all past patient records, including both university sponsored visits AND non-sponsored visits.";
    if (user.dataSharingConsent) {
      recordVisibility += ` (expires ${moment(user.dataSharingConsent?.endDate).format(
        'M/D/YYYY'
      )})`;
    }
  } else if (userView.recordViewStatus === UserRecordViewStatus.Limited) {
    recordVisibility = 'Limited';
    recordTooltip =
      "There may (or may not) be additional records from non-sponsored visits, e.g. non-sponsored visits during a campus break, that exist with Mantra Health, but aren't currently visible to this patient's collaboration team.";
    if (user.dataSharingRequest) {
      recordVisibility += ` (Pending Pt Consent)`;
      recordTooltip =
        "This patient's campus collaboration team has requested full access to this record, but the patient has not yet responded. This patient's collaboration team might only be able to see a select amount of these records until the patient grants full access.";
    }
  } else if (userView.recordViewStatus === UserRecordViewStatus.Restricted) {
    recordVisibility = 'Restricted';
    recordTooltip =
      "This patient's campus collaboration team does not have full access to this patient's ongoing record. They may, however, be able to see records that were shared by the patient in the past.";
  }

  return (
    <div>
      <CollaborationStatusRow tooltipCopy={collaborationTooltip}>
        <span className="b">Collaboration:</span> {collaborationActive ? 'Active' : 'Inactive'}
      </CollaborationStatusRow>
      <CollaborationStatusRow tooltipCopy={recordTooltip}>
        <span className="b">Record Visibility:</span> {recordVisibility}
      </CollaborationStatusRow>
    </div>
  );
}

function CollaborationStatusRow({
  children,
  tooltipCopy,
}: {
  children: React.ReactNode;
  tooltipCopy: string;
}) {
  return (
    <Text.bodySmall className="i flex items-center">
      <div>
        {children}{' '}
        <Tooltip content={tooltipCopy} style={{ display: 'inline', verticalAlign: 'middle' }} />
      </div>
    </Text.bodySmall>
  );
}
